import { AuthFunctions } from '@/api/auth/authClient'
import { InjectionKey, inject } from 'vue'

export const AUTH_FUNCTIONS_INJECTION_KEY = Symbol('authFunctions') as InjectionKey<AuthFunctions>

export function injectAuthFunctions(): AuthFunctions {
  const injected = inject(AUTH_FUNCTIONS_INJECTION_KEY)
  if (!injected) {
    throw new Error('Auth functions not provided')
  }
  return injected
}
