<template>
  <div class="bg-green1 ProgressBar">
    <div class="bg-grey1 ProgressBar__line" :style="{ width: reversedProgress }"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from '@vue/reactivity'
import { formatPercentage } from '../lib/number'

const props = defineProps<{ progress: number }>()

const reversedProgress = computed(() => {
  let validatedProgress = props.progress
  if (!validatedProgress) {
    validatedProgress = 0
  } else if (validatedProgress > 100) {
    validatedProgress = 100
  } else if (validatedProgress < 0) {
    validatedProgress = 0
  }
  return formatPercentage((100 - validatedProgress) / 100)
})
</script>

<style>
.ProgressBar {
  position: relative;
  border-radius: 4px;
}

.ProgressBar,
.ProgressBar__line {
  height: 8px;
}

.ProgressBar__line {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ProgressBar__line {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
