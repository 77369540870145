import { inject, reactive } from 'vue'

import { getTransactionListExport } from '@/api/graphql/graphqlAPI'
import {
  CARD_ID_DEFAULT_FILTER,
  LIMIT_DATE_RANGE_DAYS,
  TRANSACTION_EXPORT_STORE_INJECTION_KEY,
} from '@/constants'
import { addDays, formatDateIsoString, differenceInDays } from '@/lib/date'
import { TransactionExportFailedError } from '@/lib/domain/error/TransactionExportFailedError'
import { mapTransactionToCSVSerializeable } from '@/lib/domain/transaction'
import { download } from '@/lib/download'
import { consoleLogger, sentryLogger } from '@/lib/logger'
import { mapRangeValue, Range } from '@/lib/range'
import { toCSV } from '@/lib/string'
import { isNil } from '@/lib/type'
import { ITransactionPageData } from '@/service/useTransactions'
import { useAuthStore } from '@/store/auth'
import { tracker } from '@/api/segment/segment'
import { defaultToEmptyList, isEmpty } from '@/lib/list'
import { result as loadTransactionsResult } from '@/lib/result'
import { EmptyListError } from '@/lib/domain/error/EmptyListError'
import { DateRangeValidationError } from '@/lib/domain/error/DateRangeValidationError'

interface ITransactionExportData {
  pageInfo: ITransactionPageData
  filter: Maybe<ITransactionExportFilter>
}
interface ITransactionExportFilter {
  cardId: Maybe<string>
  date: Range<Date>
}

interface ITransactionExportOptions {
  onError?(): void
  onExported?(): void
}

export const useTransactionsExport = (
  transactionsExportOptions: ITransactionExportOptions = {}
) => {
  const controller = new AbortController()
  const data: ITransactionExportData = reactive({
    pageInfo: { cursor: null, hasNextPage: true },
    filter: null,
  })

  const exportCSVStore = inject(TRANSACTION_EXPORT_STORE_INJECTION_KEY)
  if (isNil(exportCSVStore)) {
    throw new Error('Transaction export store not provided')
  }

  interface IExportTransactionListOptions {
    cursor: Maybe<string>
    hasNext: boolean
  }

  /**
   * Load transaction event list with pending and cleared events.
   * Set result to the data store.
   * @param options - specify pagination cursor for each event type.
   * Specify if there is the next page should be loaded for each event type.
   * @returns - Promise<Maybe<Result>>
   */
  async function loadTransactionsWithAllEntries(
    options: IExportTransactionListOptions
  ): Promise<Result> {
    if (!data.filter) {
      return loadTransactionsResult.failed(
        new DateRangeValidationError('The date range not selected')
      )
    }
    const { cursor, hasNext } = options
    const { accountHolderId } = useAuthStore()
    if (!accountHolderId.value) {
      return loadTransactionsResult.failed(new Error('No account holder id'))
    }
    const result = await getTransactionListExport({
      accountHolderId: accountHolderId.value,
      cursor,
      hasNext,
      filter: {
        paymentCardId: data.filter.cardId === CARD_ID_DEFAULT_FILTER ? null : data.filter.cardId,
        // End date should be increased by 1 day so that it completely covers end date day
        date: mapRangeValue(formatDateIsoString, {
          from: data.filter.date.from,
          to: addDays(1, data.filter.date.to),
        }),
      },
      signal: controller.signal,
    })
    if (!result.ok) {
      return loadTransactionsResult.failed(
        new TransactionExportFailedError('Failed to load transactions', {
          originalError: result.error,
        })
      )
    }
    tracker.trackTransactionExportSuccess({ startedAt: exportCSVStore?.startedAt.value })
    exportCSVStore?.addPage(result.value)
    if (!result.value.transactionEvents?.pageInfo.hasNextPage) {
      return loadTransactionsResult.ok(true)
    }
    data.pageInfo.cursor = result.value.transactionEvents?.pageInfo.endCursor
    data.pageInfo.hasNextPage = Boolean(result.value.transactionEvents?.pageInfo.hasNextPage)

    await loadTransactionsWithAllEntries({
      cursor: data.pageInfo.cursor,
      hasNext: data.pageInfo.hasNextPage,
    })

    return loadTransactionsResult.ok(true)
  }

  /**
   * Load all transactions for the current filter
   */
  async function exportCSV(filter: ITransactionExportFilter) {
    const days = Math.abs(differenceInDays(filter.date.to, filter.date.from))
    if (days > LIMIT_DATE_RANGE_DAYS) {
      exportCSVStore?.setError(
        new DateRangeValidationError('The date range cannot exceed 3 months. Please try again.', {
          payload: {
            from: filter.date.from,
            to: filter.date.to,
          },
        })
      )
      openPopup()
      return
    }
    closePopup()
    exportCSVStore?.setLoading(true)
    exportCSVStore?.clearPages()
    data.filter = filter
    tracker.trackTransactionExportStart({ dateRange: filter.date })
    exportCSVStore?.setStartedAt()
    const result = await loadTransactionsWithAllEntries({ cursor: null, hasNext: true })
    exportCSVStore?.setLoading(false)

    if (!result.ok) {
      exportCSVStore?.clearPages()
      tracker.trackTransactionExportFailed({ startedAt: exportCSVStore?.startedAt.value })
      exportCSVStore?.setError(result.error)
      sentryLogger.error(result.error)
      if (transactionsExportOptions.onError) {
        transactionsExportOptions.onError()
      }
    } else {
      if (isEmpty(defaultToEmptyList(exportCSVStore?.itemList.value))) {
        exportCSVStore?.setError(
          new EmptyListError('There are no transactions within the selected time frame.')
        )
      }
      if (transactionsExportOptions.onExported) {
        transactionsExportOptions.onExported()
      }
    }
    openPopup()
  }

  function cancel() {
    controller.abort()
    exportCSVStore?.setLoading(false)
  }

  function openPopup() {
    exportCSVStore?.setPopupOpened(true)
  }

  function closePopup() {
    exportCSVStore?.setPopupOpened(false)
    exportCSVStore?.setError(null)
  }

  function handleDownload() {
    closePopup()
    const content = exportCSVStore?.itemList.value.map((item) =>
      mapTransactionToCSVSerializeable(item)
    )
    const serialised = toCSV(content)
    if (!serialised.ok) {
      exportCSVStore?.setError(serialised.error)
      openPopup()
      consoleLogger.error(serialised.error)
      sentryLogger.error(serialised.error)
      return
    }
    const downloadResult = download(serialised.value, {
      name: 'transactions',
      ext: 'csv',
      type: 'text/csv',
    })
    if (!downloadResult.ok) {
      exportCSVStore?.setError(downloadResult.error)
      sentryLogger.error(downloadResult.error)
      openPopup()
    }
  }

  return {
    loading: exportCSVStore.loading,
    error: exportCSVStore.error,
    popupOpened: exportCSVStore.popupOpened,
    exportCSV,
    cancel,
    closePopup,
    download: handleDownload,
  }
}
