import { replace, toPascalCase } from '@/lib/string'

import { DocumentCategoryType, DocumentType, DocumentUploadLink } from '@/generated/graphql'
import { filter, head } from '@/lib/list'
import { not } from '@/lib/logic'

export type ApplicationDocumentFormState = 'initial' | 'selected' | 'uploaded'
export type DocumentFormCategory = 'primary'
export type IApplicationDocumentFormValue = Record<DocumentFormCategory, IDocumentCategoryForm>

export interface IDocumentCategoryForm {
  documentList: DocumentType[]
  values: IDocumentFormValue[]
}
export interface IDocumentFormValue {
  id: string
  type: Maybe<DocumentType>
  file: Maybe<File>
  uploading: boolean
  error: Maybe<string>
  state: ApplicationDocumentFormState
}
export type DocumentFormPartialValue = { id: string } & Partial<IDocumentFormValue>

export const createFormValue = (): IApplicationDocumentFormValue => ({
  primary: {
    documentList: [],
    values: [
      {
        id: 'p1',
        type: null,
        file: null,
        uploading: false,
        error: null,
        state: 'initial',
      },
    ],
  },
})

export const setPrimaryUploadedDocument = (
  uploadedDocument: Maybe<DocumentUploadLink>,
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => {
  if (!uploadedDocument || !isPrimaryDocument(uploadedDocument)) {
    return formValue
  }
  const value = head(formValue.primary.values)
  if (!value) {
    return formValue
  }
  return setValue(
    {
      id: value.id,
      state: 'uploaded',
      type: value.type || uploadedDocument.documentType,
    },
    formValue
  )
}

export const isPrimaryDocument = (doc: DocumentUploadLink) =>
  doc.documentCategoryType === DocumentCategoryType.PrimaryDocumentType

export const setPrimaryDocumentList = (
  list: DocumentType[],
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => {
  const ordered = list.sort(documentTypeListComparator)
  return setDocumentList('primary', ordered, formValue)
}

export const isPrimaryFormValid = (formValue: IApplicationDocumentFormValue): boolean =>
  formValue.primary.values.every((value) => !value.error)

export const setFormError = (
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => {
  let newVal = { ...formValue }

  formValue.primary.values.forEach((value) => {
    newVal = setError(value.id, getFormValueError(value), newVal)
  })
  return newVal
}

export const validate = (formValue: IApplicationDocumentFormValue): boolean => {
  return isPrimaryFormValid(formValue)
}

export const setValue = (
  newPartialValue: DocumentFormPartialValue,
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => {
  const category = mapValueToCategory(newPartialValue, formValue)
  return updateFormCategoryValue(category, newPartialValue, formValue)
}

export const setUploading = (
  id: string,
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => setValue({ id, uploading: true, error: null }, formValue)

export const setUploadingFailed = (
  id: string,
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue =>
  setValue(
    {
      id,
      uploading: false,
      state: 'selected',
      error: 'Failed to upload document. Try again.',
    },
    formValue
  )

export const setUploaded = (
  id: string,
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => setValue({ id, uploading: false, state: 'uploaded' }, formValue)

export const clearAllValues = (
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => {
  return getAllValues(formValue).reduce((acum, value) => {
    return setValue(
      {
        id: value.id,
        type: null,
        file: null,
        uploading: false,
        state: 'initial',
        error: null,
      },
      acum
    )
  }, formValue)
}

const getAllValues = (formValue: IApplicationDocumentFormValue): IDocumentFormValue[] => [
  ...formValue.primary.values,
]

const mapValueToCategory = (
  newValue: DocumentFormPartialValue,
  formValue: IApplicationDocumentFormValue
): DocumentFormCategory => {
  const isPrimary = formValue.primary.values.map((v) => v.id).includes(newValue.id)
  if (isPrimary) {
    return 'primary'
  }
  return 'primary'
}

const updateFormCategoryValue = (
  category: DocumentFormCategory,
  newValue: DocumentFormPartialValue,
  formValue: IApplicationDocumentFormValue
) => {
  return {
    ...formValue,
    [category]: {
      ...formValue[category],
      values: updateItemById(newValue, formValue[category].values),
    },
  }
}

const updateItemById = (newValue: DocumentFormPartialValue, list: IDocumentFormValue[]) => {
  return list.map((item: IDocumentFormValue) => {
    if (item.id === newValue.id) {
      return { ...item, ...newValue }
    }
    return item
  })
}

const setDocumentList = (
  category: DocumentFormCategory,
  list: DocumentType[],
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => {
  return {
    ...formValue,
    [category]: { ...formValue[category], documentList: list },
  }
}

const setError = (
  id: string,
  error: Maybe<string>,
  formValue: IApplicationDocumentFormValue
): IApplicationDocumentFormValue => setValue({ id, error }, formValue)

const getFormValueError = (value: IDocumentFormValue): Maybe<string> => {
  if (!value.type) {
    return '(Required)'
  }
  if (!value.file && value.state === 'initial') {
    return 'Document required'
  }
  if (value.state !== 'uploaded') {
    return 'Please upload documents before submitting'
  }
  return null
}

const documentTypePriorityIndex = (type: DocumentType): number => {
  switch (type) {
    case DocumentType.FederalEmployerIdentificationNumber:
      return 4
    case DocumentType.SoleProprietorSocialSecurityCard:
      return 3
    case DocumentType.NonProfit_501C3:
      return 2
    case DocumentType.ArticlesOfIncorporation:
      return 1
    default:
      return 0
  }
}
const documentTypeListComparator = (a: DocumentType, b: DocumentType): number => {
  if (documentTypePriorityIndex(a) > documentTypePriorityIndex(b)) {
    return -1
  }
  if (documentTypePriorityIndex(a) < documentTypePriorityIndex(b)) {
    return 1
  }
  return 0
}

export const humanizeDocumentType = (val: DocumentType): string => {
  const pascalized = toPascalCase(replace(/_/g, ' ', val).toLowerCase())
  return pascalized
    .split(' ')
    .map((word) => {
      switch (word) {
        case 'Us':
          return 'US'
        case 'Id':
          return 'ID'
        default:
          return word
      }
    })
    .join(' ')
}

export const filterAllowedDocumentTypes = (list: DocumentType[]) =>
  filter(
    (item: DocumentType) =>
      not([DocumentType.PrisonId, DocumentType.PrisonReleasePaperwork].includes(item)),
    list
  )
