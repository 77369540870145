import { inject, InjectionKey, provide } from 'vue'

import { storage } from '@/api/storage/storage'
import { BaseError } from '@/lib/domain/error/BaseError'

export const STORAGE_INJECTION_KEY = Symbol('Client storage key') as InjectionKey<typeof storage>

export const provideStorage = () => {
  provide(STORAGE_INJECTION_KEY, storage)
}

export const injectStorage = () => {
  const stor = inject(STORAGE_INJECTION_KEY)
  if (!stor) {
    throw new BaseError('Storage key is not provided.')
  }
  return stor
}
