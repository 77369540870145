import { computed, reactive } from '@vue/reactivity'

const errorStore = reactive({
  applicationData: false,
})

export const setApplicationDataFailed = () => {
  errorStore.applicationData = true
}

export const useErrorStore = () => {
  return {
    applicationDataError: computed(() => errorStore.applicationData),
  }
}
