<template>
  <a :href="mailto" target="_blank" class="fs-14 fw-500 c-green1">{{ text }}</a>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from '@vue/reactivity'

const props = withDefaults(
  defineProps<{
    email?: string
    text?: string
  }>(),
  {
    email: '',
    text: '',
  }
)
const mailto: ComputedRef<string> = computed(() => 'mailto:'.concat(props.email))
</script>
