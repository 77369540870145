<template>
  <div class="flex-center flex-direction-column">
    <div
      class="flex-center mb-32"
      style="width: 56px; height: 56px; border-radius: 50%; border: solid 2px #16a34a"
    >
      <Icon name="check" class="c-green2 fs-16" />
    </div>
    <h2 class="fs-20 fw-bold c-black text-center mb-24">Autopay enabled</h2>
    <p class="fs-14 c-grey3 text-center mb-40">
      This is to confirm that you have authorized Tillful (owned by Tillco Holdings, LLC) to
      initiate a recurring electronic payment on the statement date in the amount indicated on your
      monthly statement from your {{ account?.accountDetails.name }} account (ending
      {{ account?.accountDetails.mask }}).
    </p>

    <div class="w-100 d-flex justify-between mb-8">
      <div class="fs-12 c-black1">Payment Confirmation Number</div>
      <div class="fs-12 fw-600 c-black1">
        <span class="mr-8">{{ shortId }}...</span>
        <Icon
          name="copy"
          title="copy to clipboard"
          class="cursor-pointer"
          @click="handleCopyToClipboard(paymentResult.id, $event)"
        />
      </div>
    </div>

    <div class="w-100 d-flex justify-between mb-8">
      <div class="fs-12 c-black1">Authorized Payment Amount</div>
      <div class="fs-12 fw-600 c-black1 text-right">Statement Balance in Full</div>
    </div>

    <div class="w-100 d-flex justify-between mb-8">
      <div class="fs-12 c-black1">Date Authorized</div>
      <div class="fs-12 fw-600 c-black1 text-right">{{ paymentResult.date }}</div>
    </div>

    <div class="w-100 d-flex justify-between mb-40">
      <div class="fs-12 c-black1">Next Scheduled Payment Date</div>
      <div class="fs-12 fw-600 c-black1 text-right">{{ paymentResult.nextDate }}</div>
    </div>

    <Button variant="primary" size="large" class="w-100" @click="$emit('onClose')"> Done </Button>

    <Popup :opened="popupOpened" :target="popupTargetRef">{{ popupContent }}</Popup>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import Popup from '@/components/Popup.vue'

import { defaultToEmptyString } from '@/lib/string'
import { useCopyClipboard } from '@/lib/composable/useCopyClipboard'
import { IPaymentAccount } from '@/lib/domain/types'

const props = defineProps<{
  account?: Maybe<IPaymentAccount>
  paymentResult: {
    id: Maybe<string>
    date: Maybe<string>
    nextDate: Maybe<string>
  }
}>()
defineEmits(['onClose'])
const shortId = computed<string>(() => defaultToEmptyString(props.paymentResult.id).slice(0, 6))
const { showCopyClipboard, popupOpened, popupTargetRef, popupContent } = useCopyClipboard()
const handleCopyToClipboard = async (id: Maybe<string>, e: Event) => {
  if (!id) {
    return
  }
  // copyToClipboard(value)
  showCopyClipboard({
    copiedInfo: id,
    htmlTargetElement: e.target as HTMLElement,
  })
}
</script>
