import { Plugin, ref } from 'vue'
import { consoleLogger } from '@/lib/logger'
import { result } from '@/lib/result'
import { captureException } from '@/api/sentry/sentry'
import { loadHubSpotScripts, createHubSpotConfig } from '@/plugins/hubspot/utils'
import { IHubSpotOptions, IHubSpotCreateForm } from '@/plugins/hubspot/types'

const createHubSpot = async (options: IHubSpotOptions) => {
  await loadHubSpotScripts()
  if (!(window as any).hbspt) {
    throw new Error('The HubSpot form was not loaded on the page')
  }

  const sdk = (window as any).hbspt

  sdk.createForm = ({ selector, onSubmit }: IHubSpotCreateForm): Result => {
    try {
      sdk.forms.create(
        createHubSpotConfig({
          ...options,
          selector,
          onSubmit,
        })
      )
      return result.ok(undefined)
    } catch (e) {
      captureException(e)
      consoleLogger.error(e)
      return result.failed(new Error('[TC] Failed to embed Hubspot form'))
    }
  }

  return sdk
}

const hubSpot: any = ref(null)

export const HubSpot: Plugin = {
  install: async (app, options: IHubSpotOptions) => {
    hubSpot.value = await createHubSpot(options)

    Object.defineProperty(app.config.globalProperties, '$hubSpotForm', {
      get() {
        return hubSpot.value
      },
    })
  },
}

export const useHubSpot = () => {
  if (!hubSpot.value) {
    throw new Error('Plugin must be installed before used it')
  }
  return hubSpot.value
}
