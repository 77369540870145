<template>
  <a :href="href" target="_blank" class="fs-14 fw-500 c-green1">
    <slot />
  </a>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from '@vue/reactivity'

const props = withDefaults(
  defineProps<{
    value?: string
  }>(),
  {
    value: '',
  }
)
const href: ComputedRef<string> = computed(() => 'tel:'.concat(props.value))
</script>
