<template>
  <div class="TransactionFilter d-flex justify-between align-center">
    <div class="TransactionFilter__header d-flex align-center">
      <h2 class="fs-20 fw-bold c-black mr-16">Transactions</h2>
      <Dropdown
        :disabled="false"
        :modelValue="value.cardId"
        class="PaymentCardDropdown fs-14 c-grey2"
        :placeholder="''"
        :options="options"
        :clearable="false"
        :searchable="false"
        :get-option-label="humanizePaymentCard"
        @option:selected="handlePaymentCardSelected"
      />
    </div>

    <div class="TransactionFilter__range d-flex justify-between align-center">
      <ButtonExport
        :loading="exportLoading"
        @onExportCSV="emit('onExportCSV')"
        @onExportCSVCancel="emit('onExportCSVCancel')"
      />
      <FormDateRangeFilter class="ml-16" :value="data.date" :max="maxDate" @change="handleChange" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from 'vue'
import Dropdown from 'vue-select'

import { createDate, formatDateIsoString, today } from '@/lib/date'
import { createRange, mapRangeValue, Range } from '@/lib/range'
import FormDateRangeFilter from '../FormDateRangeFilter.vue'
import ButtonExport from '@/components/ButtonExport.vue'
import { CARD_ID_DEFAULT_FILTER } from '@/constants'
import { CardFormFactor } from '@/generated/graphql'
import { capitalizeWord } from '@/lib/string'
import { usePaymentCards } from '@/service/usePaymentCards'
import { useTransactionsExport } from '@/service/useTransactionsExport'

// input and output format for date input element value is yyyy-mm-dd

const emit = defineEmits(['change', 'onExportCSV', 'onExportCSVCancel'])

const props = defineProps<{
  value: {
    cardId: Maybe<string>
    date: Range<Date>
  }
}>()

const {
  store: { cardList },
} = usePaymentCards()

const { loading: exportLoading } = useTransactionsExport()

const options: ComputedRef<Maybe<string>[]> = computed(() => [
  CARD_ID_DEFAULT_FILTER,
  ...cardList.value.map((card) => card.id),
])

const data: ComputedRef<{ date: Range<string> }> = computed(() => {
  return {
    date: createRange(
      formatDateIsoString(props.value.date.from),
      formatDateIsoString(props.value.date.to)
    ),
  }
})

const maxDate = formatDateIsoString(today())

const handleChange = (dateRange: Range<string>): void => {
  emit('change', { cardId: props.value.cardId, date: mapRangeValue(createDate, dateRange) })
}

const handlePaymentCardSelected = (id: Maybe<string>) => {
  emit('change', { cardId: id, date: props.value.date })
}

const humanizePaymentCard = (cardId: Maybe<string>): string => {
  if (!cardId || cardId === CARD_ID_DEFAULT_FILTER) {
    return 'All Cards'
  }
  const found = cardList.value.find((card) => card.id === cardId)
  if (!found) {
    return 'All Cards'
  }
  const formFactor = capitalizeWord(
    ((found.formFactor as Maybe<CardFormFactor>) || '').toLocaleLowerCase()
  )
  return `${formFactor} Card (${found.last4})`
}
</script>

<style>
.PaymentCardDropdown {
  width: 230px;
}
.PaymentCardDropdown .vs__dropdown-toggle {
  height: 40px;
}
.PaymentCardDropdown .vs__dropdown-toggle .vs__selected,
.PaymentCardDropdown .vs__dropdown-toggle .vs__search {
  height: 30px;
}
@media all and (max-width: 992px) {
  .TransactionFilter,
  .TransactionFilter__range,
  .TransactionFilter__header {
    flex-wrap: wrap;
    row-gap: 12px;
  }
  .TransactionFilter__header {
    align-items: flex-start;
    flex-direction: column;
  }
  .TransactionFilter__range {
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
