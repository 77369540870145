import { CardFormFactor, PaymentCardOrderStatus, PaymentCardStatus } from '@/generated/graphql'

export interface IPaymentView {
  id: string
  initiatedDate: string
  initiatedDateShort: string
  initiatedTime: string
  updatedDate: string
  updatedDateShort: string
  updatedTime: string
  status: string
  statusColor: string
  details: string
  accountMask: string
  amount: string
}

interface IPlaidMetaInstitution {
  name: string
  institution_id: string
}

interface IPlaidMetaAccount {
  id?: Maybe<string>
  name?: Maybe<string>
  mask?: Maybe<string>
  type?: Maybe<string>
  subtype?: Maybe<string>
}

export interface IPlaidMeta {
  account_id: Maybe<string>
  account: IPlaidMetaAccount
  institution: IPlaidMetaInstitution
  accounts: IPlaidMetaAccount[]
  public_token: string
}

export enum ApplicationDocumentType {
  INCORPORATION = 'INCORPORATION',
  NON_PROFITS = 'NON_PROFITS',
  FEIN = 'FEIN',
}

export interface IPaymentCardView {
  id: string
  last4: string
  date: string
  cvv: string
  status: PaymentCardStatus
}

export interface IPaymentCard {
  id: string
  status: PaymentCardStatus
  last4: string
  formFactor: CardFormFactor
  orderStatusList: PaymentCardOrderStatus[]
}

export type PaymentCardStatusFilterValue =
  | PaymentCardStatus.Active
  | PaymentCardStatus.Closed
  | PaymentCardStatus.Suspended
export type PaymentCardFilterValue = Record<PaymentCardStatusFilterValue, boolean>

export interface IFormCheckboxListValueItem {
  key: string
  value: boolean
  label: Maybe<string>
}

export interface IAccountDetails {
  availableBalance?: Maybe<number>
  currentBalance?: Maybe<number>
  mask?: Maybe<string>
  name: string
  type?: Maybe<string>
}

export interface IFinancialInstitution {
  institutionId?: Maybe<string>
  logo?: Maybe<string>
  name?: Maybe<string>
}

export interface IPaymentAccount {
  accountDetails: IAccountDetails
  accountHolderId: string
  createdAt: string
  externalFinancialBankAccountId?: Maybe<string>
  id?: Maybe<string>
  institution: IFinancialInstitution
  updatedAt: string
}

export interface IModalActionItem {
  icon: IconName
  title: string
  action: CardListMenuAction
}

export type IconName =
  | 'home'
  | 'settings'
  | 'star'
  | 'chevron-up'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'shopping-cart'
  | 'menu'
  | 'log-out'
  | 'x'
  | 'check'
  | 'alert-circle'
  | 'alert-triangle'
  | 'credit-card'
  | 'eye'
  | 'eye-off'
  | 'help-circle'
  | 'arrow-left'
  | 'lock'
  | 'unlock'
  | 'trash'
  | 'life-buoy'
  | 'file-text'
  | 'clock'
  | 'bank'
  | 'plus'
  | 'plus-circle'
  | 'payment'
  | 'external-link'
  | 'copy'
  | 'tillful-logo'
  | 'one-password-logo'
  | 'export'

export enum CardListMenuAction {
  Lock = 'lock',
  Unlock = 'unlock',
  ShowDetails = 'show details',
  HideDetails = 'hide details',
  SaveInOP = 'save in one password',
}
