<template>
  <Box class="TransactionList">
    <TransactionFilter
      :value="filterValue"
      @change="handleFilterChange"
      @onExportCSV="handlerExportCSV"
      @onExportCSVCancel="handlerExportCSVCancel"
    />
    <Divider class="TransactionList__divider" />

    <Loader v-if="loading" style="margin: 0 auto" />
    <TransactionListEmpty v-else-if="listEmpty" />

    <div v-else>
      <table class="w-100" style="border-collapse: separate; border-spacing: 0 24px">
        <thead hidden>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
        </thead>

        <tbody v-if="tablet">
          <tr v-for="(item, index) of listView" :key="item.transaction.id">
            <td v-if="item.type === 'DateDivider'" colspan="5">
              <Divider v-if="index !== 0" class="TransactionList__divider" />
              <div class="TransactionListItem-date fs-12 fw-600 c-grey4">{{ item.date }}</div>
            </td>
            <TransactionListItemView v-if="item.type === 'Transaction'" :value="item.transaction" />
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) of listView" :key="item.transaction.id">
            <td v-if="item.type === 'DateDivider'" colspan="5">
              <Divider v-if="index !== 0" class="TransactionList__divider" />
              <div class="TransactionListItem-date fs-12 fw-600 c-grey4">{{ item.date }}</div>
            </td>
            <TransactionListItemMobileView
              v-if="item.type === 'Transaction'"
              :value="item.transaction"
            />
          </tr>
        </tbody>
      </table>

      <div v-if="loadMoreAvailable" class="text-center">
        <div v-if="loadMoreLoading">
          <Loader class="d-inline-block" />
        </div>
        <div
          v-else
          class="Pagination fs-14 fw-500 c-green2 d-inline-block cursor-pointer"
          @click="handleLoadMoreClick"
        >
          <Icon name="arrow-left" class="d-inline-block mr-8" style="transform: rotate(-90deg)" />
          <span class="Pagination__page">Load More</span>
        </div>
      </div>
    </div>
  </Box>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted } from 'vue'
import { useBreakpoint } from 'vue-composable'

import Box from '../Box.vue'
import TransactionFilter from './TransactionFilter.vue'
import Divider from '../Divider.vue'
import { ITransactionView, useTransactions } from '@/service/useTransactions'
import Loader from '@/components/Loader.vue'
import TransactionListEmpty from '@/components/dashboard/TransactionListEmpty.vue'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { isEmpty, last } from '@/lib/list'
import { formatDateMonthShort } from '@/lib/date'
import TransactionListItemView from './TransactionListItemView.vue'
import TransactionListItemMobileView from './TransactionListItemMobileView.vue'
import Icon from '../Icon.vue'
import { Range } from '@/lib/range'
import { useTransactionsExport } from '@/service/useTransactionsExport'

interface ITypedTransactionView {
  type: 'DateDivider' | 'Transaction'
  date: string
  transaction: ITransactionView
}

useMountedLog('TransactionList')

const { tablet } = useBreakpoint({ tablet: 768 })

const {
  list,
  loading,
  loadMore,
  loadMoreLoading,
  loadMoreAvailable,
  setFilter,
  reLoad,
  filter: filterValue,
} = useTransactions()
const { exportCSV, cancel } = useTransactionsExport()

onMounted(reLoad)

const listEmpty: ComputedRef<boolean> = computed(() => isEmpty(list.value))

/**
 * Insert date rows in sorted list of transactions in order
 * to simplify rendering logic
 */
const listView: ComputedRef<ITypedTransactionView[]> = computed(() => {
  return list.value.reduce((sum, next) => {
    const dateDividerItem = createTypedTransactionItem('DateDivider', next)
    const transactionItem = createTypedTransactionItem('Transaction', next)
    if (isEmpty(sum)) {
      return [dateDividerItem, transactionItem]
    }
    const lastItem = last(sum) as NonNullable<ITypedTransactionView>
    const dateDifferent =
      formatDateMonthShort(new Date(lastItem.transaction.createdAt || '')) !== transactionItem.date
    if (dateDifferent) {
      return [...sum, dateDividerItem, transactionItem]
    }
    return [...sum, transactionItem]
  }, [] as ITypedTransactionView[])
})

const handleLoadMoreClick = () => loadMore()

const handleFilterChange = (value: { cardId: Maybe<string>; date: Range<Date> }) => {
  setFilter(value)
  reLoad()
}

function createTypedTransactionItem(
  type: ITypedTransactionView['type'],
  transaction: ITransactionView
): ITypedTransactionView {
  return {
    type,
    date: formatDateMonthShort(new Date(transaction.createdAt || '')),
    transaction,
  }
}

const handlerExportCSV = () => {
  exportCSV(filterValue.value)
}
const handlerExportCSVCancel = () => {
  cancel()
}
</script>

<style>
.TransactionList {
  padding: 24px;
}
.TransactionList__divider {
  margin-left: -32px;
  margin-right: -32px;
}
</style>
