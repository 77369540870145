<template>
  <div class="MaintenancePage" v-if="show">
    <Message class="MaintenancePage__message" variant="warning" :dismissable="false">
      <span class="MaintenancePage__message__text">{{ BannerConfig.maintenance.content }}</span>
    </Message>
  </div>

  <slot v-else />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import BannerConfig from '@/config/banner.json'
import Message from '@/components/Message.vue'
import { now } from '@/lib/date'
import { isBetween } from '@/lib/number'

const show = ref(false)

const { startAt, endAt } = BannerConfig.maintenance

onMounted(initBanner)

function initBanner() {
  const nowSeconds = now().getTime() / 1000
  const inTimeframe = isBetween(nowSeconds, startAt, endAt)
  show.value = inTimeframe
}
</script>

<style>
.MaintenancePage {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MaintenancePage .MaintenancePage__message {
  padding: 32px;
  margin: 32px;
}
.MaintenancePage .MaintenancePage__message .MaintenancePage__message__text {
  font-size: 32px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .MaintenancePage .MaintenancePage__message .MaintenancePage__message__text {
    font-size: 24px;
  }
}
</style>
