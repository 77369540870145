import { graphqlType } from '@/api/graphql/graphqlType'
import {
  IntegratorInitiatedAchStatus,
  OneTimeAchTransfer,
  RecurringAchTransfer,
  ScheduledTransferStatusCode,
} from '@/generated/graphql'
import { complement, both } from '@/lib/function'

export const getInitialDepostiStatusDescription = (status: Maybe<IntegratorInitiatedAchStatus>) => {
  switch (status) {
    case IntegratorInitiatedAchStatus.Canceled:
      return 'Your deposit was canceled. Please initiate a new deposit again.'
    case IntegratorInitiatedAchStatus.Failed:
      return 'Your deposit has failed. Please initiate a new deposit again.'
    case IntegratorInitiatedAchStatus.Returned:
      return 'Your deposit was returned. Please initiate a new deposit again.'
    default:
      return 'This secures your credit line, and should take 3-5 business days to clear.'
  }
}

export const isTransferCanceled = (transfer: OneTimeAchTransfer | RecurringAchTransfer) =>
  transfer.status === ScheduledTransferStatusCode.Canceled

export const isScheduledAutopay = both(
  graphqlType.isRecurringTransfer,
  complement(isTransferCanceled)
)
