interface IResultCreator {
  ok: <R = any, E = Error>(value: R) => Result<R, E>
  failed: <R = any, E = Error>(error: E) => Result<R, E>
}

export const result: IResultCreator = {
  ok: (value) => ({ ok: true, value }),
  failed: (error) => ({ ok: false, error }),
}

type ResultFailedCallback<E, T> = (error: E) => T

type ResultOkCallback<R, T> = (value: R) => T

/**
 * Call callback depending on Result.ok value
 *
 * @param failedCallback - run if result has { ok: false }
 * @param okCallback - run if result has { ok: true }
 * @param result - result to wrap
 * @returns - return value from failed / ok callbacks (porbably wrong decision because it will be hard to compose pipe for results, should return wrapped Result instead of unwrapped value)
 */
export const resultEither = <R, E, Tok = any, Tfailed = any>(
  failedCallback: ResultFailedCallback<E, Tfailed>,
  okCallback: ResultOkCallback<R, Tok>,
  result: Result<R, E>
) => {
  if (result.ok) {
    return okCallback(result.value)
  }
  return failedCallback(result.error)
}
