<template>
  <ApplicationStatusShell>
    <template v-if="statusLoaded">
      <ApplicationDocuments
        v-if="showDocumentsPage"
        class="ApplicationDocuments"
        :accountHolderId="accountHolderId"
      />
      <ApplicationStatusContent
        v-else
        class="ApplicationStatusContent"
        :invalid="applicationInvalid"
        :status="status"
        :documentsNotSubmitted="allPendingInReview && sessionListEmpty"
      />
    </template>
    <template v-else>
      <LoaderFullscreen class="LoaderFullscreen__loader" />
    </template>
  </ApplicationStatusShell>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, ComputedRef, Ref, ref, watchEffect } from 'vue'

import ApplicationStatusShell from '@/components/cardApplication/ApplicationStatusShell.vue'
import ApplicationDocuments from '@/components/cardApplication/ApplicationDocuments.vue'
import ApplicationStatusContent from '@/components/cardApplication/ApplicationStatusContent.vue'
import LoaderFullscreen from '@/components/LoaderFullscreen.vue'

import { useMountedLog } from '@/lib/composable/useMountedLog'
import { AccountHolderApplicationStatusCode } from '@/generated/graphql'
import { useCardApplicationDocuments } from '@/lib/composable/useApplicationDocuments'
import { isEmpty } from '@/lib/list'

interface ICardApplicationUrlQuery {
  ah?: string
}

useMountedLog('Application')
const {
  loadCardApplication,
  allSessionsSubmitted,
  allPendingInReview,
  uploadSessionList,
  cardApplication,
} = useCardApplicationDocuments()

const { query } = useRoute()
const sessionListEmpty = computed<boolean>(() => isEmpty(uploadSessionList.value))
const statusLoaded: Ref<boolean> = ref(false)
const status = computed<Maybe<AccountHolderApplicationStatusCode>>(
  () => cardApplication.value?.applicationState?.status
)
const applicationInvalid: Ref<boolean> = ref(false)
const showDocumentsPage = computed<boolean>(() =>
  [!applicationInvalid.value, !allSessionsSubmitted.value].every(Boolean)
)
const accountHolderId: ComputedRef<Maybe<string>> = computed(() => {
  try {
    const encoded = (query as ICardApplicationUrlQuery).ah
    return encoded ? atob(encoded) : null
  } catch (e) {
    return null
  }
})

watchEffect(async () => {
  if (!accountHolderId.value) {
    applicationInvalid.value = true
    statusLoaded.value = true
    return
  }
  const res = await loadCardApplication(accountHolderId.value)
  statusLoaded.value = true
  if (!res.ok) {
    applicationInvalid.value = true
    return
  }
})
</script>
