<template>
  <article class="PageContent bg-grey" :style="{ height: contentHeight }">
    <div class="PageContent__header">
      <PageMenuMobile v-if="showMobileMenu" />
      <h1 v-if="name" class="c-black fs-20 fw-bold d-flex justify-between">
        <span class="PageContent__header__text">{{ name }}</span>
        <slot name="header-right" />
      </h1>
    </div>
    <MessageDataError v-if="applicationDataError" style="flex-shrink: 0; margin-bottom: 24px" />

    <div class="f-grow1">
      <slot />
    </div>

    <PageFooter />
  </article>
</template>

<script setup lang="ts">
import PageMenuMobile from './PageMenuMobile.vue'
import MessageDataError from './MessageDataError.vue'
import { useErrorStore } from '../store/error'
import PageFooter from './PageFooter.vue'
import { computed } from 'vue'
import { injectDomProperties } from '@/provider/domPropertiesProvider'

withDefaults(
  defineProps<{
    name?: string
    showMobileMenu?: boolean
  }>(),
  {
    showMobileMenu: true,
  }
)

const { applicationDataError } = useErrorStore()
const $domProperties = injectDomProperties()

const contentHeight = computed(() => {
  return `calc(100vh - ${$domProperties.state.value.maintenanceMessageHeight}px)`
})
</script>

<style>
.PageContent {
  display: flex;
  flex-direction: column;

  padding: 0px 56px 56px 56px;

  overflow-y: auto;

  height: 100vh;
}
.PageFooter {
  padding-top: 16px;
}
.PageContent h1 {
  line-height: 96px;
}
.PageContent .PageMenuMobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .PageContent {
    display: block;
    padding: 0 24px;
    background-color: var(--bg-grey3);
  }
  .PageContent .PageContent__header {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--bg-grey3);
  }
  .PageContent .PageContent__header h1 {
    flex-grow: 1;
    text-align: center;
  }
  .PageContent .PageContent__header h1 .PageContent__header__text {
    flex-grow: 1;
  }
  .PageContent .PageContent__header .PageMenuMobile {
    display: initial;
  }
}
</style>
