<template>
  <LoaderFullscreen />
</template>

<script setup lang="ts">
import { onMounted } from '@vue/runtime-core'

import LoaderFullscreen from '@/components/LoaderFullscreen.vue'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'

const $auth = injectAuthFunctions()

useMountedLog('Login')

onMounted(async () => {
  await $auth.login()
})
</script>
