import { computed, reactive } from 'vue'

import { activatePaymentCard } from '@/api/graphql/graphqlAPI'
import { usePaymentCards } from '@/service/usePaymentCards'
import { useAccountHolder } from '@/store/accountHolder'

const unlockCardModalState = reactive<{
  opened: boolean
  loading: boolean
  failed: boolean
  card?: { last4: string; id: string }
}>({
  opened: false,
  loading: false,
  failed: false,
  card: undefined,
})

export const useUnlockCardModal = () => {
  const { store } = usePaymentCards()
  const { id: accountHolderId } = useAccountHolder()
  const handleOpen = (card: { last4: string; id: string }) => {
    unlockCardModalState.opened = true
    unlockCardModalState.card = card
  }

  const handleClose = () => {
    unlockCardModalState.opened = false
    unlockCardModalState.loading = false
    unlockCardModalState.failed = false
    unlockCardModalState.card = undefined
  }

  const handleLock = async () => {
    unlockCardModalState.failed = false
    unlockCardModalState.loading = true
    if (!unlockCardModalState.card) {
      unlockCardModalState.failed = true
      return
    }
    const res = await activatePaymentCard(accountHolderId.value, unlockCardModalState.card.id)
    unlockCardModalState.loading = false
    if (!res.ok) {
      unlockCardModalState.failed = true
      return
    }
    store.loadCardList()
    handleClose()
  }

  return {
    opened: computed(() => unlockCardModalState.opened),
    loading: computed(() => unlockCardModalState.loading),
    failed: computed(() => unlockCardModalState.failed),
    cardLast4: computed(() => unlockCardModalState.card?.last4),
    open: handleOpen,
    close: handleClose,
    lock: handleLock,
  }
}
