<template>
  <div class="Dropdown">
    <div
      class="Dropdown__head d-flex justify-between cursor-pointer align-center"
      ref="dropdownHeadRef"
      @click="handleToggle"
    >
      <div class="Dropdown__value" @click="handleToggle">
        <slot name="text" v-bind="{ value }" />
      </div>
      <Icon name="chevron-down" class="fs-10" />
    </div>

    <Popup
      :opened="localState.opened"
      :target="dropdownHeadRef"
      :showArrow="false"
      :style="popupStyle"
      position="bottom"
    >
      <ul class="Dropdown__menu discard-menu">
        <li
          class="Dropdown__menu__item cursor-pointer mb-8"
          v-for="item of items"
          :key="item"
          @click="handleItemClick(item)"
        >
          <slot v-if="itemSlotAvailable" name="item" v-bind="{ item }" />
          <template v-else>{{ item }}</template>
        </li>
      </ul>
    </Popup>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, useSlots } from 'vue'

import Icon from '@/components/Icon.vue'
import Popup from '@/components/Popup.vue'
import { formatPx } from '@/lib/number'
import { notNil } from '@/lib/type'

defineProps<{
  value?: Maybe<string>
  items?: string[]
}>()
const emit = defineEmits<{
  (name: 'onSelect', value: string): void
}>()

const slots = useSlots()
const itemSlotAvailable = computed<boolean>(() => notNil(slots['item']))
const localState = reactive({ opened: false })
const dropdownHeadRef = ref<any | null>(null)
const popupStyle = computed(() => {
  if (!dropdownHeadRef.value) {
    return {}
  }
  return { width: formatPx(dropdownHeadRef.value.getBoundingClientRect().width) }
})
const handleToggle = () => {
  localState.opened = !localState.opened
}
const handleItemClick = (item: string) => {
  emit('onSelect', item)
  closeMenu()
}
const closeMenu = () => {
  localState.opened = false
}
</script>

<style scoped>
.Dropdown .Dropdown__head {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid grey;
}
.Dropdown__menu {
  max-height: 300px;
  overflow-y: auto;
}
</style>
