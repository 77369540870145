import {
  CardFormFactor,
  PaymentCardListItemFragment,
  PaymentCardOrderStatus,
  PaymentCardStatus,
  PhysicalPaymentCardOrder,
} from '@/generated/graphql'
import { both, complement, defaultTo } from '@/lib/function'
import { defaultToEmptyList, filter, head, map } from '@/lib/list'
import { isNil, notNil } from '@/lib/type'
import { defaultToEmptyString } from '@/lib/string'
import { IPaymentCard, PaymentCardFilterValue, IPaymentCardView } from '@/lib/domain/types'

export const physicalPaymentCardOrders = (card: any) => card.physicalPaymentCardOrders

export const isPaymentCardPhysical = (card: IPaymentCard): boolean =>
  card.formFactor === CardFormFactor.Physical

export const isPaymentCardActive = <T extends { status?: Maybe<PaymentCardStatus> }>(
  card: T
): boolean => card.status === PaymentCardStatus.Active

export const isPaymentCardSuspended = (card: { status: PaymentCardStatus }): boolean =>
  card.status === PaymentCardStatus.Suspended

export const isPaymentCardClosed = <T extends { status: PaymentCardStatus }>(card: T): boolean =>
  card.status === PaymentCardStatus.Closed

export const isPaymentCardActivationRequired = <T extends { status?: Maybe<PaymentCardStatus> }>(
  card: Maybe<T>
): boolean => !!card && card.status === PaymentCardStatus.ActivationRequired

export const isPaymentCardMenuDisabled = (card: { status: PaymentCardStatus }) =>
  isPaymentCardClosed(card) || isPaymentCardActivationRequired(card)

export const equalByLast4 =
  (last4Input: string | null) =>
  ({ last4 }: any) =>
    last4 === last4Input

export const findActivePhysicalCard = (cardList: Maybe<IPaymentCard>[]): Maybe<IPaymentCard> =>
  head(
    filter(both(complement(isPaymentCardClosed), isPaymentCardPhysical), cardList.filter(notNil))
  )

export const mapPhysicalCardOrderToStatus = (o?: Maybe<PhysicalPaymentCardOrder>) =>
  o?.orderState?.status

export const isPaymentCardOrderStatusOrdered = (s?: Maybe<PaymentCardOrderStatus>) => {
  if (isNil(s)) {
    return false
  }
  return [
    PaymentCardOrderStatus.New,
    PaymentCardOrderStatus.Approved,
    PaymentCardOrderStatus.SentToPrinter,
    PaymentCardOrderStatus.Shipped,
  ].includes(s)
}

export const isPaymentCardOrdered = (card?: Maybe<IPaymentCard>): boolean =>
  defaultToEmptyList(card?.orderStatusList).filter(notNil).some(isPaymentCardOrderStatusOrdered)

export const mapCardToCardView = (card: IPaymentCard): IPaymentCardView => {
  return {
    id: card.id,
    last4: card.last4,
    date: `••/••`,
    cvv: `•••`,
    status: card.status,
  }
}

export const mapApiPaymentCard = (value: PaymentCardListItemFragment): IPaymentCard => {
  return {
    id: value.id,
    status: defaultTo(PaymentCardStatus.Suspended, value.status) as PaymentCardStatus,
    last4: defaultToEmptyString(value.last4),
    formFactor: defaultTo(CardFormFactor.Virtual, value.formFactor) as CardFormFactor,
    orderStatusList: map(mapCardOrderToStatus, defaultToEmptyList(value.physicalPaymentCardOrders)),
  }
}

export const mapCardOrderToStatus = (
  value?: Maybe<Partial<PhysicalPaymentCardOrder>>
): PaymentCardOrderStatus => {
  return defaultTo(PaymentCardOrderStatus.New, value?.orderState?.status) as PaymentCardOrderStatus
}

export const createPaymentCardFilterValue = (): PaymentCardFilterValue => ({
  [PaymentCardStatus.Active]: false,
  [PaymentCardStatus.Suspended]: false,
  [PaymentCardStatus.Closed]: false,
})

export const createCardFilterDefaultValue = (): PaymentCardFilterValue => {
  return Object.assign(createPaymentCardFilterValue(), {
    [PaymentCardStatus.Active]: true,
    [PaymentCardStatus.Suspended]: true,
  })
}
