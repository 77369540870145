<template>
  <Page name="Dashboard">
    <div class="Dashboard__content">
      <BalanceBox
        :currentBalance="currentBalance"
        :availableBalance="availableBalance"
        :creditLimit="creditLimit"
      />
      <SummaryBox :statementDueDate="statementDueDate" :statementBalance="statementDueBalance" />
      <PaymentCardBox :card="mainCard" :count="cardListCount" @activate="handleActivateCardClick" />
      <TransactionList class="bg-white Dashboard__content__transactions" />
    </div>
  </Page>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import Page from '@/components/Page.vue'
import BalanceBox from '@/components/dashboard/BalanceBox.vue'
import SummaryBox from '@/components/dashboard/SummaryBox.vue'
import TransactionList from '@/components/dashboard/TransactionList.vue'
import PaymentCardBox from '@/components/dashboard/PaymentCardBox.vue'

import { usePaymentCards } from '@/service/usePaymentCards'
import { useAccountHolder } from '@/store/accountHolder'

import { useMountedLog } from '@/lib/composable/useMountedLog'
import { usePolling } from '@/lib/composable/usePolling'
import { RouteName } from '@/router/route'
import { noop } from '@/lib/function'
import { length } from '@/lib/list'
import { useFinancialInsitutionStore } from '@/store/useFinancialInsitutionStore'
import { injectAccountHolderStore } from '@/provider/accountHolderStoreProvider'

useMountedLog('Dashboard')
const $accountHolderStore = injectAccountHolderStore()
const router = useRouter()
const {
  store: { mainCard, activeVirtualCardList, loadNonClosedCardList },
} = usePaymentCards()
const { currentBalance, availableBalance, creditLimit, statementDueDate, statementDueBalance, id } =
  useAccountHolder()
const { updateFinancialInstitutionList } = useFinancialInsitutionStore()
const cardListCount: ComputedRef<number> = computed(() => length(activeVirtualCardList.value))
const handleActivateCardClick = () => router.push({ name: RouteName.ActivateCard }).catch(noop)
const apiPolling = usePolling({
  time: 5000,
  callback: () => {
    $accountHolderStore.loadAccountHolder(id.value)
    updateFinancialInstitutionList()
    loadNonClosedCardList()
  },
})
onMounted(apiPolling.start)
</script>

<style>
.Dashboard__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;
  column-gap: 24px;
  row-gap: 24px;
}
.Dashboard__content__transactions {
  grid-column-start: 1;
  grid-column-end: 4;
}
@media screen and (max-width: 768px) {
  .Dashboard__content {
    display: flex;
    flex-direction: column;

    background-color: var(--bg-grey3);
  }
  .Dashboard__content__transactions {
    margin-left: -24px;
    margin-right: -24px;
  }
}
</style>
