<template>
  <SubPageContent v-if="!linkListEmpty" class="SettingsBank" title="Bank Accounts">
    <template v-slot:description>The bank account is used to fund your Tillful balance.</template>
    <!-- <template v-slot:action>
      <Button
        :loading="localState.autopayLoading"
        class="SettingsBank__autopay-button"
        variant="secondary"
        :size="'large'"
        style="width: 150px"
        @click="handleEnableAutopayClick"
      >
        {{ autopayEnabled ? 'Change Autopay' : 'Enable Autopay' }}
      </Button>

      <AutopayModal
        v-if="localState.autopayModalOpened"
        :opened="localState.autopayModalOpened"
        :accountList="linkList"
        @close="handleAutopayModalClose"
        @completed="handleAutopayCompleted"
      />
    </template> -->

    <Message
      v-if="linkError"
      variant="error"
      :dismissable="false"
      class="BankAccountErrorMessage mb-32"
      data-test-id="error-message"
    >
      {{ linkError }}
    </Message>
    <div class="SettingsBank__list mt-32">
      <SettingsBankAccountItem
        v-for="item of linkList"
        :key="item.externalFinancialBankAccountId ?? undefined"
        class="SettingsBank__list__item mb-24"
        :account="item"
        :autopayEnabled="autopayEnabledById[item.externalFinancialBankAccountId ?? '']"
        @onRemove="handleRemove"
      />

      <SettingsBankAddButton
        v-if="addBankAllowed"
        :loading="addBankLoading"
        data-test-id="add-bank-button"
        @click="handleAddBankClick()"
      />

      <SettingsRemoveBankAccountModal
        v-if="localState.removeAccount"
        :account="localState.removeAccount"
        @onCancel="handleCancelRemove"
      />
    </div>
  </SubPageContent>

  <SubPageContent v-else>
    <SettingsBankEmpty
      :loading="!accountHolderId || localState.plaidLoading"
      :error="linkError"
      :showError="!!linkError || localState.saveLinkErrorShown"
      @onAddBank="handleAddBankClick()"
      @onCloseError="handleErrorClose"
    />
  </SubPageContent>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'

import Button from '@/components/Button.vue'
import Message from '@/components/Message.vue'
import AutopayModal from '@/components/payment/AutopayModal.vue'
import SettingsBankAccountItem from '@/components/settings/SettingsBankAccountItem.vue'
import SettingsBankAddButton from '@/components/settings/SettingsBankAddButton.vue'
import SettingsBankEmpty from '@/components/settings/SettingsBankEmpty.vue'
import SettingsRemoveBankAccountModal from '@/components/settings/SettingsRemoveBankAccountModal.vue'
import SubPageContent from '@/components/SubPageContent.vue'

import { injectPlaid } from '@/provider/plaidProvider'
import { useAccountHolder } from '@/store/accountHolder'
import { usePaymentsStore } from '@/store/payments'
import { useFinancialInsitutionStore } from '@/store/useFinancialInsitutionStore'

import { clearQueryParams } from '@/api/dom/document'
import { BANK_ACCOUNT_MAX_NUMBER, FAILED_TO_CONNECT } from '@/constants'
import { usePolling } from '@/lib/composable/usePolling'
import { IPaymentAccount, IPlaidMeta } from '@/lib/domain/types'
import { noop } from '@/lib/function'
import { find, isEmpty, length } from '@/lib/list'
import { consoleLogger, sentryLogger } from '@/lib/logger'
import { eq, lt } from '@/lib/logic'
import { defaultToEmptyString } from '@/lib/string'
import { notNil } from '@/lib/type'
import {
  isAppSyncApiError,
  AppSyncApiError,
  AppSyncApiErrorType,
} from '@/lib/domain/error/AppSyncApiError'

const $plaid = injectPlaid()

const route = useRoute()

const {
  linkList,
  linkError,
  bankAccountAddedButNotAvailable,
  setError,
  saveFinancialInstitution,
  updateFinancialInstitutionList,
} = useFinancialInsitutionStore({
  onBankAccountCreated: () => {
    if (!autopayEnabled.value) {
      handleEnableAutopayClick()
    }
  },
})
const { id: accountHolderId } = useAccountHolder()
const { autopayEnabled, autopayBankAccountId, updatePaymentList } = usePaymentsStore()

const linkListEmpty = computed(() => isEmpty(linkList.value))
const addBankAllowed = computed<boolean>(() => lt(length(linkList.value), BANK_ACCOUNT_MAX_NUMBER))
const addBankLoading = computed<boolean>(() => bankAccountAddedButNotAvailable.value)
const autopayEnabledById = computed<Record<string, boolean>>(() =>
  linkList.value.reduce(
    (acum, item) =>
      Object.assign({}, acum, {
        [defaultToEmptyString(item.externalFinancialBankAccountId)]:
          notNil(autopayBankAccountId.value) &&
          eq(item.externalFinancialBankAccountId, autopayBankAccountId.value),
      }),
    {}
  )
)

const localState = reactive({
  saveLinkErrorShown: false,
  plaidLoading: false,
  autopayModalOpened: false,
  autopayLoading: false,
  removeAccount: null as Maybe<IPaymentAccount>,
})

/**
 * Update payment list as it might contain recurring transfers
 * Nedded to find out if autopay enabled or not
 */

/**
 * TODO: query only recurring transfers from incoming scheduled transfers
 */
onMounted(updatePaymentList)

onMounted(handlePlaidOAuthRedirect)

/**
 * Poll data that might be updated
 */
const apiPolling = usePolling({ time: 5000, callback: updateFinancialInstitutionList })
onMounted(apiPolling.start)

async function handlePlaidOAuthRedirect() {
  if ($plaid.isPlaidOAuthUrl(route)) {
    await handleAddBankClick(window.location.href)
    clearQueryParams()
  }
}

const handleErrorClose = setError

const BANK_ERROR_MESSAGE = 'Failed to connect bank. Please contact support or try again.'

const handleAddBankClick = async (receivedRedirectUrl?: string) => {
  if (
    // prevent from clicking more than once at a time
    localState.plaidLoading ||
    // prevent from clicking when previous bank account is being added
    addBankLoading.value
  ) {
    return
  }

  setError(null)
  localState.plaidLoading = true
  const handlerResult = await $plaid.createPlaidHandler({
    receivedRedirectUrl,
    onSuccess: handlePlaidSuccess,
    onExitError: () => {
      localState.saveLinkErrorShown = true
      setError(FAILED_TO_CONNECT)
    },
    onOpen: () => {
      localState.plaidLoading = false
    },
  })
  if (handlerResult.ok) {
    handlerResult.value.open()
  } else {
    localState.plaidLoading = false
    setError(BANK_ERROR_MESSAGE)
    consoleLogger.error(handlerResult.error)
    sentryLogger.error(handlerResult.error)
  }
}

const handlePlaidSuccess = async (_: string, meta: IPlaidMeta, handler: any) => {
  localState.saveLinkErrorShown = false
  handler.destroy()
  setError(null) // clear UI error message
  const saveLinkResult = await saveFinancialInstitution(meta)
  handleSaveLinkResult(saveLinkResult, meta)
}

const handleSaveLinkResult = (
  res: Result<boolean, Error | AppSyncApiError>,
  meta: IPlaidMeta
): void => {
  if (res.ok) {
    return
  }
  localState.saveLinkErrorShown = true
  if (!isAppSyncApiError(res.error)) {
    setError(BANK_ERROR_MESSAGE)
    return
  }
  const errorTypeMessageMap: Record<AppSyncApiErrorType | 'na', string> = {
    [AppSyncApiErrorType.InstitutionNotSupported]: [
      'At this time, our policy does not support receiving deposits or payments from ',
      meta.institution.name,
      '. Please try to connect with another financial institution.',
    ].join(''),
    na: BANK_ERROR_MESSAGE,
  }
  setError(errorTypeMessageMap[res.error.errorType ?? 'na'])
}

const handleEnableAutopayClick = () => {
  localState.autopayLoading = true
  localState.autopayModalOpened = true
}
const handleAutopayModalClose = () => {
  localState.autopayLoading = false
  localState.autopayModalOpened = false
}
const handleAutopayCompleted = noop

const handleRemove = (id: string) => {
  const link = find((l) => eq(l.id, id), linkList.value)
  localState.removeAccount = link
}
const handleCancelRemove = () => {
  localState.removeAccount = null
}
</script>

<style>
@media all and (max-width: 768px) {
  .SettingsBank .SubPageContent__header {
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;
  }
}
.SettingsBank__autopay-button {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #d1d5db;
  background-color: var(--white);
}
.SettingsBank__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
}
.Message.BankAccountErrorMessage {
  align-items: flex-start;
  color: #7f1d1d;
}

.Message.BankAccountErrorMessage .Message__icon-container .Icon {
  font-size: 18px;
  color: var(--c-red4);
}
</style>
