import { createRange, Range } from '@/lib/range'
import { formatCurrency } from '@/lib/number'
import { result } from '@/lib/result'
import { isNil, notNil } from '@/lib/type'
import { eq, gt, lt } from '@/lib/logic'
import { IPaymentAccount } from '@/lib/domain/types'

const getInitialDepositRange = (region: Maybe<string>): Range<number> => {
  switch (region) {
    case 'NM':
      return createRange(5_000, 100_000)
    case 'NY':
      return createRange(500, 50_000)
    default:
      return createRange(500, 100_000)
  }
}

const getRegularDepositRange = (region: Maybe<string>): Range<number> => {
  switch (region) {
    case 'NY':
      return createRange(100, 50_000)
    default:
      return createRange(100, 100_000)
  }
}

interface IValidateDepositOptions {
  amount: number
  range: Range<number>
  account?: Maybe<IPaymentAccount>
}

export const getDepositRange = (region: Maybe<string>, creditLimit: number) => {
  const isInitial = isNil(creditLimit) || eq(creditLimit, 0)
  return isInitial ? getInitialDepositRange(region) : getRegularDepositRange(region)
}

export const validateDeposit = (options: IValidateDepositOptions): Result<boolean> => {
  const {
    amount,
    range: { from, to },
    account,
  } = options
  const availableBalance = account?.accountDetails.availableBalance
  const currentBalance = account?.accountDetails.currentBalance

  let balanceType = 'available'
  let balanceValue = availableBalance

  if (isNil(availableBalance)) {
    balanceType = 'current'
    balanceValue = currentBalance
  }

  if (notNil(balanceValue) && gt(amount, balanceValue)) {
    const message = `Cannot initiate deposit as the amount exceeds the ${balanceType} balance (${formatCurrency(
      balanceValue
    )}) in your account. Please ensure there are sufficient funds in the account and try again, or select another account.`
    return result.failed(new Error(message))
  }
  if (gt(amount, to)) {
    return result.failed(new Error(`The deposit amount should be under ${formatCurrency(to)}.`))
  }
  if (lt(amount, from)) {
    return result.failed(new Error(`The deposit amount should be over ${formatCurrency(from)}.`))
  }
  return result.ok(true)
}

interface IValidatePaymentAmountOptions {
  amount: number
  account?: Maybe<IPaymentAccount>
}

export const validatePaymentAmount = (options: IValidatePaymentAmountOptions): Result<boolean> => {
  const { amount, account } = options
  const availableBalance = account?.accountDetails.availableBalance
  const currentBalance = account?.accountDetails.currentBalance

  let balanceType = 'available'
  let balanceValue = availableBalance

  if (isNil(availableBalance)) {
    balanceType = 'current'
    balanceValue = currentBalance
  }

  if (notNil(balanceValue) && gt(amount, balanceValue)) {
    const message = `Cannot initiate transfer as the amount exceeds the ${balanceType} balance (${formatCurrency(
      balanceValue
    )}) in your account. Please ensure there are sufficient funds in the account and try again, or select another account.`
    return result.failed(new Error(message))
  }
  return result.ok(true)
}
