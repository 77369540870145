import { onUnmounted } from 'vue'
import { computed, Ref, ref } from '@vue/reactivity'
import { useBool } from '@/lib/composable/useBool'
import { copyToClipboard } from '@/api/dom/document'

interface IShowCopyClipboard {
  copiedInfo?: string
  htmlTargetElement: HTMLElement | null
  textCopyConfirm?: string
}

export const useCopyClipboard = () => {
  const popupTargetRef: Ref<HTMLElement | null> = ref(null)
  const popupContent: Ref<string> = ref('')
  let popupTimeout: null | ReturnType<typeof setTimeout> = null
  const { value, truthyfy: openPopup, falsefy: closePopup } = useBool()

  onUnmounted(closePopup)

  return {
    showCopyClipboard: ({
      copiedInfo,
      htmlTargetElement,
      textCopyConfirm = 'Copied to clipboard!',
    }: IShowCopyClipboard): void => {
      closePopup()
      if (copiedInfo) {
        copyToClipboard(copiedInfo)
      }
      if (popupTimeout) {
        clearTimeout(popupTimeout)
      }
      popupTargetRef.value = htmlTargetElement
      popupContent.value = textCopyConfirm
      openPopup()
      popupTimeout = setTimeout(closePopup, 1000)
    },
    popupOpened: computed(() => value.value),
    popupTargetRef,
    popupContent,
  }
}
