import { BaseError } from '@/lib/domain/error/BaseError'
import { computed, inject, InjectionKey, provide, reactive } from 'vue'

const createLockCardModalState = () => {
  const state = reactive<{
    opened: boolean
    loading: boolean
    failed: boolean
    card?: { last4: string; id: string }
  }>({
    opened: false,
    loading: false,
    failed: false,
    card: undefined,
  })

  function handleOpen(card: { last4: string; id: string }) {
    state.opened = true
    state.card = card
  }

  function handleClose() {
    state.opened = false
    state.loading = false
    state.failed = false
    state.card = undefined
  }

  return {
    state: computed(() => state),
    open: handleOpen,
    close: handleClose,
    setFailed(value: boolean): void {
      state.failed = value
    },
    setLoading(value: boolean): void {
      state.loading = value
    },
  }
}

export type LockCardModalStore = ReturnType<typeof createLockCardModalState>

export const LOCK_CARD_MODAL_STORE_INJECTION_KEY = Symbol(
  'Lock card modal store key'
) as InjectionKey<LockCardModalStore>

export function provideLockCardModalStore(): void {
  const store = createLockCardModalState()
  provide(LOCK_CARD_MODAL_STORE_INJECTION_KEY, store)
}

export function injectLockCardModalStore(): LockCardModalStore {
  const injected = inject(LOCK_CARD_MODAL_STORE_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject lock card modal store')
  }
  return injected
}
