import {
  addMonths,
  setDate,
  getDate,
  addYears,
  differenceInSeconds,
  differenceInDays,
} from 'date-fns/fp'

import { padTo2Digits } from '@/lib/number'
import { result } from '@/lib/result'
import { isNil } from '@/lib/type'

type DateFormatter = (date: Date | number | undefined) => string

const dateMonthDefaultFormatter = new Intl.DateTimeFormat('en-US')

const dateMonthShortFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
})
const dateMonthShortYearFullFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})
const dateMonthShortYearFullUTCFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC',
})
const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour12: true,
  hour: 'numeric',
  minute: '2-digit',
})
const paymentCardMonthFormatter = new Intl.DateTimeFormat('en-US', {
  month: '2-digit',
})
const paymentCardYearFormatter = new Intl.DateTimeFormat('en-US', {
  year: '2-digit',
})

export const formatDateMonthShort: DateFormatter = (date) => {
  if (!date) {
    return ''
  }
  return dateMonthShortFormatter.format(date)
}

export const formatDateMonthShortYearFull: DateFormatter = (date) => {
  if (!date) {
    return ''
  }
  return dateMonthShortYearFullFormatter.format(date)
}

export const formatDateOfBirth: DateFormatter = (date) => {
  if (!date) {
    return ''
  }
  return dateMonthShortYearFullUTCFormatter.format(date)
}

export const formatTime: DateFormatter = (date) => {
  if (!date) {
    return ''
  }
  return timeFormatter.format(date).replace(' AM', 'am').replace(' PM', 'pm')
}

export const formatPaymentCardDate: DateFormatter = (date) => {
  if (!date) {
    return ''
  }
  return [paymentCardMonthFormatter.format(date), paymentCardYearFormatter.format(date)].join('/')
}

/**
 * Get current date and time moment
 * @returns date with current date and time
 */
export const now = (): Date => new Date()

/**
 * Format date to string with yyy-mm-dd format
 * @param date - Date to be formatted
 * @returns formatted date string
 */
export const formatDateIsoString = (date: Date): string => date.toISOString().split('T')[0]

export const formatDateTimeIsoString = (date: Date): string => date.toISOString()

/**
 * 1/1/2022 format
 */
export const formatDateSlashes = (date: Date): string => dateMonthDefaultFormatter.format(date)

export const createDate = (input: string | number | Date): Date => new Date(input)

export const instantiateDate = (input: Maybe<string | number | Date>): Result<Date> => {
  const error = new Error('Failed to instantiate date')
  if (isNil(input)) {
    return result.failed(error)
  }
  try {
    const date = new Date(input)
    if (isNaN(date.getTime())) {
      // Invalid Date
      return result.failed(error)
    }
    return result.ok(date)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const today = (): Date => createDate(formatDateIsoString(now()))

const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000

export const addDays = (days: number, input: Date): Date => {
  const daysMilliseconds = days * MILLISECONDS_IN_ONE_DAY
  const dateTime = Number(input)
  return new Date(dateTime + daysMilliseconds)
}

export const formatAwsDate = (date: Date): string => {
  return [
    date.getUTCFullYear(),
    padTo2Digits(date.getUTCMonth() + 1),
    padTo2Digits(date.getUTCDate()),
  ].join('')
}

export const formatAwsDateTime = (date: Date): string => {
  return [
    formatAwsDate(date),
    'T',
    padTo2Digits(date.getUTCHours()),
    padTo2Digits(date.getUTCMinutes()),
    padTo2Digits(date.getUTCSeconds()),
    'Z',
  ].join('')
}

export const todayDayOfMonth = () => now().getUTCDate()

export const addOneMonth = addMonths(1)

export { setDate, getDate, addYears, differenceInSeconds, differenceInDays }
