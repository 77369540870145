import {
  captureException as _captureException,
  captureMessage as _captureMessage,
  withScope as _withScope,
} from '@sentry/core'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'
import { ENV } from '@/lib/env'
import { getAuthFunctions } from '@/api/auth/authClient'
import { defaultToEmptyString } from '@/lib/string'
import { defaultToEmptyList } from '@/lib/list'

interface IErrorTrackingOptions {
  dsn?: string
  release: string | undefined
  branch: string | undefined
  app: App
  router: Router
  userId?: string | undefined
}

export const initErrorTracking = (options: IErrorTrackingOptions) => {
  Sentry.init({
    app: options.app,
    dsn: options.dsn,
    normalizeDepth: 5,
    release: options.release,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(options.router),
        tracingOrigins: [/card-test.tillful.com/, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,

    beforeSend: async (event: Sentry.Event) => {
      if (shouldNotSend(event)) {
        return null
      }
      return await modifyEvent(event)
    },
  })

  if (options.branch) {
    Sentry.setTag('branch', options.branch)
  }
}

export async function modifyEvent(event: Sentry.Event): Promise<Sentry.Event> {
  const modifiedEvent: Sentry.Event = { ...event }
  const authFunctions = getAuthFunctions()
  if (authFunctions.ok) {
    const userResult = await authFunctions.value.getUser()
    if (userResult.ok && userResult.value.sub) {
      modifiedEvent.user = event.user || {}
      modifiedEvent.user.id = userResult.value.sub
    }
  }

  return modifiedEvent
}

export const sentryOtpions = (app: App, router: Router): IErrorTrackingOptions => {
  return {
    app,
    router,
    dsn: ENV.TC_SENTRY_DSN,
    release: ENV.TC_GIT_SHA,
    branch: ENV.TC_BRANCH_NAME,
  }
}

const captureException = _captureException
const captureMessage = _captureMessage
const withScope = _withScope

function shouldNotSend(event: Sentry.Event): boolean {
  const exception = defaultToEmptyList(event.exception?.values)[0]
  return (
    defaultToEmptyString(exception?.type) === 'TypeError' &&
    defaultToEmptyString(event.message).includes('Load Failed')
  )
}

export { captureException, captureMessage, withScope, shouldNotSend }
