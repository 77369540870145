/**
 * Construct any message providers to external world there
 */
import { captureException, captureMessage, withScope } from '@/api/sentry/sentry'

import { BaseError } from '@/lib/domain/error/BaseError'

interface Logger {
  log(...args: any): void
  warn(...args: any): void
  error(...args: any): void
}

const createConsoleLogger = (): Logger => {
  return {
    log(...args) {
      console.log('[TC]', ...args)
    },
    warn(...args) {
      console.warn('[TC]', ...args)
    },
    error(...args) {
      console.error('[TC]', ...args)
    },
  }
}

const createSentryLogger = (): Logger => {
  return {
    log(msg: string) {
      captureMessage(msg)
    },
    error(e: BaseError<Record<string, unknown>>) {
      withScope((scope) => {
        scope.setTag('vendor', e.vendor)
        scope.setContext('data', {
          payload: e.payload ?? null,
          originalError: {
            name: e.originalError?.name,
            message: e.originalError?.message,
            stack: e.originalError?.stack,
          },
        })
        captureException(e, scope)
      })
    },
    warn() {
      throw new Error('not implemented')
    },
  }
}

const consoleLogger = createConsoleLogger()

const sentryLogger = createSentryLogger()

export { consoleLogger, sentryLogger, Logger }
