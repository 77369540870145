<template>
  <Page class="ActivateCardPage" :showMobileMenu="false">
    <Box v-if="loaded" style="padding-left: 0; padding-right: 0">
      <div v-if="!notSmallScreen" class="text-right c-grey3 mb-32">
        <Icon name="x" @click="handleCloseClick" />
      </div>

      <Message
        v-if="!activated"
        variant="warning"
        :dismissable="false"
        class="ActivateCardPage__warning-message"
      >
        For your protection, we recommend you activate this card only once it is physically in your
        possession.
      </Message>
      <div v-else style="height: 96px"></div>

      <div class="ActivateCardPage__content d-flex flex-direction-column align-center">
        <PaymentCardCircled :active="activated" :last4="last4" />

        <h2 class="mb-16 text-center fs-20 fw-bold c-black">{{ content.title }}</h2>

        <Message v-if="state.error" class="mb-24" variant="error" :dismissable="false">
          Could not activate card. Please try again later.
        </Message>

        <p class="mb-32 c-grey2 fs-14 text-center" style="line-height: 1.43">
          {{ content.description }}
        </p>

        <Button
          style="align-self: center; width: 220px"
          class="mb-20"
          variant="primary"
          size="large"
          @click="handleButtonClick"
          :loading="state.pending"
          >{{ content.buttonText }}</Button
        >
      </div>
    </Box>
  </Page>
</template>

<script setup lang="ts">
import { onMounted, ComputedRef, computed } from '@vue/runtime-core'

import { consoleLogger } from '@/lib/logger'

import Page from '@/components/Page.vue'
import Box from '@/components/Box.vue'
import Message from '@/components/Message.vue'
import Button from '@/components/Button.vue'

import { usePaymentCards } from '@/service/usePaymentCards'
import { useActivateCardModal } from '@/service/useActivateCard'
import { useRouter } from 'vue-router'
import { RouteName } from '@/router/route'
import PaymentCardCircled from '@/components/PaymentCardCircled.vue'
import Icon from '@/components/Icon.vue'
import { noop } from '@/lib/function'
import { useBreakpoint } from 'vue-composable'
import { isPaymentCardActive } from '@/lib/domain/paymentCard'
import { defaultToEmptyString } from '@/lib/string'

onMounted(() => consoleLogger.log('[Activate Card] mounted'))

const { notSmallScreen } = useBreakpoint({ notSmallScreen: 768 })

const { push } = useRouter()
const { state, activate } = useActivateCardModal()
const {
  store: { mainCard },
} = usePaymentCards()
const loaded: ComputedRef<boolean> = computed(() => Boolean(mainCard.value))
const last4: ComputedRef<string> = computed(() => defaultToEmptyString(mainCard.value?.last4))
const activated: ComputedRef<boolean> = computed(() =>
  mainCard.value ? state.value.success || isPaymentCardActive(mainCard.value) : false
)
const content: ComputedRef<{
  title: string
  description: string
  buttonText: string
}> = computed(() =>
  activated.value
    ? {
        title: 'Card Activated',
        description: 'Your card is now successfully activated and ready for use.',
        buttonText: 'Return to Dashboard',
      }
    : {
        title: 'Activate Card',
        description:
          'Your physical card must be activated before it is used. Failure to activate before use will result in a declined authorization.',
        buttonText: 'Activate Card',
      }
)
const goToDashboard = () => push({ name: RouteName.Dashboard }).catch(noop)
const handleCloseClick = goToDashboard
const handleButtonClick = () => {
  if (activated.value) {
    goToDashboard()
  } else {
    activate()
  }
}
</script>

<style scoped>
::v-deep(.PageContent) {
  background-color: var(--white);
}
.ActivateCardPage__warning-message {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 56px;
}
.ActivateCardPage__content {
  max-width: 480px;
  margin: 0 auto;
}
</style>
