import {
  FinancialAccountStatementDetailFragment,
  FinancialAccountStatementItemFragment,
  FinancialAccountStatementDetailEntryFragment,
  FinancialAccountStatement,
} from '@/generated/graphql'
import { result } from '@/lib/result'
import { isNil, notNil } from '@/lib/type'
import { formatDateMonthShort, formatDateMonthShortYearFull, now } from '@/lib/date'
import { defaultTo0, div100, formatCurrency, negate } from '@/lib/number'
import { defaultToEmptyString } from '@/lib/string'
import { eq } from '@/lib/logic'
import { graphqlType } from '@/api/graphql/graphqlType'

export const mapStatementToView = (
  statement: FinancialAccountStatementItemFragment
): IStatementItemView => {
  return {
    id: statement.id,
    period: formatStatementPeriod(statement),
    spend: formatCurrency(div100(statement.periodPurchasesAmount?.value)),
    balance: formatCurrency(div100(statement.endingPrimaryCreditBalance?.value)),
    due: formatDateMonthShortYearFull(instantiateDate(statement.paymentDueOn)),
    limit: formatCurrency(div100(statement.endingSecondaryCreditBalance?.value)),
  }
}

export interface IStatementEntryView {
  id: string | symbol
  date: string
  name: string
  amount: string
}

export const mapStatementDetailsToView = (
  statement: Maybe<FinancialAccountStatementDetailFragment>,
  statementEntryList: FinancialAccountStatementDetailEntryFragment[]
) => {
  if (isNil(statement)) {
    return {
      id: '',
      period: '',
      periodShort: '',
      newBalance: '',
      paymentDue: '',
      creditLimit: '',
      availableLimit: '',
      previousBalance: '',
      paymentsCredits: '',
      feeCharges: '',
      purchases: '',
      paymentAndCreditList: [],
      purchaseList: [],
      feeChargedList: [],
    }
  }
  const paymentAndCreditList = statementEntryList.filter(isStatementEntryPayment)
  const purchaseList = statementEntryList.filter(isStatementEntryPurchase)
  const paymentsCredits = div100(statement.periodRefundsAndPaymentsAmount?.value)
  return {
    id: statement.id,
    period: formatStatementPeriod(statement),
    periodShort: formatStatementPeriodShort(statement),
    newBalance: formatCurrency(div100(statement.endingPrimaryCreditBalance?.value)),
    paymentDue: formatDateMonthShortYearFull(instantiateDate(statement.paymentDueOn)),

    creditLimit: formatCurrency(div100(statement.endingCreditLimit?.value)),
    availableLimit: formatCurrency(div100(statement.endingSecondaryCreditBalance?.value)),

    previousBalance: formatCurrency(div100(statement.startingPrimaryCreditBalance?.value)),
    paymentsCredits: formatCurrency(
      eq(paymentsCredits, 0) ? paymentsCredits : negate(paymentsCredits)
    ),
    feeCharges: formatCurrency(div100(statement.periodFeesAmount?.value)),
    purchases: formatCurrency(div100(statement.periodPurchasesAmount?.value)),

    paymentAndCreditList: paymentAndCreditList.map(mapStatementEntryToView),
    purchaseList: purchaseList.map(mapStatementEntryToView),
    feeChargedList: [],
  }
}

const isStatementEntryPayment = (entry: FinancialAccountStatementDetailEntryFragment): boolean =>
  entry.ledgerEntry?.__typename === 'DebitLedgerEntry'

const isStatementEntryPurchase = (entry: FinancialAccountStatementDetailEntryFragment): boolean =>
  entry.ledgerEntry?.__typename === 'CreditLedgerEntry'

export const instantiateDate = (value?: Maybe<string | number | Date>) => new Date(value ?? now())

export const createStatementUrl = (
  statement: Maybe<FinancialAccountStatementItemFragment>
): Result<URL> => {
  if (!statement) {
    return result.failed(new Error('Failed to create statemnt url. Statement not provided.'))
  }
  const { periodStart, periodEnd } = statement
  if (!periodStart || !periodEnd) {
    return result.failed(
      new Error('Failed to create statemnt url. Statement does not have start or end period.')
    )
  }
  try {
    const url = new URL(window.location.origin)
    const searchQuery = new URLSearchParams({ start: periodStart, end: periodEnd })
    url.pathname = 'statement'
    url.search = searchQuery.toString()
    return result.ok(url)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const formatStatementPeriod = (statement: FinancialAccountStatementItemFragment): string =>
  [
    formatDateMonthShortYearFull(instantiateDate(statement.periodStart)),
    formatDateMonthShortYearFull(instantiateDate(statement.periodEnd)),
  ].join(' - ')

export const formatStatementPeriodShort = (
  statement: FinancialAccountStatementItemFragment
): string =>
  [
    formatDateMonthShort(instantiateDate(statement.periodStart)),
    formatDateMonthShortYearFull(instantiateDate(statement.periodEnd)),
  ].join(' to ')

export const mapStatementEntryToView = (
  entry: FinancialAccountStatementDetailEntryFragment
): IStatementEntryView => {
  const view: IStatementEntryView = {
    id: Symbol(),
    date: formatDateMonthShortYearFull(instantiateDate(entry.postDate)),
    name: getStatementEntryName(entry),
    amount: formatCurrency(div100(entry.creditAmount?.value)),
  }
  if (isStatementEntryPayment(entry)) {
    const amount = div100(entry.debitAmount?.value)
    view.amount = formatCurrency(eq(amount, 0) ? amount : negate(amount))
  }
  return view
}

export const isStatementClosed = (statement: Partial<FinancialAccountStatement>): boolean =>
  notNil(statement.closedAt)

const getStatementEntryName = (entry: FinancialAccountStatementDetailEntryFragment): string => {
  const event = entry.ledgerEntry?.financialEvent
  return graphqlType.isClearingFinancialEvent(event)
    ? defaultToEmptyString(event.merchantDetails?.name)
    : ''
}

export const isSecuredDepositStatementItem = (
  node: Maybe<WithTypename>
): node is FinancialAccountStatementItemFragment => {
  return node?.__typename === 'SecuredDepositCommercialCreditCardFinancialAccountStatement'
}

export const isSecuredDepositStatement = (
  node: Maybe<WithTypename>
): node is FinancialAccountStatementDetailFragment => {
  return node?.__typename === 'SecuredDepositCommercialCreditCardFinancialAccountStatement'
}

export const defaultToIfNotSecuredDepositStatement = <T>(
  defaulToValue: T,
  node: Maybe<WithTypename>
) => {
  if (isSecuredDepositStatement(node)) {
    return node
  }
  return defaulToValue
}
