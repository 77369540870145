import { computed, reactive } from 'vue'
import {
  endApplicationDocumentUploadSession,
  getCardProductApplication,
} from '@/api/graphql/graphqlAPI'
import { graphqlType } from '@/api/graphql/graphqlType'
import {
  AccountHolderApplicationRequiredDocument,
  AccountHolderCardProductApplication,
  AccountHolderVerification,
  AccountHolderVerificationStatusCode,
  AccountHolderVerificationStatusReasonCode,
  ApplicationDocumentUploadSessionStatus,
  DocumentCategoryType,
  DocumentUploadLink,
  DocumentUploadLinkStatusCode,
  DocumentUploadSessionStatusCode,
  Name,
  UploadLink,
  UsAccountHolderApplicationDocumentUploadSession,
} from '@/generated/graphql'
import { compareByCreatedAtDesc } from '@/lib/function'
import { defaultToEmptyList, head } from '@/lib/list'
import { notNil } from '@/lib/type'
import { defaultToEmptyString } from '../string'

const state = reactive<{
  cardApplication: Maybe<AccountHolderCardProductApplication>
}>({
  cardApplication: null,
})

interface ICardApplicationDocumentsOptions {
  onCardApplicationLoaded(): void
}

export const useCardApplicationDocuments = (options?: ICardApplicationDocumentsOptions) => {
  const accountHolder = computed(() => mapApplicationToAccountHolder(state.cardApplication))
  const primaryPerson = computed(() => accountHolder.value?.primaryAuthorizedPerson)
  const personVerification = computed(
    () => accountHolder.value?.primaryAuthorizedPerson?.currentVerification
  )
  const personVerificationUploadSession = computed(() =>
    mapVerificationToUploadSession(personVerification.value)
  )
  const personVerificationUploadSessionSubmitted = computed(() =>
    isUploadSessionSubmitted(personVerificationUploadSession.value?.status)
  )
  const businessVerification = computed(
    () => accountHolder.value?.businessProfile?.currentVerification
  )
  const businessVerificationUploadSession = computed(() =>
    mapVerificationToUploadSession(businessVerification.value)
  )
  const businessVerificationUploadSessionSubmitted = computed(() =>
    isUploadSessionSubmitted(businessVerificationUploadSession.value?.status)
  )
  const uboList = computed(() => {
    return defaultToEmptyList(accountHolder.value?.businessProfile?.ultimateBeneficialOwners)
      .filter(notNil)
      .map((ubo) => {
        return {
          id: ubo.ultimateBeneficialOwnerId,
          name: mapNameToFullName(ubo.name),
          verification: ubo.currentVerification,
        }
      })
  })
  const uboRawVerificationList = computed(() => {
    return uboList.value.map((ubo) => ubo.verification)
  })
  const uboVerificationUploadSessionList = computed(() =>
    uboRawVerificationList.value.filter(notNil).map(mapVerificationToUploadSession)
  )
  const uboVerificationUploadSessionListSubmitted = computed(() =>
    uboVerificationUploadSessionList.value
      .filter(notNil)
      .map((session) => session.status)
      .filter(notNil)
      .every(isUploadSessionSubmitted)
  )

  const uboVerificationList = computed(() => {
    return uboList.value
      .filter(notNil)
      .map((ubo) => {
        const verification = ubo.verification
        const uploadSession = mapVerificationToUploadSession(verification)
        const uploadSessionSubmitted = isUploadSessionSubmitted(uploadSession?.status)
        return {
          id: ubo.id,
          name: ubo.name,
          verification,
          available: !uploadSessionSubmitted,
          uploadSession,
          uploadSessionSubmitted,
        }
      })
      .filter(
        (verification) =>
          notNil(verification.id) &&
          notNil(verification.verification?.requiredDocuments) &&
          verification.verification?.status === AccountHolderVerificationStatusCode.Pending &&
          verification.verification?.reason ===
            AccountHolderVerificationStatusReasonCode.DocumentUploadRequired
      )
  })
  const loadCardApplication = async (id: Maybe<string>) => {
    const res = await getCardProductApplication(id)
    if (!res.ok) {
      return res
    }
    state.cardApplication = res.value
    options?.onCardApplicationLoaded()
    return res
  }

  return {
    cardApplication: computed(() => state.cardApplication),
    loadCardApplication,
    accountHolder,
    uploadSessionList: computed(() =>
      [
        personVerificationUploadSession.value,
        businessVerificationUploadSession.value,
        ...uboList.value.map((ubo) => mapVerificationToUploadSession(ubo.verification)),
      ].filter(notNil)
    ),
    personVerification: computed(() => {
      return {
        name: mapNameToFullName(primaryPerson.value?.name),
        verification: personVerification.value,
        available: !personVerificationUploadSessionSubmitted.value,
        uploadSession: personVerificationUploadSession.value,
        uploadSessionSubmitted: personVerificationUploadSessionSubmitted.value,
      }
    }),
    personVerificationUploadSession,
    personVerificationUploadSessionSubmitted,

    businessVerification: computed(() => {
      return {
        verification: businessVerification.value,
        available: !businessVerificationUploadSessionSubmitted.value,
        uploadSession: businessVerificationUploadSession.value,
        uploadSessionSubmitted: businessVerificationUploadSessionSubmitted.value,
      }
    }),
    businessVerificationUploadSession,
    businessVerificationUploadSessionSubmitted,
    uboVerificationList,
    uboNameById: computed<Record<string, string>>(() =>
      uboVerificationList.value.reduce(
        (sum, val) => (val.id ? Object.assign({}, sum, { [val.id]: val.name }) : sum),
        {}
      )
    ),
    uboVerificationListAvailable: computed(() =>
      uboRawVerificationList.value.some(isVerificationPending)
    ),

    allSessionsSubmitted: computed<boolean>(
      () =>
        Boolean(state.cardApplication) &&
        businessVerificationUploadSessionSubmitted.value &&
        personVerificationUploadSessionSubmitted.value &&
        uboVerificationUploadSessionListSubmitted.value
    ),
    allPendingInReview: computed<boolean>(() => {
      const statusList = [
        businessVerification.value?.status,
        personVerification.value?.status,
        ...uboRawVerificationList.value.map((v) => v?.status),
      ]
      const reasonList = [
        businessVerification.value?.reason,
        personVerification.value?.reason,
        ...uboRawVerificationList.value.map((v) => v?.reason),
      ]
      return [
        statusList.filter(Boolean).every((s) => s === AccountHolderVerificationStatusCode.Pending),
        reasonList
          .filter(Boolean)
          .every((s) => s === AccountHolderVerificationStatusReasonCode.InReview),
      ].every(Boolean)
    }),

    submit: () =>
      submitSessions([
        personVerificationUploadSession.value,
        businessVerificationUploadSession.value,
        ...uboVerificationUploadSessionList.value,
      ]),
    getLatestUploadedDocumentList,
    getLatestUploadedDocument: (
      session: Maybe<UsAccountHolderApplicationDocumentUploadSession>,
      categoryType?: DocumentCategoryType
    ): Maybe<DocumentUploadLink> => {
      return head(getLatestUploadedDocumentList(session, categoryType))
    },
  }
}

const getLatestUploadedDocumentList = (
  session: Maybe<UsAccountHolderApplicationDocumentUploadSession>,
  categoryType?: DocumentCategoryType
): DocumentUploadLink[] => {
  const selectedCategoryType = categoryType ?? DocumentCategoryType.PrimaryDocumentType
  return defaultToEmptyList(session?.documents as DocumentUploadLink[])
    .filter(notNil)
    .filter((d) => d.documentCategoryType === selectedCategoryType)
    .sort(compareByCreatedAtDesc)
    .filter(isUploadLinkUploaded)
}

const submitSessions = async (
  sessionList: Maybe<UsAccountHolderApplicationDocumentUploadSession>[]
) => await Promise.all(sessionList.map((session) => submitSession(session?.id)))

const submitSession = async (id: Maybe<string>): Promise<void> => {
  if (!id) {
    return
  }
  await endApplicationDocumentUploadSession(id)
}

const mapApplicationToAccountHolder = (
  cardApplication: Maybe<AccountHolderCardProductApplication>
) => {
  if (!cardApplication) {
    return null
  }
  const accountHolder = cardApplication.accountHolderSnapshot
  if (!graphqlType.isUsBusinessAccountHolderSnapshot(accountHolder)) {
    return null
  }
  return accountHolder
}

const mapVerificationToUploadSession = (
  verification: Maybe<AccountHolderVerification>
): Maybe<UsAccountHolderApplicationDocumentUploadSession> => {
  const safeList = defaultToEmptyList(verification?.requiredDocuments).filter(
    notNil
  ) as AccountHolderApplicationRequiredDocument[]
  const orderedList = safeList.sort(compareByCreatedAtDesc)
  const latest = head(orderedList)
  const latestCreated = orderedList.find(isRequiredDocumentCreated)
  const requiredDocument = latestCreated ?? latest
  const session = requiredDocument?.documentUploadSession
  return session
}

const isUploadSessionSubmitted = (status: Maybe<DocumentUploadSessionStatusCode>) =>
  !status || status === DocumentUploadSessionStatusCode.Submitted

const isRequiredDocumentCreated = (reqDoc: AccountHolderApplicationRequiredDocument) =>
  reqDoc.status === ApplicationDocumentUploadSessionStatus.Created

const isUploadLinkUploaded = (link: UploadLink): boolean =>
  link.status === DocumentUploadLinkStatusCode.Pending ||
  link.status === DocumentUploadLinkStatusCode.InProgress ||
  link.status === DocumentUploadLinkStatusCode.Completed

const isVerificationPending = (verification: Maybe<AccountHolderVerification>): boolean =>
  verification?.status === AccountHolderVerificationStatusCode.Pending

const mapNameToFullName = (name: Maybe<Name>): string =>
  [name?.givenName, name?.familyName].map(defaultToEmptyString).join(' ')
