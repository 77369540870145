import { BaseError } from './BaseError'
import { ErrorVendor } from './vendor'

export class DomError extends BaseError<any> {
  constructor(msg: string, options?: IDomErrorOptions) {
    super(msg)
    this.name = 'DomError'
    this.originalError = options?.originalError
    this.vendor = options?.vendor
  }
}

interface IDomErrorOptions {
  originalError?: Error
  vendor?: ErrorVendor
}
