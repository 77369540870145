import { formatDateTimeIsoString, instantiateDate } from '@/lib/date'
import { BaseError } from '@/lib/domain/error/BaseError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { defaultTo } from '@/lib/function'
import { consoleLogger } from '@/lib/logger'
import { result, resultEither } from '@/lib/result'
import { isNil } from '@/lib/type'

export const storage = {
  getDate(key: string): Result<Date> {
    return resultEither(
      handleFailedStorage,
      (s) => {
        const storedISODate = s.getItem(key)
        return instantiateDate(storedISODate)
      },
      getStorage()
    )
  },
  getString<T>(key: string): Maybe<T> {
    const storageResult = getStorage()
    if (!storageResult.ok) {
      return null
    }
    return storageResult.value.getItem(key) as Maybe<T>
  },
  setDateTime(key: string, value: Date) {
    localStorage.setItem(key, formatDateTimeIsoString(value))
  },
  setString(key: string, value: string) {
    localStorage.setItem(key, value)
  },
  getObject<T>(key: string): Maybe<T> {
    const storageResult = getStorage()
    if (!storageResult.ok) {
      return null
    }
    const value = storageResult.value.getItem(key)
    return JSON.parse(defaultTo('{}', value) as string)
  },
  setObject<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getNumber(key: string): Maybe<number> {
    const storageResult = getStorage()
    if (!storageResult.ok) {
      return null
    }
    return Number(storageResult.value.getItem(key))
  },
  setNumber(key: string, value: number): void {
    localStorage.setItem(key, String(value))
  },
}

function getStorage(): Result<Storage> {
  const storage = window.localStorage
  if (isNil(storage)) {
    const error = new BaseError('Local storage is not available.')
    error.vendor = ErrorVendor.TillCard
    return result.failed(error)
  }
  return result.ok(storage)
}

function handleFailedStorage(e: Error): Result {
  consoleLogger.error(e)
  return result.failed(e)
}
