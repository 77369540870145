export const gt = <T>(a: T, b: T): boolean => a > b

export const lt = <T>(a: T, b: T): boolean => a < b

export const eq = <T>(a: T, b: T): boolean => a === b

export const eqCurried =
  <T>(a: T) =>
  (b: T): boolean =>
    eq<T>(a, b)

export const not = (a: Maybe<unknown>): boolean => !a

export const gte = (a: number, b: number): boolean => gt(a, b) || eq(a, b)

export const notEq = <T>(a: T, b: T): boolean => not(eq(a, b))
