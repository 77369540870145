<template>
  <Page name="Statements">
    <Box class="bg-white">
      <Loader v-if="!statementListLoaded" class="margin-center" />

      <IconWidget
        v-else-if="listEmpty"
        title="No Statements"
        subtitle="Your first statement will be issued at the end of the current billing cycle"
      >
        <img alt="File" :src="ImageFile" />
      </IconWidget>

      <div v-else>
        <TableView :items="statementListView" :fields="fields">
          <template v-slot:tableCellContent="{ item, field }">
            <StatementDetailItemVue
              v-if="field.name === 'id'"
              @click="handleStatementViewClick(item.id)"
            />
            <StatementsTableCellContent v-else :item="item" :field="field" />
          </template>
        </TableView>

        <ButtonLoadMore
          v-if="loadMoreAvailable"
          :loading="loadMorePending"
          @click="handleLoadMore"
        />
      </div>
    </Box>
  </Page>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import Page from '@/components/Page.vue'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { useStatements } from '@/lib/composable/useStatements'
import { createStatementUrl } from '@/lib/domain/statement'
import Loader from '@/components/Loader.vue'
import TableView from '@/components/TableView.vue'
import IconWidget from '@/components/IconWidget.vue'
import Box from '@/components/Box.vue'
import ImageFile from '@/assets/icon-file.svg'
import ButtonLoadMore from '@/components/ButtonLoadMore.vue'
import StatementDetailItemVue from '@/components/statements/StatementDetailItem.vue'
import StatementsTableCellContent from '@/components/statements/StatementsTableCellContent.vue'
import { FinancialAccountStatementItemFragment } from '@/generated/graphql'
import { openUrlInNewTab } from '@/api/dom/document'

useMountedLog('Statements')
const {
  statementListView,
  statementListLoaded,
  statementListEmpty,
  statementById,
  loadMorePending,
  loadMoreAvailable,
  load,
  loadMore,
  setLoadMorePending,
  resetLoadMorePending,
} = useStatements()

onMounted(load)

const listEmpty = computed<boolean>(() => statementListLoaded.value && statementListEmpty.value)

const fields = [
  { name: 'period', header: 'Statement Period' },
  { name: 'spend', header: 'Spend' },
  { name: 'balance', header: 'New Balance' },
  { name: 'due', header: 'Payment Due By' },
  { name: 'limit', header: 'Available Limit' },
  { name: 'id', header: '' },
]
async function handleLoadMore(): Promise<void> {
  setLoadMorePending()
  await loadMore()
  resetLoadMorePending()
}
function handleStatementViewClick(id: string): void {
  const statement: FinancialAccountStatementItemFragment = statementById.value[id]
  const urlResult = createStatementUrl(statement)
  if (!urlResult.ok) {
    return
  }
  openUrlInNewTab(urlResult.value)
}
</script>
