<script setup lang="ts">
import { computed, ComputedRef } from '@vue/reactivity'
import { PaymentCardStatus } from '../../generated/graphql'
import { capitalizeWord } from '../../lib/string'
import Tag from '../Tag.vue'

const props = defineProps<{ status: PaymentCardStatus }>()

const statusNameMap: Record<PaymentCardStatus, string> = {
  [PaymentCardStatus.ActivationRequired]: PaymentCardStatus.ActivationRequired,
  [PaymentCardStatus.Active]: PaymentCardStatus.Active,
  [PaymentCardStatus.Suspended]: 'LOCKED',
  [PaymentCardStatus.Closed]: PaymentCardStatus.Closed,
}

const statusText: ComputedRef<string> = computed(() => {
  if (!props.status) {
    return ''
  }
  return statusNameMap[props.status].toLowerCase().split('_').map(capitalizeWord).join(' ')
})

const statusCssClass = computed(() => ({
  active: props.status === PaymentCardStatus.Active,
  inactive: props.status === PaymentCardStatus.ActivationRequired,
  closed: props.status === PaymentCardStatus.Closed,
  suspended: props.status === PaymentCardStatus.Suspended,
}))
</script>

<template>
  <Tag class="CardStatusTag" :class="statusCssClass">{{ statusText }}</Tag>
</template>

<style>
.CardStatusTag.active {
  color: var(--c-green);
  background: var(--bg-green2);
}
.CardStatusTag.inactive {
  color: var(--c-black);
  background: var(--bg-grey3);
}
.CardStatusTag.closed {
  color: var(--c-grey5);
  background: var(--white);
  border: solid 1px var(--c-grey5);
}
.CardStatusTag.suspended {
  color: var(--c-red);
  background: var(--bg-red);
}
</style>
