<template>
  <Modal opened @onClose="handleCancel">
    <div class="RemoveBankAccountModal__content d-flex flex-direction-column align-center">
      <div class="RemoveBankAccountModal__icon-container bg-red flex-center mb-32">
        <Icon name="trash" class="fs-32 c-red4" />
      </div>

      <h2 class="fs-20 fw-bold text-center c-black mb-16">
        Are you sure you want to remove this bank account?
      </h2>

      <Message
        v-if="disabled"
        variant="error"
        class="fs-14 fw-500 mb-32 text-center"
        :dismissable="false"
      >
        Cannot remove bank account. Please ensure you have added another bank to your account.
      </Message>

      <Message
        v-if="error"
        variant="error"
        class="fs-14 fw-500 mb-32 text-center"
        :dismissable="false"
      >
        {{ error.message }}
      </Message>

      <Button
        v-if="!disabled"
        variant="primary"
        color="red"
        size="large"
        class="mb-12"
        style="background-color: var(--c-red4); min-width: 250px; width: 100%"
        :disabled="loading"
        :loading="loading"
        @click="handleSubmit"
      >
        Remove
      </Button>

      <Button
        variant="secondary"
        size="large"
        style="background-color: white; min-width: 250px; width: 100%"
        @click="handleCancel"
      >
        Cancel
      </Button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

import Message from '@/components/Message.vue'
import { IPaymentAccount } from '@/lib/domain/types'

const props = defineProps<{
  account: IPaymentAccount
  loading: boolean
  disabled: boolean
  error: Maybe<Error>
}>()
const emit = defineEmits<{
  (event: 'onSubmit', value: string): void
  (event: 'onCancel', value: string): void
}>()
const handleCancel = () => {
  if (!props.account.id) {
    return
  }
  emit('onCancel', props.account.id)
}
const handleSubmit = () => {
  if (!props.account.id) {
    return
  }
  emit('onSubmit', props.account.id)
}
</script>

<style>
.RemoveBankAccountModal__content {
  padding: 0 52px;
}
.RemoveBankAccountModal__icon-container {
  width: 76px;
  height: 76px;
  border-radius: 50%;
}
</style>
