import { InjectionKey, inject, provide } from 'vue'

export type DomProvider = {
  window: Window
}

export const DOM_INJECTION_KEY = Symbol('dom') as InjectionKey<DomProvider>

export function provideDom(): void {
  provide(DOM_INJECTION_KEY, createDomProvider())
}

export function createDomProvider(): DomProvider {
  return {
    window: window,
  }
}

export function injectDom(): DomProvider {
  const injected = inject(DOM_INJECTION_KEY)
  if (!injected) {
    throw new Error('Dom not provided')
  }
  return injected
}
