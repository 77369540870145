import { inject, InjectionKey, provide } from 'vue'

import { createPaymentCardStore, IPaymentCardStore } from '@/store/paymentCard'

const store = createPaymentCardStore()

export const PAYMENT_CARD_STORE_INJECTION_KEY = Symbol() as InjectionKey<IPaymentCardStore>

export const providePaymentCardStore = () => {
  provide(PAYMENT_CARD_STORE_INJECTION_KEY, store)
}

export const injectPaymentCardStore = () => inject(PAYMENT_CARD_STORE_INJECTION_KEY)
