import { BaseError } from '@/lib/domain/error/BaseError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { AppSyncApiError } from '@/lib/domain/error/AppSyncApiError'

export class ApiError extends BaseError<any> {
  constructor(msg: string, options?: IApiErrorOptions<any>) {
    super(msg)
    this.name = 'ApiError'
    this.payload = options?.payload
    this.originalError = options?.originalError
    this.vendor = options?.vendor
  }
}

export interface IApiErrorOptions<T> {
  originalError?: Error | AppSyncApiError
  payload?: T
  vendor?: ErrorVendor
}
