import { provide } from 'vue'

import { FINANCIAL_INSTITUTION_STORE_INJECTION_KEY } from '@/constants'
import { createFinancialInstitutionStore } from '@/store/financialInstitution'

export const financialInstitutionStore = createFinancialInstitutionStore()

export const provideFinancialInstitutionStore = () => {
  provide(FINANCIAL_INSTITUTION_STORE_INJECTION_KEY, financialInstitutionStore)
}
