<template>
  <nav
    class="PageMenu bg-white d-flex justify-start f-dir-column"
    :class="{ 'PageMenu-expanded': menuExpanded }"
  >
    <img :src="Logo" class="PageMenu__title" width="65" />
    <Icon class="PageMenu__icon cursor-pointer" name="menu" @click="handleToggleMenuClick" />
    <p class="PageMenu__subtitle c-grey fs-14 fw-600 mb-12">{{ companyName }}</p>
    <p class="PageMenu__account fs-14 fw-500 c-grey2 mb-32">Account # {{ accountNumber }}</p>
    <ul class="PageMenu__menu c-grey1 f-grow1 d-flex justify-start f-dir-column">
      <li
        v-if="isOnboardingMenuAvailable"
        class="PageMenu__menu__item-onboarding fs-14 fw-500"
        style="margin-bottom: 40px"
      >
        <LinkInternal :name="RouteName.Onboarding" class="c-grey1 d-flex w-100">
          <PageMenuItemOnboarding />
        </LinkInternal>
      </li>

      <li class="fs-14 fw-500">
        <LinkInternal
          :name="RouteName.Dashboard"
          class="c-grey1 d-flex w-100"
          :disabled="!appViewAvailable"
        >
          <Icon name="home" />
          <span class="PageMenu__menu__item__text">Dashboard</span>
        </LinkInternal>
      </li>
      <li class="fs-14 fw-500 d-flex justify-between">
        <LinkInternal
          :name="RouteName.Cards"
          class="c-grey1 d-flex w-100"
          :disabled="!appViewAvailable"
        >
          <Icon name="credit-card" />
          <span class="PageMenu__menu__item__text f-grow1">Cards</span>
          <ActiveCardBadge />
        </LinkInternal>
      </li>
      <li class="fs-14 fw-500">
        <LinkInternal
          :name="RouteName.Payments"
          class="c-grey1 d-flex w-100"
          :disabled="!appViewAvailable"
        >
          <Icon name="payment" style="margin-right: 10px" />
          <span class="PageMenu__menu__item__text">Payments</span>
        </LinkInternal>
      </li>
      <li class="fs-14 fw-500 d-flex justify-between">
        <Link class="c-grey1 d-flex w-100" target="_blank" :href="TILLFULL_LINK.href">
          <Icon name="tillful-logo" />
          <span class="PageMenu__menu__item__text f-grow1">{{ TILLFULL_LINK.text }}</span>
        </Link>
      </li>
      <li class="fs-14 fw-500 d-flex justify-between">
        <LinkInternal
          :name="RouteName.Statements"
          class="c-grey1 d-flex w-100"
          :disabled="!appViewAvailable"
        >
          <Icon name="file-text" />
          <span class="PageMenu__menu__item__text f-grow1">Statements</span>
        </LinkInternal>
      </li>
      <li class="fs-14 fw-500">
        <LinkInternal :name="RouteName.Settings" class="c-grey1 d-flex w-100">
          <Icon name="settings" />
          <span class="PageMenu__menu__item__text">Settings</span>
        </LinkInternal>
      </li>
      <li class="fs-14 fw-500 f-grow1">
        <LinkInternal :name="RouteName.Support" class="c-grey1 d-flex w-100">
          <Icon name="life-buoy" />
          <span class="PageMenu__menu__item__text">Support</span>
        </LinkInternal>
      </li>
      <li class="fs-14 fw-500" style="margin-bottom: 0">
        <LinkInternal :name="RouteName.Agreements" class="c-grey1 d-flex w-100">
          <Icon name="file-text" />
          <span class="PageMenu__menu__item__text">Agreements</span>
        </LinkInternal>
      </li>
      <Divider />
      <li class="fs-14 fw-500">
        <LinkInternal @onClick="handleLogout" class="c-grey1 d-flex w-100">
          <Icon name="log-out" />
          <span class="PageMenu__menu__item__text">Log out</span>
        </LinkInternal>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import Icon from './Icon.vue'
import LinkInternal from './LinkInternal.vue'
import Link from './Link.vue'
import Logo from '../assets/tillfull.svg'
import ActiveCardBadge from './cards/ActiveCardBadge.vue'
import Divider from './Divider.vue'
import { useOnboardingStore } from '@/store/onboarding'
import { useAccountHolder } from '@/store/accountHolder'
import { useBool } from '@/lib/composable/useBool'
import PageMenuItemOnboarding from '@/components/PageMenuItemOnboarding.vue'
import { RouteName } from '@/router/route'
import { TILLFULL_LINK } from '@/constants'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'

const { appViewAvailable, isOnboardingMenuAvailable } = useOnboardingStore()
const { companyName, accountNumber } = useAccountHolder()
const { value: menuExpanded, toggle: toggleMenu } = useBool(false)
const $auth = injectAuthFunctions()

const handleLogout = () => $auth.logout()
const handleToggleMenuClick = toggleMenu
</script>

<style>
.PageMenu {
  padding: 24px 16px 0px 24px;
}

.PageMenu__icon {
  display: block;
  margin-bottom: 80px;
}

.PageMenu__title {
  margin-bottom: 32px;
}

@media all and (max-width: 1200px) {
  .PageMenu {
    padding-top: 40px;
  }
  .PageMenu-expanded {
    position: relative;
    /* reflect grid column width in parent Page component */
    width: 272px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.06);
  }

  .PageMenu__subtitle,
  .PageMenu__title,
  .PageMenu:not(.PageMenu-expanded) .ActiveCardBadge,
  .PageMenu__account,
  .PageMenu__menu__item-onboarding,
  .PageMenu:not(.PageMenu-expanded) .PageMenu__menu__item__text {
    display: none;
  }

  .PageMenu__menu li .Icon {
    font-size: 20px;
  }
}

@media all and (min-width: 1201px) {
  .PageMenu__icon {
    display: none;
  }
}

.PageMenu__menu {
  list-style-type: none;
  padding-left: 0;
}
.PageMenu__menu li {
  margin-bottom: 24px;
}
.PageMenu__menu li .Icon {
  margin-right: 16px;
}
</style>
