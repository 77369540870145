<template>
  <Page name="Payments">
    <Box class="bg-white mb-24">
      <div v-if="bankAvailable" class="fs-12 fw-500 c-grey2 mb-12">Bank Account</div>

      <div class="Payments__account d-flex justify-between align-center">
        <BankAccountsLink v-if="bankAvailable" :count="linkListCount" />
        <div v-else />

        <Button
          variant="primary"
          class="Payments__account__pay"
          data-test-id="new-payment"
          :disabled="!newPaymentAvailable"
          @click="handlePaymentClick"
        >
          <Icon name="plus-circle" class="mr-4" /> New Payment
        </Button>

        <PaymentModal
          v-if="paymentModal.opened"
          :variant="'payment'"
          :opened="paymentModal.opened"
          :accountList="paymentModal.accountList"
          @close="handlePaymentModalClose"
        />
      </div>
    </Box>

    <Box class="bg-white">
      <div class="Payment__list-head d-flex justify-between align-center mb-24">
        <h2 class="fs-20 fw-bold c-black mr-16">Payment History</h2>
        <FormDateRangeFilter
          :value="dateRange"
          :max="state.maxDate"
          @change="handleDateRangeChange"
        />
      </div>

      <Loader v-if="loading" style="margin: 0 auto" />
      <PaymentListEmpty v-else-if="paymentListEmpty" />
      <PaymentList v-else :items="state.paymentList" />

      <ButtonLoadMore v-if="loadMoreAvailable" :loading="loadMoreLoading" @click="loadMore" />
    </Box>
  </Page>
</template>

<script setup lang="ts">
import { capitalize, computed, ComputedRef, onMounted, reactive } from 'vue'

import Page from '@/components/Page.vue'
import Box from '@/components/Box.vue'
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import { useFinancialInsitutionStore } from '@/store/useFinancialInsitutionStore'
import { usePaymentsStore } from '@/store/payments'
import { isEmpty, map } from '@/lib/list'
import { mapRangeValue, Range } from '@/lib/range'
import {
  createDate,
  formatDateIsoString,
  formatDateMonthShort,
  formatDateMonthShortYearFull,
  formatTime,
  now,
  today,
} from '@/lib/date'
import FormDateRangeFilter from '@/components/FormDateRangeFilter.vue'
import { formatCurrency } from '@/lib/number'
import PaymentModal from '@/components/payment/PaymentModal.vue'
import PaymentListEmpty from '@/components/payment/PaymentListEmpty.vue'
import {
  AchTransactionEdgeNode,
  IntegratorInitiatedAchStatus,
  OneTimeAchTransfer,
  RecurringAchTransfer,
  ScheduledTransferStatusCode,
} from '@/generated/graphql'
import ButtonLoadMore from '@/components/ButtonLoadMore.vue'
import Loader from '@/components/Loader.vue'
import { usePolling } from '@/lib/composable/usePolling'
import BankAccountsLink from '@/components/payment/BankAccountsLink.vue'
import { useAccountHolder } from '@/store/accountHolder'
import { gt } from '@/lib/logic'
import { IPaymentView, IPaymentAccount } from '@/lib/domain/types'
import PaymentList from '@/components/payment/PaymentList.vue'
import { defaultToEmptyString } from '@/lib/string'

interface IPaymentHistoryState {
  maxDate: string
  paymentList: ComputedRef<IPaymentView[]>
}

const {
  paymentList,
  loading,
  loadMoreLoading,
  updatePaymentList,
  loadMore,
  setPaymentDateFilter,
  setLoading,
  resetLoading,
  filter,
  loadMoreAvailable,
} = usePaymentsStore()

const state = reactive<IPaymentHistoryState>({
  maxDate: formatDateIsoString(today()),
  paymentList: computed(() => map(mapPaymentToView, paymentList.value) as IPaymentView[]),
})

const dateRange: ComputedRef<Range<string>> = computed(() =>
  mapRangeValue(formatDateIsoString, filter.value.date)
)

const { creditLimit } = useAccountHolder()
const { linkList, linkListCount } = useFinancialInsitutionStore()
const apiPolling = usePolling({ time: 5000, callback: updatePaymentList })

const paymentListEmpty: ComputedRef<boolean> = computed(() => isEmpty(state.paymentList))

onMounted(apiPolling.start)

const bankAvailable = computed<boolean>(() => gt(linkListCount.value, 0))
const newPaymentAvailable = computed<boolean>(() => bankAvailable.value && gt(creditLimit.value, 0))

const handleDateRangeChange = async (e: Range<string>) => {
  setPaymentDateFilter(mapRangeValue(createDate, e))
  setLoading()
  await updatePaymentList()
  resetLoading()
}

const paymentModal = reactive<{
  opened: boolean
  accountList: IPaymentAccount[]
}>({
  opened: false,
  accountList: linkList.value,
})
const handlePaymentClick = () => {
  paymentModal.opened = true
}
const handlePaymentModalClose = () => {
  paymentModal.opened = false
}

function mapPaymentToView(
  payment: AchTransactionEdgeNode | OneTimeAchTransfer | RecurringAchTransfer
): Maybe<IPaymentView> {
  const updatedDate = payment.updatedAt ? new Date(payment.updatedAt) : now()

  switch (payment.__typename) {
    case 'SecureDepositACHTransfer':
    case 'SecureCardBalanceRepaymentACHTransfer': {
      const settlementDate = payment.settlementDate ? new Date(payment.settlementDate) : now()
      return {
        id: payment.id,
        initiatedDate: formatDateMonthShortYearFull(settlementDate),
        initiatedDateShort: formatDateMonthShort(settlementDate),
        initiatedTime: formatTime(settlementDate),
        updatedDate: formatDateMonthShortYearFull(updatedDate),
        updatedDateShort: formatDateMonthShort(updatedDate),
        updatedTime: formatTime(updatedDate),
        status: capitalize(String(payment.status?.status).toLowerCase()),
        statusColor: getPaymentStatusColor(payment.status?.status),
        details: getPaymentDetails(payment),
        amount: formatCurrency((payment.amount?.value || 0) / 100),
        accountMask: getPaymentBankAccountMask(payment),
      }
    }
    case 'OneTimeACHTransfer': {
      const createdAt = payment.createdAt ? new Date(payment.createdAt) : now()
      return {
        id: payment.id,
        initiatedDate: formatDateMonthShortYearFull(createdAt),
        initiatedDateShort: formatDateMonthShort(createdAt),
        initiatedTime: formatTime(createdAt),
        updatedDate: formatDateMonthShortYearFull(updatedDate),
        updatedDateShort: formatDateMonthShort(updatedDate),
        updatedTime: formatTime(updatedDate),
        status: capitalize(String(payment.status).toLowerCase()),
        statusColor: getPaymentStatusColor(payment.status),
        details: getPaymentDetails(payment),
        amount: formatCurrency(getTransferAmount(payment) / 100),
        accountMask: getPaymentBankAccountMask(payment),
      }
    }
    case 'RecurringACHTransfer': {
      const createdAt = payment.createdAt ? new Date(payment.createdAt) : now()
      return {
        id: payment.id,
        initiatedDate: formatDateMonthShortYearFull(createdAt),
        initiatedDateShort: formatDateMonthShort(createdAt),
        initiatedTime: formatTime(createdAt),
        updatedDate: formatDateMonthShortYearFull(updatedDate),
        updatedDateShort: formatDateMonthShort(updatedDate),
        updatedTime: formatTime(updatedDate),
        status: capitalize(String(payment.status).toLowerCase()),
        statusColor: getPaymentStatusColor(payment.status),
        details: getPaymentDetails(payment),
        amount: '',
        accountMask: getPaymentBankAccountMask(payment),
      }
    }
    default:
      return null
  }
}

function getPaymentStatusColor(
  status: Maybe<IntegratorInitiatedAchStatus | ScheduledTransferStatusCode>
): string {
  if (!status) {
    return ''
  }
  switch (status) {
    case IntegratorInitiatedAchStatus.Failed:
    case IntegratorInitiatedAchStatus.Canceled:
    case IntegratorInitiatedAchStatus.Returned:
    case ScheduledTransferStatusCode.Canceled:
      return 'var(--c-red4)'
    case IntegratorInitiatedAchStatus.Processed:
    case ScheduledTransferStatusCode.Closed:
      return 'var(--c-grey8)'
    case IntegratorInitiatedAchStatus.Initiated:
    case ScheduledTransferStatusCode.Scheduled:
      return 'var(--c-green6)'
    case IntegratorInitiatedAchStatus.Processing:
    case ScheduledTransferStatusCode.Active:
      return 'var(--c-orange)'
    default:
      return ''
  }
}

function getPaymentDetails(
  payment: Maybe<AchTransactionEdgeNode | OneTimeAchTransfer | RecurringAchTransfer>
): string {
  if (!payment) {
    return ''
  }
  switch (payment.__typename) {
    case 'SecureDepositACHTransfer':
      return 'Deposit'
    case 'SecureCardBalanceRepaymentACHTransfer':
    case 'OneTimeACHTransfer':
      return 'Payment'
    case 'RecurringACHTransfer':
      return 'Autopay'
    default:
      return ''
  }
}

function getTransferAmount(payment: Maybe<OneTimeAchTransfer>): number {
  if (payment?.transferAmount?.__typename === 'ManualTransferAmount') {
    return payment.transferAmount.amount?.value || 0
  }
  return 0
}

function getPaymentBankAccountMask(
  payment: Maybe<AchTransactionEdgeNode | OneTimeAchTransfer | RecurringAchTransfer>
): string {
  if (!payment) {
    return ''
  }
  const account = payment.fromFinancialAccount
  return account?.__typename === 'ExternalFinancialBankAccount'
    ? defaultToEmptyString(account.externalBankAccountDetails?.last4)
    : ''
}
</script>

<style>
@media all and (max-width: 768px) {
  .Payments__account {
    flex-direction: column;
  }
  .Payments__account__pay {
    width: 100%;
  }
}
</style>
