<template>
  <div class="SettingsBank__list__item mb-24">
    <button class="SettingsBank__list__item__close cursor-pointer bg-white" @click="handleRemove">
      <Icon name="trash" class="c-red1 fs-16" />
    </button>
    <div class="SettingsBank__list__item__logo mb-16">
      <img v-if="account.institution.logo" width="64" height="64" :src="logoSrc" alt="Logo" />
      <Icon v-else name="bank" class="c-grey1 fs-24" />
    </div>
    <div class="mb-8 c-grey1 fs-14 fw-bold uppercase text-center">
      {{ account.institution.name }}
    </div>
    <div class="mb-8 c-grey2 fs-14 fw-600">Account {{ account.accountDetails.mask }}</div>
    <div class="mb-8 c-grey4 fs-14 fw-500">
      {{ capitalizeWord(defaultToEmptyString(account.accountDetails.type)) }}
    </div>
    <Tag v-if="autopayEnabled" class="SettingsBank__list__item__autopay">Autopay</Tag>
  </div>
</template>

<script setup lang="ts">
import { IPaymentAccount } from '@/lib/domain/types'
import { capitalizeWord, defaultToEmptyString } from '@/lib/string'
import Icon from '@/components/Icon.vue'
import Tag from '@/components/Tag.vue'
import { computed } from 'vue'

const props = defineProps<{
  account: IPaymentAccount
  autopayEnabled?: boolean
}>()
const emit = defineEmits<{
  (event: 'onRemove', value: string): void
}>()
const logoSrc = computed<string>(() => `data:image/jpeg;base64, ${props.account.institution.logo}`)
const handleRemove = () => {
  if (!props.account.id) {
    return
  }
  emit('onRemove', props.account.id)
}
</script>

<style>
.SettingsBank__list__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 280px;
  height: 276px;
  flex-grow: 0;
  padding: 60px 50px;
  border-radius: 8px;
  border: solid 1px #e5e7eb;
}
.SettingsBank__list__item .SettingsBank__list__item__autopay {
  color: var(--c-green5);
  background-color: #e7f6df;
  position: absolute;
  top: 16px;
  left: 16px;
}
.SettingsBank__list__item__close,
.SettingsBank__list__item__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: solid 1.5px #16a34a;
}
.SettingsBank__list__item__close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  border: 0;
}
</style>
