import {
  TransactionListAuthorizationEventFragment,
  TransactionListClearingEventFragment,
} from '@/generated/graphql'
import { createDate, formatDateSlashes, formatTime } from '@/lib/date'
import { negate } from '@/lib/number'
import { CSVSerializeable, defaultToEmptyString, replace, toPascalCase } from '@/lib/string'

export const mapTransactionToCSVSerializeable = (
  transaction: TransactionListClearingEventFragment
): CSVSerializeable => {
  return {
    /**
     * First three columns required for other software interface
     */
    'Date': transaction.createdAt ? formatDateSlashes(createDate(transaction.createdAt)) : '',
    'Description': transaction.merchantDetails?.name || 'Unknown',
    'Amount': getClearingEventAmount(transaction).toFixed(2),
    /**
     * Additional data
     */
    'Id': transaction.id,
    'Time': transaction.createdAt ? formatTime(createDate(transaction.createdAt)) : '',
    'Category': mapEventToCategoryView(transaction),
    'Currency': defaultToEmptyString(transaction.approvedAmount?.currencyCode),
    'Last 4': defaultToEmptyString(transaction.paymentCard?.last4),
  }
}

export function mapEventToCategoryView(
  node: TransactionListAuthorizationEventFragment | TransactionListClearingEventFragment
): string {
  return toPascalCase(replace(/_/g, ' ', node.merchantDetails?.category || ''))
}

export function getClearingEventAmount(node: TransactionListClearingEventFragment): number {
  const amount = (node.approvedAmount?.value || 0) / 100
  return node.transaction?.__typename === 'DebitTransaction' ? amount : negate(amount)
}

export function getAuthorizationEventAmount(
  node: TransactionListAuthorizationEventFragment
): number {
  return node.transaction?.__typename === 'DebitTransaction'
    ? /**
       * For DebitTransaction use difference between transaction's approved amount
       * and all clearing events amounts
       */
      (node.transaction.transactionEvents || []).reduce((sum, val) => {
        if (val.__typename === 'ClearingEvent') {
          return sum - (val.approvedAmount?.value || 0)
        }
        return sum
      }, node.approvedAmount?.value || 0) / 100
    : /**
       * For CreditTransaction just use its negated value
       */
      negate((node.approvedAmount?.value || 0) / 100)
}
