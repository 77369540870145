import { ENV } from '@/lib/env'
import { loadScript, removeScript } from '@/api/dom/script'
import { IHubSpotConfigOptions } from '@/plugins/hubspot/types'

const SCRIPTS_SRC = {
  JQUERY: 'https://code.jquery.com/jquery-3.6.0.min.js',
  HUBSPOT: ENV.TC_HUBSPOT_SCRIPT_SRC,
}

export async function loadHubSpotScripts() {
  if (!(window as any).hbspt) {
    removeScript(SCRIPTS_SRC.JQUERY)
    removeScript(SCRIPTS_SRC.HUBSPOT)
    await loadScript(SCRIPTS_SRC.JQUERY)
    await loadScript(SCRIPTS_SRC.HUBSPOT)
  }
}

export const createHubSpotConfig = (options: IHubSpotConfigOptions) => {
  return {
    region: options.region,
    portalId: options.portalId,
    formId: options.formId,
    target: options.selector,
    translations: options.translations,
    onFormSubmitted: options.onSubmit,
  }
}
