import { onUnmounted } from 'vue'

import { createPolling } from '@/lib/function'

interface IUsePollingOptions {
  callback: () => unknown
  time: number
  immediate?: boolean
}
export const usePolling = (options: IUsePollingOptions) => {
  const polling = createPolling(options.callback, options.time)
  const immediate = options.immediate ?? true

  onUnmounted(polling.stop)

  return {
    start: () => {
      if (immediate) {
        options.callback()
      }
      polling.start()
    },
    stop: polling.stop,
  }
}
