<template>
  <div class="StatementDetailsPage bg-grey1">
    <div
      class="StatementDetailsPage__container margin-center bg-white"
      style="
        max-width: 720px;
        min-height: 100vh;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.06);
      "
    >
      <div class="StatementDetailsPage__header bg-grey2 pad-32 mb-32">
        <img :src="Logo" class="PageMenu__title mb-24" width="65" />
        <h1 class="fs-20 fw-700 mb-24" style="line-height: 30px">
          Statement for account ending in {{ accountNumberLast4 }}
          <br />
          {{ businessInformation.legalName }}
        </h1>

        <div class="fs-14 fw-500 c-grey2" style="display: grid; grid-template-columns: 1fr 1fr">
          <div class="text-left mb-8">Statement Period</div>
          <div v-if="screenMediumAndMore" class="text-right mb-8">
            {{ statementDetailsView.period }}
          </div>
          <div v-else class="text-right mb-8">{{ statementDetailsView.periodShort }}</div>

          <div class="text-left mb-8">Total Spend</div>
          <div class="text-right mb-8">{{ statementDetailsView.purchases }}</div>

          <div class="text-left mb-8">New Balance</div>
          <div class="text-right mb-8">{{ statementDetailsView.newBalance }}</div>

          <div class="text-left mb-8">Payment Due Date</div>
          <div class="text-right mb-8">{{ statementDetailsView.paymentDue }}</div>
        </div>
      </div>

      <div class="StatementDetailsPage__body padh-32 mb-32">
        <h3 class="fs-16 fw-17 c-black2 mb-16">Overview</h3>
        <div class="fs-14 fw-500 c-grey2" style="display: grid; grid-template-columns: 1fr 1fr">
          <div class="text-left mb-12">Credit Limit</div>
          <div class="text-right mb-12">{{ statementDetailsView.creditLimit }}</div>

          <div class="text-left mb-12">Available Limit</div>
          <div class="text-right mb-12">{{ statementDetailsView.availableLimit }}</div>

          <div class="text-left mb-12">New Balance</div>
          <div class="text-right mb-12">{{ statementDetailsView.newBalance }}</div>
        </div>
      </div>

      <div class="StatementDetailsPage__body padh-32">
        <h3 class="fs-16 fw-17 c-black2 mb-16">Account Summary</h3>
        <div
          class="fs-14 fw-500 c-grey2 mb-32"
          style="display: grid; grid-template-columns: 1fr 1fr"
        >
          <div class="text-left mb-12">Previous Balance</div>
          <div class="text-right mb-12">{{ statementDetailsView.previousBalance }}</div>

          <div class="text-left mb-12">Payments, Credits</div>
          <div class="text-right mb-12">{{ statementDetailsView.paymentsCredits }}</div>

          <div class="text-left mb-12">Fee Charges</div>
          <div class="text-right mb-12">{{ statementDetailsView.feeCharges }}</div>

          <div class="text-left mb-12">Purchases</div>
          <div class="text-right mb-12">{{ statementDetailsView.purchases }}</div>

          <div class="text-left mb-12">New Balance</div>
          <div class="text-right mb-12">{{ statementDetailsView.newBalance }}</div>
        </div>
      </div>

      <div
        v-if="!isEmpty(statementDetailsView.paymentAndCreditList)"
        class="StatementDetailsPage__body padh-32"
      >
        <h3 class="fs-16 fw-17 c-black2 mb-16">Payments and Credits</h3>
        <template v-for="(item, index) of statementDetailsView.paymentAndCreditList" :key="item.id">
          <Divider v-if="!screenMediumAndMore && index > 0" />
          <StatementDetailEntry class="mb-16" :value="item" :mobile="!screenMediumAndMore" />
        </template>
      </div>

      <div
        v-if="!isEmpty(statementDetailsView.purchaseList)"
        class="StatementDetailsPage__body padh-32"
      >
        <h3 class="fs-16 fw-17 c-black2 mb-16">Purchases</h3>
        <template v-for="(item, index) of statementDetailsView.purchaseList" :key="item.id">
          <Divider v-if="!screenMediumAndMore && index > 0" />
          <StatementDetailEntry class="mb-16" :value="item" :mobile="!screenMediumAndMore" />
        </template>
      </div>

      <div class="StatementDetailsPage__footer">
        <Divider />
        <h3 class="padh-32 fs-16 fw-17 c-black2 mb-16">Legal Notice</h3>
        <p class="padh-32 fs-10 fw-400 c-grey2" style="line-height: 15px">
          You are responsible for the periodic statements and for reviewing charges in your Tillful
          Card account (<Link href="https://card.tillful.com/" class="c-green2"
            >https://card.tillful.com/</Link
          >). You must immediately notify Tillful of any errors in this statement. If you are not
          satisfied with the goods or services provided by a merchant, you must attempt to resolve
          this dispute with the merchant before contacting Tillful. We will investigate any claimed
          errors or disputes reported to us but may require additional information to assist us in
          the investigation. We are not responsible for any errors or disputes that go unreported
          for 60 days or more after posting to your Tillful Credit Account. Your company's use of
          your Tillful Credit Account is subject to the Tillful Credit Agreement (<Link
            href="https://www.tillful.com/credit-agreement"
            class="c-green2"
            >https://www.tillful.com/credit-agreement</Link
          >) and the Tillful Cardholder Agreement (<Link
            href="https://www.tillful.com/agreement-card"
            class="c-green2"
            >https://www.tillful.com/agreement-card</Link
          >)
        </p>
      </div>
    </div>

    <div
      class="StatementDetailsPage__support d-flex justify-around padv-40 padh-32 margin-center"
      :class="{ 'flex-direction-column': !screenMediumAndMore }"
      style="max-width: 720px"
    >
      <div class="fs-12 fw-600 c-black2">Need Help?</div>
      <div class="fs-12 fw-500 c-grey2">
        Email us <Link class="c-green2" href="mailto:card@tillful.com">card@tillful.com</Link>
      </div>
      <div class="fs-12 fw-500 c-grey2">
        FAQ
        <Link class="c-green2" href="https://help.tillful.com/card" target="_blank"
          >https://help.tillful.com/card</Link
        >
      </div>
      <div class="fs-12 fw-500 c-grey2">
        Support
        <LinkInternal class="c-green2" to="/support" target="_blank"
          >https://card.tillful.com/support</LinkInternal
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useBreakpoint } from 'vue-composable'

import Logo from '@/assets/tillfull.svg'
import Divider from '@/components/Divider.vue'
import Link from '@/components/Link.vue'
import StatementDetailEntry from '@/components/statements/StatementDetailEntry.vue'

import { useAccountHolder } from '@/store/accountHolder'
import { useStatementDetails } from '@/lib/composable/useStatementDetails'
import { isEmpty } from '@/lib/list'
import { defaultToEmptyString } from '@/lib/string'
import LinkInternal from '@/components/LinkInternal.vue'

const { statementDetailsView } = useStatementDetails()
const { accountNumber, businessInformation } = useAccountHolder()
const { screenMediumAndMore } = useBreakpoint({ screenMediumAndMore: 768 })
const accountNumberLast4 = computed<string>(() =>
  defaultToEmptyString(accountNumber.value).slice(-4)
)
</script>
