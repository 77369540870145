<template>
  <MessagePopup :opened="isOpen">
    <div class="Cookie__content c-black2 mb-16">
      We use cookies to Improve your experience and for marketing. Read our
      <a
        class="discard-anchor c-black2 fw-bold"
        :href="AGREEMENTS_LINK.termsService.href"
        target="_blank"
        >cookie policy</a
      >
      or <span class="fw-bold">manage cookies</span>.
    </div>
    <Button type="button" variant="primary" @click="handleClick('true')">Accept All</Button>
    <Button type="button" class="ml-16" @click="handleClick('false')" bordered>Reject All</Button>
  </MessagePopup>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import Button from '@/components/Button.vue'
import MessagePopup from '@/components/MessagePopup.vue'
import { GDPR, AGREEMENTS_LINK } from '@/constants'
import { storage } from '@/api/storage/storage'

const router = useRouter()
let isOpen: Ref<boolean> = ref(true)

onMounted(async () => {
  const loadedPage = router.currentRoute.value.name
  if (storage.getString(GDPR) || !loadedPage) {
    isOpen.value = false
  }
})

const handleClick = (answer: string) => {
  isOpen.value = false
  storage.setString(GDPR, answer)
}
</script>
