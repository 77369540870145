import { computed, reactive } from 'vue'

import { getTransactionList } from '@/api/graphql/graphqlAPI'
import { graphqlType } from '@/api/graphql/graphqlType'
import {
  TransactionListAuthorizationEventFragment,
  TransactionListClearingEventFragment,
  TransactionListFinancialAccountFragment,
} from '@/generated/graphql'
import { addDays, formatDateIsoString, formatTime, today } from '@/lib/date'
import { compareByCreatedAtDesc } from '@/lib/function'
import { negate } from '@/lib/number'
import { mapRangeValue, Range } from '@/lib/range'
import { replace, toPascalCase } from '@/lib/string'
import { useAuthStore } from '@/store/auth'
import {
  getAuthorizationEventAmount,
  getClearingEventAmount,
  mapEventToCategoryView,
} from '@/lib/domain/transaction'
import { CARD_ID_DEFAULT_FILTER } from '@/constants'

export interface ITransactionView {
  id: string
  status: string
  createdAt?: Maybe<string>
  time?: string
  name?: string
  description?: Maybe<string>
  category?: string
  amount?: number
  currency?: Maybe<string>
  last4?: Maybe<string>
}

export interface ITransactionPageData {
  cursor: Maybe<string>
  hasNextPage: boolean
}

interface ITransactionData {
  list: ITransactionView[]
  loading: boolean
  loadMoreLoading: boolean
  pending: ITransactionPageData
  cleared: ITransactionPageData
  filter: {
    cardId: Maybe<string>
    date: Range<Date>
  }
}

export interface ILoadTransactionListOptions {
  pendingCursor: Maybe<string>
  pendingHasNext: boolean
  clearedCursor: Maybe<string>
  clearedHasNext: boolean
}

export const useTransactions = () => {
  const data: ITransactionData = reactive({
    list: [],
    loading: false,
    loadMoreLoading: false,
    pending: { cursor: null, hasNextPage: true },
    cleared: { cursor: null, hasNextPage: true },
    filter: {
      cardId: CARD_ID_DEFAULT_FILTER,
      date: { from: addDays(-30, today()), to: today() },
    },
  })

  /**
   * Load transaction event list with pending and cleared events.
   * Set result to the data store.
   * @param options - specify pagination cursor for each event type.
   * Specify if there is the next page should be loaded for each event type.
   * @returns - Promise
   */
  async function load(options: ILoadTransactionListOptions): Promise<unknown> {
    const { pendingCursor, pendingHasNext, clearedCursor, clearedHasNext } = options
    const { accountHolderId } = useAuthStore()
    if (!accountHolderId.value) {
      return
    }
    const result = await getTransactionList({
      accountHolderId: accountHolderId.value,
      pendingCursor,
      pendingHasNext,
      clearedCursor,
      clearedHasNext,
      filter: {
        paymentCardId: data.filter.cardId === CARD_ID_DEFAULT_FILTER ? null : data.filter.cardId,
        // End date should be increased by 1 day so that it completely covers end date day
        date: mapRangeValue(formatDateIsoString, {
          from: data.filter.date.from,
          to: addDays(1, data.filter.date.to),
        }),
      },
    })
    if (!result.ok) {
      return
    }
    const transactionList = createTransactionList(result.value)
    data.list = data.list.concat(transactionList).sort(compareByCreatedAtDesc)
    data.pending.cursor = result.value.pendingTransactions?.pageInfo.endCursor
    data.pending.hasNextPage = Boolean(result.value.pendingTransactions?.pageInfo.hasNextPage)
    data.cleared.cursor = result.value.clearedTransactions?.pageInfo.endCursor
    data.cleared.hasNextPage = Boolean(result.value.clearedTransactions?.pageInfo.hasNextPage)
  }

  /**
   * Reset transaction event list.
   * Reset cursors.
   * Use existing filters.
   */
  async function reLoad() {
    data.loading = true
    data.list = []
    await load({
      pendingCursor: null,
      pendingHasNext: true,
      clearedCursor: null,
      clearedHasNext: true,
    })
    data.loading = false
  }

  const loadMore = async () => {
    data.loadMoreLoading = true
    await load({
      pendingCursor: data.pending.cursor,
      pendingHasNext: data.pending.hasNextPage,
      clearedCursor: data.cleared.cursor,
      clearedHasNext: data.cleared.hasNextPage,
    })
    data.loadMoreLoading = false
  }

  const setFilter = (value: ITransactionData['filter']) => {
    data.filter = value
  }

  return {
    loading: computed(() => data.loading),
    loadMoreLoading: computed(() => data.loadMoreLoading),
    loadMoreAvailable: computed(() => data.pending.hasNextPage || data.cleared.hasNextPage),
    list: computed(() => data.list),
    filter: computed(() => data.filter),
    loadMore,
    reLoad,
    setFilter,
  }
}

export function createTransactionList(
  value: TransactionListFinancialAccountFragment
): ITransactionView[] {
  const clearedTransactionList = (value.clearedTransactions?.edges || [])
    .map((edge) => edge.node)
    .filter(graphqlType.isClearingTransactionEvent)
    .map((node) => mapClearingEventToView(node)) as ITransactionView[]

  const pendingTransactionList = (value.pendingTransactions?.edges || [])
    .map((edge) => edge.node)
    .filter(graphqlType.isAuthorizationTransactionEvent)
    .map((node) => mapAuthorizationEventToView(node)) as ITransactionView[]

  const transactionList = pendingTransactionList.concat(clearedTransactionList)

  return transactionList
}

function mapClearingEventToView(node: TransactionListClearingEventFragment): ITransactionView {
  return {
    id: node.id,
    status: '',
    createdAt: node.createdAt,
    time: node.createdAt ? formatTime(new Date(node.createdAt)) : '',
    name: node.merchantDetails?.name || 'Unknown',
    description: node.merchantDetails?.description,
    category: mapEventToCategoryView(node),
    amount: getClearingEventAmount(node),
    currency: node.approvedAmount?.currencyCode,
    last4: node.paymentCard?.last4,
  }
}

function mapAuthorizationEventToView(
  node: TransactionListAuthorizationEventFragment
): ITransactionView {
  return {
    id: node.id,
    status: 'Pending',
    createdAt: node.createdAt,
    time: node.createdAt ? formatTime(new Date(node.createdAt)) : '',
    name: node.merchantDetails?.name || 'Unknown',
    description: node.merchantDetails?.description,
    category: mapEventToCategoryView(node),
    amount: getAuthorizationEventAmount(node),
    currency: node.approvedAmount?.currencyCode,
    last4: node.paymentCard?.last4,
  }
}
