import {
  GenerateAccountHolderToken,
  GenerateAccountHolderTokenMutation,
  GenerateAccountHolderTokenMutationVariables,
  GenerateCardDetailsToken,
  GenerateCardDetailsTokenMutation,
  GenerateCardDetailsTokenMutationVariables,
  GenerateDocumentUploadToken,
  GenerateDocumentUploadTokenMutation,
  GenerateDocumentUploadTokenMutationVariables,
  GenerateInitialDepositToken,
  GenerateInitialDepositTokenMutation,
  GenerateInitialDepositTokenMutationVariables,
  GenerateIssuePaymentCardToken,
  GenerateIssuePaymentCardTokenMutation,
  GenerateIssuePaymentCardTokenMutationVariables,
  GenerateManageCardToken,
  GenerateManageCardTokenMutation,
  GenerateManageCardTokenMutationVariables,
  GenerateManageScheduledTransferToken,
  GenerateManageScheduledTransferTokenMutation,
  GenerateManageScheduledTransferTokenMutationVariables,
  GenerateOneTimePaymentToken,
  GenerateOneTimePaymentTokenMutation,
  GenerateOneTimePaymentTokenMutationVariables,
} from '@/generated/graphqlAppSync'
import { result } from '@/lib/result'
import { appSyncApiQuery, graphQLPublicApiQuery } from '@/api/graphql/graphqlClient'
import { consoleLogger } from '@/lib/logger'
import { IApiToken } from '@/lib/domain/token'
import { graphqlType } from '@/api/graphql/graphqlType'

export const generateAccountHolderToken = async (
  id: Maybe<string>,
  authenticated = true
): Promise<Result<IApiToken>> => {
  if (!id) {
    return result.failed(new Error('ID not provided'))
  }
  try {
    const variables: GenerateAccountHolderTokenMutationVariables = { accountHolderId: id }
    const tokenResource = authenticated ? appSyncApiQuery : graphQLPublicApiQuery
    const response = await tokenResource<GenerateAccountHolderTokenMutation>({
      query: GenerateAccountHolderToken,
      variables,
    })
    const token = response.generateToken?.accountHolderToken
    if (graphqlType.isClientToken(token)) {
      return result.ok(token)
    }
    return result.failed(new Error('Unknown type'))
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const generatePaymentCardManageToken = async (
  accountHolderId: Maybe<string>,
  cardId: Maybe<string>
): Promise<Result<IApiToken>> => {
  if (!accountHolderId || !cardId) {
    return result.failed(new Error('ID not provided'))
  }
  try {
    const variables: GenerateManageCardTokenMutationVariables = {
      input: { accountHolderId, cardId },
    }
    const response = await appSyncApiQuery<GenerateManageCardTokenMutation>({
      query: GenerateManageCardToken,
      variables,
    })
    const token = response.generateToken?.manageCardToken
    if (graphqlType.isClientToken(token)) {
      return result.ok(token)
    }
    return result.failed(new Error('Unknown type'))
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const generateApplicationDocumentClientToken = async (
  sessionId: Maybe<string>
): Promise<Result<IApiToken>> => {
  if (!sessionId) {
    return result.failed(new Error('ID not provided'))
  }
  try {
    const tokenVariables: GenerateDocumentUploadTokenMutationVariables = { sessionId }

    const tokenResponse = await graphQLPublicApiQuery<GenerateDocumentUploadTokenMutation>({
      query: GenerateDocumentUploadToken,
      variables: tokenVariables,
    })

    if (!graphqlType.isClientToken(tokenResponse.generateToken?.documentUploadToken)) {
      return result.failed(
        new Error('[createApplicationDocumentClientToken] Unknown response type for client token')
      )
    }
    if (!tokenResponse.generateToken?.documentUploadToken.value) {
      return result.failed(
        new Error('[createApplicationDocumentClientToken] Failed to create document upload token')
      )
    }
    return result.ok(tokenResponse.generateToken?.documentUploadToken)
  } catch (e) {
    consoleLogger.error(
      new Error('[createApplicationDocumentClientToken] Failed to create document upload token')
    )
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const generateInitialDepositToken = async (
  fromFinancialAccountId: Maybe<string>,
  toFinancialAccountId: Maybe<string>
): Promise<Result<IApiToken>> => {
  if (!fromFinancialAccountId || !toFinancialAccountId) {
    return result.failed(new Error('IDs not provided'))
  }
  try {
    const variables: GenerateInitialDepositTokenMutationVariables = {
      input: {
        fromFinancialAccountId,
        toFinancialAccountId,
      },
    }
    const response = await appSyncApiQuery<GenerateInitialDepositTokenMutation>({
      query: GenerateInitialDepositToken,
      variables,
    })
    const token = response.generateToken?.initialDepositToken
    if (graphqlType.isClientToken(token)) {
      return result.ok(token)
    }
    return result.failed(new Error('Unknown type'))
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const generateOneTimePaymentToken = async (
  fromFinancialAccountId: Maybe<string>,
  toFinancialAccountId: Maybe<string>
): Promise<Result<IApiToken>> => {
  if (!fromFinancialAccountId || !toFinancialAccountId) {
    return result.failed(new Error('IDs not provided'))
  }
  try {
    const variables: GenerateOneTimePaymentTokenMutationVariables = {
      input: {
        fromFinancialAccountId,
        toFinancialAccountId,
      },
    }
    const response = await appSyncApiQuery<GenerateOneTimePaymentTokenMutation>({
      query: GenerateOneTimePaymentToken,
      variables,
    })
    const token = response.generateToken?.oneTimePaymentToken
    if (graphqlType.isClientToken(token)) {
      return result.ok(token)
    }
    return result.failed(new Error('Unknown type'))
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const generatePaymentCardToken = async (
  accountHolderId: Maybe<string>,
  cardId: Maybe<string>
): Promise<Result<IApiToken>> => {
  try {
    if (!accountHolderId || !cardId) {
      return result.failed(
        new Error(
          '[TC] Failed to get payment card details. Account holder ID or Card ID not provided'
        )
      )
    }
    const variables: GenerateCardDetailsTokenMutationVariables = {
      input: { cardId, accountHolderId },
    }
    const tokenResult = await appSyncApiQuery<GenerateCardDetailsTokenMutation>({
      query: GenerateCardDetailsToken,
      variables,
    })
    const token = tokenResult.generateToken?.restrictedDetailsCardToken
    if (!graphqlType.isClientToken(token)) {
      return result.failed(new Error('Unknown type'))
    }
    return result.ok(token)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const generateManageScheduledTransferToken = async (
  transferId: Maybe<string>
): Promise<Result<IApiToken>> => {
  try {
    if (!transferId) {
      return result.failed(new Error('[TC] Failed to get token. Transfer ID not provided.'))
    }
    const variables: GenerateManageScheduledTransferTokenMutationVariables = {
      scheduledTransferId: transferId,
    }
    const tokenResult = await appSyncApiQuery<GenerateManageScheduledTransferTokenMutation>({
      query: GenerateManageScheduledTransferToken,
      variables,
    })
    const token = tokenResult.generateToken?.manageScheduledTransferToken
    if (!graphqlType.isClientToken(token)) {
      return result.failed(new Error('Unknown type'))
    }
    return result.ok(token)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const generateIssuePaymentCardToken = async (
  financialAccountId: Maybe<string>
): Promise<Result<IApiToken>> => {
  try {
    if (!financialAccountId) {
      return result.failed(
        new Error('[TC] Failed to get payment card details. Financial account ID not provided')
      )
    }
    const variables: GenerateIssuePaymentCardTokenMutationVariables = { id: financialAccountId }
    const tokenResult = await appSyncApiQuery<GenerateIssuePaymentCardTokenMutation>({
      query: GenerateIssuePaymentCardToken,
      variables,
    })
    const token = tokenResult.generateToken?.issuePaymentCardToken
    if (!graphqlType.isClientToken(token)) {
      return result.failed(new Error('Unknown type'))
    }
    return result.ok(token)
  } catch (e) {
    return result.failed(e as Error)
  }
}
