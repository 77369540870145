import { inject, InjectionKey, provide } from 'vue'
import * as LaunchDarkly from 'launchdarkly-js-client-sdk'
import { BaseError } from '@/lib/domain/error/BaseError'

export type LaunchDarklySDK = typeof LaunchDarkly

export const LAUNCHDARKLY_SDK_INJECTION_KEY = Symbol(
  'Launchdarkly SDK key'
) as InjectionKey<LaunchDarklySDK>

export function provideLaunchdarklySDK(): void {
  provide(LAUNCHDARKLY_SDK_INJECTION_KEY, LaunchDarkly)
}

export function injectLaunchdarklySDK(): LaunchDarklySDK {
  const injected = inject(LAUNCHDARKLY_SDK_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject LaunchDarkly SDK')
  }
  return injected
}
