<template>
  <Page name="Cards">
    <div class="Cards__content">
      <Box class="Cards__content__main-card bg-white" style="padding: 24px">
        <MainCard
          v-if="mainCardView"
          :card="mainCardView"
          :error="errorView.main"
          :opened="openedCardIdMap[mainCardView.id]"
          @onEyeClick="handleEyeClick"
          @onOPClick="handleOPClick"
          @onErrorDismiss="handleErrorDismiss"
        />
      </Box>
      <Box class="Cards__content__list bg-white" style="padding: 24px">
        <div class="Cards__content__head d-flex justify-between mb-32">
          <div class="Cards__content__head-title">
            <h2 class="fs-20 c-black fw-bold mb-8">
              <span class="mr-8">Virtual Cards</span>
              <span ref="helpIcon">
                <Icon
                  name="help-circle"
                  class="c-grey4 fs-16 ml-8"
                  @mouseenter="openHelp"
                  @mouseleave="closeHelp"
                />
              </span>
              <Popup :opened="popupHelpOpened" :target="helpIcon">
                <div class="HelpPopupContent">
                  A virtual card is a 16-digit card number associated to your account and can be
                  used without exposing the original credit card data.
                </div>
              </Popup>
            </h2>
            <p v-if="virtualCardCreateAvailable" class="fs-14 c-grey2">
              You can create up to {{ virtualCardCountLeft }} more virtual cards.
            </p>
          </div>

          <div class="Cards__content__head-actions d-flex">
            <CardFilterMobile
              v-if="mobileScreen"
              class="Cards__content__head-actions__filter w-100 mb-16"
              :value="virtualCardFilter"
              @onChange="handleCardFilterChange"
              @onReset="handleCardFilterReset"
              @onSubmit="handleCardFilterSubmit"
            />
            <CardFilter
              v-else
              class="Cards__content__head-actions__filter mr-16"
              :value="virtualCardFilter"
              @onChange="handleCardFilterChange"
              @onReset="handleCardFilterReset"
              @onSubmit="handleCardFilterSubmit"
            />
          </div>
          <CreateCardModal
            v-if="createCardModalOpened"
            :opened="createCardModalOpened"
            :cardsLeft="virtualCardCountLeft"
            @close="closeCreateCardModal"
            @complete="handleCreateCardComplete"
          />
        </div>
        <CardList
          v-if="!mobileScreen"
          class="Cards__table"
          :error="errorView.list"
          :cards="cardListView"
          @onEyeClick="handleEyeClick"
          @onSaveInOP="handleOPClick"
          @onErrorDismiss="handleErrorDismiss"
        />
        <CardListMobile
          v-else
          :error="errorView.list"
          :cards="cardListView"
          @onEyeClick="handleEyeClick"
          @onSaveInOP="handleOPClick"
          @onErrorDismiss="handleErrorDismiss"
        />
        <ButtonLoadMore
          v-if="virtualCardPageAvailable"
          :loading="virtualCardPageLoading"
          @click="loadMoreCardList"
        />

        <CardDetailsModal
          v-if="cardDetailsState.opened"
          :opened="cardDetailsState.opened"
          :id="cardDetailsState.id"
          @onClose="handleCloseCardDetails"
        />
        <OnePasswordDetailsModal
          v-if="OPDetailsState.opened"
          :opened="OPDetailsState.opened"
          :id="OPDetailsState.id"
          @onClose="handleCloseOPDetails"
        />
        <LockCardModal />
        <UnlockCardModal />
      </Box>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { onMounted } from '@vue/runtime-core'
import { computed, ComputedRef, reactive, Ref, ref } from '@vue/reactivity'

import Page from '../components/Page.vue'
import Box from '../components/Box.vue'
import CardList from '../components/cards/CardList.vue'
import MainCard from '../components/cards/MainCard.vue'
import { useMobileScreen } from '../api/dom/screen'
import CardListMobile from '../components/cards/CardListMobile.vue'
import Icon from '../components/Icon.vue'
import Popup from '../components/Popup.vue'
import { usePaymentCards } from '@/service/usePaymentCards'
import { useBool } from '../lib/composable/useBool'
import LockCardModal from '../components/cards/LockCardModal.vue'
import UnlockCardModal from '../components/cards/UnlockCardModal.vue'
import CardDetailsModal from '@/components/cards/CardDetailsModal.vue'
import OnePasswordDetailsModal from '@/components/onePassword/OnePasswordDetailsModal.vue'
import Button from '@/components/Button.vue'
import CreateCardModal from '@/components/cards/CreateCardModal.vue'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { usePolling } from '@/lib/composable/usePolling'
import { notNil } from '@/lib/type'
import { resultEither } from '@/lib/result'
import { noop } from '@/lib/function'
import { mapCardToCardView } from '@/lib/domain/paymentCard'
import { IPaymentCard, IPaymentCardView } from '@/lib/domain/types'
import CardFilter from '@/components/cards/CardFilter.vue'
import CardFilterMobile from '@/components/cards/CardFilterMobile.vue'
import ButtonLoadMore from '@/components/ButtonLoadMore.vue'

useMountedLog('Cards')

const mobileScreen = useMobileScreen()

const {
  store: {
    mainCard,
    virtualCardCountLeft,
    virtualCardCreateAvailable,
    virtualCardList,
    virtualCardFilter,
    virtualCardPageAvailable,
    virtualCardPageLoading,
    loadCardList,
    loadNonClosedCardList,
    loadMoreCardList,
    addCard,
    changeCardFilter,
    clearCardFilter,
    submitCardFilter,
  },
} = usePaymentCards()

onMounted(loadCardList)

const apiPolling = usePolling({ callback: loadNonClosedCardList, time: 5000, immediate: false })
onMounted(apiPolling.start)

// handle help icon popup
const helpIcon: Ref<HTMLSpanElement | null> = ref(null)
const { value: popupHelpOpened, truthyfy: openHelp, falsefy: closeHelp } = useBool(false)

// handle payment card details
const paymentCardDetailsError: Ref<string[]> = ref([])
const errorView = computed(() => ({
  main: notNil(mainCard.value) && paymentCardDetailsError.value.includes(mainCard.value.id),
  list: paymentCardDetailsError.value.some((id) =>
    virtualCardList.value.map((card) => card.id).includes(id)
  ),
}))

const cardDetailsState = reactive<{
  opened: boolean
  id: Maybe<string>
}>({
  opened: false,
  id: null,
})
const OPDetailsState = reactive<{
  opened: boolean
  id: Maybe<string>
}>({
  opened: false,
  id: null,
})
const handleOpenCardDetails = (id: string) => {
  cardDetailsState.id = id
  cardDetailsState.opened = true
}
const handleOPDetails = (id: string) => {
  OPDetailsState.id = id
  OPDetailsState.opened = true
}
const handleCloseCardDetails = () => {
  cardDetailsState.id = null
  cardDetailsState.opened = false
}
const handleCloseOPDetails = () => {
  OPDetailsState.id = null
  OPDetailsState.opened = false
}

const handleErrorDismiss = () => {
  paymentCardDetailsError.value = []
}

// virtual card list view
const openedCardIdMap: Ref<{ [key: string]: boolean }> = ref({})

const handleEyeClick = (id: string) => {
  handleOpenCardDetails(id)
}

const handleOPClick = (id: string) => {
  handleOPDetails(id)
}

const mainCardView: ComputedRef<IPaymentCardView | undefined> = computed(() => {
  const card = mainCard.value
  if (!card) {
    return
  }
  return mapCardToCardView(card)
})

const {
  value: createCardModalOpened,
  truthyfy: openCreateCardModal,
  falsefy: closeCreateCardModal,
} = useBool(false)
const handleCreateCardComplete = (cardResult: Result<IPaymentCard>) =>
  resultEither(
    noop,
    (newCard) => {
      closeCreateCardModal()
      addCard(newCard)
    },
    cardResult
  )

const cardListView: ComputedRef<IPaymentCardView[]> = computed(() => {
  return virtualCardList.value.map(mapCardToCardView)
})
const handleCardFilterSubmit = submitCardFilter
const handleCardFilterChange = changeCardFilter
const handleCardFilterReset = clearCardFilter
</script>

<style>
.Cards__content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 24px;
  row-gap: 24px;
}

.Cards__table {
  width: 100%;
}

.HelpPopupContent {
  width: 190px;
}

@media screen and (max-width: 768px) {
  .Cards__content {
    display: flex;
    flex-direction: column;

    background-color: var(--bg-grey3);
  }
  .Cards__content__list {
    margin-left: -24px;
    margin-right: -24px;
  }
  .Cards__content__head {
    flex-direction: column;
  }
  .Cards__content__head-actions {
    flex-direction: column-reverse;
  }
  .Cards__content__head-title {
    margin-bottom: 40px;
  }
  .Cards__content__head-actions__create {
    margin-bottom: 16px;
  }
  .Cards__content__head-actions__filter {
    margin-bottom: 0;
  }
}
</style>
