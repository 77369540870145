<template>
  <div class="CurrencyFormat">{{ text }}</div>
</template>

<script setup lang="ts">
import { formatCurrency } from '../lib/number'

const props = withDefaults(defineProps<{ value?: number }>(), { value: 0 })

const text = formatCurrency(props.value)
</script>
