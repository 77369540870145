<template>
  <CookieMessage />
  <DownloadMessage
    :opened="popupOpened"
    :error="error"
    @on-cancel="closePopup"
    @on-download="download"
  />
  <div>{{ error }}</div>
</template>

<script setup lang="ts">
import CookieMessage from '@/components/CookieMessage.vue'
import DownloadMessage from '@/components/DownloadMessage.vue'
import { noop } from '@/lib/function'

import { useTransactionsExport } from '@/service/useTransactionsExport'

const { error, popupOpened, closePopup, download } = useTransactionsExport({
  onError: noop,
  onExported: noop,
})
</script>
