<template>
  <div class="CardList">
    <table class="CardList__table">
      <colgroup>
        <col span="1" />
        <col span="1" />
        <col span="1" />
        <col span="1" width="150px" />
      </colgroup>
      <thead class="CardList__table__head">
        <tr class="text-left fs-12 fw-500 c-grey2 mb-24">
          <th>Card Number</th>
          <th>Expiry Date</th>
          <th>CVC</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody class="c-grey">
        <tr style="height: initial">
          <td colspan="4" style="padding-top: 16px">
            <Message v-if="error" variant="error" @onClose="$emit('onErrorDismiss')">
              Could not retrieve card details. Please try again.
            </Message>
          </td>
        </tr>

        <tr v-for="card of cards" :key="card.id">
          <td>
            <div class="d-flex align-center">
              <div
                class="
                  bg-grey
                  mr-24
                  d-flex
                  align-center
                  justify-center
                  CardList__card-icon-container
                "
              >
                <Icon class="fs-24 c-grey4" name="credit-card" />
              </div>

              <CardNumber :card="card"></CardNumber>
            </div>
          </td>

          <td class="fs-14 fw-600">
            {{ card.date }}
          </td>

          <td>
            <span class="fs-14 fw-600">{{ card.cvv }}</span>
          </td>

          <td>
            <div class="d-flex justify-between align-center">
              <CardStatusTag :status="card.status" />
              <ButtonMenu @onClick="menuToggle($event, card)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <Popup :opened="menuOpened" :target="menuTarget" :showArrow="false" position="bottom-left">
      <CardListItemMenu :card="cardForMenu" @onAction="menuAction" />
    </Popup>
  </div>
</template>

<script setup lang="ts">
import Icon from '../Icon.vue'
import CardStatusTag from './CardStatusTag.vue'
import Message from '../Message.vue'
import Popup from '../Popup.vue'
import { Ref, ref } from '@vue/reactivity'
import CardListItemMenu from './CardListItemMenu.vue'
import ButtonMenu from '../ButtonMenu.vue'
import { usePaymentCardMenu } from '@/service/usePaymentCardMenu'
import CardNumber from './CardNumber.vue'
import { IPaymentCardView } from '@/lib/domain/types'

defineProps<{ cards: IPaymentCardView[]; error: boolean }>()
const emit = defineEmits(['onEyeClick', 'onErrorDismiss', 'onSaveInOP'])

const menuTarget: Ref<HTMLElement | null> = ref(null)
const {
  menuOpened,
  menuToggle,
  menuAction,
  card: cardForMenu,
} = usePaymentCardMenu({
  onShowDetails: (id) => emit('onEyeClick', id),
  onSaveInOP: (id) => emit('onSaveInOP', id),
  onHideDetails: (id) => emit('onEyeClick', id),
  onMenuToggle: (e) => {
    menuTarget.value = e.target as HTMLButtonElement
  },
})
</script>

<style>
.Popup.CardItemPopup {
  width: 130px;
}
.CardList__table {
  width: 100%;
  border-collapse: collapse;
}

.CardList__table__head {
  height: 48px;
}

.CardList__table__head th {
  border-top: 1px solid var(--bg-grey4);
  border-bottom: 1px solid var(--bg-grey4);
}

.CardList tbody tr {
  height: 104px;
}

.CardList tbody tr td {
  white-space: nowrap;
}

.CardList__card-icon-container {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
</style>
