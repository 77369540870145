import { h } from 'vue'
import AuthGuard from '@/components/AuthGuard.vue'
import OnboardingGuard from '@/components/onboarding/OnboardingGuard.vue'

export function withAuthAndOnboardingGuard(childComponent: any) {
  return {
    setup() {
      return function () {
        return h(AuthGuard, () => [h(OnboardingGuard, () => [h(childComponent)])])
      }
    },
  }
}
