import { eq } from '@/lib/logic'

export const head = <T>(list: T[]): Maybe<T> => list[0]
export const filter = <T>(predicate: (listItem: T) => boolean, list: T[]): T[] =>
  list.filter(predicate)
export const reject = <T>(predicate: (listItem: T) => boolean, list: T[]): T[] =>
  filter((listItem: T) => !predicate(listItem), list)
export const map = <T, R>(func: (listItem: T) => R, list: T[]): R[] => list.map(func)
export const length = (list: any[]): number => list.length
export const flatten = <T>(nestedList: T[][]): T[] => ([] as T[]).concat(...nestedList)
export const joinSpace = (list: (string | number | null | undefined)[]): string => list.join(' ')
export const isEmpty = <T>(list: T[]): boolean => eq(length(list), 0)
export const last = <T>(list: T[]): Maybe<T> => list[length(list) - 1]
export const find = <T>(predicate: (item: T) => boolean, list: T[]): Maybe<T> =>
  list.find(predicate)
export const defaultToEmptyList = <T>(input?: Maybe<T[]>) => input ?? []
export const zip = <A, B, C>(zipFn: (a: A, b: B) => C, listA: A[], listB: B[]): C[] => {
  return listA.map((a, index) => zipFn(a, listB[index]))
}

export const indexBy = <T>(
  fn: (item: T) => Maybe<string | number>,
  list: T[]
): Record<string | number, T> =>
  list.reduce((acum, item) => {
    const key = fn(item)
    if (!key) {
      return acum
    }
    return Object.assign(acum, { [key]: item })
  }, {})

export const indexById = <T extends { id: string }>(list: T[]): Record<string, T> =>
  indexBy((item) => item.id, list)

export const prepend = <T>(item: T, list: T[]): T[] => [item, ...list]
