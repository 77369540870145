<template>
  <Modal :opened="opened" @onClose="handleClose">
    <AutopayConfirmation
      v-if="state.paymentCompleted"
      :account="accountById[accountMenuValue ?? '']"
      :paymentResult="state.paymentResult"
      @onClose="handleClose"
    />

    <div v-else>
      <h2 class="fs-20 fw-bold c-black text-center mb-24">Enable Autopay?</h2>
      <div class="fs-14 fw-600 c-black1" style="margin: 0; padding: 0">
        <Message
          v-if="state.paymentError"
          variant="error"
          class="mb-16"
          @onClose="handleErrorMessageClose"
          >{{ state.paymentError.message }}</Message
        >
        <div class="mb-32">
          <PaymentAccountDropdown
            :value="accountMenuValue"
            :accountList="accountList"
            @onChange="handleAccountSelect"
          />
        </div>

        <div class="mb-40">
          <FormCheckbox v-model="state.agree" :label="agreeLabel" />
        </div>

        <div class="mb-8">
          <Button
            variant="primary"
            size="large"
            class="w-100"
            :disabled="buttonPayDisabled"
            :loading="buttonPayLoading"
            @click="handlePayClick"
            >Enable Autopay</Button
          >
        </div>

        <div>
          <Button
            variant="secondary"
            size="large"
            class="w-100"
            style="background-color: var(--white)"
            @click="handleClose"
            >Continue without Autopay</Button
          >
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'

import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'

import { useAccountHolder } from '@/store/accountHolder'
import { formatDateMonthShortYearFull, now } from '@/lib/date'
import { useBool } from '@/lib/composable/useBool'
import { getAccountHolderAccountNumber } from '@/lib/domain/accountHolder'
import { defaultToEmptyString } from '@/lib/string'
import { usePaymentsStore } from '@/store/payments'
import AutopayConfirmation from './AutopayConfirmation.vue'
import { isNil, notNil } from '@/lib/type'
import { result } from '@/lib/result'
import PaymentAccountDropdown from './PaymentAccountDropdown.vue'
import { IPaymentAccount } from '@/lib/domain/types'
import Message from '../Message.vue'
import { injectApi } from '@/provider/apiProvider'
import { injectTracker } from '@/provider/trackerProvider'

interface IProps {
  opened?: boolean
  accountList: IPaymentAccount[]
}

interface IModalState {
  agree: boolean
  paymentCompleted: boolean
  paymentError: Maybe<Error>
  paymentResult: {
    id: Maybe<string>
    date: Maybe<string>
    nextDate: Maybe<string>
  }
}

const props = withDefaults(defineProps<IProps>(), {
  opened: false,
})

const $api = injectApi()
const $tracker = injectTracker()

onMounted(setInitialBankAccountValue)

const accountMenuValue = ref<string | null>(null)
const accountList = computed(() => props.accountList.filter(notNil))
const accountById = computed<Record<string, IPaymentAccount>>(() =>
  accountList.value.reduce(
    (acum, item) =>
      Object.assign(
        acum,
        item.externalFinancialBankAccountId ? { [item.externalFinancialBankAccountId]: item } : {}
      ),
    {}
  )
)
const handleAccountSelect = (id: string) => {
  accountMenuValue.value = id
}

const emit = defineEmits(['close', 'completed', 'failed'])

const { accountHolder, financialAccountId, autopayDueDay } = useAccountHolder()
const { updatePaymentList, autopayTransfer, autopayBankAccountId, autopayEnabled } =
  usePaymentsStore()

const state = reactive<IModalState>({
  agree: false,
  paymentCompleted: false,
  paymentError: null,
  paymentResult: {
    id: null,
    date: null,
    nextDate: null,
  },
})
const selectedAccountName = computed<string>(() => {
  if (!accountMenuValue.value) {
    return ''
  }
  return defaultToEmptyString(accountById.value[accountMenuValue.value].accountDetails.name)
})
const selectedAccountMask = computed<string>(() => {
  if (!accountMenuValue.value) {
    return ''
  }
  return defaultToEmptyString(accountById.value[accountMenuValue.value].accountDetails.mask)
})
const agreeLabel = computed(
  () =>
    `I authorize Tillful (owned by Tillco Holdings, LLC) to initiate a recurring monthly electronic payment on the statement date in the amount
    indicated on my monthly statement from my ${selectedAccountName.value} account (ending ${selectedAccountMask.value}).`
)
const buttonPayDisabled = computed(() => isNil(accountMenuValue.value) || !state.agree)
const { value: buttonPayLoading, truthyfy: setLoading, falsefy: resetLoading } = useBool(false)

const handlePayClick = async () => {
  setLoading()
  const paymentResult = await makePayment()
  resetLoading()
  if (!paymentResult.ok) {
    state.paymentError = paymentResult.error
    await updatePaymentList()
    emit('failed')
    return
  }
  await updatePaymentList()
  trackAutopayResult()
  state.paymentCompleted = true
  state.paymentResult = {
    id: paymentResult.value.id,
    date: formatDateMonthShortYearFull(now()),
    nextDate: paymentResult.value.nextDate
      ? formatDateMonthShortYearFull(new Date(paymentResult.value.nextDate))
      : null,
  }
  emit('completed')
}

const handleErrorMessageClose = () => {
  state.paymentError = null
}

const makePayment = async () => {
  if (!accountMenuValue.value) {
    return result.failed(new Error('Account not selected'))
  }
  const selectedAccount = accountById.value[accountMenuValue.value]
  const cancellationResult = await cancelExistingAutopay()
  if (!cancellationResult.ok) {
    return cancellationResult
  }
  const params = {
    firstName: defaultToEmptyString(accountHolder.value?.primaryAuthorizedPerson?.name?.givenName),
    lastName: defaultToEmptyString(accountHolder.value?.primaryAuthorizedPerson?.name?.familyName),
    identificationNumber: getAccountHolderAccountNumber(accountHolder.value),
    fromAccountId: defaultToEmptyString(selectedAccount.externalFinancialBankAccountId),
    toAccountId: defaultToEmptyString(financialAccountId.value),
    personId: defaultToEmptyString(accountHolder.value?.primaryAuthorizedPerson?.id),
    dayOfMonth: autopayDueDay.value,
  }
  return await $api.initiateRecurringTransfer(params)
}

const handleClose = () => emit('close')

const trackAutopayResult = async () => {
  $tracker.identifyUser({
    accountHolder: accountHolder.value,
    autopayEnabled: autopayEnabled.value,
  })
  $tracker.trackAutopay(selectedAccountMask.value)
}

function setInitialBankAccountValue() {
  if (autopayBankAccountId.value) {
    accountMenuValue.value = autopayBankAccountId.value
  }
}

const cancelExistingAutopay = async (): Promise<Result> => {
  if (!autopayTransfer.value) {
    return result.ok(true)
  }
  const params = autopayTransfer.value.id
  return await $api.cancelRecurringTransfer(params)
}
</script>

<style>
.FormCurrencyInput {
  height: auto;
  padding: 16px;
  color: var(--c-grey1);
  font-size: 16px;
  font-weight: 500;
}
</style>
