<template>
  <Modal :opened="opened" @onClose="handleClose">
    <div class="d-flex flex-direction-column">
      <h2 class="mb-24 text-center fs-20 fw-bold c-black">Unlock Card</h2>
      <Message v-if="failed" class="mb-24" variant="error" :dismissable="false">
        Failed to unlock your card. Please try again. If the problem persists, contact support.
      </Message>
      <p class="mb-24 c-grey2 fs-14" style="line-height: 1.43">
        The card ending in {{ cardLast4 }} will be instantly unlocked and ready for use. Are you
        sure you want to continue?
      </p>
      <Button
        class="mb-20"
        variant="primary"
        :bordered="true"
        size="large"
        @click="handleLock"
        :loading="loading"
        >Unlock Card</Button
      >
      <Button variant="secondary" :loading="false" size="large" @click="handleClose">Cancel</Button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '../Modal.vue'
import Message from '../Message.vue'
import Button from '../Button.vue'
import { useUnlockCardModal } from '@/service/useUnlockCard'

const { opened, failed, loading, lock, close, cardLast4 } = useUnlockCardModal()
const handleLock = () => {
  lock()
}
const handleClose = () => {
  close()
}
</script>
