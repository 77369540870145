import { BaseError } from '@/lib/domain/error/BaseError'
import { inject, InjectionKey } from 'vue'
import { FeatureFlagStore } from './featureFlagStoreProvider'

export const FEATURE_FLAG_PROVIDER_INJECTION_KEY = Symbol(
  'Feature flag key'
) as InjectionKey<FeatureFlagStore>

export function injectFeatureFlag(): FeatureFlagStore {
  const injected = inject(FEATURE_FLAG_PROVIDER_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject feature flag')
  }
  return injected
}
