import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/**   Account details for an external bank account connected through Plaid */
export type AccountDetails = {
  __typename?: 'AccountDetails';
  /**   Available account balance, guaranteed not to be null if currentBalance is null */
  availableBalance?: Maybe<Scalars['Float']>;
  /**   Current account balance, guaranteed not to be null if availableBalance is null */
  currentBalance?: Maybe<Scalars['Float']>;
  /**   Plaid Financial Institution Id */
  institution: FinancialInstitution;
  /**   The last 2-4 alphanumeric characters of an account */
  mask?: Maybe<Scalars['String']>;
  /**   Name of the account */
  name: Scalars['String'];
  /**   Plaid account subtype. Expect either checking or savings. */
  type?: Maybe<Scalars['String']>;
};

/**    Auth0 User's app_metadata */
export type AppMetadata = {
  __typename?: 'AppMetadata';
  /**    Highnote USBusinessAccountHolder id */
  accountHolderId?: Maybe<Scalars['ID']>;
  /**    Returns true if autopay consent has been captured */
  autopayEnabled?: Maybe<Scalars['Boolean']>;
  /**    Highnote USBusinessAccountHolder externalId */
  externalId?: Maybe<Scalars['ID']>;
  /**    Highnote FinancialAccount id */
  financialAccountId?: Maybe<Scalars['ID']>;
  /**    Highnote SecureDepositACHTransfer id */
  initialDepositTransferId?: Maybe<Scalars['ID']>;
};

/**    Captured consent to enable autopay */
export type AutopayConsentInput = {
  /**    The ID of the individual who is authorizing autopay */
  authorizedPersonId: Scalars['ID'];
  /**    The date and time of consent, in ISO 8601 format */
  consentTimestamp: Scalars['String'];
  /**    The template of the consent */
  template: AutopayConsentTemplateInput;
};

/**    The template of the autopay agreement consent */
export type AutopayConsentTemplateInput = {
  /**    The id indicating which consent was recorded */
  consentTemplateId: Scalars['String'];
  /**    The version of the template id */
  consentTemplateVersion: Scalars['String'];
};

/**    Input to consent to enable autopay */
export type CaptureAutopayConsentInput = {
  /**    User's consent to enable autopay */
  consent: AutopayConsentInput;
  /**    Auth0 user_id */
  userId: Scalars['ID'];
};

/**   Input for requesting ClientToken for a PaymentCard */
export type CardTokenInput = {
  /**    Highnote USBusinessAccountHolder ID */
  accountHolderId: Scalars['ID'];
  /**    Highnote PaymentCard ID */
  cardId: Scalars['ID'];
};

/**    Highnote token for use in a client-side integration */
export type ClientToken = {
  __typename?: 'ClientToken';
  /**    The expiration date for the token in ISO 8601 format */
  expirationDate: Scalars['String'];
  /**   Describes how the token can be used */
  usage?: Maybe<ClientTokenUsage>;
  /**    The value to send as an Authorization Bearer header or to provide to the SDK */
  value: Scalars['String'];
};

export enum ClientTokenUsage {
  SingleUse = 'SINGLE_USE',
  UntilExpiration = 'UNTIL_EXPIRATION'
}

export type ClosePaymentAccount = {
  __typename?: 'ClosePaymentAccount';
  /**   Confirmation Timestamp */
  timestamp: Scalars['String'];
};

export type CreateScheduledTransferTokenInput = {
  /**    ExternalFinancialBankAccount from which to withdraw funds */
  fromFinancialAccountId: Scalars['ID'];
  /**    FinancialAccount to which funds are deposited */
  toFinancialAccountId: Scalars['ID'];
};

/**   Plaid institution */
export type FinancialInstitution = {
  __typename?: 'FinancialInstitution';
  /**   The Plaid institution ID */
  institutionId?: Maybe<Scalars['ID']>;
  /**   Base64 encoded representation of the institution's logo */
  logo?: Maybe<Scalars['String']>;
  /**   Institution name */
  name?: Maybe<Scalars['String']>;
};

export type GeneratePlaidLinkTokenInput = {
  /**    A URI indicating the destination where a user should be forwarded after completing the Link flow */
  redirectURI?: Maybe<Scalars['String']>;
  /**    A unique ID representing the end user */
  userId: Scalars['ID'];
};

/**   Base type for generating ClientTokens */
export type GenerateToken = {
  __typename?: 'GenerateToken';
  /**    Generate a ClientToken to READ_BUSINESS_ACCOUNT_HOLDER_DETAILS `Authentication: Public, Private` */
  accountHolderToken?: Maybe<ClientToken>;
  /**   Generate ClientToken to CREATE_SCHEDULED_TRANSFER for a FinancialAccount */
  createScheduledTransferToken?: Maybe<ClientToken>;
  /**    Generate a ClientToken to MANAGE_DOCUMENT_UPLOAD_SESSION `Authentication: Public` */
  documentUploadToken?: Maybe<ClientToken>;
  /**   Generate a ClientToken to INITIATE_SECURE_DEPOSIT to a FinancialAccount */
  initialDepositToken?: Maybe<ClientToken>;
  /**   Generate a SINGLE_USE ClientToken to a ISSUE_PAYMENT_CARD for a FinancialAccount */
  issuePaymentCardToken?: Maybe<ClientToken>;
  /**   Generate ClientToken to MANAGE_PAYMENT_CARD */
  manageCardToken?: Maybe<ClientToken>;
  /**   Generate ClientToken to MANAGE_SCHEDULED_TRANSFER */
  manageScheduledTransferToken?: Maybe<ClientToken>;
  /**
   *   Generate ClientToken to CREATE_SCHEDULED_TRANSFER for a FinancialAccount
   * @deprecated No longer supported
   */
  oneTimePaymentToken?: Maybe<ClientToken>;
  /**   Generate ClientToken to READ_RESTRICTED_DETAILS for a PaymentCard */
  restrictedDetailsCardToken?: Maybe<ClientToken>;
};


/**   Base type for generating ClientTokens */
export type GenerateTokenAccountHolderTokenArgs = {
  accountHolderId: Scalars['ID'];
};


/**   Base type for generating ClientTokens */
export type GenerateTokenCreateScheduledTransferTokenArgs = {
  input: CreateScheduledTransferTokenInput;
};


/**   Base type for generating ClientTokens */
export type GenerateTokenDocumentUploadTokenArgs = {
  sessionId: Scalars['ID'];
};


/**   Base type for generating ClientTokens */
export type GenerateTokenInitialDepositTokenArgs = {
  input: InitialDepositTokenInput;
};


/**   Base type for generating ClientTokens */
export type GenerateTokenIssuePaymentCardTokenArgs = {
  financialAccountId: Scalars['ID'];
};


/**   Base type for generating ClientTokens */
export type GenerateTokenManageCardTokenArgs = {
  input: CardTokenInput;
};


/**   Base type for generating ClientTokens */
export type GenerateTokenManageScheduledTransferTokenArgs = {
  scheduledTransferId: Scalars['ID'];
};


/**   Base type for generating ClientTokens */
export type GenerateTokenOneTimePaymentTokenArgs = {
  input: OneTimePaymentTokenInput;
};


/**   Base type for generating ClientTokens */
export type GenerateTokenRestrictedDetailsCardTokenArgs = {
  input: CardTokenInput;
};

export type InitialDepositTokenInput = {
  /**    ExternalFinancialBankAccount from which to withdraw funds */
  fromFinancialAccountId: Scalars['ID'];
  /**    FinancialAccount to which funds are deposited */
  toFinancialAccountId: Scalars['ID'];
};

/**   Unless specified, all mutations use `Private` authentication. */
export type Mutation = {
  __typename?: 'Mutation';
  /**    Capture consent to enable autopay */
  captureAutopayConsent?: Maybe<AppMetadata>;
  /**   Closes ExternalFinancialBankAccount in Highnote, revokes Plaid access_token, and deletes record from DynamoDB */
  closePaymentAccount: ClosePaymentAccount;
  /**
   *    Generate a ClientToken for a DocumentUploadSession `Authentication: Public`
   * @deprecated No longer supported
   */
  generateDocumentUploadToken?: Maybe<ClientToken>;
  /**    Request a Plaid Link token to initiate the Link flow */
  generatePlaidLinkToken?: Maybe<PlaidLinkToken>;
  /**   Generate various ClientTokens */
  generateToken?: Maybe<GenerateToken>;
  /**    Save the SecureDepositTransferId to Auth0 user's app_metadata */
  saveInitialDepositId?: Maybe<SaveInitialDepositId>;
  /**
   *    Complete Plaid Link flow from the UI connecting an AccountHolder to an ExternalFinancialBankAccount
   * @deprecated No longer supported
   */
  saveInstitutionLink?: Maybe<SaveInstitutionLink>;
  /**   Complete Plaid Link flow by creating an ExternalFinancialBankAccount for the AccountHolder */
  savePaymentAccount?: Maybe<PaymentAccount>;
  /**    Validate Document Upload Link and return the validated AccountHolderCardProductApplication Id `Authentication: Public` */
  validateDocumentUploadLink?: Maybe<ValidateDocumentUploadLink>;
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationCaptureAutopayConsentArgs = {
  input: CaptureAutopayConsentInput;
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationClosePaymentAccountArgs = {
  externalFinancialBankAccountId: Scalars['ID'];
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationGenerateDocumentUploadTokenArgs = {
  sessionId: Scalars['ID'];
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationGeneratePlaidLinkTokenArgs = {
  input: GeneratePlaidLinkTokenInput;
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationSaveInitialDepositIdArgs = {
  input: SaveInitialDepositIdInput;
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationSaveInstitutionLinkArgs = {
  input: SaveInstitutionLinkInput;
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationSavePaymentAccountArgs = {
  input: SavePaymentAccountInput;
};


/**   Unless specified, all mutations use `Private` authentication. */
export type MutationValidateDocumentUploadLinkArgs = {
  input: ValidateDocumentUploadLinkInput;
};

export type OneTimePaymentTokenInput = {
  /**    ExternalFinancialBankAccount from which to withdraw funds */
  fromFinancialAccountId: Scalars['ID'];
  /**    FinancialAccount to which funds are deposited */
  toFinancialAccountId: Scalars['ID'];
};

/**   Record of ExternalFinancialBankAccount linked through Plaid */
export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  /**   External account details */
  accountDetails: AccountDetails;
  /**    Highnote USBusinessAccountHolder id */
  accountHolderId: Scalars['ID'];
  /**   ISO8601 timestamp when PaymentAccount was created */
  createdAt: Scalars['String'];
  /**
   *   Hignnote ExternalFinancialBankAccount id
   * @deprecated No longer supported
   */
  externalFinancialBankAccountId?: Maybe<Scalars['ID']>;
  /**   Unique PaymentAccount identifier */
  id?: Maybe<Scalars['ID']>;
  /**
   *   Plaid Financial Institution Id
   * @deprecated No longer supported
   */
  institution: FinancialInstitution;
  /**   ISO8601 timestamp when PaymentAccount was last updated */
  updatedAt: Scalars['String'];
};

/**   Payment accounts associated with a given accountHolderId */
export type PaymentAccounts = {
  __typename?: 'PaymentAccounts';
  /**   List of PaymentAccounts */
  accounts?: Maybe<Array<PaymentAccount>>;
  /**   Number of accounts found */
  count: Scalars['Int'];
};

/**    Link Token used for Initializing Link on Plaid UI */
export type PlaidLinkToken = {
  __typename?: 'PlaidLinkToken';
  /**    Expiration date in ISO-8601 format */
  expiration: Scalars['String'];
  /**    A link_token supplied to Link to initialize it and receive a public_token */
  linkToken: Scalars['String'];
  /**    A unique identifier for the Plaid request */
  requestId: Scalars['ID'];
};

/**   Unless specified, all queries use `Private` authentication. */
export type Query = {
  __typename?: 'Query';
  /**    Get Auth0 user's app_metadata */
  getAppMetadata?: Maybe<AppMetadata>;
  /**
   *    Retrieve PaymentAccount from Highnote ExternalFinancialBankAccount id
   * @deprecated No longer supported
   */
  getPaymentAccount?: Maybe<PaymentAccount>;
  /**   Retrieve all PaymentAccounts associated with a given AccountHolderId */
  getPaymentAccounts?: Maybe<PaymentAccounts>;
  /**    Status endpoint for Highnote API `Authentication: Public, Private` */
  ping: Scalars['String'];
  /**    Request a URL to view Rybbon's Reward Gallery for a user */
  viewRybbonRewardGallery?: Maybe<RybbonRewardGallery>;
};


/**   Unless specified, all queries use `Private` authentication. */
export type QueryGetAppMetadataArgs = {
  userId: Scalars['ID'];
};


/**   Unless specified, all queries use `Private` authentication. */
export type QueryGetPaymentAccountArgs = {
  externalFinancialBankAccountId: Scalars['ID'];
};


/**   Unless specified, all queries use `Private` authentication. */
export type QueryGetPaymentAccountsArgs = {
  accountHolderId: Scalars['ID'];
};


/**   Unless specified, all queries use `Private` authentication. */
export type QueryViewRybbonRewardGalleryArgs = {
  input: ViewRybbonRewardGalleryInput;
};

/**    Response body from Rybbon containing Reward Gallery URL */
export type RybbonRewardGallery = {
  __typename?: 'RybbonRewardGallery';
  /**    Indicates if request was successfully processed or not */
  success: Scalars['Boolean'];
  /**    Reward Gallery iFrame URL */
  url?: Maybe<Scalars['String']>;
};

/**    Confirmation of initialDepositTransferId which was saved */
export type SaveInitialDepositId = {
  __typename?: 'SaveInitialDepositId';
  /**    SecureDepositACHTransfer id */
  depositId: Scalars['ID'];
};

/**    Save SecureDepositACHTransfer id during onboarding */
export type SaveInitialDepositIdInput = {
  /**    Highnote SecureDepositACHTransfer id */
  depositId: Scalars['ID'];
  /**    Auth0 user_id */
  userId: Scalars['ID'];
};

/**    Confirmation that the Plaid public_token was successfully processed to a processor token, and the externalPaymentAccount was added to the Highnote account holder */
export type SaveInstitutionLink = {
  __typename?: 'SaveInstitutionLink';
  /**    The date and time, in ISO 8601 format, the Financial Account was created */
  createdAt: Scalars['String'];
  /**    External financial bank account linked to Highnote through the Plaid Link flow */
  externalFinancialBankAccountId: Scalars['ID'];
};

export type SaveInstitutionLinkInput = {
  /**    Highnote USBusinessAccountHolder id */
  accountHolderId: Scalars['ID'];
  /**    Account ID of linked Plaid account */
  accountId: Scalars['ID'];
  /**    A public_token, obtained from the Plaid Link onSuccess callback */
  publicToken: Scalars['String'];
};

export type SavePaymentAccountInput = {
  /**    Highnote USBusinessAccountHolder id */
  accountHolderId: Scalars['ID'];
  /**    Account ID of linked Plaid account */
  accountId: Scalars['ID'];
  /**   Institution ID for linked Plaid account */
  institutionId: Scalars['ID'];
  /**    A public_token, obtained from the Plaid Link onSuccess callback */
  publicToken: Scalars['String'];
};

export type ValidateDocumentUploadLink = {
  __typename?: 'ValidateDocumentUploadLink';
  /**    Validated AccountHolderCardProductApplication ID */
  applicationId: Scalars['ID'];
};

export type ValidateDocumentUploadLinkInput = {
  /**    Base64-encoded USBusinessAccountHolder ID */
  accountHolderId: Scalars['ID'];
  /**    Base64-encoded AccountHolderCardProductApplication ID */
  applicationId: Scalars['ID'];
};

export type ViewRybbonRewardGalleryInput = {
  /**    Unique user identifier */
  referenceId: Scalars['String'];
};

export type GenerateCardDetailsTokenMutationVariables = Exact<{
  input: CardTokenInput;
}>;


export type GenerateCardDetailsTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', restrictedDetailsCardToken?: { __typename: 'ClientToken', value: string, expirationDate: string } | null | undefined } | null | undefined };

export type GenerateManageCardTokenMutationVariables = Exact<{
  input: CardTokenInput;
}>;


export type GenerateManageCardTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', manageCardToken?: { __typename: 'ClientToken', value: string, expirationDate: string } | null | undefined } | null | undefined };

export type GenerateDocumentUploadTokenMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type GenerateDocumentUploadTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', documentUploadToken?: { __typename: 'ClientToken', value: string, expirationDate: string } | null | undefined } | null | undefined };

export type GenerateAccountHolderTokenMutationVariables = Exact<{
  accountHolderId: Scalars['ID'];
}>;


export type GenerateAccountHolderTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', accountHolderToken?: { __typename: 'ClientToken', value: string, expirationDate: string } | null | undefined } | null | undefined };

export type GenerateInitialDepositTokenMutationVariables = Exact<{
  input: InitialDepositTokenInput;
}>;


export type GenerateInitialDepositTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', initialDepositToken?: { __typename: 'ClientToken', value: string, expirationDate: string } | null | undefined } | null | undefined };

export type GenerateOneTimePaymentTokenMutationVariables = Exact<{
  input: OneTimePaymentTokenInput;
}>;


export type GenerateOneTimePaymentTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', oneTimePaymentToken?: { __typename: 'ClientToken', value: string, expirationDate: string, usage?: ClientTokenUsage | null | undefined } | null | undefined } | null | undefined };

export type GenerateManageScheduledTransferTokenMutationVariables = Exact<{
  scheduledTransferId: Scalars['ID'];
}>;


export type GenerateManageScheduledTransferTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', manageScheduledTransferToken?: { __typename: 'ClientToken', value: string, expirationDate: string, usage?: ClientTokenUsage | null | undefined } | null | undefined } | null | undefined };

export type GenerateIssuePaymentCardTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GenerateIssuePaymentCardTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateToken', issuePaymentCardToken?: { __typename: 'ClientToken', value: string, expirationDate: string } | null | undefined } | null | undefined };

export type GeneratePlaidLinkTokenMutationVariables = Exact<{
  input: GeneratePlaidLinkTokenInput;
}>;


export type GeneratePlaidLinkTokenMutation = { __typename?: 'Mutation', generatePlaidLinkToken?: { __typename: 'PlaidLinkToken', linkToken: string } | null | undefined };

export type SavePaymentAccountMutationVariables = Exact<{
  input: SavePaymentAccountInput;
}>;


export type SavePaymentAccountMutation = { __typename?: 'Mutation', savePaymentAccount?: { __typename: 'PaymentAccount', externalFinancialBankAccountId?: string | null | undefined, createdAt: string } | null | undefined };

export type RewardGalleryUrlQueryVariables = Exact<{
  input: ViewRybbonRewardGalleryInput;
}>;


export type RewardGalleryUrlQuery = { __typename?: 'Query', viewRybbonRewardGallery?: { __typename?: 'RybbonRewardGallery', success: boolean, url?: string | null | undefined } | null | undefined };

export type SaveInitialDepositIdMutationVariables = Exact<{
  input: SaveInitialDepositIdInput;
}>;


export type SaveInitialDepositIdMutation = { __typename?: 'Mutation', saveInitialDepositId?: { __typename: 'SaveInitialDepositId', depositId: string } | null | undefined };

export type ClosePaymentAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClosePaymentAccountMutation = { __typename?: 'Mutation', closePaymentAccount: { __typename?: 'ClosePaymentAccount', timestamp: string } };

export type GetAppMetadataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAppMetadataQuery = { __typename?: 'Query', getAppMetadata?: { __typename: 'AppMetadata', accountHolderId?: string | null | undefined, externalId?: string | null | undefined, financialAccountId?: string | null | undefined, initialDepositTransferId?: string | null | undefined, autopayEnabled?: boolean | null | undefined } | null | undefined };

export type PaymentAccountItemFragment = { __typename: 'PaymentAccount', id?: string | null | undefined, createdAt: string, updatedAt: string, accountHolderId: string, externalFinancialBankAccountId?: string | null | undefined, accountDetails: { __typename?: 'AccountDetails', mask?: string | null | undefined, name: string, type?: string | null | undefined, institution: { __typename?: 'FinancialInstitution', institutionId?: string | null | undefined, logo?: string | null | undefined, name?: string | null | undefined } } };

export type PaymentAccountDetailsBalanceFragment = { __typename?: 'PaymentAccount', accountDetails: { __typename?: 'AccountDetails', currentBalance?: number | null | undefined, availableBalance?: number | null | undefined } };

export type PaymentAccountItemWithBalanceFragment = { __typename: 'PaymentAccount', id?: string | null | undefined, createdAt: string, updatedAt: string, accountHolderId: string, externalFinancialBankAccountId?: string | null | undefined, accountDetails: { __typename?: 'AccountDetails', mask?: string | null | undefined, name: string, type?: string | null | undefined, currentBalance?: number | null | undefined, availableBalance?: number | null | undefined, institution: { __typename?: 'FinancialInstitution', institutionId?: string | null | undefined, logo?: string | null | undefined, name?: string | null | undefined } } };

export type GetPaymentAccountListQueryVariables = Exact<{
  accountHolderId: Scalars['ID'];
}>;


export type GetPaymentAccountListQuery = { __typename?: 'Query', getPaymentAccounts?: { __typename: 'PaymentAccounts', count: number, accounts?: Array<{ __typename: 'PaymentAccount', id?: string | null | undefined, createdAt: string, updatedAt: string, accountHolderId: string, externalFinancialBankAccountId?: string | null | undefined, accountDetails: { __typename?: 'AccountDetails', mask?: string | null | undefined, name: string, type?: string | null | undefined, institution: { __typename?: 'FinancialInstitution', institutionId?: string | null | undefined, logo?: string | null | undefined, name?: string | null | undefined } } }> | null | undefined } | null | undefined };

export type GetPaymentAccountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPaymentAccountQuery = { __typename?: 'Query', getPaymentAccount?: { __typename: 'PaymentAccount', id?: string | null | undefined, createdAt: string, updatedAt: string, accountHolderId: string, externalFinancialBankAccountId?: string | null | undefined, accountDetails: { __typename?: 'AccountDetails', mask?: string | null | undefined, name: string, type?: string | null | undefined, currentBalance?: number | null | undefined, availableBalance?: number | null | undefined, institution: { __typename?: 'FinancialInstitution', institutionId?: string | null | undefined, logo?: string | null | undefined, name?: string | null | undefined } } } | null | undefined };

export const PaymentAccountItem = gql`
    fragment PaymentAccountItem on PaymentAccount {
  __typename
  id
  createdAt
  updatedAt
  accountHolderId
  accountDetails {
    mask
    name
    type
    institution {
      institutionId
      logo
      name
    }
  }
  externalFinancialBankAccountId
}
    `;
export const PaymentAccountDetailsBalance = gql`
    fragment PaymentAccountDetailsBalance on PaymentAccount {
  accountDetails {
    currentBalance
    availableBalance
  }
}
    `;
export const PaymentAccountItemWithBalance = gql`
    fragment PaymentAccountItemWithBalance on PaymentAccount {
  ...PaymentAccountItem
  ...PaymentAccountDetailsBalance
}
    ${PaymentAccountItem}
${PaymentAccountDetailsBalance}`;
export const GenerateCardDetailsToken = gql`
    mutation GenerateCardDetailsToken($input: CardTokenInput!) {
  generateToken {
    restrictedDetailsCardToken(input: $input) {
      __typename
      value
      expirationDate
    }
  }
}
    `;
export const GenerateManageCardToken = gql`
    mutation GenerateManageCardToken($input: CardTokenInput!) {
  generateToken {
    manageCardToken(input: $input) {
      __typename
      value
      expirationDate
    }
  }
}
    `;
export const GenerateDocumentUploadToken = gql`
    mutation GenerateDocumentUploadToken($sessionId: ID!) {
  generateToken {
    documentUploadToken(sessionId: $sessionId) {
      __typename
      value
      expirationDate
    }
  }
}
    `;
export const GenerateAccountHolderToken = gql`
    mutation GenerateAccountHolderToken($accountHolderId: ID!) {
  generateToken {
    accountHolderToken(accountHolderId: $accountHolderId) {
      __typename
      value
      expirationDate
    }
  }
}
    `;
export const GenerateInitialDepositToken = gql`
    mutation GenerateInitialDepositToken($input: InitialDepositTokenInput!) {
  generateToken {
    initialDepositToken(input: $input) {
      __typename
      value
      expirationDate
    }
  }
}
    `;
export const GenerateOneTimePaymentToken = gql`
    mutation GenerateOneTimePaymentToken($input: OneTimePaymentTokenInput!) {
  generateToken {
    oneTimePaymentToken(input: $input) {
      __typename
      value
      expirationDate
      usage
    }
  }
}
    `;
export const GenerateManageScheduledTransferToken = gql`
    mutation GenerateManageScheduledTransferToken($scheduledTransferId: ID!) {
  generateToken {
    manageScheduledTransferToken(scheduledTransferId: $scheduledTransferId) {
      __typename
      value
      expirationDate
      usage
    }
  }
}
    `;
export const GenerateIssuePaymentCardToken = gql`
    mutation GenerateIssuePaymentCardToken($id: ID!) {
  generateToken {
    issuePaymentCardToken(financialAccountId: $id) {
      __typename
      value
      expirationDate
    }
  }
}
    `;
export const GeneratePlaidLinkToken = gql`
    mutation GeneratePlaidLinkToken($input: GeneratePlaidLinkTokenInput!) {
  generatePlaidLinkToken(input: $input) {
    __typename
    linkToken
  }
}
    `;
export const SavePaymentAccount = gql`
    mutation SavePaymentAccount($input: SavePaymentAccountInput!) {
  savePaymentAccount(input: $input) {
    __typename
    externalFinancialBankAccountId
    createdAt
  }
}
    `;
export const RewardGalleryUrl = gql`
    query RewardGalleryUrl($input: ViewRybbonRewardGalleryInput!) {
  viewRybbonRewardGallery(input: $input) {
    success
    url
  }
}
    `;
export const SaveInitialDepositId = gql`
    mutation SaveInitialDepositId($input: SaveInitialDepositIdInput!) {
  saveInitialDepositId(input: $input) {
    __typename
    depositId
  }
}
    `;
export const ClosePaymentAccount = gql`
    mutation ClosePaymentAccount($id: ID!) {
  closePaymentAccount(externalFinancialBankAccountId: $id) {
    timestamp
  }
}
    `;
export const GetAppMetadata = gql`
    query GetAppMetadata($id: ID!) {
  getAppMetadata(userId: $id) {
    __typename
    accountHolderId
    externalId
    financialAccountId
    initialDepositTransferId
    autopayEnabled
  }
}
    `;
export const GetPaymentAccountList = gql`
    query GetPaymentAccountList($accountHolderId: ID!) {
  getPaymentAccounts(accountHolderId: $accountHolderId) {
    __typename
    count
    accounts {
      ...PaymentAccountItem
    }
  }
}
    ${PaymentAccountItem}`;
export const GetPaymentAccount = gql`
    query GetPaymentAccount($id: ID!) {
  getPaymentAccount(externalFinancialBankAccountId: $id) {
    __typename
    ...PaymentAccountItemWithBalance
  }
}
    ${PaymentAccountItemWithBalance}`;