<template>
  <template v-if="banner && show">
    <div class="MessageMaintenance" ref="rootRef">
      <Message
        variant="warning"
        :dismissable="banner.type === MaintenanceFeatureFlagWindowType.DismissableBanner"
        @onClose="handleClose"
      >
        <div class="d-flex align-center">
          <Icon name="alert-circle" style="font-size: 16px; margin-right: 6px" class="c-orange1" />
          {{ banner.message }}
        </div>
      </Message>
    </div>
  </template>
</template>

<script setup lang="ts">
/**
 * Maintenance banner statefull component.
 * Content and timeframe configured by dedicated env vars.
 * Show if timestamp specified and not expired.
 * Dismissed state stored in local storage.
 */
import { computed, onUnmounted, ref, watch } from 'vue'

import Message from '@/components/Message.vue'
import { injectStorage } from '@/provider/storageProvider'
import { injectDomProperties } from '@/provider/domPropertiesProvider'
import { BANNER_NOTIFICATION_STORAGE_KEY } from '@/constants'
import { head } from '@/lib/list'
import { injectFeatureFlag } from '@/provider/featureFlagProvider'
import { MaintenanceFeatureFlagWindowType } from '@/provider/featureFlagStoreProvider'
import Icon from '@/components/Icon.vue'

const $storage = injectStorage()
const $domProperties = injectDomProperties()
const $featureFlag = injectFeatureFlag()

const rootRef = ref<Maybe<HTMLElement>>(null)
const show = ref(false)
const banner = computed(() => $featureFlag.activeWindow.value)
const resizeObserver = new ResizeObserver((entries) => {
  const entry = head(entries)
  if (!entry) {
    return
  }
  const { height } = entry.contentRect
  $domProperties.setState({ maintenanceMessageHeight: height })
})

watch(rootRef, observeSizeChange)
watch(banner, initBanner, { immediate: true })

onUnmounted(() => {
  if (rootRef.value) {
    resizeObserver.unobserve(rootRef.value)
  }
})

function initBanner() {
  if (!banner.value) {
    return
  }
  const stored = $storage.getNumber(BANNER_NOTIFICATION_STORAGE_KEY)
  if (stored === 0) {
    show.value = true
  } else {
    show.value = stored !== banner.value.startsEpoch
  }
}

function observeSizeChange(value: Maybe<HTMLElement>) {
  if (!value) {
    return
  }
  resizeObserver.unobserve(value)
  resizeObserver.observe(value)
}

function handleClose() {
  if (!banner.value) {
    return
  }
  show.value = false
  $storage.setNumber(BANNER_NOTIFICATION_STORAGE_KEY, banner.value.startsEpoch)
}
</script>

<style>
.MessageMaintenanceFullScreen__description {
  width: 80%;
}
</style>
