<template>
  <Message :variant="'error'" :dismissable="false">
    Failed to fetch your account details. Please
    <a class="fs-14 fw-500 c-red" href="" @click.prevent="handleReloadClick">reload page</a>. If the
    problem persists, contact
    <LinkMailTo :email="SUPPORT_EMAIL" text="support" style="color: var(--c-red)" />.
  </Message>
</template>

<script setup lang="ts">
import Message from './Message.vue'
import LinkMailTo from './LinkMailTo.vue'
import { SUPPORT_EMAIL } from '../constants'

const handleReloadClick = () => window.location.reload()
</script>
