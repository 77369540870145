<template>
  <slot v-if="loaded" />
  <LoaderFullscreen v-else />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { useBool } from '@/lib/composable/useBool'
import { consoleLogger } from '@/lib/logger'
import { isAuthenticated } from '@/store/auth'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import LoaderFullscreen from '@/components/LoaderFullscreen.vue'
import { tracker } from '@/api/segment/segment'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'
import { useAccountHolder } from '@/store/accountHolder'
import { usePaymentsStore } from '@/store/payments'

useMountedLog('AuthGuard')
const route = useRoute()
const { value: loaded, truthyfy: setLoaded } = useBool(false)
const $auth = injectAuthFunctions()
const { accountHolder } = useAccountHolder()
const { autopayEnabled } = usePaymentsStore()

onMounted(async () => {
  const authenticated = await isAuthenticated()
  consoleLogger.log(`[AuthGuard] To ${route.name as string}`)
  consoleLogger.log('[AuthGuard] Authenticated: ', authenticated)

  if (authenticated && $auth.isSessionExpirationValid()) {
    localStorage.removeItem('TC_RETURN_TO')
    setLoaded()
    tracker.identifyUser({
      accountHolder: accountHolder.value,
      autopayEnabled: autopayEnabled.value,
    })
  } else {
    localStorage.setItem('TC_RETURN_TO', `${window.location.origin}${window.location.pathname}`)
    $auth.logout(window.location.origin + '/login')
  }
})
</script>
