<template>
  <ul class="Settings__menu-mobile fs-14 fw-500">
    <li :class="{ active: isItemActive.company }">
      <LinkInternal class="c-grey1" :name="RouteName.SettingsCompany">Company</LinkInternal>
    </li>
    <li :class="{ active: isItemActive.personal }">
      <LinkInternal class="c-grey1" :name="RouteName.SettingsPersonal">Account Owner</LinkInternal>
    </li>
    <li :class="{ active: isItemActive.security }">
      <LinkInternal class="c-grey1" :name="RouteName.SettingsSecurity">Security</LinkInternal>
    </li>
    <li :class="{ active: isItemActive.bank }">
      <LinkInternal class="c-grey1" :name="RouteName.SettingsBank">Bank Accounts</LinkInternal>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { RouteName } from '@/router/route'
import LinkInternal from '../LinkInternal.vue'

const route = useRoute()

const isItemActive = computed(() => ({
  personal: route.name === RouteName.SettingsPersonal,
  company: route.name === RouteName.SettingsCompany,
  security: route.name === RouteName.SettingsSecurity,
  bank: route.name === RouteName.SettingsBank,
}))
</script>

<style>
.Settings__menu-mobile {
  display: flex;
  padding-left: 0;
  margin-bottom: 24px;
  list-style: none;
  overflow-x: auto;
  border-bottom: 1px solid var(--bg-grey1);
}
.Settings__menu-mobile li {
  padding-bottom: 24px;
}
.Settings__menu-mobile li:not(:first-child) {
  margin-left: 24px;
}
.Settings__menu-mobile li.active {
  border-bottom: 2px solid var(--c-green1);
}
</style>
