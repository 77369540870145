import { IAwsCredentials } from '@/api/graphql/awsCredentials'
import { encrypt, getSignature, hash } from '@/lib/cryptography'

interface IAwsRequestData {
  algorithm: string
  credentials: IAwsCredentials
  dateStamp: string
  dateTimeStamp: string
  serviceInfo: {
    region: string
    service: string
  }
  headers: Record<string, string>
  method: string
  urlPath: string
  queryParams: string
  payload: string
}
export const getAwsAuthorization = (requestData: IAwsRequestData): string => {
  const {
    algorithm,
    credentials,
    dateStamp,
    dateTimeStamp,
    serviceInfo,
    headers,
    payload,
    method,
    urlPath,
    queryParams,
  } = requestData
  const autorizationHeader = getAuthorizationHeader(
    algorithm,
    credentials.AccessKeyId,
    credentialScope(dateStamp, serviceInfo.region, serviceInfo.service),
    signedHeaders(headers),
    getSignature(
      getSigningKey(credentials.SecretKey, dateStamp, serviceInfo),
      stringToSign(
        algorithm,
        canonicalRequest({ method, path: urlPath, queryParams, headers: headers, data: payload }),
        dateTimeStamp,
        credentialScope(dateStamp, serviceInfo.region, serviceInfo.service)
      )
    )
  )
  return autorizationHeader
}

const stringToSign = function (
  algorithm: string,
  canonicalRequest: string,
  dateTimeStamp: string,
  scope: string
) {
  return [algorithm, dateTimeStamp, scope, hash(canonicalRequest)].join('\n')
}

const getSigningKey = function (
  secretKey: string,
  dateStamp: string,
  serviceInfo: { region: string; service: string }
): Uint8Array {
  const k = 'AWS4' + secretKey,
    kdate = encrypt(k, dateStamp),
    kregion = encrypt(kdate, serviceInfo.region),
    kservice = encrypt(kregion, serviceInfo.service),
    ksigning = encrypt(kservice, 'aws4_request')
  return ksigning
}

const canonicalHeaders = function (headers: Record<string, string>) {
  if (!headers || Object.keys(headers).length === 0) {
    return ''
  }
  return (
    Object.keys(headers)
      .map(function (key) {
        return {
          key: key.toLowerCase(),
          value: headers[key] ? headers[key].trim().replace(/\s+/g, ' ') : '',
        }
      })
      .sort(function (a, b) {
        return a.key < b.key ? -1 : 1
      })
      .map(function (item) {
        return item.key + ':' + item.value
      })
      .join('\n') + '\n'
  )
}

const canonicalRequest = function (request: {
  method: string
  path: string
  queryParams: string
  headers: Record<string, string>
  data: string
}) {
  return [
    request.method,
    request.path,
    request.queryParams,
    canonicalHeaders(request.headers),
    signedHeaders(request.headers),
    hash(request.data),
  ].join('\n')
}

const signedHeaders = function (headers: Record<string, string>) {
  return Object.keys(headers)
    .map(function (key) {
      return key.toLowerCase()
    })
    .sort()
    .join(';')
}

const credentialScope = function (d_str: string, region: string, service: string): string {
  return [d_str, region, service, 'aws4_request'].join('/')
}

const getAuthorizationHeader = (
  algorithm: string,
  access_key: string,
  scope: string,
  signed_headers: string,
  signature: string
): string => {
  return [
    algorithm + ' ' + 'Credential=' + access_key + '/' + scope,
    'SignedHeaders=' + signed_headers,
    'Signature=' + signature,
  ].join(', ')
}
