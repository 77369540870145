<template>
  <SettingsRemoveBankAccountModalView
    :account="account"
    :loading="state.loading"
    :error="state.error"
    :disabled="disabled"
    @onSubmit="handleSubmit"
    @onCancel="handleCancel"
  />
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

import SettingsRemoveBankAccountModalView from '@/components/settings/SettingsRemoveBankAccountModalView.vue'

import { useFinancialInsitutionStore } from '@/store/useFinancialInsitutionStore'

import { lt } from '@/lib/logic'
import { tracker } from '@/api/segment/segment'
import { closePaymentAccount } from '@/api/graphql/graphqlAPI'
import { captureException } from '@/api/sentry/sentry'
import { IPaymentAccount } from '@/lib/domain/types'

const props = defineProps<{ account: IPaymentAccount }>()
const emit = defineEmits<{
  (event: 'onSubmit', value: string): void
  (event: 'onCancel', value: string): void
}>()

const { linkListCount, updateFinancialInstitutionList } = useFinancialInsitutionStore()

const state = reactive({
  loading: false,
  error: null as Maybe<Error>,
})

const disabled = computed<boolean>(() => lt(linkListCount.value, 2))
const id = computed<Maybe<string>>(() => props.account.id)

const handleSubmit = async () => {
  if (!id.value) {
    return
  }
  emit('onSubmit', id.value)
  state.error = null
  state.loading = true
  const removeResult = await removeAccount()
  if (!removeResult.ok) {
    state.error = removeResult.error
    state.loading = false
    captureException(removeResult.error)
    return
  }
  tracker.trackRemovePaymentAccount({ account: props.account })
  await updateFinancialInstitutionList()
  state.loading = false
  handleCancel()
}
const handleCancel = () => {
  if (!id.value) {
    return
  }
  emit('onCancel', id.value)
}
const removeAccount = (): Promise<Result<boolean>> => {
  return closePaymentAccount(props.account.externalFinancialBankAccountId)
}
</script>
