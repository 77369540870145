<template>
  <slot />
</template>

<script setup lang="ts">
/**
 * Use auth client created up in the tree to provide set of usable functions
 */

import { provide } from 'vue'

import { createAuthFunctions, getAuthClient } from '@/api/auth/authClient'
import { AUTH_FUNCTIONS_INJECTION_KEY } from '@/provider/authFunctionsProvider'

provideAuthFunctions()

function provideAuthFunctions(): void {
  const authClient = getAuthClient()
  if (!authClient) {
    return
  }
  const authFunctions = createAuthFunctions(authClient)
  provide(AUTH_FUNCTIONS_INJECTION_KEY, authFunctions)
}
</script>
