import { h } from 'vue'
import AuthGuard from '@/components/AuthGuard.vue'

export function withAuthGuard(childComponent: any) {
  return {
    setup() {
      return function () {
        return h(AuthGuard, () => [h(childComponent)])
      }
    },
  }
}
