import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'

import { authError, AuthErrorConfigureClient } from '@/lib/domain/auth'
import { ENV } from '@/lib/env'
import { consoleLogger } from '@/lib/logger'
import { result } from '@/lib/result'
import { captureException } from '@/api/sentry/sentry'
import { SESSION_EXPIRATION_STORAGE_KEY } from '@/constants'
import { createDate, now } from '@/lib/date'
import { storage } from '@/api/storage/storage'
import { RedirectLoginResult } from '@auth0/auth0-spa-js'
import { AUTH0_LOGIN_REQUIRED_ERROR_CODE } from '@/constants'
import { clearQueryParams } from '@/api/dom/document'
import { BaseError } from '@/lib/domain/error/BaseError'

export interface IUserInfo {
  email: string
  sub: string
}

export type AuthFunctions = {
  login: () => Promise<void>
  logout: (returnTo?: Maybe<string>) => Promise<void>
  handleRedirectCallback: () => Promise<Result<RedirectLoginResult>>
  changePassword: (email?: string) => Promise<Result>
  getUser: () => Promise<Result<IUserInfo>>
  getTokenSilently: () => Promise<Result<string>>
  isSessionExpirationValid: () => boolean
  getSessionExpiration: () => Promise<Result<Date>>
  storeSessionExpiration: () => Promise<void>
  isAuthenticated: () => Promise<boolean>
}

let _client: Maybe<Auth0Client> = null

export function getAuthClient(): Maybe<Auth0Client> {
  return _client
}

function createAuthClient(): Promise<Auth0Client> {
  return createAuth0Client({
    domain: ENV.TC_AUTH0_DOMAIN,
    client_id: ENV.TC_AUTH0_CLIENT_ID,
    connection: ENV.TC_AUTH0_CONNECTION,
    redirect_uri: window.location.origin,
  })
}

/**
 * Setup auth client.
 * Usually should be fired on application start
 * in order to manage authenticated/not authenticated application flows
 */
export async function configureAuthClient(): Promise<
  Result<Auth0Client, AuthErrorConfigureClient>
> {
  try {
    _client = await createAuthClient()
    return result.ok(_client)
  } catch (e) {
    /**
     * TODO: do something if auth client configuration failed
     */
    const error = authError.failedToConfigureClient(e)
    consoleLogger.error(error)
    captureException(e)
    return result.failed(error)
  }
}

export function createAuthFunctions(client: Auth0Client): AuthFunctions {
  return {
    async isAuthenticated(): Promise<boolean> {
      return await client.isAuthenticated()
    },

    /**
     * True if stored session expiration date is after current moment in time
     *
     * @returns Boolean
     */
    isSessionExpirationValid(): boolean {
      const expirationDateResult = storage.getDate(SESSION_EXPIRATION_STORAGE_KEY)
      return expirationDateResult.ok && expirationDateResult.value > now()
    },

    /**
     * Obtain expiration date from current ID token.
     * @returns Date Result
     */
    async getSessionExpiration(): Promise<Result<Date>> {
      try {
        const data = await client.getIdTokenClaims()
        const exp = data?.exp
        if (!exp) {
          return result.failed(new Error('Failed to get session expiration date.'))
        }
        return result.ok(createDate(exp * 1000))
      } catch (e) {
        return result.failed(e as Error)
      }
    },

    /**
     * Store current ID token expiration date to browser storage
     * @returns Promise
     */
    async storeSessionExpiration(): Promise<void> {
      const expirationDateResult = await this.getSessionExpiration()
      if (!expirationDateResult.ok) {
        captureException(expirationDateResult.error)
        return
      }
      storage.setDateTime(SESSION_EXPIRATION_STORAGE_KEY, expirationDateResult.value)
    },

    async login() {
      await client.loginWithRedirect({
        redirect_uri: window.location.origin,
        forgotPasswordUrl: ENV.TC_FORGOT_PASSWORD_URL,
      })
    },

    async logout(returnTo) {
      await client.logout({ returnTo: returnTo || window.location.origin })
    },

    async handleRedirectCallback() {
      try {
        const state = await client.handleRedirectCallback()
        clearQueryParams()
        return result.ok(state)
      } catch (e: any) {
        const error = authError.failedRedirectCallback(e)
        consoleLogger.error(error.message)
        clearQueryParams()
        return result.failed(error)
      }
    },

    async changePassword(email) {
      try {
        const response: Response = await fetch(
          `https://${ENV.TC_AUTH0_DOMAIN}/dbconnections/change_password`,
          {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              client_id: ENV.TC_AUTH0_CLIENT_ID,
              email,
              connection: ENV.TC_AUTH0_CONNECTION,
            }),
          }
        )
        if (response.status === 200) {
          return result.ok(undefined)
        } else {
          return result.failed(new Error('Status code not 200'))
        }
      } catch (e: any) {
        captureException(e)
        return result.failed(e)
      }
    },

    async getUser(): Promise<Result<IUserInfo>> {
      try {
        const user = await client.getUser()
        if (!user?.sub) {
          return result.failed(authError.userNotAvailable())
        }
        return result.ok(user as IUserInfo)
      } catch (e) {
        consoleLogger.error(e)
        captureException(e)
        return result.failed(authError.userNotAvailable())
      }
    },

    async getTokenSilently(): Promise<Result<string>> {
      try {
        const token = await client.getTokenSilently()
        if (!token) {
          return result.failed(authError.failedToGetTokenSilently())
        }
        return result.ok(token)
      } catch (e: any) {
        if (e.error === AUTH0_LOGIN_REQUIRED_ERROR_CODE) {
          await client.logout({ returnTo: window.location.origin })
        } else {
          consoleLogger.error(e)
          captureException(e)
        }
        return result.failed(authError.failedToGetTokenSilently(e))
      }
    },
  }
}

export function getAuthFunctions(): Result<AuthFunctions> {
  const authClient = getAuthClient()
  if (!authClient) {
    return result.failed(new BaseError('Auth client was not configured'))
  }
  return result.ok(createAuthFunctions(authClient))
}
