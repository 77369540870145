<template>
  <router-link
    v-if="name"
    class="LinkInternal"
    :class="{ 'c-green1': active, 'LinkInternal-disabled': disabled }"
    :to="disabled ? '' : { name }"
    @click="handleClick"
  >
    <slot />
  </router-link>

  <a v-else class="LinkInternal" @click="handleClick">
    <slot />
  </a>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { RouteName } from '@/router/route'

const route = useRoute()
const props = defineProps<{
  disabled?: boolean
  name?: RouteName
}>()
const emit = defineEmits(['onClick'])
const active: ComputedRef<boolean> = computed(() => {
  if (!props.name) {
    return false
  }
  return props.name === route.name || route.matched.some((r) => r.name === props.name)
})
const handleClick = () => {
  emit('onClick')
}
</script>

<style scoped>
.LinkInternal {
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
}
.LinkInternal-disabled {
  opacity: 0.5;
}
</style>
