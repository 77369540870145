<template>
  <MessagePopup :opened="opened">
    <template v-if="Boolean(error)">
      <div class="DownloadMessage__content c-black2 mb-16">
        {{ error?.message }}
      </div>
      <Button type="button" @click="handleCancelClick" bordered>Cancel</Button>
    </template>

    <template v-else>
      <div class="DownloadMessage__content c-black2 mb-16">Your transactions report is ready.</div>
      <Button type="button" variant="primary" @click="handleDownloadClick">Download</Button>
      <Button type="button" class="ml-16" @click="handleCancelClick" bordered>Cancel</Button>
    </template>
  </MessagePopup>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue'
import MessagePopup from '@/components/MessagePopup.vue'

defineProps<{
  opened: boolean
  error: Maybe<Error>
}>()

const emit = defineEmits<{
  (event: 'onDownload'): void
  (event: 'onCancel'): void
}>()

function handleDownloadClick() {
  emit('onDownload')
}

function handleCancelClick() {
  emit('onCancel')
}
</script>
