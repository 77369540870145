import {
  IApiToken,
  IToken,
  mapApiTokenToToken,
  TokenKey,
  TokenResource,
  createTokenStore,
  validateToken,
} from '@/lib/domain/token'
import { result } from '@/lib/result'

export const tokenStore = createTokenStore<IToken>()

const resourceStore = createTokenStore<TokenResource>()

/**
 * Get result of stored token validation
 * If token stored and valid return it
 * Otherwise obtain new token from provided token resource and store it
 * Cache not resolved API resource so that it can be used when same token queried multiple times simultaneously
 * Clear resolved resource from store so that it can be used only once
 * @param key - token key unders which it is stored
 * @param resourceFactor - function that returns Promise containing API call for new token.
 * Necessary to postpone Promise execution
 * @returns successful result with obtained token or failed result with error
 */
export const getApiToken = async (
  key: TokenKey,
  resourceFactory: () => TokenResource
): Promise<Result<string>> => {
  const tokenResult = validateToken(tokenStore.get(key))
  if (tokenResult.ok) {
    return result.ok(tokenResult.value.value)
  }
  if (!resourceStore.has(key)) {
    resourceStore.set(key, resourceFactory())
  }
  const tokenApiResult = (await resourceStore.get(key)) as Result<IApiToken>
  resourceStore.delete(key)
  if (tokenApiResult.ok) {
    const token = mapApiTokenToToken(tokenApiResult.value)
    tokenStore.set(key, token)
    return result.ok(token.value)
  }
  return tokenApiResult
}
