<template>
  <Page name="Let's set up your Tillful Card!" class="OnboardingPage">
    <p class="fs-14 c-grey3 mb-24">Complete these 3 steps to activate your card.</p>

    <div class="OnboardingSteps bg-white">
      <div
        class="OnboardingSteps__step d-flex align-center justify-between"
        :class="onboardingStepClass(onboardingSteps.account)"
      >
        <div class="d-flex">
          <div class="OnboardingSteps__step__icon flex-center fs-14 fw-600 mr-16">
            <Icon
              v-if="onboardingSteps.account.state === OnboardingStepStatus.Completed"
              name="check"
              class="c-white fs-16"
            />
            <span v-else>1</span>
          </div>
          <div class="d-flex align-start flex-direction-column justify-center">
            <template v-if="onboardingSteps.account.state === OnboardingStepStatus.Completed">
              <p class="OnboardingSteps__step__text fs-14 fw-500 c-black">
                Connect your bank account
              </p>
              <p
                class="OnboardingSteps__step__text OnboardingSteps__step__description fs-14 c-grey3"
              >
                You can manage your bank accounts in
                <router-link to="/settings/bank" class="discard-anchor cursor-pointer c-green2"
                  >Settings -> Bank Accounts</router-link
                >
              </p>
            </template>

            <template v-else>
              <p class="OnboardingSteps__step__text fs-14 fw-500 c-black">
                Connect your bank account
              </p>
              <p
                class="OnboardingSteps__step__text OnboardingSteps__step__description fs-14 c-grey3"
              >
                Securely connect your bank account to fund your Tillful Card.
              </p>
              <Message class="mr-16" v-if="bankErrorShown" variant="error" :dismissable="false">{{
                bankState.error
              }}</Message>
            </template>
          </div>
        </div>

        <Button
          class="OnboardingSteps__step__button"
          v-if="onboardingSteps.account.state === OnboardingStepStatus.Active"
          variant="primary"
          :loading="bankAccountLoading || bankAccountAddedButNotAvailable"
          @click="handleAddBankClick"
          >Link Bank Account</Button
        >
        <Button
          class="OnboardingSteps__step__button"
          v-if="onboardingSteps.account.state === OnboardingStepStatus.Completed"
          variant="secondaryGreen"
        >
          Completed
        </Button>
      </div>

      <div
        class="OnboardingSteps__step d-flex align-center justify-between"
        :class="onboardingStepClass(onboardingSteps.deposit)"
      >
        <div class="d-flex">
          <div class="OnboardingSteps__step__icon flex-center fs-14 fw-600 mr-16">
            <Icon
              v-if="
                onboardingSteps.deposit.state === OnboardingStepStatus.Completed ||
                onboardingSteps.deposit.state === OnboardingStepStatus.Pending
              "
              name="check"
              class="c-white fs-16"
            />
            <Icon
              v-else-if="onboardingSteps.deposit.state === OnboardingStepStatus.Failed"
              name="alert-circle"
              class="c-white fs-16"
            />
            <span v-else>2</span>
          </div>
          <div class="d-flex align-start flex-direction-column justify-center">
            <p class="OnboardingSteps__step__text fs-14 fw-500 c-black">
              Make your security deposit
            </p>

            <p class="OnboardingSteps__step__text OnboardingSteps__step__description fs-14 c-grey3">
              {{ depositStepDescription }}
            </p>
          </div>
        </div>

        <Button
          class="OnboardingSteps__step__button"
          v-if="
            onboardingSteps.deposit.state === OnboardingStepStatus.Active ||
            onboardingSteps.deposit.state === OnboardingStepStatus.Failed
          "
          variant="primary"
          @click="handleInitiateDepositClick"
          >Initiate deposit</Button
        >
        <Button
          class="OnboardingSteps__step__button"
          v-else-if="onboardingSteps.deposit.state === OnboardingStepStatus.Pending"
          variant="primary"
          style="background-color: #fef3c7; color: #b45309"
          >Pending</Button
        >
        <Button
          class="OnboardingSteps__step__button"
          v-else-if="onboardingSteps.deposit.state === OnboardingStepStatus.Completed"
          variant="primary"
          style="background-color: #dcfce7; color: #15803d"
          >Completed</Button
        >
        <Button class="OnboardingSteps__step__button" v-else variant="secondary" disabled
          >Initiate deposit</Button
        >

        <PaymentModal
          v-if="paymentModalState.opened"
          :opened="paymentModalState.opened"
          :accountList="linkList"
          variant="deposit"
          @close="handlePaymentModalClose"
          @completed="handleDepositCompleted"
          @failed="handleDepositFailed"
        />
      </div>

      <div
        class="OnboardingSteps__step d-flex align-center justify-between flex-wrap"
        :class="onboardingStepClass(onboardingSteps.card)"
      >
        <div class="d-flex" style="flex-basis: 75%">
          <div class="OnboardingSteps__step__icon flex-center fs-14 fw-600 mr-16">
            <Icon
              v-if="onboardingSteps.card.state === OnboardingStepStatus.Completed"
              name="check"
              class="c-white fs-16"
            />
            <span v-else>3</span>
          </div>
          <div class="d-flex align-start flex-direction-column justify-center">
            <p class="OnboardingSteps__step__text fs-14 fw-500 c-black">
              Activate your physical card
            </p>
            <p v-if="cardOrdered" class="OnboardingSteps__step__text fs-14 c-grey3">
              Your physical card is
              <Tag style="background-color: var(--bg-yellow); color: var(--c-orange3)">Ordered</Tag>
            </p>
            <p v-else-if="cardOrderFailed" class="OnboardingSteps__step__text fs-14 c-grey3">
              Your physical card is
              <Tag style="background-color: var(--bg-red); color: var(--c-red)">Canceled</Tag>
            </p>
            <p
              v-else-if="cardShipped"
              class="OnboardingSteps__step__text OnboardingSteps__step__description fs-14 c-grey3"
            >
              Your physical card is
              <Tag style="background-color: var(--bg-green2); color: var(--c-green)">Shipped</Tag>
            </p>
            <p
              v-else-if="depositProcessedCardNotIssued"
              class="OnboardingSteps__step__text OnboardingSteps__step__description fs-14 c-grey3"
            >
              We are in the process of issuing your cards. We will notify you when your cards are
              ready.
            </p>
            <p
              v-else
              class="OnboardingSteps__step__text OnboardingSteps__step__description fs-14 c-grey3"
            >
              Received your Tillful Card in the mail? Activate it here to start spending.
            </p>
          </div>
        </div>

        <Button
          class="OnboardingSteps__step__button"
          v-if="cardActivated"
          variant="secondary"
          disabled
          >Card Activated</Button
        >
        <Button
          class="OnboardingSteps__step__button"
          v-else-if="card"
          variant="primary"
          @click="handleActivateCard"
          >Activate card</Button
        >
        <Button class="OnboardingSteps__step__button" v-else variant="secondary" disabled
          >Activate card</Button
        >
        <div
          v-if="cardOrderedOrShipped && !cardActivated"
          class="OnboardingSteps__step__message fs-12"
        >
          For your protection, we recommend you activate this card only once it is physically in
          your possession.
        </div>
        <div v-if="cardOrderFailed" class="OnboardingSteps__step__message fs-12">
          We encountered an issue with your order. Please contact
          <LinkMailTo email="card@tillful.com" text="support" style="font-size: 12px" /> for more
          information.
        </div>
      </div>

      <div v-if="autopayAvailable" class="OnboardingSteps__step">
        <div class="OnboardingSteps__step__autopay">
          <p class="fs-20 fw-bold c-black mb-8">Enable Autopay</p>
          <p class="fs-14 c-grey3 mb-16">
            Once your security deposit is confirmed on our end, you will be able to set automatic
            payments.
          </p>
          <Button
            variant="primary"
            :loading="autopayLoading"
            @click="handleEnableAutopayClick"
            style="width: 150px"
            >Enable Autopay</Button
          >
        </div>
      </div>
    </div>

    <AutopayModal
      v-if="autopayModalOpened"
      :opened="autopayModalOpened"
      :accountList="linkList"
      @close="handleAutopayModalClose"
      @completed="handleAutopayCompleted"
    />
  </Page>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { computed, ComputedRef, onMounted, reactive, Ref, ref, watch } from 'vue'

import Page from '@/components/Page.vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import PaymentModal from '@/components/payment/PaymentModal.vue'

import { useFinancialInsitutionStore } from '@/store/useFinancialInsitutionStore'
import { saveDepositId, useOnboardingStore } from '@/store/onboarding'
import { noop, runIf } from '@/lib/function'
import { RouteName } from '@/router/route'
import { head } from '@/lib/list'
import { IPlaidMeta } from '@/lib/domain/types'

import { createPlaidHandler, isPlaidOAuthUrl } from '@/api/plaid/plaid'
import {
  IntegratorInitiatedAchStatus,
  PaymentCard,
  PaymentCardOrderStatus,
} from '@/generated/graphql'
import Tag from '@/components/Tag.vue'
import LinkMailTo from '@/components/LinkMailTo.vue'
import { useBool } from '@/lib/composable/useBool'
import { getInitialDepostiStatusDescription } from '@/lib/domain/transfer'
import AutopayModal from '@/components/payment/AutopayModal.vue'
import { loadAppMetadata } from '@/store/auth'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { usePolling } from '@/lib/composable/usePolling'
import { usePaymentsStore } from '@/store/payments'
import Message from '@/components/Message.vue'
import { consoleLogger, sentryLogger } from '@/lib/logger'
import { useAccountHolder } from '@/store/accountHolder'
import { usePaymentCards } from '@/service/usePaymentCards'
import { FAILED_TO_CONNECT, INSTITUTION_NOT_SUPPORTED } from '@/constants'
import { notNil } from '@/lib/type'
import { AppSyncApiErrorType, isAppSyncApiError } from '@/lib/domain/error/AppSyncApiError'
import { injectAccountHolderStore } from '@/provider/accountHolderStoreProvider'

useMountedLog('Onboarding')

const {
  store: { mainCard, mainCardActive },
} = usePaymentCards()

const {
  depositProcessedCardNotIssued,
  depositPending,
  depositFailed: loadedDepositFailed,
  depositCanceled,
  depositReturned,
  stepBankAccountCompleted,
  stepDepositCompleted,
  stepCardCompleted,
  onboardingCompleted,
} = useOnboardingStore()
const {
  saveFinancialInstitution,
  updateFinancialInstitutionList,
  linkList,
  bankAccountAddedButNotAvailable,
} = useFinancialInsitutionStore()
const { loadDepositList, autopayEnabled } = usePaymentsStore()
const router = useRouter()
const route = useRoute()
const {
  value: bankAccountLoading,
  truthyfy: setBankAccountLoading,
  falsefy: resetBankAccountLoading,
} = useBool(false)
const { value: bankAccountConnectedEvent, truthyfy: setBankAccountConnected } = useBool(false)
const {
  value: depositOptimisticallyPending,
  truthyfy: setDepositPending,
  falsefy: resetDepositPending,
} = useBool(false)
const { value: dataLoaded, truthyfy: setDataLoaded } = useBool(false)

onMounted(handlePlaidOAuthIfAvailable)
onMounted(redirectIfCompleted)

const apiPolling = usePolling({
  callback: async () => {
    await loadData()
    resetDepositPending()
  },
  time: 10_000,
})
onMounted(apiPolling.start)

const $accountHolderStore = injectAccountHolderStore()

const { id: accountHolderId } = useAccountHolder()

/**
 * TODO: load all at one query
 */
async function loadData() {
  await $accountHolderStore.loadAccountHolder(accountHolderId.value)
  await updateFinancialInstitutionList()
  await loadDepositList()
  setDataLoaded()
}

function redirectIfCompleted() {
  if (onboardingCompleted.value) {
    router.push({ name: RouteName.Dashboard })
  }
}

function handlePlaidOAuthIfAvailable() {
  if (!isPlaidOAuthUrl(route)) {
    return
  }
  handlePlaidOAuth()
}

enum OnboardingStepStatus {
  Initial = 'Initial',
  Active = 'Active',
  Pending = 'Pending',
  Completed = 'Completed',
  Failed = 'Failed',
}

interface IOnboardingStep {
  state: OnboardingStepStatus
}

const depositPaymentFailed: Ref<boolean> = ref(false)
const depositFailed: ComputedRef<boolean> = computed(
  () => depositPaymentFailed.value || loadedDepositFailed.value
)
const depositStepDescription: ComputedRef<string> = computed(() => {
  if (depositPaymentFailed.value) {
    return 'Deposit failed to initiate. Please try again.'
  }
  if (depositCanceled.value) {
    return getInitialDepostiStatusDescription(IntegratorInitiatedAchStatus.Canceled)
  }
  if (depositReturned.value) {
    return getInitialDepostiStatusDescription(IntegratorInitiatedAchStatus.Returned)
  }
  if (depositFailed.value) {
    return getInitialDepostiStatusDescription(IntegratorInitiatedAchStatus.Failed)
  }
  return getInitialDepostiStatusDescription(null)
})
const card = computed(() => mainCard.value as Maybe<PaymentCard>)
const cardOrderStatus = computed(
  () => head(card.value?.physicalPaymentCardOrders || [])?.orderState?.status
)
const cardOrdered = computed(
  () =>
    cardOrderStatus.value &&
    [
      PaymentCardOrderStatus.New,
      PaymentCardOrderStatus.Approved,
      PaymentCardOrderStatus.SentToPrinter,
    ].includes(cardOrderStatus.value)
)
const cardShipped = computed(
  () => cardOrderStatus.value && cardOrderStatus.value === PaymentCardOrderStatus.Shipped
)
const cardActivated: ComputedRef<boolean> = computed(() => mainCardActive.value)
const cardOrderedOrShipped = computed(() => cardOrdered.value || cardShipped.value)
const cardOrderFailed = computed(
  () =>
    cardOrderStatus.value &&
    [PaymentCardOrderStatus.Canceled, PaymentCardOrderStatus.ShipFailed].includes(
      cardOrderStatus.value
    )
)

const onboardingSteps = computed<{
  account: IOnboardingStep
  deposit: IOnboardingStep
  card: IOnboardingStep
}>(() => ({
  account: {
    state: stepBankAccountCompleted.value
      ? OnboardingStepStatus.Completed
      : OnboardingStepStatus.Active,
  },
  deposit: {
    state:
      stepBankAccountCompleted.value && stepDepositCompleted.value
        ? OnboardingStepStatus.Completed
        : depositPending.value || depositOptimisticallyPending.value
        ? OnboardingStepStatus.Pending
        : depositFailed.value
        ? OnboardingStepStatus.Failed
        : stepBankAccountCompleted.value && !stepDepositCompleted.value
        ? OnboardingStepStatus.Active
        : OnboardingStepStatus.Initial,
  },
  card: {
    state:
      stepBankAccountCompleted.value && stepDepositCompleted.value && !stepCardCompleted.value
        ? OnboardingStepStatus.Active
        : stepCardCompleted.value
        ? OnboardingStepStatus.Completed
        : OnboardingStepStatus.Initial,
  },
}))

const onboardingStepClass = (step: IOnboardingStep) => ({
  'OnboardingSteps__step-completed':
    step.state === OnboardingStepStatus.Completed || step.state === OnboardingStepStatus.Pending,
  'OnboardingSteps__step-active': step.state === OnboardingStepStatus.Active,
  'OnboardingSteps__step-failed': step.state === OnboardingStepStatus.Failed,
})

const bankState = reactive<{ error: Maybe<string> }>({
  error: null,
})
const bankErrorShown = computed<boolean>(() => notNil(bankState.error))
const showBankError = (error: string): void => {
  bankState.error = error
}
const hideBankError = () => {
  bankState.error = null
}
const handleAddBankClick = async () => {
  setBankAccountLoading()
  hideBankError()
  const handlerResult = await createPlaidHandler({
    onSuccess: (_, meta) => handlePlaidSuccess(meta),
    onExitError: () => {
      showBankError(FAILED_TO_CONNECT)
    },
    onOpen: resetBankAccountLoading,
  })
  if (!handlerResult.ok) {
    consoleLogger.error(handlerResult.error)
    sentryLogger.error(handlerResult.error)
    resetBankAccountLoading()
    showBankError(FAILED_TO_CONNECT)
    return
  }
  handlerResult.value.open()
}

const handlePlaidOAuth = async () => {
  setBankAccountLoading()
  const handler = await createPlaidHandler({
    receivedRedirectUrl: window.location.href,
    onSuccess: (_, meta) => handlePlaidSuccess(meta),
    onOpen: resetBankAccountLoading,
  })
  // clear Plaid query after it is used
  window.history.replaceState({}, document.title, window.location.pathname)
  if (!handler.ok) {
    return
  }
  handler.value.open()
}

const handlePlaidSuccess = async (meta: IPlaidMeta) => {
  hideBankError()
  setBankAccountLoading()
  const saveLinkResult = await saveFinancialInstitution(meta)
  resetBankAccountLoading()
  if (saveLinkResult.ok) {
    setBankAccountConnected()
    return
  }

  if (!isAppSyncApiError(saveLinkResult.error)) {
    showBankError(FAILED_TO_CONNECT)
    return
  }
  const errorTypeMessageMap: Record<AppSyncApiErrorType | 'na', string> = {
    [AppSyncApiErrorType.InstitutionNotSupported]: INSTITUTION_NOT_SUPPORTED,
    na: FAILED_TO_CONNECT,
  }
  showBankError(errorTypeMessageMap[saveLinkResult.error.errorType ?? 'na'])
}

const paymentModalState = reactive({
  opened: false,
})
const handleInitiateDepositClick = () => {
  paymentModalState.opened = true
}
const handlePaymentModalClose = () => {
  paymentModalState.opened = false
}

const handleDepositCompleted = async (id: string) => {
  depositPaymentFailed.value = false
  setDepositPending()
  if (!id) {
    handleDepositFailed()
    return
  }
  const saveResult = await saveDepositId(id)
  if (!saveResult.ok) {
    handleDepositFailed()
    return
  }
  await loadAppMetadata()
  await loadDepositList()
}

const handleDepositFailed = async () => {
  depositPaymentFailed.value = true
}

const handleActivateCard = () => {
  router.push({ name: RouteName.ActivateCard }).catch(noop)
}

const autopayAvailable = computed(
  () => dataLoaded.value && stepBankAccountCompleted.value && !autopayEnabled.value
)
const {
  value: autopayModalOpened,
  truthyfy: openAutopayModal,
  falsefy: closeAutopayModal,
} = useBool()
const {
  value: autopayLoading,
  truthyfy: setAutopayLoading,
  falsefy: resetAutopayLoading,
} = useBool()
const runAutopayEvent = computed(
  () => bankAccountConnectedEvent.value && !autopayEnabled.value && stepBankAccountCompleted.value
)
const handleEnableAutopayClick = () => {
  setAutopayLoading()
  openAutopayModal()
}
const handleAutopayModalClose = () => {
  resetAutopayLoading()
  closeAutopayModal()
}
const handleAutopayCompleted = noop
watch(runAutopayEvent, runIf(handleEnableAutopayClick))
</script>

<style>
.OnboardingSteps {
  max-width: 708px;
}
.OnboardingSteps__step {
  padding: 24px;
  row-gap: 16px;
}
.OnboardingSteps__step__autopay {
  border-radius: 8px;
  background-color: #e7f6df;
  padding: 24px;
}
.OnboardingSteps__step:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}
.OnboardingSteps__step__icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px #e4e4e7;
  color: #9ca3af;
}
.OnboardingSteps__step.OnboardingSteps__step-active .OnboardingSteps__step__icon {
  border: solid 2px var(--c-green6);
  color: var(--c-green6);
}
.OnboardingSteps__step.OnboardingSteps__step-completed .OnboardingSteps__step__icon {
  border: solid 2px var(--c-green6);
  background-color: var(--c-green6);
  color: white;
}
.OnboardingSteps__step.OnboardingSteps__step-failed .OnboardingSteps__step__icon {
  border: solid 2px var(--c-red);
  background-color: var(--c-red);
  color: white;
}
.OnboardingSteps__step__text {
  line-height: 1.43;
}
.OnboardingSteps__step__message {
  flex-grow: 1;
  padding: 10px 8px;
  /* icon size + icon offset */
  margin-left: calc(40px + 16px);
  border-radius: 4px;
  background-color: var(--bg-grey2);
  color: var(--c-black1);
}
.OnboardingSteps__step__button {
  width: 140px;
  flex-shrink: 0;
}

@media all and (max-width: 768px) {
  .OnboardingSteps__step {
    flex-direction: column;
    align-items: flex-start;
  }
  .OnboardingSteps__step__icon {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
  .OnboardingSteps__step__icon .Icon {
    font-size: 8px;
  }
  .OnboardingSteps__step__description {
    margin-bottom: 16px;
  }
  .OnboardingSteps__step__message,
  .OnboardingSteps__step__button {
    /* icon size + icon offset */
    margin-left: calc(24px + 16px);
  }
}

@media all and (min-width: 768px) {
  .OnboardingPage .PageContent h1 {
    line-height: normal;
    margin-top: 36px;
    margin-bottom: 8px;
  }
  .OnboardingSteps__step__button {
    white-space: nowrap;
  }
}
</style>
