import { computed, reactive } from 'vue'

import { usePaymentCards } from '@/service/usePaymentCards'
import { useAccountHolder } from '@/store/accountHolder'
import { injectApi } from '@/provider/apiProvider'
import { injectTracker } from '@/provider/trackerProvider'
import { injectLogger } from '@/provider/loggerProvider'

const modalState = reactive({ pending: false, error: false, success: false })
export const useActivateCardModal = () => {
  const { store } = usePaymentCards()
  const { id: accountHolderId } = useAccountHolder()
  const $api = injectApi()
  const $tracker = injectTracker()
  const $logger = injectLogger()
  const handleModalActivate = async () => {
    try {
      const id = store.mainCard.value?.id
      if (!id) {
        throw new Error('[useActivateCardModal] Payment card ID was not provided')
      }
      if (!accountHolderId.value) {
        throw new Error('[useActivateCardModal] Account holder ID was not provided')
      }
      modalState.pending = true
      modalState.error = false
      modalState.success = false
      const result = await $api.activatePaymentCard(accountHolderId.value, id)
      if (!result.ok) {
        modalState.error = true
        $logger.error(result)
      } else {
        modalState.success = true
        store.loadCardList()
        $tracker.trackCardActivated({ last4: store.mainCard.value?.last4 })
      }
    } catch (e) {
      $logger.error(e)
      modalState.error = true
    } finally {
      modalState.pending = false
    }
  }

  return {
    state: computed(() => modalState),
    activate: handleModalActivate,
  }
}
