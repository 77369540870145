<template>
  <slot v-if="appViewAvailable" />
</template>

<script setup lang="ts">
import { noop } from '@/lib/function'
import { RouteName } from '@/router/route'
import { useOnboardingStore } from '@/store/onboarding'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const { appViewAvailable } = useOnboardingStore()
const router = useRouter()
onMounted(async () => {
  if (!appViewAvailable.value) {
    await router.push({ name: RouteName.Onboarding }).catch(noop)
  }
})
</script>
