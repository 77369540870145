<template>
  <Page name="Settings">
    <template #header-right>
      <span class="fs-12 fw-600" style="color: #9ca3af">v{{ $env.TC_APP_VERSION }}</span>
    </template>
    <SubPage>
      <SettingsPageMenuMobile v-if="mobileScreen" />
      <SettingsPageMenu v-else />
    </SubPage>
  </Page>
</template>

<script setup lang="ts">
import Page from '../components/Page.vue'
import SettingsPageMenu from '../components/settings/SettingsPageMenu.vue'
import { useMobileScreen } from '../api/dom/screen'
import SettingsPageMenuMobile from '../components/settings/SettingsPageMenuMobile.vue'
import SubPage from '../components/SubPage.vue'
import { injectEnv } from '@/provider/envProvider'
import { onMounted } from 'vue'

const $env = injectEnv()

const mobileScreen = useMobileScreen()
</script>
