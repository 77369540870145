export const noop = () => undefined

type Compose2 = <R1, R2, T>(f1: (b: R2) => R1, f2: (a: T) => R2) => (a: T) => R1
export const compose2: Compose2 = (f1, f2) => (input) => f1(f2(input))

type Predicate<T> = (input: T) => boolean

type Both = <T>(f1: Predicate<T>, f2: Predicate<T>) => (input: T) => boolean
export const both: Both = (f1, f2) => (input) => f1(input) && f2(input)

type Complement = <T>(f1: Predicate<T>) => (input: T) => boolean
export const complement: Complement = (f1) => (input) => !f1(input)

interface IPollingManager {
  start(): void
  stop(): void
}
export const createPolling = (callback: () => void, interval: number): IPollingManager => {
  let intervalRef: Maybe<any> = null
  const safeInterval = interval || 0
  return {
    start() {
      if (safeInterval > 0) {
        intervalRef = setInterval(callback, safeInterval)
      }
    },
    stop() {
      clearInterval(intervalRef)
    },
  }
}

export const runIf = (callback: () => unknown) => (value: unknown) => {
  if (value) {
    callback()
  }
}

export const compareByCreatedAtDesc = <T extends { createdAt?: Maybe<string> }>(a: T, b: T) => {
  if (!a.createdAt || !b.createdAt) {
    return 0
  }
  if (a.createdAt < b.createdAt) {
    return 1
  }
  if (a.createdAt > b.createdAt) {
    return -1
  }
  return 0
}

export const defaultTo = <T1, T2>(to: T2, from: T1): T1 | T2 => from ?? to

export function all<T>(funcList: Array<(item: T) => boolean>) {
  return function (item: T) {
    return funcList.every((func) => func(item))
  }
}
