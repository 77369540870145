<template>
  <div class="CardListMobile">
    <Message v-if="error" variant="error" class="mb-24" @onClose="$emit('onErrorDismiss')">
      Could not retrieve card details. Please try again.
    </Message>

    <div class="CardListMobile__item" v-for="cardView of cards">
      <Divider class="mb-24" />

      <div class="d-flex justify-between mb-24">
        <div>
          <div class="fs-14 fw-500 mb-12 c-grey2">Card Number</div>
          <CardNumber :hidden="cardView.hidden" :card="cardView" />
        </div>

        <div>
          <CardStatusTag :status="cardView.status" />
          <ButtonMenu @onClick="menuToggle($event, cardView)" />
        </div>
      </div>
      <div class="d-flex justify-start mb-24">
        <div class="mr-42">
          <div class="fs-14 fw-500 mb-12 c-grey2">Expiry Date</div>
          <div class="fs-14 fw-600 c-black">{{ cardView.date }}</div>
        </div>
        <div class="mr-42">
          <div class="fs-14 fw-500 mb-12 c-grey2">CVC</div>
          <div class="fs-14 fw-600 c-black">{{ cardView.cvv }}</div>
        </div>
      </div>
    </div>

    <ModalAction
      :opened="menuOpened"
      :actions="cardActions"
      @onClose="handleActionClose"
      @onAction="handleMenuAction"
    />
  </div>
</template>

<script setup lang="ts">
import CardStatusTag from './CardStatusTag.vue'
import Divider from '../Divider.vue'
import Message from '../Message.vue'
import { computed, ComputedRef } from '@vue/reactivity'
import ButtonMenu from '../ButtonMenu.vue'
import { usePaymentCardMenu } from '@/service/usePaymentCardMenu'
import { getDeviceType, DeviceType } from '@/api/dom/device'
import { noop } from '../../lib/function'
import ModalAction from '../ModalAction.vue'
import {
  isPaymentCardActive,
  isPaymentCardSuspended,
  isPaymentCardActivationRequired,
} from '../../lib/domain/paymentCard'
import { CardListMenuAction, IModalActionItem } from '@/lib/domain/types'
import { reject } from '../../lib/list'
import CardNumber from './CardNumber.vue'

defineProps<{ cards: any[]; error: boolean }>()
const emit = defineEmits(['onEyeClick', 'onErrorDismiss', 'onSaveInOP'])

const {
  menuOpened,
  menuToggle,
  menuAction,
  closeMenu,
  card: cardForMenu,
} = usePaymentCardMenu({
  onShowDetails: (id) => emit('onEyeClick', id),
  onSaveInOP: (id) => emit('onSaveInOP', id),
  onHideDetails: (id) => emit('onEyeClick', id),
  onMenuToggle: noop,
})

const handleActionClose = closeMenu
const handleMenuAction = menuAction
const device: DeviceType = getDeviceType()

const cardActions: ComputedRef<IModalActionItem[]> = computed(() => {
  let allActions = [
    { icon: 'eye', title: 'Show Details', action: CardListMenuAction.ShowDetails },
    { icon: 'lock', title: 'Lock Card', action: CardListMenuAction.Lock },
    { icon: 'unlock', title: 'Unlock Card', action: CardListMenuAction.Unlock },
    { icon: 'one-password-logo', title: 'Save in 1Password', action: CardListMenuAction.SaveInOP },
  ]
  if (cardForMenu.value && isPaymentCardSuspended(cardForMenu.value)) {
    allActions = reject((a) => a.action === CardListMenuAction.Lock, allActions)
  }
  if (cardForMenu.value && isPaymentCardActive(cardForMenu.value)) {
    allActions = reject((a) => a.action === CardListMenuAction.Unlock, allActions)
  }
  if (isPaymentCardActivationRequired(cardForMenu.value) || device !== DeviceType.Desktop) {
    allActions = reject((a) => a.action === CardListMenuAction.SaveInOP, allActions)
  }
  return allActions as IModalActionItem[]
})
</script>
