import { toHex } from '@aws-sdk/util-hex-encoding'
import { Sha256 } from '@aws-crypto/sha256-js'

export const encrypt = (key: string | Uint8Array, src: string): Uint8Array => {
  const hash = new Sha256(key)
  hash.update(src)
  return hash.digestSync()
}

export const hash = function (src: string): string {
  const arg = src || ''
  const hash = new Sha256()
  hash.update(arg)
  return toHex(hash.digestSync())
}

export const getSignature = (signingKey: string | Uint8Array, strToSign: string): string =>
  toHex(encrypt(signingKey, strToSign))
