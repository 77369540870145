import { ComputedRef } from '@vue/reactivity'
import { ENV } from '@/lib/env'
import { isNil, notNil } from '@/lib/type'
import { computed, reactive } from '@vue/reactivity'
import { queryCardList } from '@/api/graphql/graphqlAPI'
import { PageInfo, PaymentCardListItemFragment, PaymentCardStatus } from '@/generated/graphql'
import {
  findActivePhysicalCard,
  isPaymentCardActivationRequired,
  isPaymentCardActive,
  isPaymentCardSuspended,
  isPaymentCardOrdered,
  mapApiPaymentCard,
  createCardFilterDefaultValue,
} from '@/lib/domain/paymentCard'
import { prepend, filter, length, map } from '@/lib/list'
import { resultEither } from '@/lib/result'
import { useAuthStore } from '@/store/auth'
import { setApplicationDataFailed } from '@/store/error'
import { gt } from '@/lib/logic'

import { IPaymentCard, PaymentCardFilterValue } from '@/lib/domain/types'
import { remove, add, getTruthyKeyList } from '@/lib/object'
import { storage } from '@/api/storage/storage'
import { OPTIMISTIC_CARD_STORAGE_KEY } from '@/constants'
import { defaultToOldId } from '@/lib/domain/id'

interface IPaymentCardState {
  physicalPaymentCardList: IPaymentCard[]
  nonClosedPaymentCardList: IPaymentCard[]

  virtualPaymentCardList: IPaymentCard[]
  virtualPaymentCardFilter: PaymentCardFilterValue
  virtualPaymentCardFilterSubmitted: PaymentCardFilterValue
  virtualPaymentCardPage: {
    current: Maybe<PageInfo>
    next: Maybe<PageInfo>
  }
  virtualCardPageLoading: boolean
}

export interface IPaymentCardStore {
  cardList: ComputedRef<IPaymentCard[]>
  cardById: ComputedRef<Record<string, IPaymentCard>>
  virtualCardList: ComputedRef<IPaymentCard[]>
  virtualCardCountLeft: ComputedRef<number>
  virtualCardCreateAvailable: ComputedRef<boolean>
  activeCardCount: ComputedRef<number>
  activeVirtualCardList: ComputedRef<IPaymentCard[]>
  mainCard: ComputedRef<Maybe<IPaymentCard>>
  mainCardActive: ComputedRef<boolean>
  mainCardActivated: ComputedRef<boolean>
  mainCardOrdered: ComputedRef<boolean>
  mainCardActivationRequired: ComputedRef<boolean>
  virtualCardFilter: ComputedRef<PaymentCardFilterValue>
  virtualCardPageAvailable: ComputedRef<boolean>
  virtualCardPageLoading: ComputedRef<boolean>

  addCard(value: IPaymentCard): void
  loadCardList(): Promise<void>
  loadNonClosedCardList(): Promise<void>
  loadMoreCardList(): Promise<void>
  changeCardFilter(value: PaymentCardFilterValue): void
  submitCardFilter(value: PaymentCardFilterValue): void
  clearCardFilter(): void
}

export const createPaymentCardStore = (): IPaymentCardStore => {
  const state = reactive<IPaymentCardState>({
    physicalPaymentCardList: [],
    /**
     * Pre-filtered lists for computational tasks
     * Only works when user is allowed to have not more than 20 non closed cards.
     * Otherwise some cards will be only available on next API pages
     */
    nonClosedPaymentCardList: [],

    /**
     * Used for Cards page list.
     * Depends on applied filters and pagination
     */
    virtualPaymentCardList: [],
    virtualPaymentCardFilter: createCardFilterDefaultValue(),
    virtualPaymentCardFilterSubmitted: createCardFilterDefaultValue(),
    virtualPaymentCardPage: { current: null, next: null },
    virtualCardPageLoading: false,
  })

  const cardList = computed(() => [
    ...state.physicalPaymentCardList,
    ...state.nonClosedPaymentCardList,
  ])
  const virtualCardList = computed(() => state.virtualPaymentCardList)
  const activeVirtualCardList = computed(() =>
    filter(isPaymentCardActive, state.nonClosedPaymentCardList)
  )
  const virtualCardCountLeft = computed(
    () => ENV.TC_VIRTUAL_CARD_COUNT_LIMIT - length(state.nonClosedPaymentCardList)
  )
  const mainCard = computed(() => findActivePhysicalCard(state.physicalPaymentCardList))
  const cardById = computed<Record<string, IPaymentCard>>(() =>
    cardList.value.reduce((acum, item) => add(item.id, item, acum), {})
  )

  const setCardList = (value: PaymentCardListItemFragment[]) => {
    let list = map(mapApiPaymentCard, value)
    const listIds: string[] = map((i) => i.id, list)
    let optimisticCardStorage: Record<string, IPaymentCard> =
      storage.getObject(OPTIMISTIC_CARD_STORAGE_KEY) || {}
    Object.keys(optimisticCardStorage).forEach((id: string) => {
      /**
       * client store old IDs, so that it has to be converted to new ID
       * to make it comparable to new IDs returned from API
       */
      const newFormatId = defaultToOldId(id)

      if (listIds.includes(id) || listIds.includes(newFormatId)) {
        optimisticCardStorage = remove(id, optimisticCardStorage)
      } else {
        list = prepend(optimisticCardStorage[id], list)
      }
    })
    storage.setObject(OPTIMISTIC_CARD_STORAGE_KEY, optimisticCardStorage)
    return list
  }

  const loadCardList = async () => {
    state.virtualPaymentCardPage.current = null
    state.virtualPaymentCardPage.next = null
    const list = await loadFilteredCardList(state.virtualPaymentCardPage.current)
    state.virtualPaymentCardList = setCardList(list as PaymentCardListItemFragment[])
  }

  const loadFilteredCardList = async (page: Maybe<PageInfo>) => {
    const { accountHolderId } = useAuthStore()
    const cardListResult = await queryCardList({
      accountHolderId: accountHolderId.value,
      virtualCardAfterCursor: page?.endCursor,
      virtualCardStatusFilter: getTruthyKeyList(
        state.virtualPaymentCardFilterSubmitted
      ) as PaymentCardStatus[],
    })
    return resultEither(
      setApplicationDataFailed,
      (value) => {
        state.virtualPaymentCardPage.current = state.virtualPaymentCardPage.next
        state.virtualPaymentCardPage.next = value.pageInfo
        return value.data.virtual
      },
      cardListResult
    )
  }

  const loadNonClosedCardList = async () => {
    const { accountHolderId } = useAuthStore()
    const cardListResult = await queryCardList({
      accountHolderId: accountHolderId.value,
      virtualCardAfterCursor: null,
      virtualCardStatusFilter: [],
    })
    resultEither(
      setApplicationDataFailed,
      (value) => {
        state.physicalPaymentCardList = map(mapApiPaymentCard, value.data.physical)
        state.nonClosedPaymentCardList = setCardList(value.data.nonClosed)
      },
      cardListResult
    )
  }

  const loadMoreCardList = async () => {
    state.virtualCardPageLoading = true
    const list = await loadFilteredCardList(state.virtualPaymentCardPage.next)
    state.virtualCardPageLoading = false
    state.virtualPaymentCardList = [
      ...state.virtualPaymentCardList,
      ...map(mapApiPaymentCard, list as PaymentCardListItemFragment[]),
    ]
  }

  const addCard = (value: PaymentCardListItemFragment) => {
    const card: IPaymentCard = mapApiPaymentCard(value)
    state.nonClosedPaymentCardList = prepend(card, state.nonClosedPaymentCardList)
    state.virtualPaymentCardList = prepend(card, state.virtualPaymentCardList)

    let optimisticCardStorage: Record<string, IPaymentCard> =
      storage.getObject(OPTIMISTIC_CARD_STORAGE_KEY) || {}
    optimisticCardStorage = add(card.id, card, optimisticCardStorage)
    storage.setObject(OPTIMISTIC_CARD_STORAGE_KEY, optimisticCardStorage)
  }

  return {
    cardList,
    cardById,
    virtualCardList,
    activeVirtualCardList,
    activeCardCount: computed(() => length(activeVirtualCardList.value)),
    virtualCardCountLeft,
    virtualCardCreateAvailable: computed(() => gt(virtualCardCountLeft.value, 0)),
    mainCard,
    mainCardActive: computed(() => notNil(mainCard.value) && isPaymentCardActive(mainCard.value)),
    mainCardActivated: computed(() => {
      if (isNil(mainCard.value)) {
        return false
      }
      return isPaymentCardActive(mainCard.value) || isPaymentCardSuspended(mainCard.value)
    }),
    mainCardOrdered: computed(() => isPaymentCardOrdered(mainCard.value)),
    mainCardActivationRequired: computed(() => isPaymentCardActivationRequired(mainCard.value)),
    virtualCardFilter: computed(() => state.virtualPaymentCardFilter),
    virtualCardPageAvailable: computed(() =>
      Boolean(state.virtualPaymentCardPage?.next?.hasNextPage)
    ),
    virtualCardPageLoading: computed(() => state.virtualCardPageLoading),

    addCard,
    loadCardList,
    loadNonClosedCardList,
    loadMoreCardList,
    changeCardFilter(value) {
      state.virtualPaymentCardFilter = value
    },
    clearCardFilter() {
      state.virtualPaymentCardFilter = createCardFilterDefaultValue()
    },
    submitCardFilter(value) {
      state.virtualPaymentCardFilterSubmitted = value
      loadCardList()
    },
  }
}
