import { InjectionKey } from 'vue'
import { IFinancialInstitutionStore } from '@/store/financialInstitution'
import { ITransactionExportStore } from '@/store/transactionExport'

export const SUPPORT_EMAIL = 'support@tillful.com'

export const SESSION_EXPIRATION_STORAGE_KEY = 'SESSION_EXPIRATION_STORAGE_KEY'
export const EXTERNAL_BANK_ACCOUNT_STORAGE_KEY = 'TC_EXTERNAL_BANK_ACCOUNT'
export const OPTIMISTIC_CARD_STORAGE_KEY = 'OPTIMISTIC_CARD_STORAGE_KEY'
export const BANNER_NOTIFICATION_STORAGE_KEY = 'BANNER_NOTIFICATION_STORAGE_KEY'
export const PLAID_LINK_TOKEN_STORAGE_KEY = 'TC_PLAID_LINK_TOKEN'
export const FEATURE_FLAGS_STORAGE_KEY = 'TC_FEATURE_FLAGS_STORAGE_KEY'

export const API_TOKEN_EXPIRATION_MARGIN = 30 // seconds

export const BANK_ACCOUNT_MAX_NUMBER = 4

export const STATEMENTS_STATE_INJECT_KEY = Symbol()
export const FINANCIAL_INSTITUTION_STORE_INJECTION_KEY = Symbol(
  'Financial institution store'
) as InjectionKey<IFinancialInstitutionStore>

export const TRANSACTION_EXPORT_STORE_INJECTION_KEY = Symbol(
  'Transaction export store'
) as InjectionKey<ITransactionExportStore>

export const HN_ERROR_CODE = {
  SOURCE_ACCOUNT_CLOSED: 'SOURCE_ACCOUNT_CLOSED',
  SOURCE_ACCOUNT_NOT_ACTIVE: 'SOURCE_ACCOUNT_NOT_ACTIVE',
}

export const GDPR = 'GDPR:accepted'

export const AGREEMENTS_LINK = {
  privacyPolicy: {
    href: 'https://www.tillful.com/privacy-card',
    text: 'Privacy Policy',
  },
  termsService: {
    href: 'https://www.tillful.com/terms-card',
    text: 'Terms of Service',
  },
  creditAgreement: {
    href: 'http://tillful.com/credit-agreement',
    text: 'Credit Agreement',
  },
  cardholderAgreement: {
    href: 'https://www.tillful.com/agreement-card',
    text: 'Cardholder Agreement',
  },
}
export const TILLFULL_LINK = {
  href: 'https://app.tillful.com',
  text: 'Business Credit Score',
}

export const LIMIT_DATE_RANGE_DAYS = 92

export const CARD_ID_DEFAULT_FILTER = 'all_cards'

export const FAILED_TO_CONNECT =
  'Failed to connect bank account. Please try again later or contact support.'

export const INSTITUTION_NOT_SUPPORTED =
  'This financial institution is not supported. Please connect another financial institution.'

export const INSTITUTION_NOT_SUPPORTED_2 =
  'At this time, our policy does not support receiving deposits or payments from the selected financial institution. Please try to connect with another financial institution.'

export const AUTH0_LOGIN_REQUIRED_ERROR_CODE = 'login_required'

export const PAYMENT_FAILED_MESSAGE =
  'Could not initiate a payment. Please try again later. If the problem persists, contact support.'

export const ACCOUNT_CLOSED_MESSAGE =
  'The bank account has been closed. Please try using another one.'
