<template>
  <router-link
    to="/settings/bank"
    class="
      Payments__account__link
      d-flex
      justify-between
      align-center
      bg-grey
      cursor-pointer
      pad-16
    "
    style="text-decoration: none"
  >
    <div style="width: 40px; height: 40px; border-radius: 50%" class="flex-center bg-white mr-16">
      <Icon name="bank" />
    </div>
    <div class="fs-14 fw-600 c-green2">
      <span class="mr-8">{{ text }}</span>
      <Icon name="arrow-left" style="transform: rotate(180deg); display: inline-block" />
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { pluralize } from '@/lib/string'
import { defaultTo0 } from '@/lib/number'
import Icon from '@/components/Icon.vue'

const props = defineProps<{ count: number }>()

const count = computed<number>(() => defaultTo0(props.count))
const text = computed<string>(() => `${count.value} ${pluralize('Account', count.value)}`)
</script>

<style>
.Payments__account__link {
  margin-bottom: 24px;
}
@media all and (max-width: 768px) {
  .Payments__account__link {
    width: 100%;
  }
}
</style>
