import { differenceInSeconds, formatDateTimeIsoString, now } from '@/lib/date'
import { ApplicationEnvironment, ENV } from '@/lib/env'
import { consoleLogger } from '@/lib/logger'
import { result } from '@/lib/result'
import { captureException } from '@/api/sentry/sentry'
import { getAuthFunctions } from '@/api/auth/authClient'
import { IPaymentAccount } from '@/lib/domain/types'
import { Range } from '@/lib/range'
import { UsBusinessAccountHolder } from '@/generated/graphql'

export const load = () => {
  try {
    if (!ENV.TC_SEGMENT_KEY) {
      if (ENV.TC_ENV !== ApplicationEnvironment.Development) {
        throw new Error('Segment key not provided.')
      }
    } else {
      window.analytics.load(ENV.TC_SEGMENT_KEY)
    }
  } catch (e) {
    consoleLogger.error(e)
  }
}

export interface IIdentifyOptions {
  userId?: Maybe<string>
  email?: Maybe<string>
  createdAt?: Maybe<string>
  external_id?: Maybe<string>
  primary_authorized_person_id?: Maybe<string>
  primary_authorized_person_email?: Maybe<string>
  autopay_enabled?: boolean
}

export const identify = async (userId: string, options: IIdentifyOptions): Promise<void> => {
  try {
    window.analytics.identify(userId, options)
  } catch (e) {
    captureException(e)
    consoleLogger.error(e)
  }
}

export const track = async (name: string, options: Record<string, unknown>): Promise<void> => {
  try {
    window.analytics.track(name, options)
  } catch (e) {
    captureException(e)
    consoleLogger.error(e)
  }
}

export const page = (name?: Maybe<string>): void => {
  if (!name) {
    return
  }

  try {
    window.analytics.page(name)
  } catch (e) {
    captureException(e)
    consoleLogger.error(e)
  }
}

export interface ITrackAutopayOptions {
  external_financial_account_last4?: Maybe<string>
  consent_timestamp?: Maybe<string>
  consent_template_id?: Maybe<string>
  consent_template_version?: Maybe<string>
}

export type IdentifyUserOptions = {
  accountHolder: Maybe<UsBusinessAccountHolder>
  autopayEnabled: boolean
}

export const identifyUser = async ({
  accountHolder,
  autopayEnabled,
}: IdentifyUserOptions): Promise<Result<unknown>> => {
  const authFunctions = getAuthFunctions()
  if (!authFunctions.ok) {
    captureException(authFunctions.error)
    return authFunctions
  }
  const userResult = await authFunctions.value.getUser()
  if (!userResult.ok) {
    captureException(userResult.error)
    return userResult
  }
  tracker.identify(userResult.value.sub, {
    userId: userResult.value.sub,
    email: userResult.value.email,
    createdAt: accountHolder?.createdAt,
    external_id: accountHolder?.externalId,
    primary_authorized_person_id: accountHolder?.primaryAuthorizedPerson?.id,
    primary_authorized_person_email: accountHolder?.primaryAuthorizedPerson?.email,
    autopay_enabled: Boolean(autopayEnabled),
  })
  return result.ok(true)
}

export const trackAutopay = async (last4: Maybe<string>): Promise<Result<boolean>> => {
  track('Autopay Enabled', {
    external_financial_account_last4: last4,
    consent_timestamp: formatDateTimeIsoString(now()),
    consent_template_id: ENV.TC_AUTOPAY_CONSENT_TEMPLATE_ID,
    consent_template_version: ENV.TC_AUTOPAY_CONSENT_TEMPLATE_VERSION,
  })
  return result.ok(true)
}

interface ITrackBankAccountLinkedOptions {
  id: Maybe<string>
  last4: Maybe<string>
  name: Maybe<string>
}
const trackBankAccountLinked = async (
  options: ITrackBankAccountLinkedOptions
): Promise<Result<boolean>> => {
  track('Bank Account Linked', {
    external_financial_account_last4: options.last4,
    external_financial_account_institution_id: options.id,
    external_financial_account_institution_name: options.name,
  })
  return result.ok(true)
}

interface ITrackDepositInitiatedOptions {
  last4: Maybe<string>
  amount: Maybe<number>
}
const trackDepositInitiated = async (
  options: ITrackDepositInitiatedOptions
): Promise<Result<boolean>> => {
  track('Deposit Initiated', {
    amount: options.amount,
    external_financial_account_last4: options.last4,
  })
  return result.ok(true)
}

interface ITrackPaymentInitiatedOptions {
  last4: Maybe<string>
  amount: Maybe<number>
}
const trackPaymentInitiated = async (
  options: ITrackPaymentInitiatedOptions
): Promise<Result<boolean>> => {
  track('Payment Initiated', {
    amount: options.amount,
    external_financial_account_last4: options.last4,
  })
  return result.ok(true)
}

interface ITrackCardActivatedOptions {
  last4: Maybe<string>
}
const trackCardActivated = async (
  options: ITrackCardActivatedOptions
): Promise<Result<boolean>> => {
  track('Card Activated', {
    payment_card_last4: options.last4,
  })
  return result.ok(true)
}

interface ITrackRemovePaymentAccountOptions {
  account: IPaymentAccount
}
const trackRemovePaymentAccount = async (
  options: ITrackRemovePaymentAccountOptions
): Promise<Result<boolean>> => {
  track('Bank Account Removed', {
    external_financial_account_last4: options.account.accountDetails.mask,
    external_financial_account_institution_id: options.account.institution.institutionId,
    external_financial_account_institution_name: options.account.institution.name,
  })
  return result.ok(true)
}

interface ITrackTransactionExportStartOptions {
  dateRange: Range<Date>
}
const trackTransactionExportStart = (options: ITrackTransactionExportStartOptions) => {
  track('Transaction Export Start', {
    date_from: options.dateRange.from,
    date_to: options.dateRange.to,
  })
  return result.ok(true)
}

interface ITrackTransactionExportSuccessOptions {
  startedAt: Maybe<Date>
}
const trackTransactionExportSuccess = (options: ITrackTransactionExportSuccessOptions) => {
  track('Transaction Export Success', {
    time_to_complete: options.startedAt
      ? `${differenceInSeconds(options.startedAt, now())} seconds`
      : 'N/A',
  })
  return result.ok(true)
}

interface ITrackTransactionExportFailedOptions {
  startedAt: Maybe<Date>
}
const trackTransactionExportFailed = (options: ITrackTransactionExportFailedOptions) => {
  track('Transaction Export Failed', {
    time_to_complete: options.startedAt
      ? `${differenceInSeconds(options.startedAt, now())} seconds`
      : 'N/A',
  })
  return result.ok(true)
}

const trackPaymentAccountNoBalances = (options: {
  accountHolderId: Maybe<string>
  last4: Maybe<string>
  institutionId: Maybe<string>
  institutionName: Maybe<string>
}) => {
  track('Balance Check No Balances', {
    account_holder_id: options.accountHolderId,
    external_financial_account_last4: options.last4,
    external_financial_account_institution_id: options.institutionId,
    external_financial_account_institution_name: options.institutionName,
  })
}

export const tracker = {
  identify,
  identifyUser,

  trackAutopay,
  trackBankAccountLinked,
  trackDepositInitiated,
  trackPaymentInitiated,
  trackCardActivated,
  trackRemovePaymentAccount,
  trackPaymentAccountNoBalances,

  trackTransactionExportStart,
  trackTransactionExportSuccess,
  trackTransactionExportFailed,

  page,
}
