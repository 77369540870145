<template>
  <div class="ApplicationStatusContent flex-center flex-direction-column" style="height: 90vh">
    <div
      class="ApplicationStatusContent__header flex-center flex-direction-column"
      :class="headerClass"
    >
      <ApplicationStatusIcon :name="content.icon" class="mb-24" />
      <h1 class="mb-16" data-test-id="application-status-title">{{ content.title }}</h1>
    </div>
    <p
      class="ApplicationStatusContent__description fs-16 c-grey2 text-center"
      data-test-id="application-status-description"
    >
      {{ content.description }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { AccountHolderApplicationStatusCode } from '@/generated/graphql'
import { computed, ComputedRef } from 'vue'
import ApplicationStatusIcon from './ApplicationStatusIcon.vue'

const props = defineProps<{
  status?: Maybe<AccountHolderApplicationStatusCode>
  invalid?: boolean
  documentsNotSubmitted?: boolean
}>()

const headerClass: ComputedRef<Record<string, boolean>> = computed(() => ({
  'ApplicationStatusContent__header-green c-green5':
    props.status === AccountHolderApplicationStatusCode.Approved,
  'ApplicationStatusContent__header-red c-red4':
    props.invalid ||
    props.status === AccountHolderApplicationStatusCode.Denied ||
    props.status === AccountHolderApplicationStatusCode.Closed,
  'ApplicationStatusContent__header-orange c-orange2':
    props.status === AccountHolderApplicationStatusCode.Pending ||
    props.status === AccountHolderApplicationStatusCode.InReview,
}))

const content: ComputedRef<{
  icon: 'invalid' | 'declined' | 'approved' | 'pending'
  title: string
  description: string
}> = computed(() => {
  if (props.invalid) {
    return {
      icon: 'invalid',
      title: 'Application Invalid',
      description: 'Unfortunately, we were not able to identify your application.',
    }
  }
  switch (props.status) {
    case AccountHolderApplicationStatusCode.Approved:
      return {
        icon: 'approved',
        title: 'Application Approved!',
        description:
          'Congratulations! Your application has been approved. Follow the instructions in your email to get started.',
      }

    case AccountHolderApplicationStatusCode.Denied:
      return {
        icon: 'declined',
        title: 'Application Declined',
        description:
          'Unfortunately, we are unable to approve your request at the time. We will be happy to evaluate another application for you in the future.',
      }

    case AccountHolderApplicationStatusCode.Pending:
    case AccountHolderApplicationStatusCode.InReview:
      return {
        icon: 'pending',
        title: 'Application in Review',
        description: props.documentsNotSubmitted
          ? 'You will be notified via email when a decision is made.'
          : 'Thank you for submitting your documents. You will be notified via email when a decision is made.',
      }

    case AccountHolderApplicationStatusCode.Closed:
      return {
        icon: 'declined',
        title: 'Application Closed',
        description:
          'This application has been closed. We will be happy to evaluate another application for you in the future.',
      }

    default:
      return {
        icon: 'invalid',
        title: 'Application Invalid',
        description: 'Unfortunately, we were not able to identify your application.',
      }
  }
})
</script>

<style>
.ApplicationStatusContent {
  max-width: 532px;
  margin: 0 auto;
}
.ApplicationStatusContent__header .ApplicationStatusIcon .Icon {
  color: white;
}
.ApplicationStatusContent__header-green .ApplicationStatusIcon {
  background-color: var(--c-green5);
}
.ApplicationStatusContent__header-red .ApplicationStatusIcon {
  background-color: var(--c-red4);
}
.ApplicationStatusContent__header-orange .ApplicationStatusIcon {
  background-color: var(--c-orange2);
}
.ApplicationStatusContent__description {
  line-height: 1.5;
}
</style>
