import { BaseError } from '@/lib/domain/error/BaseError'
import { renderFields } from '@highnoteplatform/card-viewer'
import { inject, InjectionKey, provide } from 'vue'

export type CardViewer = {
  renderFields: typeof renderFields
}

export const CARD_VIEWER_INJECTION_KEY = Symbol('Card viewer key') as InjectionKey<CardViewer>

export function provideCardViewer(): void {
  const cardViewer = { renderFields }
  provide(CARD_VIEWER_INJECTION_KEY, cardViewer)
}

export function injectCardViewer(): CardViewer {
  const injected = inject(CARD_VIEWER_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Card viewer not provided.')
  }
  return injected
}
