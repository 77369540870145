<template>
  <div class="BankAccountMenuItem d-flex align-center justify-between pad-16 cursor-pointer">
    <div class="d-flex">
      <div class="BankAccountMenuItem__icon flex-center bg-white mr-16">
        <img v-if="value.institution.logo" width="40" height="40" :src="logoSrc" alt="Logo" />
        <Icon v-else name="bank" />
      </div>
      <div class="fs-14 fw-600 c-green2">
        <div class="fs-14 fw-600 c-black1 mb-4">
          {{ capitalizeWord(defaultToEmptyString(value.accountDetails.type)) }} -
          {{ value.accountDetails.mask }}
        </div>
        <div class="fs-14 fw-500 c-grey5">
          {{ value.institution.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IPaymentAccount } from '@/lib/domain/types'
import { capitalizeWord, defaultToEmptyString } from '@/lib/string'
import Icon from '@/components/Icon.vue'
import { computed } from 'vue'

const props = defineProps<{
  value: IPaymentAccount
}>()
const logoSrc = computed<string>(() => `data:image/jpeg;base64, ${props.value.institution.logo}`)
</script>

<style>
.BankAccountMenuItem__icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px #e5e7eb;
}
</style>
