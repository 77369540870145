import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
} from '@aws-sdk/client-cognito-identity'

import { ENV } from '@/lib/env'
import { consoleLogger } from '@/lib/logger'
import { result } from '@/lib/result'
import { captureException } from '@/api/sentry/sentry'

export interface IAwsCredentials {
  AccessKeyId: string
  SecretKey: string
  SessionToken: string
}

async function getCredentials(client: CognitoIdentityClient): Promise<Result<IAwsCredentials>> {
  try {
    const region = ENV.TC_APP_SYNC_REGION
    const identityPoolId = `${region}:${ENV.TC_APP_SYNC_GUID}`
    const getIdentityIdCommand = new GetIdCommand({ IdentityPoolId: identityPoolId })
    const { IdentityId } = await client.send(getIdentityIdCommand)
    const getCredentialsCommand = new GetCredentialsForIdentityCommand({ IdentityId })
    const data = await client.send(getCredentialsCommand)
    const credentials = data.Credentials
    if (
      !credentials ||
      !credentials.AccessKeyId ||
      !credentials.SecretKey ||
      !credentials.SessionToken
    ) {
      const error = new Error('Failed to get AWS credentials.')
      captureException(error)
      return result.failed(error)
    }
    return result.ok({
      AccessKeyId: credentials.AccessKeyId,
      SecretKey: credentials.SecretKey,
      SessionToken: credentials.SessionToken,
    })
  } catch (e) {
    consoleLogger.error('Failed to get AWS cognito credentials', e)
    captureException(e)
    return result.failed(e as Error)
  }
}

function getAWSClient(): CognitoIdentityClient {
  const region = ENV.TC_APP_SYNC_REGION
  const client = new CognitoIdentityClient({ region })
  return client
}

export { getCredentials, getAWSClient }
