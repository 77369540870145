<template>
  <div class="FormCheckboxList">
    <FormCheckbox
      v-for="item of value"
      :key="item.key"
      class="mb-16"
      :modelValue="item.value"
      @update:modelValue="handleChange(item)"
    >
      <template #label>
        <span class="FormCheckboxList__checkbox-label">{{ item.label }}</span>
      </template>
    </FormCheckbox>
  </div>
</template>

<script setup lang="ts">
import FormCheckbox from '@/components/FormCheckbox.vue'

import { IFormCheckboxListValueItem } from '@/lib/domain/types'
import { not } from '@/lib/logic'

defineProps<{ value: IFormCheckboxListValueItem[] }>()
const emit = defineEmits<{ (event: 'onChange', value: IFormCheckboxListValueItem): void }>()

const handleChange = (oldValue: IFormCheckboxListValueItem) =>
  emit('onChange', { ...oldValue, value: not(oldValue.value) })
</script>

<style>
.FormCheckboxList__checkbox-label {
  font-size: 14px;
  font-weight: 400;
  user-select: none;
}
</style>
