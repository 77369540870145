<template>
  <table class="Payment__list w-100">
    <thead>
      <th class="text-left fs-12 fw-500 c-grey2">Initiated At</th>
      <th class="text-left fs-12 fw-500 c-grey2">Updated At</th>
      <th v-if="middleAndMore" class="text-left fs-12 fw-500 c-grey2">Confirmation Number</th>
      <th v-if="middleAndMore" class="text-left fs-12 fw-500 c-grey2">Status</th>
      <th v-if="middleAndMore" class="text-left fs-12 fw-500 c-grey2">Details</th>
      <th v-if="middleAndMore" class="text-left fs-12 fw-500 c-grey2">Bank Account</th>
      <th class="text-right fs-12 fw-500 c-grey2">Amount</th>
    </thead>

    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td class="text-left fs-12 fw-500 c-grey2">
          <div v-if="smallScreen">
            {{ item.initiatedDateShort }} &middot; {{ item.initiatedTime }}
          </div>
          <div v-else>{{ item.initiatedDate }} &middot; {{ item.initiatedTime }}</div>

          <div
            v-if="smallScreen"
            class="Payment__list__item__status text-left fs-12 fw-500 c-grey2 mt-8"
            :style="{ color: item.statusColor }"
          >
            {{ item.status }}
          </div>
        </td>

        <td class="text-left fs-12 fw-500 c-grey2">
          <template v-if="smallScreen">
            <div>{{ item.updatedDateShort }} &middot; {{ item.updatedTime }}</div>
            <div>
              {{ item.details }}
            </div>
          </template>
          <template v-else> {{ item.updatedDate }} &middot; {{ item.updatedTime }} </template>
        </td>

        <td v-if="middleAndMore" class="text-left fs-12 fw-500 c-grey2">
          {{ item.id.slice(0, 6) }}...
          <Icon
            name="copy"
            class="cursor-pointer"
            title="copy to clipboard"
            @click="handleCopyToClipboard(item.id, $event)"
          />
        </td>

        <td
          v-if="middleAndMore"
          class="Payment__list__item__status text-left fs-12 fw-500 c-grey2"
          :style="{ color: item.statusColor }"
        >
          {{ item.status }}
        </td>

        <td v-if="middleAndMore" class="text-left fs-12 fw-500 c-grey2">
          {{ item.details }}
        </td>

        <td v-if="middleAndMore" class="text-left fs-12 fw-500 c-grey2">
          {{ item.accountMask }}
        </td>

        <td class="text-right fs-12 fw-500 c-grey2">
          <div>{{ item.amount }}</div>
          <div v-if="smallScreen" class="fs-12 fw-500 c-grey2 mt-8">
            Bank Acct. {{ item.accountMask }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <Popup :opened="popupOpened" :target="popupTargetRef">{{ popupContent }}</Popup>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useBreakpoint } from 'vue-composable'

import { IPaymentView } from '@/lib/domain/types'
import { useCopyClipboard } from '@/lib/composable/useCopyClipboard'
import Icon from '@/components/Icon.vue'
import Popup from '@/components/Popup.vue'

defineProps<{ items: IPaymentView[] }>()

const { middleAndMore } = useBreakpoint({ middleAndMore: 768 })
const smallScreen = computed(() => !middleAndMore.value)
const { showCopyClipboard, popupOpened, popupTargetRef, popupContent } = useCopyClipboard()
const handleCopyToClipboard = async (id: string, e: Event) => {
  showCopyClipboard({
    copiedInfo: id,
    htmlTargetElement: e.target as HTMLElement,
  })
}
</script>

<style>
.Payment__list th {
  height: 48px;
  border-top: 1px solid var(--bg-grey4);
  border-bottom: 1px solid var(--bg-grey4);
}
.Payment__list td {
  height: 48px;
}
.Payment__list__item-active td {
  font-weight: 600;
  color: var(--c-grey);
}
.Payment__list td.Payment__list__item__status-pending {
  color: var(--c-orange);
}
.Payment__list td.Payment__list__item__status-completed {
  color: var(--c-green6);
}

@media all and (max-width: 768px) {
  .Payment__list-head {
    flex-direction: column;
    align-items: flex-start;
  }
  .Payment__list-head h2 {
    margin-bottom: 16px;
  }
  .Payment__list td {
    height: 72px;
    padding-top: 16px;
    border-bottom: 1px solid var(--bg-grey4);
    vertical-align: top;
  }
}
</style>
