<template>
  <div class="MainCard">
    <div class="d-flex justify-between align-start">
      <PaymentCardMock :card="card" class="mb-24" />
      <CardStatusTag :status="card.status" />
    </div>

    <Divider style="margin-top: 0" />

    <Message v-if="error" variant="error" class="mb-24" @onClose="$emit('onErrorDismiss')">
      Could not retrieve card details. Please try again.
    </Message>

    <div class="d-flex justify-between mb-24">
      <div>
        <div class="fs-14 fw-500 mb-12 c-grey2">Card Number</div>
        <CardNumber :card="card" />
      </div>
      <div>
        <VisibilityButton :opened="opened" @onClick="$emit('onEyeClick', card.id)" />
      </div>
    </div>

    <div class="d-flex justify-start mb-24">
      <div class="mr-42">
        <div class="fs-14 fw-500 mb-12 c-grey2">Expiry Date</div>
        <div class="fs-14 fw-600 c-black">{{ card.date }}</div>
      </div>
      <div>
        <div class="fs-14 fw-500 mb-12 c-grey2">CVC</div>
        <div class="fs-14 fw-600 c-black">{{ card.cvv }}</div>
      </div>
    </div>

    <div class="fs-14 fw-500 mb-12 c-grey2">Billing Address</div>
    <Address class="fw-600" :value="businessInformation.address" />
    <Divider class="mb-24 mt-24" />
    <OnePasswordBtn v-if="showOnePassBtn" @onClick="$emit('onOPClick', card.id)" />
  </div>
</template>

<script setup lang="ts">
import { onUnmounted } from '@vue/runtime-core'
import { computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'

import Divider from '../Divider.vue'
import CardStatusTag from './CardStatusTag.vue'
import VisibilityButton from '../VisibilityButton.vue'
import PaymentCardMock from '../PaymentCardMock.vue'
import Message from '../Message.vue'
import Button from '../Button.vue'
import { getDeviceType, DeviceType } from '@/api/dom/device'
import { useLockCardModal } from '@/service/useLockCard'
import { useUnlockCardModal } from '@/service/useUnlockCard'
import {
  isPaymentCardActivationRequired,
  isPaymentCardActive,
  isPaymentCardSuspended,
} from '@/lib/domain/paymentCard'
import CardNumber from '@/components/cards/CardNumber.vue'
import OnePasswordBtn from '@/components/onePassword/OnePasswordBtn.vue'
import { RouteName } from '@/router/route'
import { noop } from '@/lib/function'
import { IPaymentCardView } from '@/lib/domain/types'
import { useAccountHolder } from '@/store/accountHolder'
import Address from '../Address.vue'

const props = withDefaults(
  defineProps<{
    card: IPaymentCardView
    opened: boolean
    error: boolean
  }>(),
  {
    opened: false,
  }
)

defineEmits(['onEyeClick', 'onErrorDismiss', 'onOPClick'])

const device: DeviceType = getDeviceType()

const { businessInformation } = useAccountHolder()

// activate card
const activationRequired = computed(() => isPaymentCardActivationRequired(props.card))
const router = useRouter()
const handleActivateCardClick = () => router.push({ name: RouteName.ActivateCard }).catch(noop)

const isActive = computed(() => isPaymentCardActive(props.card))
const { open: openLockCardModal, close: closeLockCardModal } = useLockCardModal()
const handleLock = () => openLockCardModal(props.card)

const isSuspended = computed(() => isPaymentCardSuspended(props.card))
const { open: openUnlockCardModal, close: closeUnlockCardModal } = useUnlockCardModal()
const handleUnlock = () => openUnlockCardModal(props.card)
const showOnePassBtn = computed(() => !activationRequired.value && device === DeviceType.Desktop)

onUnmounted(() => {
  closeLockCardModal()
  closeUnlockCardModal()
})
</script>
