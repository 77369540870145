<template>
  <div :class="['Loader', sizeCssClass]"></div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from '@vue/reactivity'

const { size, color } = withDefaults(
  defineProps<{
    size?: 'small' | 'medium' | 'large'
    color?: 'green' | 'red'
  }>(),
  {
    size: 'medium',
    color: 'green',
  }
)

const sizeCssClass: ComputedRef<object> = computed(() => ({
  'Loader-small': size === 'small',
  'Loader-medium': size === 'medium',
  'Loader-large': size === 'large',
  'Loader-red': color === 'red',
}))
</script>

<style scoped>
.Loader {
  border-style: solid;
  border-color: var(--bg-grey3);
  border-top-style: solid;
  border-top-color: var(--bg-green);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.Loader-red {
  border-top-color: var(--c-red2);
}

.Loader-large {
  width: 60px;
  height: 60px;
  border-width: 10px;
  border-top-width: 10px;
}

.Loader-medium {
  width: 30px;
  height: 30px;
  border-width: 5px;
  border-top-width: 5px;
}

.Loader-small {
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-top-width: 2px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
