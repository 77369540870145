import { computed, inject, reactive } from 'vue'

import { defaultToEmptyList, flatten, isEmpty, map } from '@/lib/list'
import { useBool } from '@/lib/composable/useBool'
import { isStatementClosed, mapStatementToView } from '@/lib/domain/statement'
import { IStatementState, clearStatementState, loadStatementList } from '@/store/statements'
import { STATEMENTS_STATE_INJECT_KEY } from '@/constants'
import {
  FinancialAccountStatementDetailFragment,
  FinancialAccountStatementItemFragment,
} from '@/generated/graphql'
import { useAccountHolder } from '@/store/accountHolder'
import { getStatementByPeriod } from '@/api/graphql/graphqlAPI'
import { resultEither } from '../result'

interface IUseStatementsLocalState {
  statementDetails: Maybe<FinancialAccountStatementDetailFragment>
  error: Maybe<Error>
}

export const useStatements = () => {
  const state = inject<IStatementState>(STATEMENTS_STATE_INJECT_KEY)
  const localState = reactive<IUseStatementsLocalState>({
    statementDetails: null,
    error: null,
  })
  const { id } = useAccountHolder()
  const {
    value: loadMorePending,
    truthyfy: setLoadMorePending,
    falsefy: resetLoadMorePending,
  } = useBool()
  const statementList = computed(() => {
    if (!state) {
      return []
    }
    const statementListNested = map(
      (key) => state.statementListByCursor[key],
      defaultToEmptyList(state.cursorList)
    )
    return flatten(statementListNested).filter(isStatementClosed)
  })
  const statementById = computed<Record<string, FinancialAccountStatementItemFragment>>(() =>
    statementList.value.reduce((acum, item) => Object.assign(acum, { [item.id]: item }), {})
  )
  const statementListView = computed<IStatementItemView[]>(() => {
    return map(mapStatementToView, statementList.value)
  })

  const setStatement = (statement: Maybe<FinancialAccountStatementDetailFragment>) =>
    Object.assign(localState, { statementDetails: statement })

  const setError = (error: Error) => Object.assign(localState, { error })

  return {
    statementListView,
    statementById,
    statementListLoaded: computed<boolean>(() => Boolean(state?.loaded)),
    statementListEmpty: computed<boolean>(() => isEmpty(statementListView.value)),
    statementDetails: computed(() => localState.statementDetails),
    loadMorePending: computed(() => loadMorePending.value),
    loadMoreAvailable: computed(() => state?.currentPageInfo?.hasNextPage),

    load() {
      clearStatementState()
      loadStatementList()
    },
    loadMore: () => loadStatementList(state?.currentPageInfo?.endCursor),
    async loadStatementByPeriod(start: string, end: string) {
      const statementResult = await getStatementByPeriod({
        accountHolderId: id.value,
        start,
        end,
      })
      resultEither(setError, setStatement, statementResult)
    },
    setLoadMorePending,
    resetLoadMorePending,
  }
}
