<template>
  <div
    v-if="banner?.type === MaintenanceFeatureFlagWindowType.FullOverlay"
    class="MessageMaintenanceFullScreen flex-center flex-direction-column bg-yellow"
    :style="{ height: '100vh' }"
  >
    <div
      class="MessageMaintenanceFullScreen__header d-flex align-center flex-direction-column mb-12"
    >
      <Icon name="alert-circle" style="font-size: 80px; margin-bottom: 32px" class="c-orange1" />
      <h1 class="mb-32 c-orange1 fs-52 text-center">We'll be back soon!</h1>
    </div>
    <p
      class="MessageMaintenanceFullScreen__description fs-24 c-grey2 text-center"
      data-test-id="application-status-description"
    >
      {{ banner.message }}
    </p>
  </div>

  <slot v-else />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { injectFeatureFlag } from '@/provider/featureFlagProvider'
import { MaintenanceFeatureFlagWindowType } from '@/provider/featureFlagStoreProvider'
import Icon from '@/components/Icon.vue'

const $featureFlag = injectFeatureFlag()
const banner = computed(() => $featureFlag.activeWindow.value)
</script>

<style>
.MessageMaintenanceFullScreen__description {
  width: 80%;
}
</style>
