import { reactive } from 'vue'

import { FinancialAccountStatementItemFragment, PageInfo } from '@/generated/graphql'
import { getStatementList } from '@/api/graphql/graphqlAPI'
import { IPaginatedList } from '@/api/graphql/types'
import { useAccountHolder } from '@/store/accountHolder'

export interface IStatementState {
  loaded: boolean
  statementListByCursor: Record<string, FinancialAccountStatementItemFragment[]>
  cursorList: string[]
  error: Maybe<Error>
  currentPageInfo: Maybe<PageInfo>
}

export const state = reactive<IStatementState>({
  loaded: false,
  statementListByCursor: {},
  cursorList: [],
  error: null,
  currentPageInfo: null,
})

export const loadStatementList = async (cursor?: Maybe<string>) => {
  const { id } = useAccountHolder()

  const res = await getStatementList({
    accountHolderId: id.value,
    pageCursor: cursor,
  })

  if (!res.ok) {
    state.error = res.error
    return
  }
  setLoadedResult(res.value)
  setLoaded()
}

const setLoaded = () => {
  state.loaded = true
}

const setLoadedResult = (value: IPaginatedList<FinancialAccountStatementItemFragment>) => {
  const cursor = value.pageInfo?.startCursor
  if (!cursor) {
    return
  }
  state.cursorList = [...state.cursorList, cursor]
  state.statementListByCursor[cursor] = value.data
  state.currentPageInfo = value.pageInfo
}

export function clearStatementState(): void {
  state.loaded = false
  state.cursorList = []
  state.statementListByCursor = {}
  state.currentPageInfo = null
}
