<script lang="ts">
import { defineComponent, useSlots, VNode, h, computed } from 'vue'

export default defineComponent({
  props: {
    if: { type: Boolean, default: false },
  },
  setup(props) {
    const slots = useSlots()
    let children: VNode[] = []
    if (slots.default) {
      children = slots.default()
    }
    let activeChild = computed(() => (props.if ? children[0] : children[1]))
    return () => {
      if (!activeChild.value) {
        return null
      }
      return h(activeChild.value)
    }
  },
})
</script>
