import { result } from '@/lib/result'

export const mapOldToNewId = (id: string): Result<string> => {
  try {
    const decoded: string = atob(id)
    const referenceId: string = decoded.split('.').pop() as string
    return result.ok(referenceId)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const defaultToOldId = (id: string): string => {
  const idResult = mapOldToNewId(id)
  if (!idResult.ok) {
    return id
  }
  return idResult.value
}
