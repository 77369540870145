<template>
  <div class="ApplicationDocumentFormContainer bg-white">
    <div v-if="header || subheader" class="mb-8 padh-32">
      <h3 class="fs-20 fw-bold mb-24 padt-24">{{ header }}</h3>
      <div class="fs-16 fw-bold">{{ subheader }}</div>
    </div>

    <div
      class="ApplicationDocumentForm__all-uploaded padh-32 padv-32"
      v-if="isEmpty(value.primary.values)"
    >
      All documents uploaded.
    </div>

    <div
      v-for="formValue in value.primary.values"
      :key="formValue.id"
      class="ApplicationDocumentForm"
    >
      <form v-if="formValue.type">
        <p class="mb-12">{{ humanizeDocumentType(formValue.type) }}</p>
        <FormFile
          class="mb-24"
          :value="formValue.file"
          :state="formValue.state"
          :loading="formValue.uploading"
          :error="formValue.error"
          @change="handleDocumentChange(formValue.id, $event)"
          @upload="handleDocumentUpload(formValue)"
          @clear="handleDocumentClear(formValue.id, formValue)"
        />

        <div
          v-if="formValue.error"
          class="ApplicationDocumentForm__error fs-12 fw-500 mt-16 c-red4"
        >
          {{ formValue.error }}
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import FormFile from '../FormFile.vue'
import {
  IApplicationDocumentFormValue,
  IDocumentFormValue,
  DocumentFormPartialValue,
  humanizeDocumentType,
} from '@/lib/domain/application'
import { isEmpty } from '@/lib/list'

defineProps<{
  value: IApplicationDocumentFormValue
  header?: string
  subheader?: string
}>()

const emit = defineEmits<{
  (event: 'change', value: DocumentFormPartialValue): void
  (event: 'upload', value: DocumentFormPartialValue): void
}>()

const handleDocumentChange = (id: string, file: Maybe<File>) => {
  if (!file) {
    return
  }

  emit('change', {
    id,
    file,
    state: 'selected',
    error: getFileError(file),
  })
}

const handleDocumentClear = (id: string, formValue: IDocumentFormValue) => {
  if (formValue.uploading) {
    return
  }
  emit('change', {
    id,
    file: null,
    error: null,
    state: 'initial',
  })
}

const handleDocumentUpload = async (formValue: IDocumentFormValue) => emit('upload', formValue)

const getFileError = (file: File): Maybe<string> => {
  if (!validateFileSize(file)) {
    return 'Maximum size limit of 10MB exceeded'
  }
  if (!validateFileType(file)) {
    return 'Invalid file format'
  }
  return null
}

const validateFileSize = (file: File) => file.size / 1024 / 1024 < 10
const ALLOWED_FILE_TYPES = ['application/pdf', 'image/png', 'image/jpeg']
const validateFileType = (file: File) => ALLOWED_FILE_TYPES.includes(file.type)
</script>

<style>
.ApplicationDocumentFormContainer {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.06);
}
.ApplicationDocumentForm {
  padding: 32px;
  padding-bottom: 0;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.ApplicationDocumentForm ul {
  line-height: 1.71;
}
.ApplicationDocumentForm__documents__toggle {
  color: var(--c-green1);
  font-size: 14px;
  background-color: white;
  border: none;
}
.ApplicationDocumentForm__documents__toggle .Icon {
  font-size: 8px;
}
@media all and (max-width: 768px) {
  .ApplicationDocumentForm {
    grid-template-columns: 1fr;
  }
  .ApplicationDocumentForm__documents {
    grid-row: 1;
  }
  .ApplicationDocumentForm__documents ul {
    padding-left: 24px;
  }
}
</style>
