<template>
  <table v-if="notMobile" class="TableView w-100">
    <thead>
      <th
        v-for="(item, index) in fields"
        :key="item.name"
        class="text-right fs-12 fw-500 c-grey2"
        :class="{ 'text-left': index === 0 }"
      >
        {{ item.header }}
      </th>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td
          v-for="(field, index) in fields"
          :key="field.name"
          class="text-right fs-12 fw-500 c-grey2"
          :class="{ 'text-left': index === 0 }"
        >
          <slot name="tableCellContent" v-bind="{ item, field }" />
        </td>
      </tr>
    </tbody>
  </table>

  <table v-else class="TableView-mobile w-100">
    <tr v-for="item in items" :key="item.id">
      <td class="fs-12 fw-500 c-grey2">
        <div v-for="field in fields" :key="field.name" class="mb-8 d-flex justify-between">
          <div>{{ field.header }}</div>
          <slot name="tableCellContent" v-bind="{ item, field }" />
        </div>
        <Divider />
      </td>
    </tr>
  </table>
</template>

<script setup lang="ts">
import { useBreakpoint } from 'vue-composable'

import Divider from '@/components/Divider.vue'

const props = withDefaults(
  defineProps<{
    items: Array<ITableViewItem>
    fields: Array<ITableViewField>
    mobileBreakpoint?: number
  }>(),
  {
    mobileBreakpoint: 768,
  }
)

const { notMobile } = useBreakpoint({ notMobile: props.mobileBreakpoint })
</script>

<style>
.TableView th {
  height: 48px;
  border-top: 1px solid var(--bg-grey4);
  border-bottom: 1px solid var(--bg-grey4);
}
.TableView td {
  height: 48px;
}
</style>
