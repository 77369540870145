<template>
  <div class="FormCheckbox d-flex cursor-pointer" @click="handleClick">
    <div
      class="FormCheckbox__toggle flex-center"
      :class="{ 'FormCheckbox__toggle-active': modelValue }"
    >
      <Icon v-if="modelValue" name="check" class="fs-10 c-white" />
    </div>
    <div v-if="label" class="FormCheckbox__label">
      {{ label }}
    </div>
    <div v-else class="FormCheckbox__label">
      <slot name="label" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue'

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    label?: string
  }>(),
  {
    modelValue: false,
  }
)

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>()

const handleClick = () => emit('update:modelValue', !props.modelValue)
</script>

<style>
.FormCheckbox__toggle {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: white;
  border: solid 1px #d1d5db;
}
.FormCheckbox__toggle-active {
  background-color: var(--bg-green4);
}
.FormCheckbox .FormCheckbox__label {
  font-size: 12px;
  color: var(--c-grey2);
  font-weight: normal;
}
</style>
