<template>
  <ul class="CardListItemMenu discard-menu fs-12 c-black2">
    <li
      class="CardListItemMenu__item d-flex align-center"
      @click="$emit('onAction', CardListMenuAction.ShowDetails)"
    >
      <Icon name="eye" />
      <span>Show Details</span>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from '@vue/reactivity'
import { PaymentCardStatus } from '../../generated/graphql'
import {
  isPaymentCardActive,
  isPaymentCardSuspended,
  isPaymentCardActivationRequired,
} from '../../lib/domain/paymentCard'
import { CardListMenuAction } from '@/lib/domain/types'
import Icon from '../Icon.vue'

const props = defineProps<{
  card?: { status: PaymentCardStatus }
}>()
defineEmits(['onAction'])

const isActive = computed(() => (props.card ? isPaymentCardActive(props.card) : false))
const isSuspended = computed(() => (props.card ? isPaymentCardSuspended(props.card) : false))
const isActivationRequired = computed(() => isPaymentCardActivationRequired(props.card))
</script>

<style>
.CardListItemMenu {
  width: 145px;
}
.CardListItemMenu__item {
  height: 32px;
}
.CardListItemMenu__item:hover {
  background-color: var(--bg-grey2);
  cursor: pointer;
}
.CardListItemMenu__item > span {
  margin-left: 8px;
}
.CardListItemMenu__item--one-pass > span {
  margin-left: 6px;
}
@media all and (max-width: 768px) {
  .CardListItemMenu {
    width: auto;
  }
}
</style>
