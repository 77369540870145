import { createApp } from 'vue'

import App from '@/App.vue'
import router from '@/router/router'
import { HubSpot } from '@/plugins/hubspot/vue/plugin'
import HubSpotTranslations from '@/plugins/hubspot/translations'
import { initErrorTracking, sentryOtpions } from '@/api/sentry/sentry'
import { load as initSegment } from '@/api/segment/segment'
import { ENV } from '@/lib/env'

import '@/api/segment/script'
import '@1password/save-button'

const app = createApp(App)
app.use(router)
app.use(HubSpot, {
  region: ENV.TC_HUBSPOT_REGION,
  portalId: ENV.TC_HUBSPOT_PORTAL_ID,
  formId: ENV.TC_HUBSPOT_FORM_ID,
  translations: HubSpotTranslations,
})

initErrorTracking(sentryOtpions(app, router))
initSegment()

app.mount('#app')
