import { getAuthFunctions } from '@/api/auth/authClient'
import { loadScript } from '@/api/dom/script'
import { generatePlaidLinkToken } from '@/api/graphql/graphqlAPI'
import { PLAID_LINK_TOKEN_STORAGE_KEY } from '@/constants'
import { DomError } from '@/lib/domain/error/DomError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { ENV } from '@/lib/env'
import { result } from '@/lib/result'
import { RouteLocationNormalizedLoaded } from 'vue-router'

export interface IPlaidMetaInstitution {
  name: string
  institution_id: string
}

export interface IPlaidMetaAccount {
  id?: Maybe<string>
  name?: Maybe<string>
  mask?: Maybe<string>
  type?: Maybe<string>
  subtype?: Maybe<string>
}

export interface IPlaidMeta {
  account_id: Maybe<string>
  account: IPlaidMetaAccount
  institution: IPlaidMetaInstitution
  accounts: IPlaidMetaAccount[]
  public_token: string
}

export interface IPlaidHandler {
  open(): void
  destroy(): void
}

type PlaidEventName = 'OPEN'

export interface IPlaidOnExitError {
  error_type: string
  error_code: string
  error_message: string
  display_message: string
  documentation_url?: string
  suggested_action?: string
  request_id: string
}

export interface IPlaidOnExitMetaData {
  institution: {
    name: string
    institution_id: string
  }
  status: string
  link_session_id: string
  request_id: string
}

interface IPlaidHandlerOptions {
  receivedRedirectUrl?: string
  onSuccess: (token: string, meta: IPlaidMeta, handler: IPlaidHandler) => void
  onOpen?: () => void
  onExitError?: (error?: IPlaidOnExitError, metadata?: IPlaidOnExitMetaData) => void
}

const SCRIPTS_SRC = {
  // JQUERY: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.3/jquery.min.js',
  PLAID: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
}

export const createPlaidHandler = async ({
  receivedRedirectUrl,
  onExitError,
  onSuccess,
  onOpen,
}: IPlaidHandlerOptions): Promise<Result<IPlaidHandler>> => {
  try {
    const scriptResult = await loadScript(SCRIPTS_SRC.PLAID)
    if (!scriptResult.ok) {
      throw new DomError('Failed to load Plaid SDK', {
        originalError: scriptResult.error,
        vendor: ErrorVendor.Plaid,
      })
    }

    const tokenResult = await getToken(Boolean(receivedRedirectUrl))

    if (!tokenResult.ok) {
      throw tokenResult.error
    }

    storePlaidLinkToken(tokenResult.value)

    const plaid = window.Plaid
    const plaidHandler = plaid.create({
      clientName: 'Tillful Card',
      env: ENV.TC_PLAID_ENV,
      product: ['auth'],
      countryCodes: ['US'],
      language: 'en',

      token: tokenResult.value,
      receivedRedirectUri: receivedRedirectUrl,

      onSuccess: (token: string, meta: IPlaidMeta) => {
        removeStoredPlaidLinkToken()
        onSuccess(token, meta, plaidHandler)
      },
      onExit: (error: IPlaidOnExitError, _metadata: IPlaidOnExitMetaData) => {
        if (error && onExitError) {
          onExitError()
        }
      },
      onEvent(eventName: PlaidEventName) {
        switch (eventName) {
          case 'OPEN': {
            if (onOpen) {
              onOpen()
            }
            break
          }
          default:
            return
        }
      },
    })
    return result.ok(plaidHandler)
  } catch (error: any) {
    return result.failed(error)
  }
}

const getToken = async (isOAuth: boolean): Promise<Result<string>> => {
  if (isOAuth) {
    return getStoredPlaidLinkToken()
  }
  return await generateLinkToken()
}

export const generateLinkToken = async (): Promise<Result<string>> => {
  const auth = getAuthFunctions()
  if (!auth.ok) {
    return auth
  }
  const user = await auth.value.getUser()
  if (!user.ok) {
    return user
  }
  const userId = user.value.sub
  const tokenResult = await generatePlaidLinkToken(userId, window.location.href)
  return tokenResult
}

export const isPlaidOAuthUrl = (route: RouteLocationNormalizedLoaded): boolean =>
  Boolean(route.query.oauth_state_id)

export const storePlaidLinkToken = (value: string): void => {
  localStorage.setItem(PLAID_LINK_TOKEN_STORAGE_KEY, value)
}
export const getStoredPlaidLinkToken = (): Result<string> => {
  const value = localStorage.getItem(PLAID_LINK_TOKEN_STORAGE_KEY)
  if (value) {
    return result.ok(value)
  }
  return result.failed(new Error('[TC] Plaid link token was not stored'))
}
export const removeStoredPlaidLinkToken = (): Result<boolean> => {
  localStorage.removeItem(PLAID_LINK_TOKEN_STORAGE_KEY)
  return result.ok(true)
}
