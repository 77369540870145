import { consoleLogger } from '@/lib/logger'
import { result } from '@/lib/result'

export const loadScript = async (src: string): Promise<Result> => {
  try {
    const existing = document.querySelector(`script[src="${src}"]`)
    if (existing) {
      // do not load twice
      return result.ok(undefined)
    }
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => resolve(result.ok(undefined))
      script.onerror = () =>
        resolve(result.failed(new Error('[TC] Failed to load script with src '.concat(src))))
      document.body.appendChild(script)
    })
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(new Error('[TC] Failed to load script with src '.concat(src)))
  }
}

export const removeScript = (scriptSrc: string) =>
  Array.from(document.querySelectorAll('script'))
    .filter((script: HTMLScriptElement) => script.src === scriptSrc)
    .forEach((script: HTMLScriptElement) => script.remove())
