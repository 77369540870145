<template>
  <div class="Message d-flex justify-start align-center" :class="variantClass">
    <div class="Message__icon-container mr-8 c-white text-center">
      <Icon :name="iconName" class="fs-12 fw-bold" />
    </div>
    <div class="f-grow1 fs-14" :class="textColorClass">
      <slot />
    </div>
    <Icon
      v-if="props.dismissable"
      name="x"
      class="fs-12"
      :class="textColorClass"
      @click="$emit('onClose')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity'
import Icon from './Icon.vue'

const props = withDefaults(
  defineProps<{ variant?: 'success' | 'error' | 'warning'; dismissable?: boolean }>(),
  {
    variant: 'success',
    dismissable: true,
  }
)
defineEmits(['onClose'])

const variantClass = computed(() => ({
  'Message-success': props.variant === 'success',
  'Message-error': props.variant === 'error',
  'Message-warning bg-yellow': props.variant === 'warning',
}))
const textColorClass = computed(() => ({
  'c-green3': props.variant === 'success',
  'c-red': props.variant === 'error',
  'c-orange1': props.variant === 'warning',
}))
const iconName = computed(() => {
  if (props.variant === 'error') {
    return 'alert-circle'
  } else if (props.variant === 'warning') {
    return 'alert-triangle'
  }
  return 'check'
})
</script>

<style scoped>
.Message {
  min-height: 40px;
  padding: 10px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
}

.Message__icon-container {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  line-height: 16px;
  border-radius: 50%;
}

.Message.Message-success {
  background-color: #dcfce7;
}
.Message.Message-error {
  background-color: var(--bg-red);
}

.Message.Message-success .Message__icon-container {
  background-color: #16a34a;
}

.Message.Message-error .Message__icon-container .Icon {
  font-size: 18px;
  color: var(--c-red);
}

.Message.Message-warning .Message__icon-container .Icon {
  font-size: 18px;
  color: var(--c-red1);
}
</style>
