<template>
  <Box class="bg-white fs-14 fw-500 d-flex flex-direction-column" style="padding: 24px">
    <div class="minh-116">
      <p class="fs-14 fw-500 c-grey6 mb-8">Next Payment Due Date</p>
      <p class="fs-14 fw-500 c-black mb-16">{{ dueDateText }}</p>

      <p class="fs-14 fw-500 c-grey6 mb-8">Outstanding Balance</p>
      <p class="fs-14 fw-500 c-black mb-16">{{ balanceText }}</p>
    </div>
    <Divider />
  </Box>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Box from '@/components/Box.vue'
import Divider from '@/components/Divider.vue'

import { defaultTo0, formatCurrency } from '@/lib/number'
import { formatDateMonthShortYearFull } from '@/lib/date'

const props = defineProps<{
  statementDueDate?: Date
  statementBalance?: number
}>()

const dueDateText = computed(() => formatDateMonthShortYearFull(props.statementDueDate))
const balanceText = computed(() => formatCurrency(defaultTo0(props.statementBalance) / 100))
</script>
