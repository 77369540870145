<template>
  <div
    class="FormFile d-flex justify-center align-center flex-direction-column text-center"
    :class="{
      FormFile__selected: state === 'selected',
      FormFile__uploaded: state === 'uploaded',
      FormFile__error: error,
    }"
    @dragover.prevent
    @drop.prevent="handleDrop"
  >
    <input
      ref="inputRef"
      hidden
      type="file"
      accept="image/jpeg, image/png, .pdf"
      @input="handleInput"
    />

    <div v-if="state === 'selected'">
      <Icon
        class="fs-10 cursor-pointer"
        name="x"
        @click="handleCloseClick"
        style="top: 16px; right: 16px"
      />
      <p class="fs-14 fw-500 c-grey1 mb-8">Upload file</p>
      <p class="fs-12 fw-500 c-grey4 mb-16">{{ fileName }}</p>
      <Button
        variant="primary"
        :loading="loading"
        type="button"
        :disabled="loading"
        @click="handleUploadClick"
        >Upload</Button
      >
    </div>

    <div v-if="state === 'uploaded'">
      <p class="fs-14 fw-500 c-green4 mb-8">Document uploaded</p>
      <p class="fs-12 fw-500 c-grey4 mb-16">
        Changed your mind? You can upload another document to replace an existing one.
      </p>
      <Button variant="secondary" bordered type="button" @click="handleBrowseClick">Browse</Button>
    </div>

    <div v-if="state === 'initial'">
      <p class="fs-14 fw-500 c-grey1 mb-8">Drag and drop file</p>
      <p class="fs-12 fw-500 c-grey4 mb-16">Supported file type: JPG, PNG, PDF · Max 10 MB</p>
      <p class="fs-14 c-grey4 mb-16">
        &#x23AF;&#x23AF;&#x23AF;
        <span class="mr-8 ml-8">or</span>
        &#x23AF;&#x23AF;&#x23AF;
      </p>
      <Button variant="secondary" bordered type="button" @click="handleBrowseClick">Browse</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { head } from '@/lib/list'
import { computed, ComputedRef, ref, Ref, watch } from 'vue'
import Button from './Button.vue'
import Icon from './Icon.vue'

const props = withDefaults(
  defineProps<{
    value?: Maybe<File>
    loading?: boolean
    state: 'initial' | 'selected' | 'uploaded'
    error?: Maybe<string>
  }>(),
  {
    modelValue: undefined,
    loading: false,
    state: 'initial',
    uploaded: false,
    error: undefined,
  }
)
const emit = defineEmits<{
  (event: 'change', value: Maybe<File>): void
  (event: 'upload'): void
  (event: 'clear'): void
}>()

const inputRef: Ref<any> = ref(null)
const fileName: ComputedRef<Maybe<string>> = computed(() => props.value?.name)

watch(fileName, (newFile: Maybe<string>) => {
  if (!newFile) {
    clearInput()
  }
})

const handleBrowseClick = () => {
  const input = inputRef.value as HTMLInputElement
  if (!input) {
    return
  }
  input.click()
}

const handleInput = (e: Event) => {
  const el = e.target as HTMLInputElement
  const file = head(Array.from(el.files || []))
  emitFile(file)
}

const emitFile = (file: Maybe<File>) => emit('change', file)

const handleUploadClick = () => emit('upload')

const handleCloseClick = () => {
  clearInput()
  emit('clear')
}

const handleDrop = (e: DragEvent) => {
  e.preventDefault()
  if (!e.dataTransfer) {
    return
  }
  const file: Maybe<File> = head(Array.from(e.dataTransfer.files) || [])
  emitFile(file)
}

const clearInput = () => {
  const input = inputRef.value as HTMLInputElement
  if (input) {
    input.value = ''
  }
}
</script>

<style>
.FormFile {
  position: relative;
  height: 236px;
  padding: 0 72px;
  border-radius: 8px;
  border: dashed 1px var(--c-grey6);
  background-color: var(--bg-grey2);
}
.FormFile.FormFile__selected {
  border-color: var(--c-grey2);
  background-color: var(--bg-grey4);
}
.FormFile.FormFile__uploaded {
  border-style: solid;
  border-color: var(--c-green2);
  background-color: var(--bg-green3);
}
.FormFile.FormFile__error {
  border-style: dashed;
  border-color: var(--c-red3);
  background-color: var(--bg-red);
}
.FormFile .Icon {
  color: var(--c-grey2);
  position: absolute;
}

@media all and (max-width: 768px) {
  .FormFile {
    position: relative;
    height: 236px;
    padding: 0 12px;
    border-radius: 8px;
    border: dashed 1px var(--c-grey6);
    background-color: var(--bg-grey2);
  }
}
</style>
