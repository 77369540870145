export const isProduction = () => import.meta.env.PROD

export enum ApplicationEnvironment {
  Development = 'development',
  Preview = 'preview',
  Staging = 'staging',
  Production = 'production',
}

export const ENV = {
  TC_HIGHNOTE_API_URL: import.meta.env.TC_HIGHNOTE_API_URL,
  TC_AUTH0_CLIENT_ID: import.meta.env.TC_AUTH0_CLIENT_ID,
  TC_AUTH0_DOMAIN: import.meta.env.TC_AUTH0_DOMAIN,
  TC_AUTH0_CONNECTION: import.meta.env.TC_AUTH0_CONNECTION,
  TC_GRAPHQL_API_URL: import.meta.env.TC_GRAPHQL_API_URL,
  TC_APP_SYNC_GUID: import.meta.env.TC_APP_SYNC_GUID,
  TC_APP_SYNC_REGION: import.meta.env.TC_APP_SYNC_REGION,
  TC_FORGOT_PASSWORD_URL: import.meta.env.TC_FORGOT_PASSWORD_URL,
  TC_HUBSPOT_SCRIPT_SRC: import.meta.env.TC_HUBSPOT_SCRIPT_SRC,
  TC_HUBSPOT_REGION: import.meta.env.TC_HUBSPOT_REGION,
  TC_HUBSPOT_PORTAL_ID: import.meta.env.TC_HUBSPOT_PORTAL_ID,
  TC_HUBSPOT_FORM_ID: import.meta.env.TC_HUBSPOT_FORM_ID,
  TC_PLAID_ENV: import.meta.env.TC_PLAID_ENV,
  TC_SENTRY_DSN: import.meta.env.TC_SENTRY_DSN,
  TC_SEGMENT_KEY: import.meta.env.TC_SEGMENT_KEY,
  TC_TRANSFER_CONSENT_TEMPLATE_ID: import.meta.env.TC_TRANSFER_CONSENT_TEMPLATE_ID,
  TC_TRANSFER_CONSENT_TEMPLATE_VERSION: import.meta.env.TC_TRANSFER_CONSENT_TEMPLATE_VERSION,
  TC_AUTOPAY_CONSENT_TEMPLATE_ID: import.meta.env.TC_AUTOPAY_CONSENT_TEMPLATE_ID,
  TC_AUTOPAY_CONSENT_TEMPLATE_VERSION: import.meta.env.TC_AUTOPAY_CONSENT_TEMPLATE_VERSION,
  TC_ENV: import.meta.env.TC_ENV as ApplicationEnvironment,
  TC_VIRTUAL_CARD_COUNT_LIMIT: Number(import.meta.env.TC_VIRTUAL_CARD_COUNT_LIMIT),
  TC_VIRTUAL_CARD_EXPIRATION_YEARS: Number(import.meta.env.TC_VIRTUAL_CARD_EXPIRATION_YEARS),
  TC_GIT_SHA: process.env.GIT_SHA,
  TC_BRANCH_NAME: process.env.BRANCH_NAME,
  TC_APP_VERSION: process.env.TC_APP_VERSION,
  TC_LAUNCHDARKLY_CLIENT_ID: process.env.TC_LAUNCHDARKLY_CLIENT_ID,
}
