<template>
  <slot v-if="authHandled" />
  <LoaderFullscreen v-else />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { noop } from '@/lib/function'
import { injectDom } from '@/provider/domProvider'
import { injectLogger } from '@/provider/loggerProvider'
import LoaderFullscreen from '@/components/LoaderFullscreen.vue'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'

const router = useRouter()
const logger = injectLogger()
const dom = injectDom()
const $auth = injectAuthFunctions()

const authHandled = ref(false)

onMounted(async () => {
  if (redirectedFromAuth()) {
    await handleRedirectAuth()
  }
  authHandled.value = true
})

function redirectedFromAuth() {
  return (
    dom.window.location.search.includes('code=') && dom.window.location.search.includes('state=')
  )
}

async function handleRedirectAuth() {
  const redirectCallbackResult = await $auth.handleRedirectCallback()
  if (!redirectCallbackResult.ok) {
    return
  }
  logger.log('[AuthorizationProvider] Handle redirect callback OK')
  $auth.storeSessionExpiration()
  const returnTo: Maybe<string> = localStorage.getItem('TC_RETURN_TO')
  if (!returnTo) {
    return
  }
  logger.log('[AuthorizationProvider] Return to: ', returnTo)
  await router.push(returnTo).catch(noop)
}
</script>
