<template>
  <Teleport to="body">
    <Transition appear name="fade">
      <div
        v-if="opened"
        ref="popup"
        class="Popup bg-white c-black2 fs-12"
        v-bind="$attrs"
        :style="positionPoint"
      >
        <div v-if="showArrow" class="Popup__pointer"></div>
        <div class="pad-8">
          <slot />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ComputedRef, ref, Ref } from 'vue'

import { NodePosition, scrollY } from '@/api/dom/document'
import { formatPx } from '@/lib/number'

export default defineComponent({
  inheritAttrs: false,

  props: {
    opened: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showArrow: {
      type: Boolean as PropType<Maybe<boolean>>,
      default: true,
    },
    target: { type: Object as PropType<HTMLElement | null>, default: null },
    position: {
      type: String as PropType<'top-center' | 'bottom-left' | 'bottom'>,
      default: 'top-center',
    },
  },

  setup(props) {
    const popup: Ref<HTMLDivElement | null> = ref(null)

    const positionPoint: any = computed(() => {
      if (props.target && popup.value) {
        switch (props.position) {
          case 'top-center': {
            const rect = props.target.getBoundingClientRect()
            const top = rect.top - popup.value.offsetHeight - 10 + scrollY()
            const left = rect.left - popup.value.offsetWidth / 2 + rect.width / 2
            return { top: formatPx(top), left: formatPx(left) }
          }

          case 'bottom-left': {
            const rect = props.target.getBoundingClientRect()
            const top = rect.bottom + scrollY()
            const left = rect.right - popup.value.offsetWidth
            return { top: formatPx(top), left: formatPx(left) }
          }

          case 'bottom': {
            const rect = props.target.getBoundingClientRect()
            const top = rect.bottom + scrollY()
            const left = rect.left
            return { top: formatPx(top), left: formatPx(left) }
          }

          default:
            break
        }
      }
      return { top: formatPx(0), left: formatPx(0) }
    })

    return {
      positionPoint,
      popup,
    }
  },
})
</script>

<style>
.Popup {
  position: absolute;
  border-top-left-radius: 10px;
  border-radius: 5px;
  border: 1px solid var(--grey);
}
.Popup__pointer {
  position: absolute;
  bottom: -7.5px;
  left: calc(50% - 7.5px);
  width: 15px;
  height: 15px;
  background: var(--white);
  border-bottom: 1px solid var(--grey);
  border-right: 1px solid var(--grey);
  border-bottom-right-radius: 5px;
  transform-origin: center;
  transform: rotate(45deg);
}
</style>
