import { BaseError } from '@/lib/domain/error/BaseError'
import { ErrorVendor } from '@/lib/domain/error/vendor'

export class TransactionExportFailedError extends BaseError<any> {
  constructor(msg: string, options?: ITransactionExportFailedErrorOptions<any>) {
    super(msg)
    this.name = 'TransactionExportFailedError'
    this.payload = options?.payload
    this.originalError = options?.originalError
    this.vendor = options?.vendor
  }
}

export interface ITransactionExportFailedErrorOptions<T> {
  originalError?: Error
  payload?: T
  vendor?: ErrorVendor
}
