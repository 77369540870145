<template>
  <Page name="Agreements">
    <Box class="Agreements__content bg-white">
      <a
        v-for="link in linkList"
        :key="link.href"
        class="Agreements__content__link c-grey1 discard-anchor mr-16 mb-16"
        :href="link.href"
        target="_blank"
      >
        <div class="Agreements__content__link__icon flex-center mb-16">
          <Icon name="file-text" class="fs-20" />
        </div>
        <div class="fs-14 fw-500">{{ link.text }} <Icon name="external-link" class="fs-14" /></div>
      </a>
    </Box>
  </Page>
</template>

<script setup lang="ts">
import Page from '../components/Page.vue'
import Icon from '@/components/Icon.vue'
import Box from '@/components/Box.vue'
import { AGREEMENTS_LINK } from '@/constants'

const linkList = [
  AGREEMENTS_LINK.privacyPolicy,
  AGREEMENTS_LINK.termsService,
  AGREEMENTS_LINK.creditAgreement,
  AGREEMENTS_LINK.cardholderAgreement,
]
</script>

<style>
.Agreements__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}
.Agreements__content__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  padding: 24px 60px;
  border-radius: 8px;
  border: solid 1px var(--bg-grey1);
}
.Agreements__content__link__icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--bg-grey2);
}
.Agreements__content__link:hover {
  color: var(--c-green1);
  border: solid 1px var(--c-green1);
}
</style>
