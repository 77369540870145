<template>
  <div class="PageMenuMobile">
    <Icon class="fs-24" name="menu" @click="handleIconClick" />

    <div v-if="opened" class="PageMenuMobile__popup bg-white" @click.stop="handlePopupClick">
      <nav class="PageMenuMobile__nav">
        <div class="d-flex justify-between">
          <img :src="Logo" class="PageMenuMobile__title" width="65" />
          <Icon class="PageMenuMobile__icon fs-20" name="x" @click="handleCloseClick" />
        </div>
        <p class="PageMenuMobile__subtitle c-grey fs-16 fw-600 mb-12">{{ companyName }}</p>
        <p class="fs-14 fw-500 c-grey2 mb-40">Account # {{ accountNumber }}</p>
        <ul class="PageMenuMobile__menu c-grey1">
          <li v-if="isOnboardingMenuAvailable" class="fs-14 fw-500" style="margin-bottom: 40px">
            <LinkInternal :name="RouteName.Onboarding" class="c-grey1 d-flex w-100">
              <PageMenuItemOnboarding />
            </LinkInternal>
          </li>

          <li class="fs-16 fw-500">
            <LinkInternal :name="RouteName.Dashboard" class="c-grey1" :disabled="!appViewAvailable">
              <Icon name="home" />
              <span class="PageMenuMobile__menu__item__text">Dashboard</span>
            </LinkInternal>
          </li>
          <li class="fs-16 fw-500 d-flex justify-between">
            <LinkInternal :name="RouteName.Cards" class="c-grey1" :disabled="!appViewAvailable">
              <Icon name="credit-card" />
              <span class="PageMenuMobile__menu__item__text">Cards</span>
            </LinkInternal>
            <ActiveCardBadge />
          </li>
          <li class="fs-16 fw-500">
            <LinkInternal :name="RouteName.Payments" class="c-grey1" :disabled="!appViewAvailable">
              <Icon name="payment" style="margin-right: 10px" />
              <span class="PageMenuMobile__menu__item__text">Payments</span>
            </LinkInternal>
          </li>
          <li class="fs-16 fw-500 d-flex justify-between">
            <Link class="c-grey1" target="_blank" :href="TILLFULL_LINK.href">
              <Icon name="tillful-logo" />
              <span class="PageMenuMobile__menu__item__text">{{ TILLFULL_LINK.text }}</span>
            </Link>
          </li>
          <li class="fs-16 fw-500 d-flex justify-between">
            <LinkInternal
              :name="RouteName.Statements"
              class="c-grey1"
              :disabled="!appViewAvailable"
            >
              <Icon name="file-text" />
              <span class="PageMenuMobile__menu__item__text">Statements</span>
            </LinkInternal>
          </li>
          <li class="fs-16 fw-500">
            <LinkInternal :name="RouteName.Settings" class="c-grey1">
              <Icon name="settings" />
              <span class="PageMenuMobile__menu__item__text">Settings</span>
            </LinkInternal>
          </li>
          <li class="fs-16 fw-500 ml-32">
            <LinkInternal :name="RouteName.SettingsPersonal" class="c-grey1">
              <span class="PageMenuMobile__menu__item__text">Account Owner</span>
            </LinkInternal>
          </li>
          <li class="fs-16 fw-500 ml-32">
            <LinkInternal :name="RouteName.SettingsCompany" class="c-grey1">
              <span class="PageMenuMobile__menu__item__text">Company</span>
            </LinkInternal>
          </li>
          <li class="fs-16 fw-500 ml-32">
            <LinkInternal :name="RouteName.SettingsSecurity" class="c-grey1">
              <span class="PageMenuMobile__menu__item__text">Security</span>
            </LinkInternal>
          </li>
          <li class="fs-16 fw-500 ml-32">
            <LinkInternal :name="RouteName.SettingsBank" class="c-grey1">
              <span class="PageMenuMobile__menu__item__text">Bank Accounts</span>
            </LinkInternal>
          </li>

          <li class="fs-16 fw-500">
            <LinkInternal :name="RouteName.Support" class="c-grey1 d-flex w-100">
              <Icon name="life-buoy" />
              <span class="PageMenuMobile__menu__item__text">Support</span>
            </LinkInternal>
          </li>

          <li class="fs-16 fw-500">
            <LinkInternal :name="RouteName.Agreements" class="c-grey1">
              <Icon name="file-text" />
              <span class="PageMenuMobile__menu__item__text">Agreements</span>
            </LinkInternal>
          </li>

          <li class="fs-16 fw-500">
            <LinkInternal @onClick="handleLogout" class="c-grey1">
              <Icon name="log-out" />
              <span class="PageMenuMobile__menu__item__text">Log out</span>
            </LinkInternal>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { RouteName } from '@/router/route'
import { useRoute } from 'vue-router'

import Icon from '@/components/Icon.vue'
import LinkInternal from '@/components/LinkInternal.vue'
import Link from '@/components/Link.vue'
import Logo from '@/assets/tillfull.svg'
import ActiveCardBadge from '@/components/cards/ActiveCardBadge.vue'
import { useOnboardingStore } from '@/store/onboarding'
import { useAccountHolder } from '@/store/accountHolder'
import PageMenuItemOnboarding from '@/components/PageMenuItemOnboarding.vue'
import { TILLFULL_LINK } from '@/constants'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'

const { appViewAvailable, isOnboardingMenuAvailable, closeOnboardingMenu } = useOnboardingStore()
const { companyName, accountNumber } = useAccountHolder()
const $auth = injectAuthFunctions()

const opened = ref(false)

const route = useRoute()
watch(route, () => {
  closeMenu()
})

const toggleMenu = () => {
  opened.value = !opened.value
}

const closeMenu = () => {
  opened.value = false
}

const handleIconClick = () => {
  toggleMenu()
}

const handleCloseClick = () => {
  closeMenu()
}

const handlePopupClick = () => {}

const handleLogout = () => $auth.logout()

const handleOnboardingMenuClose = closeOnboardingMenu
</script>

<style>
.PageMenuMobile__popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--white);
  z-index: 1;
  overflow-y: auto;
}
.PageMenuMobile__nav {
  padding: 32px 24px 0px 24px;
}

.PageMenuMobile__icon,
.PageMenuMobile__title {
  margin-bottom: 28px;
}

.PageMenuMobile__menu {
  list-style-type: none;
  padding-left: 0;
}
.PageMenuMobile__menu li {
  margin-bottom: 24px;
}
.PageMenuMobile__menu li .Icon {
  margin-right: 16px;
}
</style>
