<template>
  <SubPageContent class="AccountOwnerPage" title="Account Owner">
    <template v-slot:description>
      If you wish to update the following information for the primary authorized person, contact us
      at
      <Link class="c-green2" href="mailto:card@tillful.com">card@tillful.com</Link>
    </template>

    <SettingsPersonalData :value="personalInformation" />
  </SubPageContent>
</template>

<script setup lang="ts">
import { useAccountHolder } from '@/store/accountHolder'
import Link from '../Link.vue'
import SubPageContent from '../SubPageContent.vue'
import SettingsPersonalData from './SettingsPersonalData.vue'

const { personalInformation } = useAccountHolder()
</script>
