<template>
  <template v-if="!localState.isShowCancel">
    <Button
      type="button"
      variant="secondary"
      class="d-flex align-center"
      @click="$emit('onExportCSV')"
      :disabled="loading"
      bordered
    >
      <Icon class="fs-14" name="export" />
      <span class="ml-8">Export</span>
      <Loader v-if="loading" class="ml-8" size="medium" />
    </Button>
  </template>
  <template v-else>
    <Button
      type="button"
      variant="secondary"
      class="d-flex align-center"
      @click="$emit('onExportCSVCancel')"
      bordered
    >
      <span class="ml-8">Cancel export</span>
      <Loader v-if="loading" class="ml-8" size="medium" />
    </Button>
  </template>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue'
import Loader from '@/components/Loader.vue'
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'

defineEmits(['onExportCSV', 'onExportCSVCancel'])

const props = withDefaults(
  defineProps<{
    loading?: boolean
    timeout?: number
  }>(),
  {
    loading: false,
    timeout: 60000,
  }
)

let cancelTimeout: null | ReturnType<typeof setTimeout> = null
const localState = reactive({
  isShowCancel: false,
})

watch(
  () => props.loading,
  (loading) => {
    if (loading) {
      cancelTimeout = setTimeout(() => {
        localState.isShowCancel = true
      }, props.timeout)
    } else if (cancelTimeout) {
      clearTimeout(cancelTimeout)
      localState.isShowCancel = false
    }
  }
)
</script>

<style></style>
