import { computed, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'

import { getStatementByPeriod } from '@/api/graphql/graphqlAPI'
import { closeCurrentTab } from '@/api/dom/document'
import {
  FinancialAccountStatementDetailEntryFragment,
  FinancialAccountStatementDetailFragment,
} from '@/generated/graphql'

import { useAccountHolder } from '@/store/accountHolder'
import { notNil } from '@/lib/type'
import { mapStatementDetailsToView } from '@/lib/domain/statement'
import { defaultToEmptyList } from '@/lib/list'

interface IUseStatementsLocalState {
  statementDetails: Maybe<FinancialAccountStatementDetailFragment>
  statementEntryList: FinancialAccountStatementDetailEntryFragment[]
  error: Maybe<Error>
}

interface IStatementDetailPageQueryParams {
  start?: string
  end?: string
}

interface IStatementDetailPageValidQueryParams {
  start: string
  end: string
}

export const useStatementDetails = () => {
  const { id } = useAccountHolder()
  const { query } = useRoute()

  const localState = reactive<IUseStatementsLocalState>({
    statementDetails: null,
    statementEntryList: [],
    error: null,
  })

  onMounted(() => loadStatementIfValidQuery(query))

  const statementDetailsView = computed(() =>
    mapStatementDetailsToView(localState.statementDetails, localState.statementEntryList)
  )

  function setStatement(statement: Maybe<FinancialAccountStatementDetailFragment>) {
    const entryList = defaultToEmptyList(statement?.statementEntries?.edges)
      .map((e) => e.node)
      .filter(notNil)
    localState.statementDetails = statement
    localState.statementEntryList = localState.statementEntryList.concat(entryList)
  }

  function setError(error: Error) {
    return Object.assign(localState, { error })
  }

  function loadStatementIfValidQuery(queryParams: IStatementDetailPageQueryParams): void {
    if (!isQueryValid(queryParams)) {
      closeCurrentTab()
    } else {
      loadStatement(queryParams.start, queryParams.end)
    }
  }

  async function loadStatement(start: string, end: string) {
    await loadStatementWithAllEntries({
      start,
      end,
      entryCursor: null, // start with first entries page
    })
  }

  async function loadStatementWithAllEntries(options: {
    start: string
    end: string
    entryCursor: Maybe<string>
  }) {
    const res = await getStatementByPeriod({
      accountHolderId: id.value,
      start: options.start,
      end: options.end,
      entryCursor: options.entryCursor,
    })

    // quit recursion on error
    if (!res.ok) {
      setError(res.error)
      return
    }

    // set result
    setStatement(res.value)

    const page = res.value?.statementEntries?.pageInfo
    // quit recursion if last page loaded
    if (!page?.hasNextPage) {
      return
    }

    // continue recursion
    await loadStatementWithAllEntries({
      start: options.start,
      end: options.end,
      entryCursor: page.endCursor,
    })
  }

  function isQueryValid(
    query: IStatementDetailPageQueryParams
  ): query is IStatementDetailPageValidQueryParams {
    return notNil(query.start) && notNil(query.end)
  }

  return {
    statementDetailsView,
  }
}
