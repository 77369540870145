<template>
  <Modal :opened="opened" @onClose="handleClose">
    <div class="d-flex flex-direction-column">
      <h2 class="mb-24 text-center fs-20 fw-bold c-black">Lock Card</h2>
      <Message v-if="failed" class="mb-24" variant="error" :dismissable="false">
        Failed to lock your card. Please try again. If the problem persists, contact support.
      </Message>
      <p class="mb-24 c-grey2 fs-14" style="line-height: 1.43">
        The card ending in {{ cardLast4 }} will be instantly locked. All future authorizations will
        be declined until it is unlocked again. Are you sure you want to continue?
      </p>
      <Button
        class="LockCardModal__submit mb-20"
        variant="danger"
        size="large"
        @click="handleLock"
        :loading="loading"
        >Lock Card</Button
      >
      <Button
        class="LockCardModal__cancel"
        variant="secondary"
        :loading="false"
        size="large"
        @click="handleClose"
        >Cancel</Button
      >
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '../Modal.vue'
import Message from '../Message.vue'
import Button from '../Button.vue'
import { useLockCardModal } from '@/service/useLockCard'

const { opened, failed, loading, lock, close, cardLast4 } = useLockCardModal()
const handleLock = () => {
  lock()
}
const handleClose = () => {
  close()
}
</script>
