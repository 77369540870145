import { BaseError } from '@/lib/domain/error/BaseError'
import { computed, ComputedRef, inject, InjectionKey, provide, reactive } from 'vue'

export type DomPropertiesState = {
  maintenanceMessageHeight: number
}

export type DomPropertiesStore = {
  state: ComputedRef<DomPropertiesState>
  setState(value: Partial<DomPropertiesState>): void
}

export const DOM_PROPERTIES_STORE_INJECTION_KEY = Symbol(
  'DOM properties store key'
) as InjectionKey<DomPropertiesStore>

export function provideDomProperties(): void {
  const store = createDomPropertiesStore()
  provide(DOM_PROPERTIES_STORE_INJECTION_KEY, store)
}

export function injectDomProperties(): DomPropertiesStore {
  const injected = inject(DOM_PROPERTIES_STORE_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject DOM properties')
  }
  return injected
}

export function createDomPropertiesStore(): DomPropertiesStore {
  const state: DomPropertiesState = reactive({
    maintenanceMessageHeight: 0,
  })
  return {
    state: computed(() => state),
    setState(value) {
      Object.assign(state, value)
    },
  }
}
