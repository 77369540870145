<template>
  <Modal :opened="opened" @onClose="$emit('onClose')">
    <div class="OnePasswordDetailsModal__content" ref="contentWrapperRef">
      <Message v-if="state.failed" :dismissable="false" variant="error" style="margin: 0 5px">
        Could not retrieve card details. Please try again or contact support.
      </Message>
      <Message
        v-if="!state.extentionInstalled"
        :dismissable="false"
        variant="warning"
        style="margin: 0 5px"
      >
        Please install the
        <Link
          class="c-green2"
          href="https://support.1password.com/getting-started-browser/"
          target="_blank"
        >
          1Password extension
        </Link>
        to your browser before saving your card data.
      </Message>

      <Loader v-if="loading" style="margin: 0 auto" />

      <div v-show="showButton">
        <OnePasswordBtn />
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { ref, reactive } from '@vue/reactivity'
import { onMounted } from 'vue'

import Modal from '@/components/Modal.vue'
import Loader from '@/components/Loader.vue'
import OnePasswordBtn from '@/components/onePassword/OnePasswordBtn.vue'
import { useAccountHolder } from '@/store/accountHolder'
import { generatePaymentCardToken } from '@/api/graphql/clientToken'
import { getCardDetails } from '@/api/graphql/graphqlAPI'
import { encodeOPSaveRequest, SaveRequest } from '@1password/save-button'
import { PaymentCardRestrictedDetails } from '@/generated/graphql'
import { captureException } from '@/api/sentry/sentry'
import { useBool } from '@/lib/composable/useBool'
import Message from '@/components/Message.vue'
import Link from '@/components/Link.vue'

const props = withDefaults(
  defineProps<{
    opened?: boolean
    id?: Maybe<string>
  }>(),
  {}
)

const $emit = defineEmits<{
  (event: 'onClose'): void
}>()

const { value: loading, truthyfy: showLoading, falsefy: hideLoading } = useBool()
const { id: accountHolderId, personalInformation, businessInformation } = useAccountHolder()
const state = reactive({ failed: false, extentionInstalled: false })
const contentWrapperRef = ref<HTMLLIElement | null>(null)
const showButton = false
const encodeCardInfo = ref<Maybe<string>>('')

onMounted(async () => {
  if (!props.id) {
    return
  }
  showLoading()
  await checkExtentionInstalled()
  if (state.extentionInstalled) {
    await updateEncodeCardInfo(props.id)
  }
  hideLoading()
  if (!state.failed && state.extentionInstalled) {
    await updateValue(encodeCardInfo.value)
    showOPWidget()
  }
})

const updateEncodeCardInfo = async (id: string): Promise<void> => {
  const tokenResult = await generatePaymentCardToken(accountHolderId.value, id)
  if (!tokenResult.ok) {
    handleError(tokenResult.error)
    state.failed = true
    return
  }
  const sensitiveCardInfo = await getCardDetails(tokenResult.value.value, id)
  if (!sensitiveCardInfo.ok) {
    handleError(sensitiveCardInfo.error)
    state.failed = true
    return
  }
  const date = new Date(sensitiveCardInfo.value.expirationDate as string)
  const ccExpMonth = String(date.getUTCMonth() + 1).padStart(2, '0')
  const ccExpYear = date.getFullYear()
  const saveRequest = {
    title: 'Tillful Secured Credit Card',
    fields: [
      {
        autocomplete: 'cc-name',
        value: personalInformation.value.name,
      },
      {
        autocomplete: 'cc-number',
        value: (sensitiveCardInfo.value.restrictedDetails as PaymentCardRestrictedDetails)?.number,
      },
      {
        autocomplete: 'cc-exp',
        value: `${ccExpYear}${ccExpMonth}`,
      },
      {
        autocomplete: 'cc-csc',
        value: (sensitiveCardInfo.value.restrictedDetails as PaymentCardRestrictedDetails)?.cvv,
      },
      {
        autocomplete: 'cc-type',
        value: 'credit',
      },
      {
        autocomplete: 'street-address',
        value: businessInformation.value.address?.streetAddress,
      },
      {
        autocomplete: 'address-level2',
        value: businessInformation.value.address?.locality,
      },
      {
        autocomplete: 'address-level1',
        value: businessInformation.value.address?.region,
      },
      {
        autocomplete: 'postal-code',
        value: businessInformation.value.address?.postalCode,
      },
    ],
  }
  encodeCardInfo.value = encodeOPSaveRequest(saveRequest as SaveRequest)
}

const showOPWidget = async () => {
  if (contentWrapperRef.value) {
    const opButton: Maybe<HTMLButtonElement> = contentWrapperRef.value
      .querySelector('onepassword-save-button')
      ?.shadowRoot?.querySelector('button[data-onepassword-save-button]')
    opButton?.click()
    $emit('onClose')
  } else {
    state.failed = true
  }
}

const checkExtentionInstalled = async () => {
  if (contentWrapperRef.value) {
    const opButton: Maybe<HTMLButtonElement> = contentWrapperRef.value
      .querySelector('onepassword-save-button')
      ?.shadowRoot?.querySelector('button[data-onepassword-save-button]')

    if (opButton && !opButton.hasAttribute('disabled')) {
      state.extentionInstalled = true
    }
  }
}

const updateValue = (saveRequest: Maybe<string>) => {
  contentWrapperRef.value
    ?.querySelector('onepassword-save-button')
    ?.shadowRoot?.querySelector('button[data-onepassword-save-button]')
    ?.setAttribute('value', saveRequest ?? '')
}

const handleError = (e: any) => {
  captureException(e)
}
</script>
