<template>
  <div class="ForgotPassword d-flex flex-direction-column">
    <div class="ForgotPassword__brand c-black fs-20 fw-bold">
      <img :src="Logo" width="65" />
    </div>
    <div class="ForgotPassword__form-container f-grow1">
      <Icon
        name="arrow-left"
        @click="handleBack"
        class="ForgotPassword__btn-back mb-24 c-grey3 fs-20 d-block"
      />
      <h1 class="ForgotPassword__title mb-14 text-center fs-24 fw-bold c-black">Forgot password</h1>
      <p class="ForgotPassword__subtitle text-center mb-48 c-grey3 fs-14">
        Please enter the email address for your account. You will receive an email to set a new
        password.
      </p>
      <Message v-if="successShown" variant="success" class="mb-24" @onClose="handleMessageClose">
        An email has been sent to {{ emailValue }} with instructions to reset your password.
      </Message>
      <Message v-if="errorShown" variant="error" class="mb-24" @onClose="handleMessageClose">
        There was an error attempting to reset your password. Please try again later.
      </Message>
      <form @submit.prevent="handleFormSubmit" class="ForgotPassword__form">
        <label for="email" class="mb-40 d-block">
          <p class="mb-8 fs-14 fw-500 c-black2">Email Address</p>
          <input
            :value="emailValue"
            :disabled="pending"
            @input="handleInput"
            placeholder="email@example.com"
            type="email"
            id="email"
            class="ForgotPassword__input-email w-100 fs-14 c-black"
          />
        </label>
        <Button
          variant="primary"
          :disabled="pending"
          type="submit"
          class="ForgotPassword__btn-submit w-100"
          >Reset Password</Button
        >
      </form>
    </div>

    <PageFooter class="mb-56" />
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { RouteName } from '@/router/route'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { getAuthFunctions } from '@/api/auth/authClient'
import { consoleLogger } from '@/lib/logger'
import Message from '@/components/Message.vue'
import Logo from '@/assets/tillfull.svg'
import PageFooter from '@/components/PageFooter.vue'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'

const router = useRouter()
const $auth = injectAuthFunctions()

const pending: Ref<boolean> = ref(false)
const successShown: Ref<boolean> = ref(false)
const errorShown: Ref<boolean> = ref(false)
const emailValue: Ref<string> = ref('')
const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement
  emailValue.value = target.value
}

const handleFormSubmit = async () => {
  pending.value = true
  successShown.value = false
  errorShown.value = false
  const result = await $auth.changePassword(emailValue.value)
  if (result.ok) {
    consoleLogger.log('[Forgot Password] email sent successfully')
    successShown.value = true
  } else {
    consoleLogger.error('[Forgot Password] failed to send email', result.error)
    errorShown.value = true
  }
  pending.value = false
}

const handleMessageClose = () => {
  successShown.value = false
  errorShown.value = false
}

const handleBack = () => {
  router.push({ name: RouteName.Login })
}
</script>

<style scoped>
.ForgotPassword * {
  box-sizing: border-box;
}

.ForgotPassword {
  height: 100vh;
}
.ForgotPassword__brand {
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 54px;
}
.ForgotPassword__form-container {
  margin: 0 auto;
  max-width: calc(375px + 48px);
  padding-left: 24px;
  padding-right: 24px;
}

.ForgotPassword__form-container input {
  height: 40px;
  font-size: 14px;
  gap: 8px;
  padding: 10px 8px 10px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #d1d5db;
  background-color: #fff;
}
.ForgotPassword__form-container input:placeholder {
  font-size: 14px;
  color: var(--c-grey3);
}

.ForgotPassword__btn-submit {
  font-size: 14px;
}
</style>
