import { computed, inject } from 'vue'
import { captureException } from '@/api/sentry/sentry'

import {
  saveInstitutionLink,
  ISaveInstitutionLinkOptions,
  getPaymentAccountList,
} from '@/api/graphql/graphqlAPI'
import { tracker } from '@/api/segment/segment'
import { IPaymentAccount, IPlaidMeta } from '@/lib/domain/types'
import { length } from '@/lib/list'
import { consoleLogger, sentryLogger } from '@/lib/logger'
import { result } from '@/lib/result'
import { defaultToEmptyString } from '@/lib/string'
import { isNil, notNil } from '@/lib/type'
import { useAccountHolder } from '@/store/accountHolder'
import { financialInstitutionStore } from '@/provider/financialInstitutionStore'
import {
  FINANCIAL_INSTITUTION_STORE_INJECTION_KEY,
  EXTERNAL_BANK_ACCOUNT_STORAGE_KEY,
} from '@/constants'
import { noop } from '@/lib/function'
import { gt } from '@/lib/logic'
import { defaultToOldId } from '@/lib/domain/id'
import { ApiError } from '@/lib/domain/error/ApiError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { AppSyncApiError } from '@/lib/domain/error/AppSyncApiError'

export interface IPlaidHandlerOptions {
  token: string
  onSuccess: (token: string, meta: any, handler: any) => void
}

interface IOptions {
  onBankAccountCreated?(): void
}

export const useFinancialInsitutionStore = (options: IOptions = {}) => {
  const { id: accountHolderId } = useAccountHolder()
  const { onBankAccountCreated = noop } = options

  const store = inject(FINANCIAL_INSTITUTION_STORE_INJECTION_KEY)
  if (isNil(store)) {
    throw new Error('Financial institution store not provided')
  }

  const linkList = computed(() => store.financialInstitutionList.value.filter(notNil))
  const linkById = computed<Record<string, IPaymentAccount>>(() =>
    linkList.value
      .filter((i) => notNil(i.externalFinancialBankAccountId))
      .reduce(
        (acum, val) => ({
          ...acum,
          [defaultToEmptyString(val.externalFinancialBankAccountId)]: val,
        }),
        {}
      )
  )

  const bankAccountAddedButNotAvailable = computed<boolean>(() => {
    const inMemoryId: Maybe<string> = store.addedBankAccountId.value
    if (notNil(inMemoryId)) {
      return (
        isNil(linkById.value[inMemoryId]) &&
        // check if ID with old format applicable to list of IDs with new format
        isNil(linkById.value[defaultToOldId(inMemoryId)])
      )
    }

    const storedId = getStoredBankAccountId()
    if (storedId.ok) {
      return (
        isNil(linkById.value[storedId.value]) &&
        // check if ID with old format applicable to list of IDs with new format
        isNil(linkById.value[defaultToOldId(storedId.value)])
      )
    }
    return false
  })

  const loadFinancialInstitutionList = async (id: Maybe<string>): Promise<void> => {
    const res = await getPaymentAccountList(id)
    if (!res.ok) {
      const error = new ApiError('Failed to load payment account list.', {
        originalError: res.error,
        vendor: ErrorVendor.TillCard,
        payload: {
          accountHolderId: id,
        },
      })
      sentryLogger.error(error)
    } else {
      store.setFinancialInstitutionList(res.value)
    }
  }

  const linkListCount = computed<number>(() => length(linkList.value))

  return {
    linkList,
    linkListCount,
    linkById,
    linkAvailable: computed<boolean>(() => gt(linkListCount.value, 0)),
    bankAccountAddedButNotAvailable,
    linkError: computed<Maybe<string>>(() => store.error.value),
    setError: store.setError,
    saveFinancialInstitution: (meta: IPlaidMeta) => {
      return saveFinancialInstitution(meta, accountHolderId.value)
    },
    loadFinancialInstitutionList,
    updateFinancialInstitutionList: async () => {
      await loadFinancialInstitutionList(accountHolderId.value)

      const idResult = getStoredBankAccountId()
      if (idResult.ok) {
        const idAvailableInList =
          notNil(store.financialInstitutionById.value[idResult.value]) ||
          notNil(store.financialInstitutionById.value[defaultToOldId(idResult.value)])

        if (idAvailableInList) {
          // remove stored key as bank account now available in account holder data
          removeStoredBankAccountId()
          onBankAccountCreated()
        }
      }
    },
  }
}

const saveFinancialInstitution = async (
  meta: IPlaidMeta,
  accountHolderId: Maybe<string>
): Promise<Result<boolean, Error | AppSyncApiError>> => {
  const paramsResult = mapPlaidMetaToApiParams(meta, accountHolderId)
  if (!paramsResult.ok) {
    return paramsResult
  }
  storeBankAccountId(String(Math.random()))
  const saveLinkResult = await saveInstitutionLink(paramsResult.value)
  if (!saveLinkResult.ok) {
    removeStoredBankAccountId()
    return saveLinkResult
  }
  const storeIdResult = storeBankAccountId(saveLinkResult.value)
  if (!storeIdResult.ok) {
    consoleLogger.error(storeIdResult.error)
  }
  trackInstitution(meta)
  return result.ok(true)
}

const mapPlaidMetaToApiParams = (
  meta: IPlaidMeta,
  accountHolderId: Maybe<string>
): Result<ISaveInstitutionLinkOptions> => {
  if (
    !accountHolderId ||
    !meta.account_id ||
    !meta.institution.institution_id ||
    !meta.public_token
  ) {
    const error = new Error('Failed to save institution. Not all params provided')
    const params = {
      accountHolderId: accountHolderId,
      accountId: meta.account_id,
      institutionId: meta.institution?.institution_id,
      publicToken: meta.public_token,
    }
    consoleLogger.error(error, params)
    captureException({ error, params })
    return result.failed(error)
  }
  return result.ok({
    accountHolderId: accountHolderId,
    accountId: meta.account_id,
    institutionId: meta.institution?.institution_id,
    publicToken: meta.public_token,
  })
}

const storeBankAccountId = (id: Maybe<string>): Result<boolean> => {
  if (!id) {
    return result.failed(new Error('Failed to store external bank account id. No id provided.'))
  }
  try {
    financialInstitutionStore.setAddedBankAccount(id)
    localStorage.setItem(EXTERNAL_BANK_ACCOUNT_STORAGE_KEY, id)
    return result.ok(true)
  } catch (e) {
    return result.failed(e as Error)
  }
}
const getStoredBankAccountId = (): Result<string> => {
  try {
    const value = localStorage.getItem(EXTERNAL_BANK_ACCOUNT_STORAGE_KEY)
    if (!value) {
      return result.failed(new Error('Failed to get stored bank account ID. No ID found.'))
    }
    return result.ok(value)
  } catch (e) {
    return result.failed(e as Error)
  }
}
const removeStoredBankAccountId = (): Result<boolean> => {
  financialInstitutionStore.setAddedBankAccount(null)
  localStorage.removeItem(EXTERNAL_BANK_ACCOUNT_STORAGE_KEY)
  return result.ok(true)
}

const trackInstitution = (meta: IPlaidMeta) =>
  tracker.trackBankAccountLinked({
    last4: meta.account.mask,
    id: meta.institution.institution_id,
    name: meta.institution.name,
  })
