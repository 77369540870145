import { computed, ComputedRef, reactive } from 'vue'

import { indexBy } from '@/lib/list'
import { IPaymentAccount } from '@/lib/domain/types'

interface IFinancialInstitutionState {
  error?: string
  addedBankAccountId: Maybe<string>
  financialInstitutionList: IPaymentAccount[]
}

export interface IFinancialInstitutionStore {
  error: ComputedRef<Maybe<string>>
  addedBankAccountId: ComputedRef<Maybe<string>>
  financialInstitutionList: ComputedRef<IPaymentAccount[]>
  financialInstitutionById: ComputedRef<Record<string, IPaymentAccount>>
  setError(error: Maybe<string>): void
  setAddedBankAccount(id: Maybe<string>): void
  setFinancialInstitutionList(list: IPaymentAccount[]): void
}

export const createFinancialInstitutionStore = (): IFinancialInstitutionStore => {
  const state = reactive<IFinancialInstitutionState>({
    error: undefined,
    addedBankAccountId: undefined,
    financialInstitutionList: [],
  })

  const setError = (er?: string) => {
    state.error = er
  }

  const setAddedBankAccount = (id: Maybe<string>) => {
    state.addedBankAccountId = id
  }

  const setFinancialInstitutionList = (list: IPaymentAccount[]): void => {
    state.financialInstitutionList = list
  }

  return {
    error: computed(() => state.error),
    addedBankAccountId: computed(() => state.addedBankAccountId),
    financialInstitutionList: computed(() => state.financialInstitutionList),
    financialInstitutionById: computed(() =>
      indexBy((item) => item.externalFinancialBankAccountId, state.financialInstitutionList)
    ),
    setError,
    setAddedBankAccount,
    setFinancialInstitutionList,
  }
}
