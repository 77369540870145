<template>
  <Box class="BalanceBox bg-white" style="padding: 24px">
    <div class="minh-116">
      <p class="fs-14 fw-500 c-grey6 mb-12">Current Balance</p>
      <p class="mb-24 d-flex justify-between align-end">
        <span v-if="currentBalance" class="fs-24 fw-bold c-black">
          <span>{{ currentBalanceIntText }}</span
          >.<span class="fs-12">{{ currentBalanceCentText }}</span>
        </span>
        <span v-else class="fs-24 fw-bold c-black">
          <span>$0</span>.<span class="fs-12">00</span>
        </span>

        <span
          class="BalanceBox__available-balance fs-14 fw-500 c-grey3"
          ref="availableBalanceEl"
          @mouseenter="openTooltip"
          @mouseleave="closeTooltip"
        >
          {{ availableBalanceText }}
        </span>
      </p>
      <Popup :opened="popupHelpOpened" :target="availableBalanceEl">
        <div class="fs-14 c-grey">Available Balance</div>
      </Popup>
      <ProgressBar :progress="progress" />
    </div>
    <Divider />
    <div class="d-flex justify-between"></div>
  </Box>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from 'vue'

import Box from '@/components/Box.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import Popup from '@/components/Popup.vue'
import Divider from '@/components/Divider.vue'

import { formatCurrency } from '@/lib/number'
import { useBool } from '@/lib/composable/useBool'

const props = defineProps<{
  currentBalance?: Maybe<number>
  availableBalance?: Maybe<number>
  creditLimit?: Maybe<number>
}>()

const currentBalanceIntText = computed(() => formatCurrency(props.currentBalance).split('.')[0])
const currentBalanceCentText = computed(() => formatCurrency(props.currentBalance).split('.')[1])
const availableBalanceText = computed(() => formatCurrency(props.availableBalance))
const progress = computed(() => {
  if (!props.currentBalance || !props.availableBalance) {
    return 0
  }
  return 100 * (props.currentBalance / props.availableBalance)
})

const availableBalanceEl: Ref<HTMLDivElement | null> = ref(null)
const { value: popupHelpOpened, truthyfy: openTooltip, falsefy: closeTooltip } = useBool(false)
</script>
