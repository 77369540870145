export interface Range<T> {
  from: T
  to: T
}

export const createRange = <T>(from: T, to: T): Range<T> => ({ from, to })

export const mapRangeValue = <T, R>(fn: (a: T) => R, range: Range<T>): Range<R> => ({
  from: fn(range.from),
  to: fn(range.to),
})
