import { computed, ComputedRef, reactive } from 'vue'
import { Range } from '@/lib/range'
import {
  TransactionListClearingEventFragment,
  TransactionListExportFinancialAccountFragment,
} from '@/generated/graphql'
import { now } from '@/lib/date'

interface ITransactionExportState {
  loading: boolean
  error: Maybe<Error>
  fetchedPages: TransactionListClearingEventFragment[]
  dataRange: Maybe<Range<Date>>
  popupOpened: boolean
  startedAt: Maybe<Date>
}

export interface ITransactionExportStore {
  loading: ComputedRef<boolean>
  error: ComputedRef<Maybe<Error>>
  itemList: ComputedRef<TransactionListClearingEventFragment[]>
  dataRangeFromLastExport: ComputedRef<Maybe<Range<Date>>>
  popupOpened: ComputedRef<boolean>
  startedAt: ComputedRef<Maybe<Date>>
  setPopupOpened(opened: Maybe<boolean>): void
  setLoading(loading: Maybe<boolean>): void
  setError(error: Maybe<Error>): void
  addPage(transactions: TransactionListExportFinancialAccountFragment): void
  clearPages(): void
  setStartedAt(): void
}

export const createTransactionExportStore = (): ITransactionExportStore => {
  const state = reactive<ITransactionExportState>({
    loading: false,
    error: null,
    fetchedPages: [],
    dataRange: null,
    popupOpened: false,
    startedAt: null,
  })

  const setError = (error: Maybe<Error>) => {
    state.error = error
  }

  const setLoading = (loading: boolean) => {
    state.loading = loading
  }

  const setPopupOpened = (opened: boolean) => {
    state.popupOpened = opened
  }

  const addPage = (page: TransactionListExportFinancialAccountFragment) => {
    const transactions = (page.transactionEvents?.edges || []).map((i) => i.node) || []
    state.fetchedPages = [...state.fetchedPages, ...transactions].filter(
      (item): item is TransactionListClearingEventFragment => item?.__typename === 'ClearingEvent'
    )
  }

  const clearPages = () => {
    state.fetchedPages = []
  }

  const setStartedAt = () => {
    state.startedAt = now()
  }

  return {
    loading: computed(() => state.loading),
    error: computed(() => state.error),
    itemList: computed(() => state.fetchedPages),
    dataRangeFromLastExport: computed(() => state.dataRange),
    popupOpened: computed(() => state.popupOpened),
    startedAt: computed(() => state.startedAt),
    setError: setError,
    setLoading: setLoading,
    setPopupOpened: setPopupOpened,
    addPage: addPage,
    clearPages: clearPages,
    setStartedAt,
  }
}
