import { tracker } from '@/api/segment/segment'
import { BaseError } from '@/lib/domain/error/BaseError'
import { inject, InjectionKey, provide } from 'vue'

export type Tracker = typeof tracker

export const TRACKER_INJECTION_KEY = Symbol('Traker key') as InjectionKey<Tracker>

export function provideTracker(): void {
  provide(TRACKER_INJECTION_KEY, tracker)
}

export function injectTracker(): Tracker {
  const injected = inject(TRACKER_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject Tracker')
  }
  return injected
}
