<template>
  <SubPageContent title="Company">
    <template v-slot:description>
      If you wish to update the following information about the company, contact us at
      <Link class="c-green2" href="mailto:card@tillful.com">card@tillful.com</Link>
    </template>

    <div class="SettingsCompany__info">
      <div class="fs-14 fw-600 c-black2 mb-16 SettingsCompany__info__label">
        Legal Business Name
      </div>
      <div class="fs-14 c-black2 mb-8 SettingsCompany__info__value">{{ info.legalName }}</div>

      <div class="fs-14 fw-600 c-black2 mb-16 SettingsCompany__info__label">DBA Name</div>
      <div class="fs-14 c-black2 mb-8 SettingsCompany__info__value">{{ info.doingAsName }}</div>

      <div class="fs-14 fw-600 c-black2 mb-16 SettingsCompany__info__label">Business Type</div>
      <div class="fs-14 c-black2 mb-8 SettingsCompany__info__value">{{ info.type }}</div>

      <div class="fs-14 fw-600 c-black2 mb-16 SettingsCompany__info__label">Phone Number</div>
      <div class="fs-14 c-black2 mb-16 SettingsCompany__info__value">
        <span v-if="info.phoneList.length === 0">-</span>
        <div v-for="item in info.phoneList" :key="item.label">
          <span class="fw-600 c-black2 d-inline-block" style="width: 50px; margin-right: 12px">{{
            item.label
          }}</span>
          <span>{{ item.number }}</span>
        </div>
      </div>

      <div class="fs-14 fw-600 c-black2 mb-16 SettingsCompany__info__label">Billing Address</div>
      <Address class="mb-16" :value="info.address" />

      <div class="fs-14 fw-600 c-black2 mb-72 SettingsCompany__info__label">Website</div>
      <div class="fs-14 c-black2 mb-72 SettingsCompany__info__value">
        <Link class="c-green2" :href="info.website || ''" target="_blank">{{ info.website }}</Link>
      </div>
    </div>

    <div v-if="ownersAvailable">
      <h2 class="fs-18 fw-600 c-black">Ultimate Beneficial Owners</h2>
      <Divider />

      <div v-for="item in info.owners" :key="item.email">
        <div
          class="d-flex justify-between align-center cursor-pointer"
          @click="handleToggleOwner(item.email)"
        >
          <h4 class="fs-16 fw-600 c-grey4">{{ item.name }}</h4>

          <Icon
            name="chevron-right"
            :style="{
              transform: !openedOwnerMap[item.email] ? 'none' : 'rotate(90deg)',
              transitionProperty: 'transform',
              transitionDuration: '0.3s',
              transitionTimingFunction: 'ease',
            }"
            class="c-grey3 fs-12"
          />
        </div>

        <Transition appear name="fade">
          <SettingsPersonalData v-if="openedOwnerMap[item.email]" :value="item" class="mt-16" />
        </Transition>

        <Divider />
      </div>
    </div>
  </SubPageContent>
</template>

<script setup lang="ts">
import { computed, ComputedRef, reactive } from 'vue'

import { useAccountHolder } from '@/store/accountHolder'

import Link from '@/components/Link.vue'
import SubPageContent from '@/components/SubPageContent.vue'
import Divider from '@/components/Divider.vue'
import Icon from '@/components/Icon.vue'

import { length } from '@/lib/list'
import { gt, not } from '@/lib/logic'
import SettingsPersonalData from './SettingsPersonalData.vue'
import Address from '../Address.vue'

const { businessInformation: info } = useAccountHolder()
const ownersAvailable: ComputedRef<boolean> = computed(() => gt(length(info.value.owners), 0))
const openedOwnerMap: Record<string, boolean> = reactive({})
const handleToggleOwner = (id: string) => {
  openedOwnerMap[id] = not(openedOwnerMap[id])
}
</script>

<style>
.SettingsCompany__info {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

@media all and (max-width: 768px) {
  .SettingsCompany__info {
    grid-template-columns: none;
  }
  .SettingsCompany__info__label {
    margin-bottom: 8px;
  }
  .SettingsCompany__info__value {
    margin-bottom: 24px;
  }
}
</style>
