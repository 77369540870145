<template>
  <Modal :opened="opened" @onClose="handleClose">
    <PaymentConfirmation
      v-if="state.paymentCompleted"
      :title="confirmationTitle"
      :account="account"
      :paymentResult="state.paymentResult"
      @onDone="handleClose"
    />

    <div v-else>
      <h2 class="fs-20 fw-bold c-black text-center mb-24">{{ title }}</h2>
      <div class="fs-14 fw-600 c-black1" style="margin: 0; padding: 0">
        <Message
          v-if="variant === 'deposit'"
          class="mb-12 fw-normal"
          variant="warning"
          :dismissable="false"
        >
          <span v-if="isInitialDeposit">
            You may deposit an amount between {{ allowedMinDepositText }} and
            {{ allowedMaxDepositText }}
          </span>
          <span v-else>
            Your current credit limit is {{ creditLimitText }}. You may deposit an amount between
            {{ allowedMinDepositText }} and {{ allowedMaxDepositText }}
          </span>
        </Message>

        <div class="mb-32">
          <div class="mb-16">1. Select the account</div>
          <PaymentAccountDropdown
            v-if="!accountListEmpty"
            :value="state.accountId"
            :accountList="accountList"
            @onChange="handleAccountSelect"
          />
          <div v-else style="font-weight: normal">
            There are no bank accounts connected.
            <LinkInternal class="c-green1" style="text-decoration: underline" to="/settings/bank"
              >Add a bank account</LinkInternal
            >
            to initiate a deposit.
          </div>
        </div>

        <div class="mb-32">
          <div class="mb-16">
            2. Enter the amount
            <span v-if="state.accountLoading" style="font-weight: normal"
              >(Fetching balance...)</span
            >
          </div>
          <FormCurrencyInput
            v-model="state.amount"
            @blur="handleCurrencyBlur"
            data-test-id="amount"
          />
          <div
            v-if="amountError"
            class="c-red"
            style="font-weight: normal"
            data-test-id="amount-error"
          >
            {{ amountError }}
          </div>
        </div>

        <div class="mb-32">
          <div class="mb-16">3. Agree to the terms</div>
          <FormCheckbox v-model="state.agree" :label="agreeLabel" data-test-id="agree" />
        </div>

        <Message v-if="state.paymentFailed" class="mb-40" variant="error" :dismissable="false">
          {{ state.paymentFailed }}
        </Message>

        <div class="mb-8">
          <Button
            id="submit-payment"
            data-test-id="submit"
            variant="primary"
            size="large"
            class="w-100"
            :disabled="buttonPayDisabled"
            :loading="buttonPayLoading"
            @click="handlePayClick"
            >{{ submitText }}</Button
          >
        </div>

        <div>
          <Button
            id="cancel-payment"
            variant="secondary"
            size="large"
            class="w-100"
            style="background-color: var(--white)"
            @click="handleClose"
            >Cancel</Button
          >
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ComputedRef, reactive } from 'vue'

import Button from '@/components/Button.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import FormCurrencyInput from '@/components/FormCurrencyInput.vue'
import LinkInternal from '@/components/LinkInternal.vue'
import Message from '@/components/Message.vue'
import Modal from '@/components/Modal.vue'
import PaymentConfirmation from '@/components/payment/PaymentConfirmation.vue'
import PaymentAccountDropdown from '@/components/payment/PaymentAccountDropdown.vue'

import { useBool } from '@/lib/composable/useBool'
import { formatDateMonthShortYearFull } from '@/lib/date'
import { getAccountHolderAccountNumber } from '@/lib/domain/accountHolder'
import { getDepositRange, validateDeposit, validatePaymentAmount } from '@/lib/domain/payment'
import { IPaymentAccount } from '@/lib/domain/types'
import { head, indexBy, isEmpty } from '@/lib/list'
import { formatCurrency } from '@/lib/number'
import { result } from '@/lib/result'
import { defaultToEmptyString } from '@/lib/string'
import { isNil } from '@/lib/type'
import { useAccountHolder } from '@/store/accountHolder'
import { ACCOUNT_CLOSED_MESSAGE, HN_ERROR_CODE, PAYMENT_FAILED_MESSAGE } from '@/constants'
import { eq } from '@/lib/logic'
import { createRange, Range } from '@/lib/range'
import { injectApi } from '@/provider/apiProvider'
import { injectTracker } from '@/provider/trackerProvider'

type Mode = 'payment' | 'deposit'

const $api = injectApi()
const $tracker = injectTracker()

interface IProps {
  opened?: boolean
  accountList: IPaymentAccount[]
  variant?: Mode
}

interface IEmits {
  (event: 'close'): void
  (event: 'completed', depositId: string, amount: number): void
  (event: 'failed'): void
}

interface ILocalState {
  accountId: Maybe<string>
  accountLoading: boolean
  balanceById: Record<string, IPaymentAccount>

  agree: boolean
  paymentCompleted: boolean
  amount: string
  amountTouched: boolean
  paymentResult: {
    id: string
    shortId: string
    amount: string
    date: string
  }
  paymentFailed: Maybe<string>
}

const props = withDefaults(defineProps<IProps>(), {
  variant: 'payment',
  accountList: () => [],
  opened: false,
})

const emit = defineEmits<IEmits>()

const { accountHolder, creditLimit } = useAccountHolder()
const { value: buttonPayLoading, truthyfy: setLoading, falsefy: resetLoading } = useBool(false)

const titleTextMap: Record<Mode, string> = {
  payment: 'New payment',
  deposit: 'Initiate Deposit',
}
const confirmationTitleTextMap: Record<Mode, string> = {
  payment: 'Thank you for your payment.',
  deposit: 'Thank you for initiating the deposit.',
}
const submitTextMap: Record<Mode, string> = {
  payment: 'Make new payment',
  deposit: 'Initiate Deposit',
}

/**
 * Hold temporary state for currently opened modal.
 */
const state = reactive<ILocalState>({
  accountId: null,
  accountLoading: false,
  balanceById: {},

  agree: false,
  paymentCompleted: false,
  amount: '',
  amountTouched: false,
  paymentResult: {
    id: '',
    shortId: '',
    amount: '',
    date: '',
  },
  paymentFailed: null,
})

const accountById = computed<Record<string, IPaymentAccount>>(() =>
  indexBy((item) => item.externalFinancialBankAccountId, props.accountList)
)
const accountListEmpty = computed<boolean>(() => isEmpty(props.accountList))
const account = computed<Maybe<IPaymentAccount>>(
  () => accountById.value[defaultToEmptyString(state.accountId)]
)

/**
 * Compute payment account with balance fetched by selected account ID
 */
const accountWithBalance = computed<Maybe<IPaymentAccount>>(() => {
  return state.accountId ? state.balanceById[state.accountId] : null
})

const title = computed(() => titleTextMap[props.variant])
const confirmationTitle = computed(() => confirmationTitleTextMap[props.variant])
const submitText = computed(() => submitTextMap[props.variant])
const accountMask = computed(() => account.value?.accountDetails.mask)
const amountNumber: ComputedRef<number> = computed(() => Number(state.amount))
const isInitialDeposit = computed<boolean>(() => eq(creditLimit.value, 0))
const region = computed<Maybe<string>>(() =>
  defaultToEmptyString(accountHolder.value?.businessProfile?.billingAddress?.region)
)
const depositValueRange = computed<Range<number>>(() =>
  getDepositRange(region.value, creditLimit.value)
)
const allowedDepositValueRange = computed<Range<number>>(() =>
  createRange(depositValueRange.value.from, depositValueRange.value.to - creditLimit.value)
)
const allowedMinDepositText = computed<string>(() => formatCurrency(depositValueRange.value.from))
const allowedMaxDepositText = computed<string>(() =>
  formatCurrency(allowedDepositValueRange.value.to)
)

/**
 * Compute text for current credit limit value.
 */
const creditLimitText = computed<string>(() => formatCurrency(creditLimit.value))

/**
 * Validate entered amount agains Plaid link available and current balances.
 * Compute error message for invalid amount entry.
 */
const amountError = computed<Maybe<string>>(() => {
  if (!state.amountTouched) {
    return null
  }
  const variantValidatorMap: Record<Mode, () => Result<boolean>> = {
    payment: () =>
      validatePaymentAmount({
        amount: amountNumber.value,
        account: accountWithBalance.value,
      }),
    deposit: () =>
      validateDeposit({
        amount: amountNumber.value,
        range: allowedDepositValueRange.value,
        account: accountWithBalance.value,
      }),
  }
  const validator = variantValidatorMap[props.variant]
  const validationResult = validator()
  if (validationResult.ok) {
    return null
  }
  return validationResult.error.message
})

/**
 * Send Sentry event if there are no available and current balances
 * in provided payment account
 */
function logBalanceAvailability(account: Maybe<IPaymentAccount>): void {
  const availableBalance = account?.accountDetails.availableBalance
  const currentBalance = account?.accountDetails.currentBalance
  if (isNil(availableBalance) && isNil(currentBalance)) {
    $tracker.trackPaymentAccountNoBalances({
      accountHolderId: account?.accountHolderId,
      last4: account?.accountDetails.mask,
      institutionId: account?.institution.institutionId,
      institutionName: account?.institution.name,
    })
  }
}

const amountValid = computed(() => state.amountTouched && !amountError.value)

/**
 * Compute user consent agreement text.
 */
const agreeLabel = computed(
  () =>
    `I authorize Tillful (owned by Tillco Holdings, LLC) to initiate a one-time electronic payment in the amount of ${formatCurrency(
      amountNumber.value
    )} from my ${account.value?.accountDetails.name || '(Not selected)'} account (ending ${
      accountMask.value || '(Not selected)'
    }). Any payment made after 3:00 pm ET will be submitted the following business day.`
)

/**
 * Compute disabled state for form submit button.
 * Disabled if payment account not selected, amount input is empty, amount input invalid,
 * agreement checkbox off, account balance fetching in progress.
 */
const buttonPayDisabled = computed(
  () =>
    isNil(account.value) ||
    !state.amount ||
    !amountValid.value ||
    !state.agree ||
    state.accountLoading
)

/**
 * Store touched state of the input for validation purposes.
 */
const handleCurrencyBlur = () => {
  state.amountTouched = true
}

/**
 * Store selected account ID to local state.
 * Fetch payment account with balance data by selected ID and store to local state.
 * Update loading state for payment account balance.
 * @param value - Selected payment account ID
 */
const handleAccountSelect = async (value: string) => {
  state.accountId = value
  state.accountLoading = true
  const accountResult = await $api.getPaymentAccount(value)
  state.accountLoading = false
  if (!accountResult.ok || isNil(accountResult.value.externalFinancialBankAccountId)) {
    return
  }
  state.balanceById[accountResult.value.externalFinancialBankAccountId] = accountResult.value
}

const handlePayClick = async () => {
  state.paymentFailed = null
  setLoading()
  let paymentResult = result.failed(new Error('Failed to initiate the payment'))
  switch (props.variant) {
    case 'deposit': {
      paymentResult = await makeInitialDeposit()
      break
    }
    case 'payment': {
      paymentResult = await makePayment()
      break
    }
  }
  resetLoading()

  trackPaymentResult(paymentResult)

  logBalanceAvailability(accountWithBalance.value)

  if (!paymentResult.ok) {
    state.paymentFailed = humanizeError(paymentResult.error)
    emit('failed')
    return
  }
  state.paymentCompleted = true
  state.paymentResult = {
    id: paymentResult.value.id,
    shortId: paymentResult.value.id.slice(0, 6),
    amount: formatCurrency(paymentResult.value.amount / 100),
    date: formatDateMonthShortYearFull(new Date(paymentResult.value.date)),
  }
  emit('completed', paymentResult.value.id, paymentResult.value.amount)
}

const humanizeError = (error: Error): string => {
  switch (error.message) {
    case HN_ERROR_CODE.SOURCE_ACCOUNT_CLOSED:
      return ACCOUNT_CLOSED_MESSAGE
    default:
      return PAYMENT_FAILED_MESSAGE
  }
}

const makeInitialDeposit = async () => {
  return await $api.initiateSecureDeposit({
    amount: Math.round(amountNumber.value * 100), // convert to cent
    personId: accountHolder.value?.primaryAuthorizedPerson?.id || '',
    firstName: accountHolder.value?.primaryAuthorizedPerson?.name?.givenName || '',
    lastName: accountHolder.value?.primaryAuthorizedPerson?.name?.familyName || '',
    identificationNumber: getAccountHolderAccountNumber(accountHolder.value),
    fromAccountId: account.value?.externalFinancialBankAccountId || '',
    toAccountId: head(accountHolder.value?.financialAccounts?.edges || [])?.node?.id || '',
  })
}

const makePayment = async () => {
  return await $api.initiateOneTimeTransfer({
    amount: Math.round(amountNumber.value * 100), // convert to cent
    firstName: accountHolder.value?.primaryAuthorizedPerson?.name?.givenName || '',
    lastName: accountHolder.value?.primaryAuthorizedPerson?.name?.familyName || '',
    identificationNumber: getAccountHolderAccountNumber(accountHolder.value),
    fromAccountId: account.value?.externalFinancialBankAccountId || '',
    toAccountId: head(accountHolder.value?.financialAccounts?.edges || [])?.node?.id || '',
    personId: accountHolder.value?.primaryAuthorizedPerson?.id || '',
  })
}

const handleClose = () => emit('close')

const trackPaymentResult = (res: Result<unknown>) => {
  if (!res.ok) {
    return
  }
  switch (props.variant) {
    case 'deposit': {
      $tracker.trackDepositInitiated({
        last4: accountMask.value,
        amount: amountNumber.value,
      })
      break
    }
    case 'payment': {
      $tracker.trackPaymentInitiated({
        last4: accountMask.value,
        amount: amountNumber.value,
      })
      break
    }
    default:
      break
  }
}
</script>

<style scoped>
.PaymentModal__account {
  border: solid 1px #e5e7eb;
  border-radius: 8px;
}
.PaymentModal__account-active {
  border: solid 1px #74ca47;
  background-color: #f3faef;
}
.PaymentModal__account__icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px #e5e7eb;
}
.FormCurrencyInput {
  height: auto;
  padding: 16px;
  color: var(--c-grey1);
  font-size: 16px;
  font-weight: 500;
}
</style>
