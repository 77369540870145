import { computed, ComputedRef, ref } from '@vue/reactivity'
import { onMounted, onUnmounted } from '@vue/runtime-core'

export const SMALL_SCREEN_BREAKPOINT = 768

export const useScreenWidth = () => {
  const width = ref(window.document.body.offsetWidth)
  const listener: EventListener = () => {
    width.value = window.document.body.offsetWidth
  }

  onMounted(() => {
    window.addEventListener('resize', listener)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', listener)
  })

  return width
}

export const useMobileScreen = () => {
  const width = useScreenWidth()
  const result: ComputedRef<boolean> = computed(() => width.value <= SMALL_SCREEN_BREAKPOINT)
  return result
}
