<script setup lang="ts">
import { computed } from '@vue/reactivity'

import { IPaymentCardView } from '@/lib/domain/types'
import PhysicalActive from '@/assets/physical-card-active.svg'
import PhysicalInactive from '@/assets/physical-card-inactive.svg'
import { PaymentCardStatus } from '../generated/graphql'

interface IProps {
  card: IPaymentCardView
}
const props = defineProps<IProps>()
const cardImg = computed(() =>
  props.card.status === PaymentCardStatus.Active ? PhysicalActive : PhysicalInactive
)
const statusCssClass = computed(() => ({
  active: props.card.status === PaymentCardStatus.Active,
  inactive: props.card.status === PaymentCardStatus.ActivationRequired,
  closed: props.card.status === PaymentCardStatus.Closed,
  suspended: props.card.status === PaymentCardStatus.Suspended,
}))
</script>

<template>
  <div class="PaymentCard__mock fs-14 fw-500" :class="statusCssClass">
    <img :src="cardImg" class="w-100" />
    <p class="PaymentCard__number">•••• {{ card.last4 }}</p>
  </div>
</template>

<style>
.PaymentCard__mock {
  color: var(--c-white);
  position: relative;
  width: 165px;
  height: 109px;
  border-radius: 6px;
}
.PaymentCard__number {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
</style>
