import { sentryLogger } from './logger'
import { result } from './result'

/**
 * Create not attached anchor element with download attribute.
 * Dispatches click event programatically.
 * @param content - BlobPart type, often just a string
 * @param options - required attributes of downloaded file
 * @example
 *
    download(csv.value, {
      name: 'transactions',
      ext: 'csv',
      type: 'text/csv',
    })
 */
export const download = (content: BlobPart, options: IDownloadOptions): Result<undefined> => {
  try {
    const name = `${options.name}.${options.ext}`
    const blob = new Blob([content], { type: options.type })
    const data = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = data
    link.download = name
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    )
    link.remove()
    return result.ok(undefined)
  } catch (e) {
    return result.failed(e as Error)
  }
}

interface IDownloadOptions {
  name: string
  ext: 'csv' | 'txt'
  type: 'text/csv' | 'text'
}
