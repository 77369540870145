import { computed } from 'vue'

import { usePaymentCards } from '@/service/usePaymentCards'
import { useAccountHolder } from '@/store/accountHolder'
import { injectApi } from '@/provider/apiProvider'
import { injectLockCardModalStore } from '@/provider/lockCardModalProvider'

export const useLockCardModal = () => {
  const $store = injectLockCardModalStore()
  const $api = injectApi()
  const { store } = usePaymentCards()
  const { id: accountHolderId } = useAccountHolder()

  const handleLock = async () => {
    $store.setFailed(false)
    $store.setLoading(true)

    const res = await $api.lockPaymentCard(accountHolderId.value, $store.state.value.card)
    $store.setLoading(false)
    if (!res.ok) {
      $store.setFailed(true)
    } else {
      store.loadCardList()
      $store.close()
    }
  }

  return {
    opened: computed(() => $store.state.value.opened),
    loading: computed(() => $store.state.value.loading),
    failed: computed(() => $store.state.value.failed),
    cardLast4: computed(() => $store.state.value.card?.last4),
    open: $store.open,
    close: $store.close,
    lock: handleLock,
  }
}
