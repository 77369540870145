<template>
  <Modal :opened="opened" @onClose="$emit('close')">
    <div class="d-flex flex-direction-column">
      <h2 class="mb-24 text-center fs-20 fw-bold c-black">Create Card</h2>
      <Message v-if="state.failed" class="mb-24" variant="error" :dismissable="false">
        Failed to create card. Please try again. If the problem persists, contact support.
      </Message>
      <p class="mb-24 c-grey2 fs-14" style="line-height: 1.43">
        Are you sure you want to create a new virtual card? You can create up to
        {{ cardsLeft }} more virtual cards.
      </p>
      <Button
        v-if="createAllowed"
        class="mb-20"
        variant="primary"
        size="large"
        @click="handleCreate"
        :loading="state.loading"
        >Create Card</Button
      >
      <Button
        variant="secondary"
        :loading="false"
        size="large"
        @click="$emit('close')"
        style="background-color: white"
        >Cancel</Button
      >
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, reactive } from 'vue'

import Modal from '@/components/Modal.vue'
import Message from '@/components/Message.vue'
import Button from '@/components/Button.vue'
import { result } from '@/lib/result'
import { useAccountHolder } from '@/store/accountHolder'
import { captureException } from '@/api/sentry/sentry'
import { gt } from '@/lib/logic'
import { mapApiPaymentCard } from '@/lib/domain/paymentCard'
import { IPaymentCard } from '@/lib/domain/types'
import { usePaymentCards } from '@/service/usePaymentCards'
import { injectApi } from '@/provider/apiProvider'

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'complete', res: Result<IPaymentCard>): void
}>()

const props = withDefaults(
  defineProps<{
    opened?: boolean
    cardsLeft?: number
  }>(),
  {
    opened: false,
    cardsLeft: 0,
  }
)

const $api = injectApi()

const { financialAccountId } = useAccountHolder()

const {
  store: { loadNonClosedCardList },
} = usePaymentCards()
const state = reactive({ failed: false, loading: false })
const createAllowed = computed<boolean>(() => gt(props.cardsLeft, 0))

const handleCreate = async () => {
  state.failed = false
  state.loading = true
  const issueResult = await $api.issueVirtualPaymentCard(financialAccountId.value)
  if (!issueResult.ok) {
    state.failed = true
    state.loading = false
    emit('complete', result.failed(issueResult.error))
    captureException(issueResult.error)
    return
  }
  await loadNonClosedCardList()
  emit('complete', result.ok(mapApiPaymentCard(issueResult.value)))
  state.loading = false
}
</script>
