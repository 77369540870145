import { BaseError } from '@/lib/domain/error/BaseError'
import { Logger, consoleLogger, sentryLogger } from '@/lib/logger'
import { InjectionKey, inject, provide } from 'vue'

export type LoggerProvider = {
  console: Logger
  sentry: Logger
}

export const LOGGER_INJECTION_KEY = Symbol('logger') as InjectionKey<LoggerProvider>

export function createLoggerProvider(): LoggerProvider {
  return {
    console: consoleLogger,
    sentry: sentryLogger,
  }
}

export function provideLogger(): void {
  provide(LOGGER_INJECTION_KEY, createLoggerProvider())
}

export type LoggerName = 'console' | 'sentry'

export function injectLogger(name: LoggerName = 'console'): Logger {
  const injected = inject(LOGGER_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Logger not provided')
  }
  return injected[name]
}
