<template>
  <div class="Tag padh-12 padv-4 bg-grey3 fs-12 fw-500 c-black1 d-inline-block">
    <slot />
  </div>
</template>

<style scoped>
.Tag {
  border-radius: 999px;
}
</style>
