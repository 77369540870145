<script setup lang="ts">
import 'vue-select/dist/vue-select.css'
import '@/styles/global.css'
import '@/styles/font.css'
import '@/styles/transition.css'
import AuthorizationProvider from '@/provider/AuthorizationProvider.vue'
import InitialDataProvider from '@/provider/InitialDataProvider.vue'
import StoreProvider from '@/provider/StoreProvider.vue'
import MessageProvider from '@/provider/MessageProvider.vue'
import FeatureFlagProvider from '@/provider/FeatureFlagProvider.vue'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { provideStorage } from '@/provider/storageProvider'
import { provideEnv } from '@/provider/envProvider'
import MaintenancePage from '@/pages/MaintenancePage.vue'
import { provideLogger } from '@/provider/loggerProvider'
import AuthRedirectHandler from '@/provider/AuthRedirectHandler.vue'
import AuthFunctionsProvider from '@/provider/AuthFunctionsProvider.vue'
import { provideDom } from '@/provider/domProvider'
import { provideCardViewer } from '@/provider/cardViewerProvider'
import { provideApi } from '@/provider/apiProvider'
import { provideTracker } from '@/provider/trackerProvider'
import { providePlaid } from '@/provider/plaidProvider'
import { provideLockCardModalStore } from '@/provider/lockCardModalProvider'
import { provideAccountHolderStore } from '@/provider/accountHolderStoreProvider'
import { provideDomProperties } from '@/provider/domPropertiesProvider'
import { provideLaunchdarklySDK } from '@/provider/launchDarklyProvider'
import { provideFeatureFlagStore } from '@/provider/featureFlagStoreProvider'
import MessageMaintenanceFullscreen from '@/components/MessageMaintenanceFullscreen.vue'

useMountedLog('App')

provideStorage()
provideEnv()
provideLogger()
provideDom()
provideCardViewer()
provideApi()
provideTracker()
providePlaid()
provideLockCardModalStore()
provideAccountHolderStore()
provideDomProperties()
provideLaunchdarklySDK()
provideFeatureFlagStore()
</script>

<template>
  <MaintenancePage>
    <StoreProvider>
      <AuthorizationProvider>
        <AuthFunctionsProvider>
          <FeatureFlagProvider>
            <AuthRedirectHandler>
              <InitialDataProvider>
                <MessageMaintenanceFullscreen>
                  <router-view />
                  <MessageProvider />
                </MessageMaintenanceFullscreen>
              </InitialDataProvider>
            </AuthRedirectHandler>
          </FeatureFlagProvider>
        </AuthFunctionsProvider>
      </AuthorizationProvider>
    </StoreProvider>
  </MaintenancePage>
</template>
