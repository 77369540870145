<template>
  <div class="CardFilterMobile">
    <Button
      variant="secondary"
      bordered
      class="CardFilterMobile__button d-flex justify-between align-center w-100"
      @click="handleOpenClick"
    >
      <span class="fs-12 fw-500 mr-8">Filter</span>
      <div class="d-flex align-center">
        <Badge class="mr-8 bg-green4">{{ filterCount }}</Badge>
        <Icon class="fs-8" name="chevron-down" />
      </div>
    </Button>

    <Modal v-if="popupOpened" :opened="popupOpened" @onClose="closePopup">
      <div class="pad-16" style="min-width: 220px">
        <h3 class="fs-20 fw-bold mb-16 text-center">Filter Cards</h3>
        <form @submit.prevent="handleFormSubmit">
          <FormCheckboxList :value="checkboxListValue" @onChange="handleCheckboxListChange" />

          <Divider />
          <div class="d-flex flex-direction-column">
            <Button class="CardFilterMobil__submit w-100 mb-16" type="submit" variant="primary"
              >Apply</Button
            >
            <Button
              class="CardFilterMobile__cancel discard-button c-green1 w-100"
              type="button"
              @click="handleFormClear"
              >Reset</Button
            >
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Badge from '@/components/Badge.vue'
import Divider from '@/components/Divider.vue'
import Modal from '@/components/Modal.vue'

import { useBool } from '@/lib/composable/useBool'
import { filter, length } from '@/lib/list'
import { IFormCheckboxListValueItem, PaymentCardFilterValue } from '@/lib/domain/types'
import { createPaymentCardFilterValue } from '@/lib/domain/paymentCard'
import { PaymentCardStatus } from '@/generated/graphql'
import FormCheckboxList from '../FormCheckboxList.vue'

const props = defineProps<{ value?: Maybe<PaymentCardFilterValue> }>()
const safeValue = computed<PaymentCardFilterValue>(
  () => props.value ?? createPaymentCardFilterValue()
)

const emit = defineEmits<{
  (event: 'onChange', value: PaymentCardFilterValue): void
  (event: 'onSubmit', value: PaymentCardFilterValue): void
  (event: 'onReset'): void
}>()

const { value: popupOpened, truthyfy: openPopup, falsefy: closePopup } = useBool()
const handleOpenClick = () => (popupOpened.value ? closePopup() : openPopup())

const valueList = computed<boolean[]>(() => Object.entries(safeValue.value).map(([, val]) => val))
const cardStatusAll = computed<boolean>(() => valueList.value.every(Boolean))
const filterCount = computed<number>(() => length(filter(Boolean, valueList.value)))

const checkboxListValue = computed<IFormCheckboxListValueItem[]>(() => {
  return [
    {
      key: 'all',
      value: cardStatusAll.value,
      label: 'All',
    },
    {
      key: PaymentCardStatus.Active,
      value: safeValue.value.ACTIVE,
      label: 'Active',
    },
    {
      key: PaymentCardStatus.Closed,
      value: safeValue.value.CLOSED,
      label: 'Closed',
    },
    {
      key: PaymentCardStatus.Suspended,
      value: safeValue.value.SUSPENDED,
      label: 'Locked',
    },
  ]
})

const handleCheckboxListChange = (event: IFormCheckboxListValueItem) => {
  handleCardStatusChange(event.key as PaymentCardStatus | 'all', event.value)
}

const handleCardStatusChange = (key: PaymentCardStatus | 'all', value: boolean) => {
  if (key === 'all') {
    handleCardStatusAllChange(value)
  } else {
    emit('onChange', Object.assign({}, safeValue.value, { [key]: value }))
  }
}

const handleFormSubmit = () => {
  emit('onSubmit', safeValue.value)
  closePopup()
}

const handleFormClear = () => emit('onReset')

const handleCardStatusAllChange = (value: boolean) => {
  if (!value) {
    emit('onChange', createPaymentCardFilterValue())
  } else {
    emit('onChange', {
      [PaymentCardStatus.Active]: true,
      [PaymentCardStatus.Suspended]: true,
      [PaymentCardStatus.Closed]: true,
    })
  }
}
</script>

<style>
.FormCheckboxList .FormCheckbox {
  margin-bottom: 32px;
}
.CardFilterMobile__cancel {
  text-decoration: underline;
}
</style>
