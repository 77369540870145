<template>
  <Box class="bg-white d-flex flex-direction-column" style="padding: 24px">
    <div class="d-flex justify-between minh-116">
      <div class="d-flex flex-direction-column justify-between" style="padding-bottom: 7px">
        <p class="fs-14 fw-500 c-grey6">Main Card</p>
        <div v-if="activationRequired" class="bg-grey2">
          <Button variant="primary" size="medium" :loading="false" @click="$emit('activate')"
            >Activate Card</Button
          >
        </div>
      </div>
      <PaymentCardMock :card="card" />
    </div>
    <Divider />
    <div class="d-flex justify-between align-center" style="height: 18px">
      <div v-if="(count ?? 0) > 0">
        <span class="fs-14 fw-bold c-grey1">{{ count }}</span>
        <span class="fs-12 fw-500 c-grey1">{{ cardListText }}</span>
      </div>
      <div v-else class="fs-12 fw-500 c-grey1">No Active Virtual Cards</div>
      <LinkInternal :name="RouteName.Cards" class="c-green2 fs-12 fw-500 white-space-no-wrap">
        View All Cards <Icon class="fs-10" name="chevron-right" />
      </LinkInternal>
    </div>
  </Box>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from '@vue/reactivity'

import Box from '@/components/Box.vue'
import PaymentCardMock from '@/components/PaymentCardMock.vue'
import Divider from '@/components/Divider.vue'
import LinkInternal from '@/components/LinkInternal.vue'
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import { RouteName } from '@/router/route'

import { pluralize } from '@/lib/string'
import { PaymentCardStatus } from '@/generated/graphql'
import { isPaymentCardActivationRequired } from '@/lib/domain/paymentCard'
import { IPaymentCard, IPaymentCardView } from '@/lib/domain/types'
import { defaultTo0 } from '@/lib/number'

defineEmits(['activate'])
const props = defineProps<{
  card?: Maybe<IPaymentCard>
  count?: Maybe<number>
}>()

// payment cards info
const card: ComputedRef<IPaymentCardView> = computed(() => {
  if (!props.card) {
    return { last4: '0000', status: PaymentCardStatus.Closed, date: '', cvv: '', id: '' }
  }
  return { last4: props.card.last4, status: props.card.status, date: '', cvv: '', id: '' }
})
const cardListText: ComputedRef<string> = computed(
  () => ` Active Virtual ${pluralize('Card', defaultTo0(props.count))}`
)

// activate card
const activationRequired = computed(() => isPaymentCardActivationRequired(props.card))
</script>
