<template>
  <Message v-if="successShown" variant="success" class="mb-24" @onClose="handleMessageClose">
    An email has been sent to {{ userEmail }} with instructions to reset your password.
  </Message>
  <Message v-if="error" variant="error" class="mb-24" @onClose="handleMessageClose">
    There was an error attempting to reset your password. Please try again later.
  </Message>
  <SubPageContent title="Security">
    <template v-slot:description>
      In order to reset your password, click the button below and receive instructions on email.
    </template>
    <Button :disabled="pending" variant="primary" @click="handleResetClick">Reset Password</Button>
  </SubPageContent>
</template>

<script setup lang="ts">
import { Ref, ref } from '@vue/reactivity'
import Button from '../Button.vue'
import Message from '../Message.vue'
import { useAuthStore } from '../../store/auth'
import SubPageContent from '../SubPageContent.vue'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'

const successShown = ref(false)
const pending = ref(false)
const error: Ref<Error | null> = ref(null)

const { userEmail } = useAuthStore()
const $auth = injectAuthFunctions()

const handleResetClick = async () => {
  successShown.value = false
  error.value = null

  pending.value = true
  const result = await $auth.changePassword(userEmail.value)
  if (result.ok) {
    successShown.value = true
  } else {
    error.value = result.error
  }
  pending.value = false
}

const handleMessageClose = () => {
  successShown.value = false
  error.value = null
}
</script>
