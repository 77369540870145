import { capitalize } from 'vue'
import { EmptyListError } from './domain/error/EmptyListError'
import { defaultToEmptyList, isEmpty } from './list'
import { eq } from './logic'
import { result } from './result'

/**
 * Make plural form of input word
 * @param word Text
 * @param count Number of "words"
 * @returns Input text with added "s" at the end if needed
 */
export const pluralize = (word: string, count: number): string =>
  eq(count, 1) ? word : [word, 's'].join('')

/**
 * Capitalize first letter of provided input string
 * @param word Text
 * @returns Input string with capitalized first letter
 */
export const capitalizeWord = capitalize

export const replace = (char: string | RegExp, withChar: string, input: string) =>
  input.replace(char, withChar)

export const toPascalCase = (input: string) =>
  input
    .trim()
    .split(' ')
    .map((word) => capitalizeWord(word.trim().toLowerCase()))
    .join(' ')

export const defaultToEmptyString = <T>(input?: Maybe<T>) => input ?? ''

export type CSVSerializeable = Record<string, string>

export const toCSV = (value?: Maybe<CSVSerializeable[]>): Result<string> => {
  const validated = defaultToEmptyList(value)
  if (isEmpty(validated)) {
    throw new EmptyListError('Failed to serialize CSV. Empty list provided')
  }
  try {
    const headers: string[] = Object.keys(validated[0])
    const headersRow: string = headers.map(wrapDoubleQuotes).join(',')
    const valueRowList: string[][] = validated.map((item) => headers.map((key) => item[key]))
    const valueRowMappedList: string[] = valueRowList.map((valueList) =>
      valueList.map(wrapDoubleQuotes).join(',')
    )
    const resultString = [headersRow].concat(valueRowMappedList).join('\n')
    return result.ok(resultString)
  } catch (error) {
    return result.failed(error as Error)
  }
}

export const wrapDoubleQuotes = (value: string) => `"${value}"`
