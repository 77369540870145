import {
  AccountHolder,
  AccountHolderCardProductApplication,
  AccountHolderQuery,
  AccountHolderQueryVariables,
  ActivatePaymentCard,
  ActivatePaymentCardMutation,
  ActivatePaymentCardMutationVariables,
  CardProductApplicationDocuments,
  CardProductApplicationDocumentsQuery,
  CardProductApplicationDocumentsQueryVariables,
  LockPaymentCard,
  LockPaymentCardMutation,
  LockPaymentCardMutationVariables,
  TransactionEventFilter,
  TransactionList,
  TransactionListFinancialAccountFragment,
  TransactionListQuery,
  TransactionListQueryVariables,
  UsBusinessAccountHolder,
  StartDocumentUploadMutationVariables,
  StartDocumentUpload,
  CreateDocumentUploadLinkMutationVariables,
  DocumentType,
  CreateDocumentUploadLinkMutation,
  CreateDocumentUploadLink,
  EndDocumentUploadMutationVariables,
  EndDocumentUploadMutation,
  EndDocumentUpload,
  StartDocumentUploadMutation,
  ScheduledTransferStatusCode,
  InitiateSecureDeposit,
  InitiateSecureDepositMutationVariables,
  InitiateSecureDepositMutation,
  Iso4217Alpha3SupportedCurrency,
  InitiateOneTimeTransfer,
  InitiateOneTimeTransferMutation,
  InitiateOneTimeTransferMutationVariables,
  PaymentCardListQueryVariables,
  PaymentCardListQuery,
  PaymentCardList,
  PaymentCardListItemFragment,
  PaymentList,
  PaymentListQuery,
  PaymentListQueryVariables,
  AchTransactionEdgeNode,
  IssueVirtualPaymentCardMutationVariables,
  IssueVirtualPaymentCardMutation,
  IssueVirtualPaymentCard,
  OneTimeAchTransfer,
  InitiateRecurringTransferMutationVariables,
  InitiateRecurringTransferMutation,
  InitiateRecurringTransfer,
  RecurringAchTransferFrequencyCode,
  RecurringAchTransfer,
  TransferBalanceAmountCode,
  SecureDepositAchTransfer,
  LastDeposit,
  LastDepositQuery,
  LastDepositQueryVariables,
  FinancialAccountStatementItemFragment,
  StatementListQueryVariables,
  StatementListQuery,
  StatementList,
  DepositListQueryVariables,
  DepositListQuery,
  DepositList,
  StatementByPeriodQueryVariables,
  StatementByPeriodQuery,
  StatementByPeriod,
  FinancialAccountStatementDetailFragment,
  CancelScheduledTransferMutationVariables,
  CancelScheduledTransferMutation,
  CancelScheduledTransfer,
  PaymentCardStatus,
  ViewPaymentCardQuery,
  ViewPaymentCardQueryVariables,
  ViewPaymentCard,
  PaymentCard,
  TransactionListExportFinancialAccountFragment,
  TransactionListExportQueryVariables,
  TransactionListExport,
} from '@/generated/graphql'
import {
  SavePaymentAccount,
  SavePaymentAccountMutation,
  SavePaymentAccountMutationVariables,
  SaveInitialDepositId,
  SaveInitialDepositIdMutation,
  SaveInitialDepositIdMutationVariables,
  AppMetadata,
  GetAppMetadata,
  GetAppMetadataQuery,
  GetAppMetadataQueryVariables,
  GeneratePlaidLinkToken,
  GeneratePlaidLinkTokenMutation,
  GeneratePlaidLinkTokenMutationVariables,
  GetPaymentAccountListQueryVariables,
  GetPaymentAccountList,
  GetPaymentAccountListQuery,
  PaymentAccountItemFragment,
  ClosePaymentAccountMutationVariables,
  ClosePaymentAccountMutation,
  ClosePaymentAccount,
  GetPaymentAccountQueryVariables,
  GetPaymentAccountQuery,
  GetPaymentAccount,
} from '@/generated/graphqlAppSync'
import { result } from '@/lib/result'
import { apiQuery, appSyncApiQuery } from '@/api/graphql/graphqlClient'
import { graphqlType } from '@/api/graphql/graphqlType'
import { Range, mapRangeValue } from '@/lib/range'
import { consoleLogger, sentryLogger } from '@/lib/logger'
import {
  addDays,
  formatDateIsoString,
  formatDateTimeIsoString,
  now,
  addYears,
  today,
  todayDayOfMonth,
} from '@/lib/date'
import { getAuthFunctions } from '@/api/auth/authClient'
import { defaultToEmptyList, filter, head, map, find } from '@/lib/list'
import { ENV } from '@/lib/env'
import { defaultToEmptyString } from '@/lib/string'
import { captureException } from '@/api/sentry/sentry'
import { eq, gte } from '@/lib/logic'
import { notNil } from '@/lib/type'
import { mapAccountHolderToFinancialAccount } from '@/lib/domain/accountHolder'
import {
  generateAccountHolderToken,
  generateApplicationDocumentClientToken,
  generateInitialDepositToken,
  generateManageScheduledTransferToken,
  generateIssuePaymentCardToken,
  generateOneTimePaymentToken,
  generatePaymentCardManageToken,
} from '@/api/graphql/clientToken'
import { getApiToken } from '@/store/clientToken'
import { TOKEN_STORE_KEYS } from '@/lib/domain/token'
import { HN_ERROR_CODE } from '@/constants'
import { IPaginatedList, IPaginatedResponse } from '@/api/graphql/types'
import { IPaymentAccount } from '@/lib/domain/types'
import { mapPaymentAccountToDomain } from '@/lib/domain/paymentAccount'
import { isSecuredDepositStatementItem } from '@/lib/domain/statement'
import { ApiError } from '@/lib/domain/error/ApiError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { AppSyncApiError } from '@/lib/domain/error/AppSyncApiError'

const getAccountHolderToken = (accountHolderId: Maybe<string>) =>
  getApiToken(TOKEN_STORE_KEYS.ACCOUNT_HOLDER, () => generateAccountHolderToken(accountHolderId))

const getPublicAccountHolderToken = (accountHolderId: Maybe<string>) =>
  getApiToken(TOKEN_STORE_KEYS.ACCOUNT_HOLDER, () =>
    generateAccountHolderToken(accountHolderId, false)
  )

const getPaymentCardManageToken = (accountHolderId: Maybe<string>, cardId: Maybe<string>) =>
  getApiToken(TOKEN_STORE_KEYS.PAYMENT_CARD, () =>
    generatePaymentCardManageToken(accountHolderId, cardId)
  )

const getApplicationDocumentClientToken = (sessionId: Maybe<string>) =>
  generateApplicationDocumentClientToken(sessionId)

const getOneTimePaymentToken = (
  fromFinancialAccountId: Maybe<string>,
  toFinancialAccountId: Maybe<string>
) => generateOneTimePaymentToken(fromFinancialAccountId, toFinancialAccountId)

const getInitialDepositToken = (
  fromFinancialAccountId: Maybe<string>,
  toFinancialAccountId: Maybe<string>
) => generateInitialDepositToken(fromFinancialAccountId, toFinancialAccountId)

interface IQueryCardListOptions {
  accountHolderId: Maybe<string>
  virtualCardAfterCursor: Maybe<string>
  virtualCardStatusFilter: PaymentCardStatus[]
}
export const queryCardList = async (
  options: IQueryCardListOptions
): Promise<
  Result<
    IPaginatedResponse<{
      physical: PaymentCardListItemFragment[]
      nonClosed: PaymentCardListItemFragment[]
      virtual: PaymentCardListItemFragment[]
    }>
  >
> => {
  try {
    if (!options.accountHolderId) {
      return result.failed(
        new Error('[TC] Account holder ID was not provided while quering card list')
      )
    }
    const tokenResult = await getAccountHolderToken(options.accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: PaymentCardListQueryVariables = {
      accountHolderId: options.accountHolderId,
      virtualCardAfterCursor: options.virtualCardAfterCursor,
      virtualCardStatusFilter: options.virtualCardStatusFilter,
    }
    const queryResult = await apiQuery<PaymentCardListQuery>({
      query: PaymentCardList,
      variables,
      token,
    })
    if (queryResult.node?.__typename !== 'USBusinessAccountHolder') {
      return result.failed(new Error('Unknown type'))
    }
    return result.ok({
      data: {
        physical: defaultToEmptyList(queryResult.node.paymentCardPhysicalList?.edges)
          .map((edge) => edge.node)
          .filter(notNil),
        nonClosed: defaultToEmptyList(queryResult.node.paymentCardNonClosedList?.edges)
          .map((edge) => edge.node)
          .filter(notNil),
        virtual: defaultToEmptyList(queryResult.node.paymentCardVirtualList?.edges)
          .map((edge) => edge.node)
          .filter(notNil),
      },
      pageInfo: queryResult.node.paymentCardVirtualList?.pageInfo,
    })
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(new Error('[TC] Failed to query card list'))
  }
}

export const lockPaymentCard = async (
  accountHolderId: Maybe<string>,
  card?: { id: string }
): Promise<Result> => {
  try {
    if (!card?.id || !accountHolderId) {
      return result.failed(new Error('[TC] No card provided to lock'))
    }

    const tokenResult = await getPaymentCardManageToken(accountHolderId, card.id)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: LockPaymentCardMutationVariables = {
      input: { paymentCardId: card.id },
    }
    const response = await apiQuery<LockPaymentCardMutation>({
      query: LockPaymentCard,
      variables,
      token,
    })

    if (graphqlType.isAccessDeniedError(response.suspendPaymentCard)) {
      return result.failed(new Error(`[TC] Failed to lock card. Access denied.`))
    } else if (graphqlType.isUserError(response.suspendPaymentCard)) {
      return result.failed(new Error(`[TC] Failed to lock card. User error.`))
    } else if (graphqlType.isPaymentCard(response.suspendPaymentCard)) {
      return result.ok(undefined)
    }

    return result.failed(new Error('[TC] Failed to lock card. Unhandled Type.'))
  } catch (e) {
    return result.failed(new Error('[TC] Failed to lock card.'))
  }
}

export const activatePaymentCard = async (
  accountHolderId: Maybe<string>,
  id: string
): Promise<Result<undefined>> => {
  try {
    if (!accountHolderId) {
      return result.failed(new Error('[TC] Failed to activate card.'))
    }
    const tokenResult = await getPaymentCardManageToken(accountHolderId, id)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: ActivatePaymentCardMutationVariables = {
      input: { paymentCardId: id },
    }
    const response = await apiQuery<ActivatePaymentCardMutation>({
      query: ActivatePaymentCard,
      variables,
      token,
    })

    switch (response.activatePaymentCard?.__typename) {
      case 'UserError':
        return result.failed(new Error('[TC] Failed to activate card. User error.'))
      case 'AccessDeniedError':
        return result.failed(new Error('[TC] Failed to activate card. Access denied.'))
      case 'PaymentCard':
        return result.ok(undefined)
      default:
        return result.failed(new Error('[TC] Failed to activate card. Unhandled type.'))
    }
  } catch (e) {
    return result.failed(e as any)
  }
}

export const getCardDetails = async (
  paymentCardtoken: string,
  id: string
): Promise<Result<PaymentCard>> => {
  try {
    const variables: ViewPaymentCardQueryVariables = {
      id: id,
    }
    const response = await apiQuery<ViewPaymentCardQuery>({
      query: ViewPaymentCard,
      variables,
      token: paymentCardtoken,
    })

    if (graphqlType.isPaymentCard(response.node)) {
      return result.ok(response.node)
    }

    return result.failed(new Error('[TC] Failed to load card details. Unexpected type.'))
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const getAccountHolder = async (
  id: Maybe<string>
): Promise<Result<UsBusinessAccountHolder>> => {
  if (!id) {
    return result.failed(new Error('[TC] Failed to load account holder. ID not provided.'))
  }
  try {
    const tokenResult = await getAccountHolderToken(id)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: AccountHolderQueryVariables = { accountHolderId: id }
    const response = await apiQuery<AccountHolderQuery>({ query: AccountHolder, variables, token })
    if (graphqlType.isUsBusinessAccountHolder(response.node)) {
      return result.ok(response.node)
    }
    return result.failed(new Error('[TC] Failed to load account holder. Unexpected type.'))
  } catch (e) {
    return result.failed(e as Error)
  }
}

interface IGetTransactionListOptions {
  accountHolderId: string
  pendingCursor: Maybe<string>
  pendingHasNext: boolean
  clearedCursor: Maybe<string>
  clearedHasNext: boolean
  filter: {
    paymentCardId: Maybe<string>
    date: Range<string>
  }
  signal?: AbortSignal
}
export const getTransactionList = async (
  options: IGetTransactionListOptions
): Promise<Result<TransactionListFinancialAccountFragment>> => {
  const { accountHolderId, pendingCursor, clearedCursor, filter, signal } = options
  const dateFilter = { createdAt: { between: { start: filter.date.from, end: filter.date.to } } }
  try {
    const tokenResult = await getAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: TransactionListQueryVariables = {
      accountHolderId,
      pendingHasNext: options.pendingHasNext,
      pendingAfterCursor: pendingCursor,
      pendingFilterBy: {
        paymentCardId: {
          equals: filter.paymentCardId,
        },
        onlyOpenAuthorizations: { equals: true },
        ...dateFilter,
      },
      clearedHasNext: options.clearedHasNext,
      clearedAfterCursor: clearedCursor,
      clearedFilterBy: {
        paymentCardId: {
          equals: filter.paymentCardId,
        },
        eventType: { equals: TransactionEventFilter.ClearingEvent },
        ...dateFilter,
      },
    }
    const response = await apiQuery<TransactionListQuery>({
      query: TransactionList,
      variables,
      token,
      signal,
    })
    if (!graphqlType.isUsBusinessAccountHolder(response.node)) {
      return result.failed(
        new Error('[TC] Failed to load transaction event list. Unexpected type.')
      )
    }
    const financialAccount: Maybe<TransactionListFinancialAccountFragment> = head(
      response.node.financialAccounts?.edges || []
    )?.node as TransactionListFinancialAccountFragment
    if (!financialAccount || !graphqlType.isFinancialAccount(financialAccount)) {
      return result.failed(
        new Error('[TC] Failed to load transaction event list. Unexpected type.')
      )
    }
    return result.ok(financialAccount)
  } catch (e) {
    return result.failed(e as Error)
  }
}

interface IGetTransactionListExportOptions {
  accountHolderId: string
  cursor: Maybe<string>
  hasNext: boolean
  filter: {
    paymentCardId: Maybe<string>
    date: Range<string>
  }
  signal?: AbortSignal
}
export const getTransactionListExport = async (
  options: IGetTransactionListExportOptions
): Promise<Result<TransactionListExportFinancialAccountFragment>> => {
  const { accountHolderId, cursor, hasNext, filter, signal } = options
  const dateFilter = { createdAt: { between: { start: filter.date.from, end: filter.date.to } } }
  try {
    const tokenResult = await getAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: TransactionListExportQueryVariables = {
      accountHolderId,
      hasNext: hasNext,
      afterCursor: cursor,
      filterBy: {
        paymentCardId: {
          equals: filter.paymentCardId,
        },
        eventType: {
          includes: [TransactionEventFilter.ClearingEvent],
        },
        ...dateFilter,
      },
    }
    const response = await apiQuery<TransactionListQuery>({
      query: TransactionListExport,
      variables,
      token,
      signal,
    })
    if (!graphqlType.isUsBusinessAccountHolder(response.node)) {
      throw new Error('[TC] Failed to load transaction list. Unexpected type.')
    }
    const financialAccount: Maybe<TransactionListExportFinancialAccountFragment> = head(
      response.node.financialAccounts?.edges || []
    )?.node as TransactionListExportFinancialAccountFragment
    if (!financialAccount || !graphqlType.isFinancialAccount(financialAccount)) {
      throw new Error('[TC] Failed to load transaction event list. Unexpected type.')
    }
    return result.ok(financialAccount)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const getCardProductApplication = async (
  accountHolderId: Maybe<string>
): Promise<Result<Maybe<AccountHolderCardProductApplication>>> => {
  if (!accountHolderId) {
    return result.failed(
      new Error('[TC] Failed to load card product application. ID was not provided.')
    )
  }
  try {
    const tokenResult = await getPublicAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: CardProductApplicationDocumentsQueryVariables = { id: accountHolderId }
    const response = await apiQuery<CardProductApplicationDocumentsQuery>({
      query: CardProductApplicationDocuments,
      variables,
      token,
    })
    if (!graphqlType.isUsBusinessAccountHolder(response.node)) {
      return result.failed(
        new Error('[TC] Failed to load card product application. Unexpected type.')
      )
    }
    const cardApplication = head(response.node.cardProductApplications?.edges || [])?.node
    return result.ok(cardApplication)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const startApplicationDocumentUploadSession = async (sessionId: string): Promise<Result> => {
  const clientTokenResult = await getApplicationDocumentClientToken(sessionId)
  if (!clientTokenResult.ok) {
    return clientTokenResult
  }

  try {
    const variables: StartDocumentUploadMutationVariables = {
      input: { documentUploadSessionId: sessionId },
    }
    await apiQuery<StartDocumentUploadMutation>({
      query: StartDocumentUpload,
      variables,
      token: clientTokenResult.value.value,
    })
    return result.ok(undefined)
  } catch (e) {
    consoleLogger.error(
      new Error('[startApplicationDocumentUploadSession] Failed to start upload session')
    )
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const uploadApplicationDocument = async (
  sessionId: string,
  documentType: DocumentType,
  file: File
): Promise<Result> => {
  try {
    const uploadUrlResult = await createApplicationDocumentUploadUrl(sessionId, documentType)
    if (!uploadUrlResult.ok) {
      return uploadUrlResult
    }
    await fetch(uploadUrlResult.value, { method: 'PUT', body: file })
    return result.ok(undefined)
  } catch (e) {
    consoleLogger.error(
      new Error('[uploadApplicationDocument] Failed to upload application document')
    )
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const createApplicationDocumentUploadUrl = async (
  sessionId: string,
  documentType: DocumentType
): Promise<Result<string>> => {
  try {
    const clientTokenResult = await getApplicationDocumentClientToken(sessionId)
    if (!clientTokenResult.ok) {
      return clientTokenResult
    }
    const linkVariables: CreateDocumentUploadLinkMutationVariables = {
      input: { documentType, documentUploadSessionId: sessionId },
    }
    const linkResponse = await apiQuery<CreateDocumentUploadLinkMutation>({
      query: CreateDocumentUploadLink,
      variables: linkVariables,
      token: clientTokenResult.value.value,
    })
    if (!graphqlType.isDocumentUploadLink(linkResponse.createDocumentUploadLink)) {
      return result.failed(new Error('[uploadDocument] Unknown response type for upload link'))
    }
    if (!linkResponse.createDocumentUploadLink.uploadUrl) {
      return result.failed(new Error('[uploadDocument] Invalid upload url responded'))
    }
    return result.ok(linkResponse.createDocumentUploadLink.uploadUrl)
  } catch (e) {
    consoleLogger.error(
      new Error('[createApplicationDocumentUploadUrl] Failed to create upload url')
    )
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const endApplicationDocumentUploadSession = async (sessionId: string): Promise<Result> => {
  const clientTokenResult = await getApplicationDocumentClientToken(sessionId)
  if (!clientTokenResult.ok) {
    return clientTokenResult
  }

  try {
    const endVariables: EndDocumentUploadMutationVariables = {
      input: { documentUploadSessionId: sessionId },
    }
    await apiQuery<EndDocumentUploadMutation>({
      query: EndDocumentUpload,
      variables: endVariables,
      token: clientTokenResult.value.value,
    })
    return result.ok(undefined)
  } catch (e) {
    consoleLogger.error(
      new Error('[endApplicationDocumentUploadSession] Failed to end upload session')
    )
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const generatePlaidLinkToken = async (
  userId: string,
  redirectUrl: string
): Promise<Result<string>> => {
  try {
    const variables: GeneratePlaidLinkTokenMutationVariables = {
      input: {
        userId,
        redirectURI: redirectUrl,
      },
    }
    const response = await appSyncApiQuery<GeneratePlaidLinkTokenMutation>({
      query: GeneratePlaidLinkToken,
      variables,
    })
    if (!graphqlType.isAppSyncPlaidLinkToken(response.generatePlaidLinkToken)) {
      throw new ApiError('[generatePlaidLinkToken] Unknown response type for link token', {
        vendor: ErrorVendor.TillCard,
        payload: response,
      })
    }
    return result.ok(response.generatePlaidLinkToken.linkToken)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export interface ISaveInstitutionLinkOptions {
  accountHolderId: string
  accountId: string
  institutionId: string
  publicToken: string
}
type SaveInstitutionLinkResult = Result<string, ApiError | AppSyncApiError>
export const saveInstitutionLink = async (
  options: ISaveInstitutionLinkOptions
): Promise<SaveInstitutionLinkResult> => {
  try {
    if (
      !options.accountId ||
      !options.accountHolderId ||
      !options.publicToken ||
      !options.institutionId
    ) {
      return result.failed(
        new ApiError('Failed to save financial institution link. Options invalid.', {
          payload: options,
          vendor: ErrorVendor.TillCard,
        })
      )
    }
    const variables: SavePaymentAccountMutationVariables = {
      input: {
        accountHolderId: options.accountHolderId,
        accountId: options.accountId,
        institutionId: options.institutionId,
        publicToken: options.publicToken,
      },
    }
    const response = await appSyncApiQuery<SavePaymentAccountMutation>({
      query: SavePaymentAccount,
      variables,
    })
    if (!graphqlType.isAppSyncPaymentAccount(response.savePaymentAccount)) {
      return result.failed(
        new ApiError('Failed to save institution link. Unknown type', {
          payload: {
            variables,
            respondWith: response.savePaymentAccount,
          },
          vendor: ErrorVendor.TillCard,
        })
      )
    }
    const id: Maybe<string> = response.savePaymentAccount.externalFinancialBankAccountId
    if (!id) {
      return result.failed(
        new ApiError('Failed to save institution link. External bank account id not provided.', {
          payload: {
            variables,
            respondWith: response.savePaymentAccount,
          },
          vendor: ErrorVendor.TillCard,
        })
      )
    }
    return result.ok(id)
  } catch (e) {
    consoleLogger.error('Failed to save institution link.')
    return result.failed(e as ApiError | AppSyncApiError)
  }
}

export const getPaymentAccountList = async (
  accountHolderId: Maybe<string>
): Promise<Result<IPaymentAccount[]>> => {
  try {
    if (!accountHolderId) {
      throw new Error('Failed to get payment accounts. Account holder ID not provided.')
    }
    const variables: GetPaymentAccountListQueryVariables = { accountHolderId }
    const response = await appSyncApiQuery<GetPaymentAccountListQuery>({
      query: GetPaymentAccountList,
      variables,
    })
    if (!graphqlType.isAppSyncPaymentAccounts(response.getPaymentAccounts)) {
      throw new Error('Failed to get payment accounts. Unknown type')
    }
    const list = defaultToEmptyList(response.getPaymentAccounts.accounts)
      .filter(graphqlType.isAppSyncPaymentAccount)
      .filter(notNil) as PaymentAccountItemFragment[]
    const mapped = map(mapPaymentAccountToDomain, list)
    return result.ok(mapped)
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const getPaymentAccount = async (id: Maybe<string>): Promise<Result<IPaymentAccount>> => {
  try {
    if (!id) {
      throw new ApiError('Failed to get payment account. ID not provided.', {
        vendor: ErrorVendor.TillCard,
      })
    }
    const variables: GetPaymentAccountQueryVariables = { id }
    const response = await appSyncApiQuery<GetPaymentAccountQuery>({
      query: GetPaymentAccount,
      variables,
    })
    if (!graphqlType.isAppSyncPaymentAccountWithBalance(response.getPaymentAccount)) {
      throw new ApiError('Failed to get payment account. Unknown type.', {
        vendor: ErrorVendor.TillCard,
        payload: {
          paymentAccountId: id,
          response,
        },
      })
    }
    return result.ok(mapPaymentAccountToDomain(response.getPaymentAccount))
  } catch (e) {
    consoleLogger.error(e)
    if (e instanceof ApiError) {
      sentryLogger.error(e)
    } else {
      sentryLogger.error(
        new ApiError('Failed to get payment account by ID', {
          originalError: e as Error,
          vendor: ErrorVendor.TillCard,
          payload: {
            paymentAccountId: id,
          },
        })
      )
    }
    return result.failed(e as Error)
  }
}

interface IInitiateSecureDepositOptions {
  amount: number // in cents
  personId: string
  firstName: string
  lastName: string
  identificationNumber: string
  fromAccountId: string
  toAccountId: string
}
interface IInitiateOneTimePaymentOptions extends IInitiateSecureDepositOptions {
  personId: string
}
interface IInitiateSecureDepositResult {
  amount: number
  date: string
  id: string
}
export const initiateSecureDeposit = async (
  options: IInitiateSecureDepositOptions
): Promise<Result<IInitiateSecureDepositResult>> => {
  try {
    const variables: InitiateSecureDepositMutationVariables = {
      input: {
        amount: {
          value: options.amount,
          currencyCode: Iso4217Alpha3SupportedCurrency.Usd,
        },
        descriptor: {
          companyEntryDescription: 'TillfulDep',
          individualIdentificationNumber: options.identificationNumber.slice(0, 15),
          individualName: [options.firstName, options.lastName].join(' ').slice(0, 15),
        },
        transferAgreementConsent: {
          authorizedPersonId: options.personId,
          consentTimestamp: now().toISOString(),
          template: {
            consentTemplateId: ENV.TC_TRANSFER_CONSENT_TEMPLATE_ID,
            consentTemplateVersion: ENV.TC_TRANSFER_CONSENT_TEMPLATE_VERSION,
          },
        },
        fromFinancialAccountId: options.fromAccountId,
        toFinancialAccountId: options.toAccountId,
        cancellationPeriodMillis: 0,
      },
    }

    const tokenResult = await getInitialDepositToken(options.fromAccountId, options.toAccountId)
    if (!tokenResult.ok) {
      return tokenResult
    }

    const response = await apiQuery<InitiateSecureDepositMutation>({
      query: InitiateSecureDeposit,
      variables,
      token: tokenResult.value.value,
    })
    if (graphqlType.isUserError(response.initiateSecureDepositACHTransfer)) {
      const errors = defaultToEmptyList(response.initiateSecureDepositACHTransfer.errors)
      const closedAccountError = errors.find((e) => eq(e.code, HN_ERROR_CODE.SOURCE_ACCOUNT_CLOSED))
      if (closedAccountError) {
        return result.failed(new Error(HN_ERROR_CODE.SOURCE_ACCOUNT_CLOSED))
      }
      return result.failed(new Error('Failed to initiate deposit. User error.'))
    }
    if (graphqlType.isAccessDeniedError(response.initiateSecureDepositACHTransfer)) {
      return result.failed(new Error('Failed to initiate deposit. Access Denied.'))
    }
    return result.ok({
      id: response.initiateSecureDepositACHTransfer?.id || '',
      amount: response.initiateSecureDepositACHTransfer?.amount?.value || 0,
      date: response.initiateSecureDepositACHTransfer?.createdAt || now().toISOString(),
    })
  } catch (e) {
    consoleLogger.error('Failed to initiate deposit.')
    return result.failed(e as Error)
  }
}

export const getAppMetadata = async (): Promise<Result<AppMetadata>> => {
  const auth = getAuthFunctions()
  if (!auth.ok) {
    return auth
  }
  const userResult = await auth.value.getUser()
  if (!userResult.ok) {
    consoleLogger.error('[getUserInfo] Failed to get current user')
    return userResult
  }
  try {
    const variables: GetAppMetadataQueryVariables = { id: userResult.value.sub }
    const response = await appSyncApiQuery<GetAppMetadataQuery>({
      query: GetAppMetadata,
      variables,
    })
    if (response.getAppMetadata?.__typename !== 'AppMetadata') {
      return result.failed(new Error('Failed to load app metadata. Unknown type.'))
    }
    return result.ok(response.getAppMetadata)
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const saveInitialDepositId = async (
  depositId: Maybe<string>,
  userId: Maybe<string>
): Promise<Result<string>> => {
  if (!depositId || !userId) {
    return result.failed(new Error('Failed to save deposit ID. Not all params provided.'))
  }
  try {
    const variables: SaveInitialDepositIdMutationVariables = {
      input: { userId, depositId },
    }
    const response = await appSyncApiQuery<SaveInitialDepositIdMutation>({
      query: SaveInitialDepositId,
      variables,
    })
    if (!graphqlType.isAppSyncSaveDepositId(response.saveInitialDepositId)) {
      return result.failed(new Error('[saveInitialDepositId] Unknown response type.'))
    }
    return result.ok(response.saveInitialDepositId.depositId)
  } catch (e) {
    return result.failed(e as Error)
  }
}

export const initiateOneTimeTransfer = async (
  options: IInitiateOneTimePaymentOptions
): Promise<Result<IInitiateSecureDepositResult>> => {
  try {
    const variables: InitiateOneTimeTransferMutationVariables = {
      input: {
        transferAmountStrategy: {
          transferAmount: {
            value: options.amount,
            currencyCode: Iso4217Alpha3SupportedCurrency.Usd,
          },
        },
        transferAgreementConsent: {
          authorizedPersonId: options.personId,
          consentTimestamp: formatDateTimeIsoString(now()),
          template: {
            consentTemplateId: ENV.TC_TRANSFER_CONSENT_TEMPLATE_ID,
            consentTemplateVersion: ENV.TC_TRANSFER_CONSENT_TEMPLATE_VERSION,
          },
        },
        transferDate: formatDateIsoString(now()),
        descriptor: {
          companyEntryDescription: 'TillfulPmt',
          individualIdentificationNumber: options.identificationNumber.slice(0, 15),
          individualName: [options.firstName, options.lastName].join(' ').slice(0, 15),
        },
        fromFinancialAccountId: options.fromAccountId,
        toFinancialAccountId: options.toAccountId,
      },
    }
    const tokenResult = await getOneTimePaymentToken(options.fromAccountId, options.toAccountId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const response = await apiQuery<InitiateOneTimeTransferMutation>({
      query: InitiateOneTimeTransfer,
      variables,
      token: tokenResult.value.value,
    })
    if (response.createOneTimeACHTransfer?.__typename === 'UserError') {
      const errors = defaultToEmptyList(response.createOneTimeACHTransfer.errors)
      const closedAccountError = errors.find((e) =>
        eq(e.code, HN_ERROR_CODE.SOURCE_ACCOUNT_NOT_ACTIVE)
      )
      if (closedAccountError) {
        return result.failed(new Error(HN_ERROR_CODE.SOURCE_ACCOUNT_NOT_ACTIVE))
      }
      return result.failed(new Error('Failed to initiate one time payment. User error.'))
    }
    if (response.createOneTimeACHTransfer?.__typename === 'AccessDeniedError') {
      return result.failed(new Error('Failed to initiate one time payment. Access Denied.'))
    }
    const transferAmount = response.createOneTimeACHTransfer?.transferAmount
    return result.ok({
      id: response.createOneTimeACHTransfer?.id || '',
      amount:
        transferAmount?.__typename === 'ManualTransferAmount' ? transferAmount.amount?.value : 0,
      date: response.createOneTimeACHTransfer?.createdAt || now().toISOString(),
    })
  } catch (e) {
    consoleLogger.error('Failed to initiate deposit.')
    return result.failed(e as Error)
  }
}

interface IInitiateRecurringTransferOptions {
  personId: string
  firstName: string
  lastName: string
  identificationNumber: string
  fromAccountId: string
  toAccountId: string
  dayOfMonth: number
}
interface IInitiateRecurringTransferResult {
  id: string
  date: string
  nextDate: Maybe<string>
}
export const initiateRecurringTransfer = async (
  options: IInitiateRecurringTransferOptions
): Promise<Result<IInitiateRecurringTransferResult>> => {
  try {
    const tokenResult = await getOneTimePaymentToken(options.fromAccountId, options.toAccountId)
    if (!tokenResult.ok) {
      return tokenResult
    }

    if (gte(options.dayOfMonth, todayDayOfMonth())) {
      await initiateOneTimeStatementTransfer(options, tokenResult.value.value)
    }

    const variables: InitiateRecurringTransferMutationVariables = {
      input: {
        transferAmountStrategy: {
          balanceAmountType: TransferBalanceAmountCode.OutstandingStatementBalance,
        },
        transferAgreementConsent: {
          authorizedPersonId: options.personId,
          consentTimestamp: formatDateTimeIsoString(now()),
          template: {
            consentTemplateId: ENV.TC_AUTOPAY_CONSENT_TEMPLATE_ID,
            consentTemplateVersion: ENV.TC_AUTOPAY_CONSENT_TEMPLATE_VERSION,
          },
        },
        transferDayOfMonth: options.dayOfMonth,
        frequency: RecurringAchTransferFrequencyCode.Monthly,

        descriptor: {
          companyEntryDescription: 'TillfulPmt',
          individualIdentificationNumber: options.identificationNumber.slice(0, 15),
          individualName: [options.firstName, options.lastName].join(' ').slice(0, 15),
        },
        fromFinancialAccountId: options.fromAccountId,
        toFinancialAccountId: options.toAccountId,
      },
    }
    const response = await apiQuery<InitiateRecurringTransferMutation>({
      query: InitiateRecurringTransfer,
      variables,
      token: tokenResult.value.value,
    })
    if (graphqlType.isUserError(response.createRecurringACHTransfer)) {
      const errorMesage = [
        'Failed to initiate recurring payment.',
        'User error:',
        defaultToEmptyList(response.createRecurringACHTransfer.errors)
          .map((e) => e.description)
          .filter(notNil)
          .join(', '),
      ].join(' ')
      return result.failed(new Error(errorMesage))
    }
    if (graphqlType.isAccessDeniedError(response.createRecurringACHTransfer)) {
      const errorMessage = [
        'Failed to initiate recurring payment. Access Denied: ',
        response.createRecurringACHTransfer.message,
      ].join('')
      return result.failed(new Error(errorMessage))
    }
    return result.ok({
      id: defaultToEmptyString(response.createRecurringACHTransfer?.id),
      date: response.createRecurringACHTransfer?.createdAt ?? now().toISOString(),
      nextDate: response.createRecurringACHTransfer?.nextScheduledTransferDate,
    })
  } catch (e) {
    captureException(e)
    consoleLogger.error('Failed to initiate recurring payment.')
    return result.failed(e as Error)
  }
}

export const initiateOneTimeStatementTransfer = async (
  options: IInitiateRecurringTransferOptions,
  token: string
): Promise<Result<boolean>> => {
  try {
    const nextTransferDate: Date = new Date(now().setUTCDate(options.dayOfMonth))
    const variables: InitiateOneTimeTransferMutationVariables = {
      input: {
        transferAmountStrategy: {
          balanceAmountType: TransferBalanceAmountCode.OutstandingStatementBalance,
        },
        transferAgreementConsent: {
          authorizedPersonId: options.personId,
          consentTimestamp: formatDateTimeIsoString(now()),
          template: {
            consentTemplateId: ENV.TC_AUTOPAY_CONSENT_TEMPLATE_ID,
            consentTemplateVersion: ENV.TC_AUTOPAY_CONSENT_TEMPLATE_VERSION,
          },
        },
        transferDate: formatDateIsoString(nextTransferDate),
        descriptor: {
          companyEntryDescription: 'TillfulPmt',
          individualIdentificationNumber: options.identificationNumber.slice(0, 15),
          individualName: [options.firstName, options.lastName].join(' ').slice(0, 15),
        },
        fromFinancialAccountId: options.fromAccountId,
        toFinancialAccountId: options.toAccountId,
      },
    }
    await apiQuery<InitiateOneTimeTransferMutation>({
      query: InitiateOneTimeTransfer,
      variables,
      token,
    })
    return result.ok(true)
  } catch (e) {
    captureException(e)
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

interface IGetPaymentListFilters {
  date?: Range<Date>
  posted: {
    cursor?: Maybe<string>
    count?: number
  }
  scheduled: {
    cursor?: Maybe<string>
    count?: number
  }
}
export const getPaymentList = async (
  accountHolderId: Maybe<string>,
  filters: IGetPaymentListFilters
): Promise<
  Result<{
    posted: AchTransactionEdgeNode[]
    scheduled: OneTimeAchTransfer[]
    autopayTransfer: Maybe<RecurringAchTransfer>
    pagination: {
      postedCursor: Maybe<string>
      postedHasNext: boolean
      scheduledCursor: Maybe<string>
      scheduledHasNext: boolean
    }
  }>
> => {
  if (!accountHolderId) {
    return result.failed(
      new Error('[Graphql API] [getPaymentList] Account holder ID not provided.')
    )
  }
  try {
    const dateFilter: Maybe<Range<Maybe<string>>> = filters.date
      ? mapRangeValue(formatDateIsoString, {
          from: filters.date.from,
          to: addDays(1, filters.date.to),
        })
      : null
    const filterBy = dateFilter
      ? {
          settlementDate: {
            between: {
              start: dateFilter.from,
              end: dateFilter.to,
            },
          },
        }
      : null
    const variables: PaymentListQueryVariables = {
      id: accountHolderId,
      scheduledItemsNumber: filters.scheduled.count,
      scheduledAfterCursor: filters.scheduled.cursor,
      postedItemsNumber: filters.posted.count,
      postedAfterCursor: filters.posted.cursor,
      postedFilterBy: filterBy,
    }
    const tokenResult = await getAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const response = await apiQuery<PaymentListQuery>({
      query: PaymentList,
      variables,
      token,
    })
    if (!graphqlType.isUsBusinessAccountHolder(response.node)) {
      return result.failed(
        new Error('[Graphql API] [getPaymentList] Failed to load. Unknown type.')
      )
    }
    const financialAccount = head(response.node.financialAccounts?.edges || [])?.node
    if (!financialAccount) {
      return result.failed(
        new Error('[Graphql API] [getPaymentList] Failed to load. No financial account loaded.')
      )
    }
    const scheduledList = filter(
      (node: Maybe<OneTimeAchTransfer | RecurringAchTransfer>) =>
        (node?.__typename === 'OneTimeACHTransfer' ||
          node?.__typename === 'RecurringACHTransfer') &&
        (node.transferEvents?.edges?.length || 0) === 0,
      map((item) => item.node, financialAccount.incomingScheduledTransfers?.edges || [])
    )
    // TODO have to be updated if we add filters for payment list as necessary payments can be out of list
    const autopayTransfer = find(
      (node: Maybe<RecurringAchTransfer>) =>
        node?.__typename === 'RecurringACHTransfer' &&
        node?.status !== ScheduledTransferStatusCode.Canceled,
      map((item) => item.node, financialAccount.incomingScheduledTransfers?.edges || [])
    )
    const list = map(
      (item) => item.node,
      financialAccount.integratorInitiatedAchTransfers?.edges || []
    )
    const pageInfo = financialAccount.integratorInitiatedAchTransfers?.pageInfo
    const scheduledPageInfo = financialAccount.incomingScheduledTransfers?.pageInfo
    return result.ok({
      posted: list as AchTransactionEdgeNode[],
      scheduled: scheduledList as OneTimeAchTransfer[],
      autopayTransfer: autopayTransfer,
      pagination: {
        postedCursor: pageInfo?.endCursor,
        postedHasNext: Boolean(pageInfo?.hasNextPage),
        scheduledCursor: scheduledPageInfo?.endCursor,
        scheduledHasNext: Boolean(scheduledPageInfo?.hasNextPage),
      },
    })
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const getDepositList = async (
  accountHolderId: Maybe<string>
): Promise<Result<SecureDepositAchTransfer[]>> => {
  try {
    if (!accountHolderId) {
      return result.failed(new Error('[getDepositList] Account holder ID not provided.'))
    }
    const tokenResult = await getAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: DepositListQueryVariables = { id: accountHolderId }
    const response = await apiQuery<DepositListQuery>({ query: DepositList, variables, token })
    if (!graphqlType.isUsBusinessAccountHolder(response.node)) {
      return result.failed(new Error('Unexpected type.'))
    }
    const financialAccount = mapAccountHolderToFinancialAccount(response.node)
    const transferList = defaultToEmptyList(
      financialAccount?.integratorInitiatedAchTransfers?.edges
    )
      .map((edge) => edge.node)
      .filter(graphqlType.isSecureDepositTransfer)
    return result.ok(transferList)
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const getLastDeposit = async (
  accountHolderId: Maybe<string>
): Promise<Result<SecureDepositAchTransfer>> => {
  try {
    if (!accountHolderId) {
      return result.failed(new Error('[getLastDeposit] Account holder ID not provided.'))
    }
    const tokenResult = await getAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: LastDepositQueryVariables = { id: accountHolderId }
    const response = await apiQuery<LastDepositQuery>({
      query: LastDeposit,
      variables,
      token,
    })
    if (!graphqlType.isUsBusinessAccountHolder(response.node)) {
      return result.failed(new Error('Unexpected type.'))
    }
    const financialAccount = head(response.node.financialAccounts?.edges || [])?.node
    const transfer = head(financialAccount?.integratorInitiatedAchTransfers?.edges || [])?.node
    if (!graphqlType.isSecureDepositTransfer(transfer)) {
      return result.failed(new Error('Unexpected type.'))
    }
    return result.ok(transfer)
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const issueVirtualPaymentCard = async (
  financialAccountId: Maybe<string>
): Promise<Result<PaymentCardListItemFragment>> => {
  if (!financialAccountId) {
    return result.failed(new Error('Financial account not provided'))
  }
  try {
    const variables: IssueVirtualPaymentCardMutationVariables = {
      input: {
        financialAccountId,
        options: {
          activateOnCreate: true,
          expirationDate: formatDateTimeIsoString(
            addYears(ENV.TC_VIRTUAL_CARD_EXPIRATION_YEARS, today())
          ),
        },
      },
    }
    const tokenResult = await generateIssuePaymentCardToken(financialAccountId)
    if (!tokenResult.ok) {
      return result.failed(new Error('Failed to generate one time token to issue payment card'))
    }
    const response = await apiQuery<IssueVirtualPaymentCardMutation>({
      query: IssueVirtualPaymentCard,
      variables,
      token: tokenResult.value.value,
    })
    if (graphqlType.isAccessDeniedError(response.issuePaymentCardForFinancialAccount)) {
      return result.failed(new Error('[issueVirtualPaymentCard] Access denied.'))
    }
    if (graphqlType.isUserError(response.issuePaymentCardForFinancialAccount)) {
      return result.failed(new Error('[issueVirtualPaymentCard] User error.'))
    }
    if (!graphqlType.isPaymentCard(response.issuePaymentCardForFinancialAccount)) {
      return result.failed(new Error('[issueVirtualPaymentCard] Unknown type.'))
    }
    return result.ok(response.issuePaymentCardForFinancialAccount)
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

interface IGetStatementListOptions {
  accountHolderId?: Maybe<string>
  pageCursor?: Maybe<string>
}
export const getStatementList = async (
  options: IGetStatementListOptions = {}
): Promise<Result<IPaginatedList<FinancialAccountStatementItemFragment>>> => {
  try {
    const { accountHolderId, pageCursor } = options
    if (!accountHolderId) {
      return result.failed(new Error('[getStatementList] Account holder ID not provided.'))
    }
    const tokenResult = await getAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: StatementListQueryVariables = {
      id: accountHolderId,
      first: 15,
      pageCursor,
    }
    const response = await apiQuery<StatementListQuery>({
      query: StatementList,
      variables,
      token,
    })
    if (response.node?.__typename !== 'USBusinessAccountHolder') {
      return result.failed(new Error('Unexpected type.'))
    }
    const financialAccount = head(defaultToEmptyList(response.node.financialAccounts?.edges))?.node
    const page = financialAccount?.statements
    const list = map((e) => e.node, defaultToEmptyList(page?.edges))
      .filter(notNil)
      .filter(isSecuredDepositStatementItem) as FinancialAccountStatementItemFragment[]

    return result.ok({ data: list, pageInfo: page?.pageInfo })
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

interface IGetStatementByPeriodOptions {
  accountHolderId?: Maybe<string>
  start?: string
  end?: string
  entryCursor?: Maybe<string>
}
export const getStatementByPeriod = async (
  options: IGetStatementByPeriodOptions = {}
): Promise<Result<Maybe<FinancialAccountStatementDetailFragment>>> => {
  try {
    const { accountHolderId, start, end } = options
    if (!accountHolderId || !start || !end) {
      return result.failed(new Error('[getStatementByPeriod] Required parameters not provided.'))
    }
    const tokenResult = await getAccountHolderToken(accountHolderId)
    if (!tokenResult.ok) {
      return tokenResult
    }
    const token = tokenResult.value
    const variables: StatementByPeriodQueryVariables = {
      id: accountHolderId,
      first: 1,
      start,
      end,
      entryCursor: options.entryCursor,
    }
    const response = await apiQuery<StatementByPeriodQuery>({
      query: StatementByPeriod,
      variables,
      token,
    })
    if (response.node?.__typename !== 'USBusinessAccountHolder') {
      return result.failed(new Error('Unexpected type.'))
    }
    const financialAccount = head(defaultToEmptyList(response.node.financialAccounts?.edges))?.node
    const list = map((e) => e.node, defaultToEmptyList(financialAccount?.statements?.edges)).filter(
      notNil
    )
    const item = head(list)
    if (item?.__typename !== 'SecuredDepositCommercialCreditCardFinancialAccountStatement') {
      return result.failed(new Error('Statement type not supported.'))
    }
    return result.ok(item)
  } catch (e) {
    consoleLogger.error(e)
    return result.failed(e as Error)
  }
}

export const cancelRecurringTransfer = async (
  transferId: Maybe<string>
): Promise<Result<boolean>> => {
  try {
    if (!transferId) {
      return result.failed(new Error('[cancelRecurringTransfer] Transfer ID not provided.'))
    }
    const tokenResult = await generateManageScheduledTransferToken(transferId)
    if (!tokenResult.ok) {
      return tokenResult
    }

    const variables: CancelScheduledTransferMutationVariables = {
      input: { scheduledTransferId: transferId },
    }
    const response = await apiQuery<CancelScheduledTransferMutation>({
      query: CancelScheduledTransfer,
      variables,
      token: tokenResult.value.value,
    })
    if (graphqlType.isUserError(response.cancelScheduledTransfer)) {
      const errorMesage = [
        'Failed to cancel recurring payment.',
        'User error:',
        defaultToEmptyList(response.cancelScheduledTransfer.errors)
          .map((e) => e.description)
          .filter(notNil)
          .join(', '),
      ].join(' ')
      return result.failed(new Error(errorMesage))
    }
    if (graphqlType.isAccessDeniedError(response.cancelScheduledTransfer)) {
      return result.failed(
        new Error(
          'Failed to cancel recurring payment. Access Denied: ' +
            response.cancelScheduledTransfer.message
        )
      )
    }
    if (!graphqlType.isRecurringTransfer(response.cancelScheduledTransfer)) {
      return result.failed(new Error('Failed to cancel recurring payment. Unknown type.'))
    }
    return result.ok(true)
  } catch (e) {
    captureException(e)
    consoleLogger.error('Failed to cancel recurring payment.')
    return result.failed(e as Error)
  }
}

export const closePaymentAccount = async (
  externalFinancialBankAccountId: Maybe<string>
): Promise<Result<boolean>> => {
  try {
    if (!externalFinancialBankAccountId) {
      return result.failed(
        new Error('[closePaymentAccount] External financial bank account ID not provided.')
      )
    }
    const variables: ClosePaymentAccountMutationVariables = { id: externalFinancialBankAccountId }
    const response = await appSyncApiQuery<ClosePaymentAccountMutation>({
      query: ClosePaymentAccount,
      variables,
    })
    return result.ok(true)
  } catch (e) {
    captureException(e)
    consoleLogger.error('Failed to cancel recurring payment.')
    return result.failed(e as Error)
  }
}
