import * as Plaid from '@/api/plaid/plaid'
import { BaseError } from '@/lib/domain/error/BaseError'
import { inject, InjectionKey, provide } from 'vue'

type PlaidApi = typeof Plaid

export const PLAID_API_INJECTION_KEY = Symbol('Plaid key') as InjectionKey<PlaidApi>

export function providePlaid(): void {
  provide(PLAID_API_INJECTION_KEY, Plaid)
}

export function injectPlaid(): PlaidApi {
  const injected = inject(PLAID_API_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject Plaid')
  }
  return injected
}
