<template>
  <input class="FormCurrencyInput" ref="inputRef" @blur="$emit('blur')" />
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue'

import InputMask from 'imask'

defineProps<{
  modelValue?: string | number
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
  (event: 'blur'): void
}>()

const inputRef: Ref<HTMLInputElement | null> = ref(null)

onMounted(() => {
  const input = inputRef.value
  const masked = InputMask(input, {
    mask: '$num',
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ',',
        scale: 2,
        signed: false,
        padFractionalZeros: true,
        normalizeZeros: false,
        radix: '.',
      },
    },
  })
  masked.on('accept', () => onAccept(masked.unmaskedValue))
})

const onAccept = (value: string) => {
  emit('update:modelValue', value)
}
</script>
