<template>
  <slot v-if="state.loaded" />
  <LoaderFullscreen v-else />
</template>

<script setup lang="ts">
import { FEATURE_FLAG_PROVIDER_INJECTION_KEY } from '@/provider/featureFlagProvider'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { injectEnv } from '@/provider/envProvider'
import { injectAuthFunctions } from '@/provider/authFunctionsProvider'
import { defaultToEmptyString } from '@/lib/string'
import { onMounted, provide, reactive } from 'vue'
import { LDClient, LDContext, LDOptions } from 'launchdarkly-js-client-sdk'
import { BaseError } from '@/lib/domain/error/BaseError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { injectLogger } from '@/provider/loggerProvider'
import { injectLaunchdarklySDK } from '@/provider/launchDarklyProvider'
import LoaderFullscreen from '@/components/LoaderFullscreen.vue'
import { injectFeatureFlagStore } from '@/provider/featureFlagStoreProvider'
import { injectDom } from '@/provider/domProvider'

useMountedLog('FeatureFlagProvider')

const $env = injectEnv()
const $auth = injectAuthFunctions()
const $logger = injectLogger('sentry')
const $ld = injectLaunchdarklySDK()
const $featureFlagStore = injectFeatureFlagStore()

provide(FEATURE_FLAG_PROVIDER_INJECTION_KEY, $featureFlagStore)

onMounted(() => {
  initFeatureFlag()
})

type CreateFeatureFlagClientOptions = {
  key: string
  userId: string
}

type ProvideFeatureFlagOptions = CreateFeatureFlagClientOptions

type ComponentState = {
  loaded: boolean
}

const state = reactive<ComponentState>({
  loaded: false,
})

async function initFeatureFlag() {
  try {
    const userResult = await $auth.getUser()
    if (!userResult.ok) {
      throw userResult.error
    }
    const options: ProvideFeatureFlagOptions = {
      key: defaultToEmptyString($env.TC_LAUNCHDARKLY_CLIENT_ID),
      userId: userResult.value.sub,
    }
    const client: LDClient = createFeatureFlagClient(options)

    client
      .waitForInitialization()
      .then(() => {
        const flags = client.allFlags()
        $featureFlagStore.updateFeatureFlags(flags)
      })
      .catch(captureLaunchDarklyError)

    client.on('change', () => {
      const flags = client.allFlags()
      $featureFlagStore.updateFeatureFlags(flags)
    })

    client.on('error', captureLaunchDarklyError)
  } catch (e: any) {
    captureLaunchDarklyError(e)
  } finally {
    state.loaded = true
  }
}

function captureLaunchDarklyError(e: Error) {
  const error = new BaseError('Launch Darkly errored')
  error.vendor = ErrorVendor.LaunchDarkly
  error.originalError = e
  $logger.error(error)
}

function createFeatureFlagClient(arg: CreateFeatureFlagClientOptions): LDClient {
  const context = createFeatureFlagClientContext(arg.userId)
  const options: LDOptions = {
    bootstrap: 'localStorage',
  }
  const client: LDClient = $ld.initialize(arg.key, context, options)
  return client
}

const dom = injectDom()

function createFeatureFlagClientContext(userId: string): LDContext {
  const context: LDContext = {
    kind: 'user',
    key: userId,
    url: dom.window.location.toString(),
  }
  return context
}
</script>
