<template>
  <Badge v-if="activeCardCount > 0" class="ActiveCardBadge">{{ activeCardCount }}</Badge>
</template>

<script lang="ts" setup>
import Badge from '@/components/Badge.vue'
import { usePaymentCards } from '@/service/usePaymentCards'

const {
  store: { activeCardCount },
} = usePaymentCards()
</script>
