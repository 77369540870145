<script setup lang="ts">
import { providePaymentCardStore } from '@/provider/paymentCard'
import { provideFinancialInstitutionStore } from '@/provider/financialInstitutionStore'
import { provideTransactionExportStore } from '@/provider/transactionExportStore'

providePaymentCardStore()
provideFinancialInstitutionStore()
provideTransactionExportStore()
</script>

<template>
  <slot />
</template>
