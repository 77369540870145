import {
  AccessDeniedError,
  AccountHolderCardProductApplication,
  ClearingEvent,
  ClientToken,
  DocumentUploadLink,
  ExternalFinancialBankAccount,
  ManualTransferAmount,
  OneTimeAchTransfer,
  PaymentCard,
  RecurringAchTransfer,
  SecureDepositAchTransfer,
  TransactionListAuthorizationEventFragment,
  TransactionListClearingEventFragment,
  TransactionListFinancialAccountFragment,
  UsBusinessAccountHolder,
  UsBusinessAccountHolderSnapshot,
  UserError,
} from '@/generated/graphql'
import {
  ClientToken as AppSyncClientToken,
  SaveInitialDepositId,
  PlaidLinkToken,
  PaymentAccounts,
  PaymentAccountItemWithBalanceFragment,
  PaymentAccountItemFragment,
} from '@/generated/graphqlAppSync'

export const graphqlType = {
  isPaymentCard: (input?: any): input is PaymentCard => {
    return input?.__typename === 'PaymentCard'
  },
  isAccessDeniedError: (input?: any): input is AccessDeniedError => {
    return input?.__typename === 'AccessDeniedError'
  },
  isUserError: (input?: any): input is UserError => {
    return input?.__typename === 'UserError'
  },
  isClientToken: (input?: any): input is ClientToken => {
    return input?.__typename === 'ClientToken'
  },
  isAppSyncClientToken: (input?: any): input is AppSyncClientToken => {
    return input?.__typename === 'ClientToken'
  },
  isDocumentUploadLink: (input?: any): input is DocumentUploadLink => {
    return input?.__typename === 'DocumentUploadLink'
  },
  isUsBusinessAccountHolder: (input?: any): input is UsBusinessAccountHolder => {
    return input?.__typename === 'USBusinessAccountHolder'
  },
  isUsBusinessAccountHolderSnapshot: (input?: any): input is UsBusinessAccountHolderSnapshot => {
    return input?.__typename === 'USBusinessAccountHolderSnapshot'
  },
  isClearingTransactionEvent: (input?: any): input is TransactionListClearingEventFragment =>
    input?.__typename === 'ClearingEvent',
  isClearingFinancialEvent: (input?: any): input is ClearingEvent =>
    input?.__typename === 'ClearingEvent',
  isAuthorizationTransactionEvent: (
    input?: any
  ): input is TransactionListAuthorizationEventFragment =>
    input?.__typename === 'AuthorizationEvent',
  isFinancialAccount: (input?: any): input is TransactionListFinancialAccountFragment => {
    return input?.__typename === 'FinancialAccount'
  },
  isCardProductApplication: (input?: any): input is AccountHolderCardProductApplication => {
    return input?.__typename === 'AccountHolderCardProductApplication'
  },
  isSecureDepositTransfer: (input?: any): input is SecureDepositAchTransfer => {
    return input?.__typename === 'SecureDepositACHTransfer'
  },
  isRecurringTransfer: (input?: any): input is RecurringAchTransfer => {
    return input?.__typename === 'RecurringACHTransfer'
  },
  isOneTimeTransfer: (input?: any): input is OneTimeAchTransfer => {
    return input?.__typename === 'OneTimeAchTransfer'
  },
  isManualTransferAmount: (input?: any): input is ManualTransferAmount => {
    return input?.__typename === 'ManualTransferAmount'
  },
  isExternalBankAccount: (input?: any): input is ExternalFinancialBankAccount => {
    return input?.__typename === 'ExternalFinancialBankAccount'
  },

  isAppSyncPlaidLinkToken: (input?: any): input is PlaidLinkToken => {
    return input?.__typename === 'PlaidLinkToken'
  },
  isAppSyncPaymentAccount: (input?: any): input is PaymentAccountItemFragment => {
    return input?.__typename === 'PaymentAccount'
  },
  isAppSyncPaymentAccountWithBalance: (
    input?: any
  ): input is PaymentAccountItemWithBalanceFragment => {
    return input?.__typename === 'PaymentAccount'
  },
  isAppSyncPaymentAccounts: (input?: any): input is PaymentAccounts => {
    return input?.__typename === 'PaymentAccounts'
  },
  isAppSyncSaveDepositId: (input?: any): input is SaveInitialDepositId => {
    return input?.__typename === 'SaveInitialDepositId'
  },
}
