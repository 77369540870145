import { isNil } from '@/lib/type'

/**
 * Format number to currency string like "$1,000.00"
 * @param value - Number to format
 * @returns string with number formatted in USD
 */
export const formatCurrency = (value?: Maybe<number>): string => {
  try {
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    return value || value === 0 ? formatter.format(value) : ''
  } catch (e) {
    return ''
  }
}

export const div100 = (value?: Maybe<number>) => defaultTo0(value) / 100

export const formatPercentage = (value?: number, fraction?: number): string => {
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: fraction || 0,
    })
    return value || value === 0 ? formatter.format(value) : ''
  } catch (e) {
    return ''
  }
}

export const formatPx = (value?: number): string | undefined => {
  if (!value) {
    return
  }
  return String(value).concat('px')
}

export const formatNumber = (value?: number): string => {
  if (isNil(value)) {
    return '0'
  }
  try {
    const formatter = new Intl.NumberFormat('en-US')
    return formatter.format(value)
  } catch (e) {
    return '0'
  }
}

export const negate = (input: number): number => input * -1

export const padTo2Digits = (num: number): string => num.toString().padStart(2, '0')

export const inc = (num?: Maybe<number>): number => (num ?? 0) + 1

export const defaultTo0 = <T>(input?: Maybe<T>) => input ?? 0

export const isBetween = (value: number, min: number, max: number): boolean =>
  value >= min && value <= max
