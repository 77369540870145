<template>
  <IfElse :if="loaded">
    <slot />
    <LoaderFullscreen />
  </IfElse>
</template>

<script setup lang="ts">
import { onMounted, provide } from 'vue'
import { state as statementsState } from '@/store/statements'

import { useBool } from '@/lib/composable/useBool'
import IfElse from '@/components/IfElse.vue'
import { useMountedLog } from '@/lib/composable/useMountedLog'
import { useAuthStore, loadAppMetadata } from '@/store/auth'
import { usePaymentsStore } from '@/store/payments'
import { STATEMENTS_STATE_INJECT_KEY } from '@/constants'
import { useFinancialInsitutionStore } from '@/store/useFinancialInsitutionStore'
import { usePaymentCards } from '@/service/usePaymentCards'
import LoaderFullscreen from '@/components/LoaderFullscreen.vue'
import { injectAuthFunctions } from './authFunctionsProvider'
import { injectAccountHolderStore } from './accountHolderStoreProvider'

const { accountHolderId, loadUser } = useAuthStore()
const { value: loaded, truthyfy: setLoaded } = useBool(false)
const { loadDepositList } = usePaymentsStore()
const { loadFinancialInstitutionList } = useFinancialInsitutionStore()
const {
  store: { loadNonClosedCardList },
} = usePaymentCards()

useMountedLog('InitialDataProvider')

provide(STATEMENTS_STATE_INJECT_KEY, statementsState)

const $accountHolderStore = injectAccountHolderStore()

const $auth = injectAuthFunctions()

onMounted(async () => {
  if (await $auth.isAuthenticated()) {
    await loadAppMetadata()

    if (!accountHolderId.value) {
      return
    }

    await Promise.all([
      loadUser(),
      $accountHolderStore.loadAccountHolder(accountHolderId.value),
      loadFinancialInstitutionList(accountHolderId.value),
      loadNonClosedCardList(),
      loadDepositList(),
    ])
  }
  setLoaded()
})
</script>
