import { createRouter, createWebHistory } from 'vue-router'
import SettingsCompanyInformationVue from '@/components/settings/SettingsCompanyInformation.vue'
import SettingsPersonalInformation from '@/components/settings/SettingsPersonalInformation.vue'
import SettingsSecurityVue from '@/components/settings/SettingsSecurity.vue'
import AgreementsVue from '@/pages/Agreements.vue'
import Cards from '@/pages/Cards.vue'
import Dashboard from '@/pages/Dashboard.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'
import Login from '@/pages/Login.vue'
import Settings from '@/pages/Settings.vue'
import Support from '@/pages/Support.vue'
import { RouteName } from '@/router/route'
import ApplicationVue from '@/pages/CardApplication.vue'
import ActivateCardVue from '@/pages/ActivateCard.vue'
import OnboardingVue from '@/pages/Onboarding.vue'
import SettingsBankVue from '@/components/settings/SettingsBank.vue'
import PaymentsVue from '@/pages/Payments.vue'
import { tracker } from '@/api/segment/segment'
import StatementsVue from '@/pages/Statements.vue'
import StatementDetailsVue from '@/pages/StatementDetails.vue'
import { withAuthAndOnboardingGuard } from '@/hoc/withAuthAndOnboardingGuard'
import { withAuthGuard } from '@/hoc/withAuthGuard'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // public pages
    {
      path: '/application',
      name: RouteName.Application,
      component: ApplicationVue,
    },
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/login',
      name: RouteName.Login,
      component: Login,
    },
    {
      path: '/forgot-password',
      name: RouteName.ForgotPassword,
      component: ForgotPassword,
    },

    // private pages
    {
      path: '/onboarding',
      name: RouteName.Onboarding,
      component: withAuthGuard(OnboardingVue),
    },
    {
      path: '/dashboard',
      name: RouteName.Dashboard,
      component: withAuthAndOnboardingGuard(Dashboard),
    },
    {
      path: '/activate',
      name: RouteName.ActivateCard,
      component: withAuthGuard(ActivateCardVue),
    },
    {
      path: '/cards',
      name: RouteName.Cards,
      component: withAuthAndOnboardingGuard(Cards),
    },
    {
      path: '/payments',
      name: RouteName.Payments,
      component: withAuthAndOnboardingGuard(PaymentsVue),
    },
    {
      path: '/statements',
      name: RouteName.Statements,
      component: withAuthAndOnboardingGuard(StatementsVue),
    },
    {
      path: '/statement',
      name: RouteName.StatementDetails,
      component: withAuthAndOnboardingGuard(StatementDetailsVue),
    },
    {
      path: '/settings',
      name: RouteName.Settings,
      component: withAuthGuard(Settings),
      redirect: '/settings/company-information',
      children: [
        {
          path: 'company-information',
          name: RouteName.SettingsCompany,
          component: SettingsCompanyInformationVue,
        },
        {
          path: 'personal-information',
          name: RouteName.SettingsPersonal,
          component: SettingsPersonalInformation,
        },
        {
          path: 'security',
          name: RouteName.SettingsSecurity,
          component: SettingsSecurityVue,
        },
        {
          path: 'bank',
          name: RouteName.SettingsBank,
          component: SettingsBankVue,
        },
      ],
    },
    {
      path: '/support',
      name: RouteName.Support,
      component: withAuthGuard(Support),
    },
    {
      path: '/agreements',
      name: RouteName.Agreements,
      component: withAuthGuard(AgreementsVue),
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/dashboard',
    },
  ],
})

export const createPageTitle = (pageName?: string) =>
  [pageName, 'Tillful Card'].filter(Boolean).join(' - ')

const routeToTitleMapper: Record<RouteName, string | undefined> = {
  [RouteName.Login]: 'Login',
  [RouteName.ForgotPassword]: 'Forgot Password',
  [RouteName.Application]: 'Application',
  [RouteName.Onboarding]: 'Onboarding',
  [RouteName.Dashboard]: 'Dashboard',
  [RouteName.ActivateCard]: 'Activate Card',
  [RouteName.Cards]: 'Cards',
  [RouteName.Payments]: 'Payments',
  [RouteName.Statements]: 'Statements',
  [RouteName.StatementDetails]: 'Statement Summary',
  [RouteName.Settings]: 'Settings',
  [RouteName.SettingsCompany]: 'Company Settings',
  [RouteName.SettingsPersonal]: 'Personal Settings',
  [RouteName.SettingsSecurity]: 'Security Settings',
  [RouteName.SettingsBank]: 'Bank Account Settings',
  [RouteName.Support]: 'Support',
  [RouteName.Agreements]: 'Agreements',
}

router.afterEach((to) => {
  const humanisedPageName = routeToTitleMapper[to.name as RouteName]
  document.title = createPageTitle(humanisedPageName)
  tracker.page(humanisedPageName as string)
})

export default router
