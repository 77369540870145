interface IAuthError extends Error {
  originalError?: any
}

class AuthErrorUserNotAvailable extends Error implements IAuthError {
  name = 'AuthErrorUserNotAvailable'
  message = '[TC] User does not exists of user.sub not provided'
  constructor() {
    super()
  }
}

class AuthErrorFailedToGetTokenSilently extends Error {
  name = 'AuthErrorFailedToGetTokenSilently'
  message = '[TC] Failed to get token silently'
  originalError = null
  constructor(e: any) {
    super()
    this.originalError = e
  }
}

class AuthErrorFailedToGetUserInfo extends Error {
  name = 'AuthErrorFailedToGetUserInfo'
  message = '[TC] Failed to get user info'
  constructor() {
    super()
  }
}

class AuthErrorUserInfoOptionsNotProvided extends Error {
  name = 'AuthErrorUserInfoOptionsNotProvided'
  message = '[TC] User sub and/or token was not provided'
  constructor() {
    super()
  }
}

class AuthErrorUserInfoNotReturned extends Error {
  name = 'AuthErrorUserInfoNotReturned'
  message = '[TC] Failed to fetch user info'
  constructor() {
    super()
  }
}

class AuthErrorAccountHolderIdNotAvailable extends Error {
  name = 'AuthErrorAccountHolderIdNotAvailable'
  message = '[TC] Failed to get accountHolderId for current user'
  constructor() {
    super()
  }
}

class AuthErrorInitialDepositIdNotAvailable extends Error {
  name = 'AuthErrorInitialDepositIdNotAvailable'
  message = '[TC] Failed to get initialDepositTransferId for current user'
  constructor() {
    super()
  }
}

export class AuthErrorConfigureClient extends Error {
  name = 'AuthErrorConfigureClient'
  message = '[TC] Failed to configure auth client'
  originalError = null
  constructor(e: any) {
    super()
    this.originalError = e
  }
}

export class AuthErrorClientNotConfigured extends Error {
  name = 'AuthErrorClientNotConfigured'
  message = '[TC] Auth client not configured'
  constructor() {
    super()
  }
}

export class AuthErrorFailedRedirectCallback extends Error implements IAuthError {
  name = 'AuthErrorFailedRedirectCallback'
  message = '[TC] Failed to handle redirect callback'
  originalError = null
  constructor(e?: any) {
    super()
    this.originalError = e
  }
}

export const authError = {
  userNotAvailable: () => new AuthErrorUserNotAvailable(),
  failedToGetTokenSilently: (e?: any) => new AuthErrorFailedToGetTokenSilently(e),
  failedToGetUserInfo: () => new AuthErrorFailedToGetUserInfo(),
  userInfoOptionsNotProvided: () => new AuthErrorUserInfoOptionsNotProvided(),
  userInfoNotReturned: () => new AuthErrorUserInfoNotReturned(),
  accountHolderNotAvailable: () => new AuthErrorAccountHolderIdNotAvailable(),
  initialDepositIdNotAvailable: () => new AuthErrorInitialDepositIdNotAvailable(),
  failedToConfigureClient: (e?: any) => new AuthErrorConfigureClient(e),
  clientNotConfigured: () => new AuthErrorClientNotConfigured(),
  failedRedirectCallback: (e?: any) => new AuthErrorFailedRedirectCallback(e),
}
