export enum RouteName {
  Login = 'Login',
  ForgotPassword = 'ForgotPassword',
  Application = 'Application',
  Onboarding = 'Onboarding',
  Dashboard = 'Dashboard',
  ActivateCard = 'ActivateCard',
  Cards = 'Cards',
  Statements = 'Statements',
  StatementDetails = 'StatementDetails',
  Settings = 'Settings',
  SettingsCompany = 'SettingsCompany',
  SettingsPersonal = 'SettingsPersonal',
  SettingsSecurity = 'SettingsSecurity',
  SettingsBank = 'SettingsBank',
  Payments = 'Payments',
  Support = 'Support',
  Agreements = 'Agreements',
}
