import { DocumentNode, print } from 'graphql'
import { head, length } from '@/lib/list'
import { gt } from '@/lib/logic'
import { getAuthFunctions } from '@/api/auth/authClient'
import { ENV } from '@/lib/env'
import { getAWSClient, getCredentials } from './awsCredentials'
import { formatAwsDateTime, formatAwsDate, now } from '@/lib/date'
import { getAwsAuthorization } from '@/api/graphql/awsAuthorization'
import { captureException } from '@/api/sentry/sentry'
import { ApiError } from '@/lib/domain/error/ApiError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { BaseError } from '@/lib/domain/error/BaseError'
import { defaultToEmptyString } from '@/lib/string'

interface IApiQueryOptios {
  query: string | DocumentNode
  variables?: any
  token?: string
  signal?: Maybe<AbortSignal>
}

interface IAppSyncApiQueryOptions {
  query: string | DocumentNode
  variables?: any
  token?: string
}

const apiQuery = <R = any>({
  query,
  variables = {},
  token,
  signal,
}: IApiQueryOptios): Promise<R> => {
  const authHeader = `Bearer ${token}`
  const queryString = typeof query === 'string' ? query : print(query)
  return fetch(ENV.TC_HIGHNOTE_API_URL, {
    signal,
    method: 'POST',
    headers: {
      'Authorization': authHeader,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query: queryString, variables }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.errors) {
        throw response.errors[0]
      }
      return response
    })
    .then((response) => response.data as R)
}

const appSyncApiQuery = async <R = any>({
  query,
  variables = {},
}: IAppSyncApiQueryOptions): Promise<R> => {
  const auth = getAuthFunctions()
  if (!auth.ok) {
    return Promise.reject(new BaseError('Auth client was not configured'))
  }
  const tokenResult: Result<string> = await auth.value.getTokenSilently()
  if (!tokenResult.ok) {
    return Promise.reject(
      new ApiError('Failed to get token', {
        originalError: tokenResult.error,
        vendor: ErrorVendor.TillCard,
      })
    )
  }
  const url: string = ENV.TC_GRAPHQL_API_URL
  const queryString = typeof query === 'string' ? query : print(query)
  return fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': tokenResult.value,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query: queryString, variables }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.errors && gt(length(response.errors), 0)) {
        const error = head(response.errors)
        captureException(error)
        throw error
      }
      return response
    })
    .then((response) => response.data as R)
}

const graphQLPublicApiQuery = async <R = any>({
  query,
  variables = {},
}: IAppSyncApiQueryOptions): Promise<R> => {
  const client = getAWSClient()
  const credentialsResult = await getCredentials(client)
  if (!credentialsResult.ok) {
    const error = new Error('[TC] Failed to get credentials')
    captureException(error)
    throw error
  }
  const queryString = typeof query === 'string' ? query : print(query)
  const credentials = credentialsResult.value
  const date = now()
  const dateTimeStamp = formatAwsDateTime(date)
  const dateStamp = formatAwsDate(date)
  const algorithm = 'AWS4-HMAC-SHA256'
  const method = 'POST'
  const url = ENV.TC_GRAPHQL_API_URL
  const host = defaultToEmptyString(url).replace('/graphql', '').replace('https://', '')
  const urlPath = encodeURIComponent('/graphql').replace(/%2F/gi, '/')
  const queryParams = ''
  const payload = JSON.stringify({ query: queryString, variables })
  const securityToken = credentials.SessionToken
  const region = ENV.TC_APP_SYNC_REGION
  const service = 'appsync'

  const rawHeaders = {
    'host': host,
    'x-amz-date': dateTimeStamp,
    'x-amz-security-token': securityToken,
  }
  const headers = new Headers()
  headers.append('host', host)
  headers.append('x-amz-date', dateTimeStamp)
  headers.append('x-amz-security-token', securityToken)
  headers.append(
    'Authorization',
    getAwsAuthorization({
      algorithm,
      credentials,
      dateStamp,
      dateTimeStamp,
      serviceInfo: { region, service },
      headers: rawHeaders,
      method,
      urlPath,
      queryParams,
      payload,
    })
  )

  const response = await fetch(url, {
    method,
    headers,
    body: payload,
  })

  const parsed = await response.json()
  if (parsed.errors && gt(length(parsed.errors), 0)) {
    const error = head(parsed.errors)
    captureException(error)
    throw error
  }
  return parsed.data as R
}

export { apiQuery, appSyncApiQuery, graphQLPublicApiQuery }
