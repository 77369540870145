import { API_TOKEN_EXPIRATION_MARGIN } from '@/constants'
import { now } from '@/lib/date'
import { result } from '../result'
import { isNil } from '../type'

export interface IToken {
  value: string
  expirationDateTime: Date
}

export interface IApiToken {
  value: string
  expirationDate: string
}

type TokenName =
  | 'ACCOUNT_HOLDER'
  | 'PAYMENT_CARD'
  | 'APPLICATION_DOCUMENT'
  | 'ONE_TIME_PAYMENT'
  | 'PAYMENT_CARD_DETAILS'
  | 'INITIAL_DEPOSIT'

export const TOKEN_STORE_KEYS: Readonly<Record<TokenName, TokenKey>> = {
  ACCOUNT_HOLDER: Symbol('Account holder'),
  PAYMENT_CARD: Symbol('Payment card management'),
  PAYMENT_CARD_DETAILS: Symbol('Payment card restricted details'),
  APPLICATION_DOCUMENT: Symbol('Document upload session'),
  ONE_TIME_PAYMENT: Symbol('One time payment'),
  INITIAL_DEPOSIT: Symbol('Initial deposit'),
}

export type TokenKey = string | symbol

export type TokenResource = Promise<Result<IApiToken>>

export const isTokenExpired = (token: IToken): boolean =>
  token.expirationDateTime.getTime() - API_TOKEN_EXPIRATION_MARGIN * 1000 < now().getTime()

export const mapApiTokenToToken = (apiToken: IApiToken): IToken => ({
  value: apiToken.value,
  expirationDateTime: new Date(apiToken.expirationDate),
})

interface TokenStore<T> {
  get: (key: TokenKey) => T
  set: (key: TokenKey, value: T) => TokenStore<T>
  delete: (key: TokenKey) => void
  has: (key: TokenKey) => boolean
}

export const createTokenStore = <T>(): TokenStore<T> => new Map()

export const validateToken = (token: Maybe<IToken>): Result<IToken> => {
  if (isNil(token)) {
    return result.failed(new Error(`No token stored`))
  }
  if (isTokenExpired(token)) {
    return result.failed(new Error(`Token expired`))
  }
  return result.ok(token)
}
