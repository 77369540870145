<template>
  <div ref="wrapperRef" class="OnePasswordBtn" />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { onMounted } from '@vue/runtime-core'
import { activateOPButton } from '@1password/save-button'
import { defaultToEmptyString } from '@/lib/string'

const TAG = 'onepassword-save-button'

const props = withDefaults(
  defineProps<{
    value?: string
  }>(),
  {
    value: undefined,
  }
)

const $emit = defineEmits(['onClick'])

const wrapperRef = ref<HTMLElement | null>(null)

onMounted(() => {
  createElement()
  activateOPButton()
})

watch(props, (newValue) => updateValue(defaultToEmptyString(newValue.value)))

const createElement = () => {
  const wrapper = wrapperRef.value
  if (!wrapper) {
    return
  }
  const el = document.createElement(TAG)
  el.setAttribute('value', defaultToEmptyString(props.value))
  el.setAttribute('data-onepassword-type', 'credit-card')
  el.setAttribute('data-theme', 'light')
  el.setAttribute('lang', 'en')
  el.setAttribute('padding', 'none')
  el.addEventListener('click', handleClick)
  wrapper.appendChild(el)
}

const updateValue = (value: string) => {
  const wrapper = wrapperRef.value
  if (!wrapper) {
    return
  }
  const el = wrapper.querySelector(TAG)
  if (!el) {
    return
  }
  el.setAttribute('value', value)
}

const handleClick = (e: any) => {
  e.stopPropagation()
  if (
    e
      .composedPath()
      .some((el: HTMLElement) => el.hasAttribute && el.hasAttribute('data-onepassword-save-button'))
  ) {
    $emit('onClick')
  }
}
</script>

<style></style>
