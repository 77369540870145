import { getAccountHolder } from '@/api/graphql/graphqlAPI'
import { UsBusinessAccountHolder } from '@/generated/graphql'
import { BaseError } from '@/lib/domain/error/BaseError'
import { inject, InjectionKey, provide, reactive } from 'vue'

export type AccountHolderStore = {
  state: AccountHolderStoreState
  loadAccountHolder(id: Maybe<string>): Promise<void>
}

type AccountHolderStoreState = {
  accountHolder: Maybe<UsBusinessAccountHolder>
}

export function createAccountHolderStore(): AccountHolderStore {
  const state = reactive<AccountHolderStoreState>({
    accountHolder: null,
  })
  return {
    state,
    async loadAccountHolder(id) {
      const result = await getAccountHolder(id)
      if (result.ok) {
        state.accountHolder = result.value
      }
    },
  }
}

export const ACCOUNT_HOLDER_STORE_INJECTION_KEY = Symbol(
  'Account holder key'
) as InjectionKey<AccountHolderStore>

export function provideAccountHolderStore() {
  const store = createAccountHolderStore()
  provide(ACCOUNT_HOLDER_STORE_INJECTION_KEY, store)
}

export function injectAccountHolderStore(): AccountHolderStore {
  const injected = inject(ACCOUNT_HOLDER_STORE_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject account holder store')
  }
  return injected
}
