<template>
  <div class="Address fs-14 c-black">
    <div v-for="(item, index) in addressList" :key="index">
      {{ item }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { Address } from '@/generated/graphql'

const props = defineProps<{
  value: Maybe<Partial<Address>>
}>()

const regionAddressText = computed<string>(() =>
  [
    props.value?.locality,
    [props.value?.region, props.value?.postalCode]
      .filter((item): item is string => !!item)
      .join(' '),
  ]
    .filter((item): item is string => !!item)
    .join(', ')
)

const addressList = computed<Maybe<string>[]>(() =>
  [props.value?.streetAddress, props.value?.extendedAddress, regionAddressText.value].filter(
    (item): item is string => !!item
  )
)
</script>
