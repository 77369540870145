<template>
  <button class="Button" :class="className" :disabled="loading">
    <Loader v-if="loading" style="margin: 0 auto" size="medium" :color="loaderColor" />
    <slot v-else />
  </button>
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity'
import Loader from './Loader.vue'

const props = withDefaults(
  defineProps<{
    variant?: 'primary' | 'secondary' | 'danger' | 'secondaryGreen'
    bordered?: boolean
    size?: 'large' | 'medium'
    loading?: boolean
  }>(),
  {
    variant: 'secondary',
    bordered: false,
    size: 'medium',
    loading: false,
  }
)
const className = computed(() => ({
  'Button-primary bg-green1 fw-500 c-white': props.variant === 'primary',
  'Button-secondary bg-grey3 fw-500 c-grey1': props.variant === 'secondary',
  'Button-secondary bg-green21 fw-500 c-green0': props.variant === 'secondaryGreen',
  'Button-danger fw-500 bg-white': props.variant === 'danger',
  'fs-12': props.size === 'medium',
  'Button-large fs-14': props.size === 'large',
  'Button-bordered': props.bordered,
}))
const loaderColor = computed(() => {
  return props.variant === 'danger' ? 'red' : undefined
})
</script>

<style>
.Button {
  height: 36px;
  padding: 0 16px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}
.Button:disabled {
  opacity: 0.5;
}
.Button.Button-bordered {
  background-color: var(--white);
}
.Button.Button-primary.Button-bordered {
  color: var(--c-green1);
  border: 1px solid var(--c-green1);
}
.Button.Button-secondary.Button-bordered {
  border: 1px solid var(--grey);
}
.Button.Button-danger {
  color: var(--c-red2);
  border: 1px solid var(--c-red2);
}
.Button.Button-large {
  height: 48px;
}
.Button:disabled {
  opacity: 0.5;
}
</style>
