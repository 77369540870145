import { isNil } from '@/lib/type'

export enum AppSyncApiErrorType {
  InstitutionNotSupported = 'INSTITUTION_NOT_SUPPORTED',
}

export interface AppSyncApiError extends Error {
  path?: string[]
  data?: any
  errorType?: AppSyncApiErrorType
  errorInfo?: any
  locations?: any[]
}

export const isAppSyncApiError = (error: AppSyncApiError): error is AppSyncApiError => {
  const errorTypeList: AppSyncApiErrorType[] = Object.values(AppSyncApiErrorType)
  return !isNil(error.errorType) && errorTypeList.includes(error.errorType)
}
