import { getUSStateLabelByCode } from '@/api/region/region'
import { graphqlType } from '@/api/graphql/graphqlType'
import {
  Address,
  BusinessStructure,
  FinancialAccount,
  Ledger,
  LedgerName,
  Name,
  NormalBalance,
  Phone,
  UsBusinessAccountHolder,
  UsBusinessUltimateBeneficialOwner,
} from '@/generated/graphql'
import { defaultToEmptyList, head, joinSpace, length, map } from '@/lib/list'
import { capitalizeWord } from '@/lib/string'
import { formatDateOfBirth } from '@/lib/date'
import { formatPercentage } from '@/lib/number'
import { result } from '@/lib/result'
import { mapOldToNewId } from '@/lib/domain/id'

interface IApiPerson {
  name?: Maybe<Name>
  email?: Maybe<string>
  phoneNumbers?: Maybe<Phone[]>
  homeAddress?: Maybe<Address>
  dateOfBirth?: Maybe<string>
  percentageOwnership?: Maybe<number>
}

interface IPhoneInfo {
  label: string
  number: string
}

export interface IPersonalInformation {
  name: string
  email: string
  phoneList: IPhoneInfo[]
  address: Maybe<Partial<Address>>
  dateOfBirth: string
  ownership: string
}

export interface IBusinessInformation {
  legalName: Maybe<string>
  doingAsName: Maybe<string>
  type: Maybe<string>
  phoneList: IPhoneInfo[]
  address: Maybe<Partial<Address>>
  website: Maybe<string>
  owners: IPersonalInformation[]
}

export const accountHolderToPersonInformation = (
  accountHolder?: UsBusinessAccountHolder | unknown | null
): IPersonalInformation => {
  if (
    !graphqlType.isUsBusinessAccountHolder(accountHolder) ||
    !accountHolder.primaryAuthorizedPerson
  ) {
    return {
      name: '-',
      email: '-',
      phoneList: [],
      address: null,
      dateOfBirth: '-',
      ownership: '-',
    }
  }
  const person = accountHolder.primaryAuthorizedPerson
  return personToPersonalInfo(person)
}

export const accountHolderToBusinessInformation = (
  accountHolder?: UsBusinessAccountHolder | unknown | null
): IBusinessInformation => {
  if (!graphqlType.isUsBusinessAccountHolder(accountHolder) || !accountHolder.businessProfile) {
    return {
      legalName: '-',
      doingAsName: '-',
      type: '-',
      phoneList: [],
      address: null,
      website: '-',
      owners: [],
    }
  }
  const businessProfile = accountHolder.businessProfile
  const owners = (businessProfile.ultimateBeneficialOwners ||
    []) as NonNullable<UsBusinessUltimateBeneficialOwner>[]
  const businessAddress = businessProfile.billingAddress
  return {
    legalName: businessProfile.name?.legalBusinessName || '-',
    doingAsName: businessProfile.name?.doingBusinessAsName || '-',
    type: humanizeBusinessType(businessProfile.businessType) || '-',
    phoneList: map(phoneToPhoneInfo, businessProfile.phoneNumbers || []),
    address: businessAddress,
    website: businessProfile.website || '-',
    owners: map(personToPersonalInfo, owners),
  }
}

function phoneToPhoneInfo(phone: Phone): IPhoneInfo {
  return {
    label: capitalizeWord((phone.label || '').toLowerCase()),
    number: `+${phone.countryCode} ${phone.number}`,
  }
}

function humanizeBusinessType(type: Maybe<BusinessStructure>): string {
  if (!type) {
    return ''
  }
  const businessTypeToText: Record<BusinessStructure, string> = {
    [BusinessStructure.Corporation]: 'Corporation',
    [BusinessStructure.Llc]: 'Limited Liability Company',
    [BusinessStructure.NonProfit]: 'Non Profit',
    [BusinessStructure.Partnership]: 'Partnership',
    [BusinessStructure.SoleProprietorship]: 'Sole Proprietorship',
  }
  return businessTypeToText[type]
}

function personToPersonalInfo(person: IApiPerson): IPersonalInformation {
  return {
    name:
      joinSpace([
        person.name?.givenName,
        person.name?.middleName,
        person.name?.familyName,
      ]).trim() || '-',
    email: person.email || '-',
    phoneList: map(phoneToPhoneInfo, person.phoneNumbers || []),
    address: person.homeAddress,
    dateOfBirth: person.dateOfBirth ? formatDateOfBirth(new Date(person.dateOfBirth)) : '-',
    ownership: formatPercentage((person.percentageOwnership || 0) / 100, 2) || '-',
  }
}

export const getAccountHolderAccountNumber = (
  accountHolder: Maybe<Partial<UsBusinessAccountHolder>>
): string => {
  const splitted = (accountHolder?.externalId || '').split('|')
  if (length(splitted) > 1) {
    return splitted[1]
  }
  return splitted[0]
}

export const mapAccountHolderToFinancialAccount = (
  accountHolder: Maybe<Partial<UsBusinessAccountHolder>>
): Maybe<FinancialAccount> =>
  head(defaultToEmptyList(accountHolder?.financialAccounts?.edges))?.node

export const findLedgerByName = (name: LedgerName, ledgers: Ledger[]): Maybe<Ledger> => {
  return ledgers.find((ledger) => ledger.name === name)
}

export const getLedgerRawBalance = (ledger: Maybe<Ledger>): number => {
  return (
    (ledger?.normalBalance === NormalBalance.Credit
      ? ledger.creditBalance?.value
      : ledger?.debitBalance?.value) || 0
  )
}

export const getLedgerBalance = (name: LedgerName, ledgers: Ledger[]): number => {
  const ledger = findLedgerByName(name, ledgers)
  const balance = getLedgerRawBalance(ledger)
  return (balance || 0) / 100
}
