export interface NodePosition {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

export const scrollY = () => window.scrollY

export const copyToClipboard = (value: string) => window.navigator.clipboard.writeText(value)

export const clearQueryParams = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const openUrlInNewTab = (url: string | URL) => window.open(url, '_blank')

export const closeCurrentTab = () => window.close()
