import { inject, InjectionKey, provide } from 'vue'

import { ENV } from '@/lib/env'
import { BaseError } from '@/lib/domain/error/BaseError'

export const ENV_INJECTION_KEY = Symbol('Env injection key') as InjectionKey<typeof ENV>

export const provideEnv = () => {
  provide(ENV_INJECTION_KEY, ENV)
}

export const injectEnv = () => {
  const injected = inject(ENV_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Env key is not provided.')
  }
  return injected
}
