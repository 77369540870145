<template>
  <Modal :opened="opened" @onClose="$emit('onClose')">
    <Loader v-if="loading" style="margin: 0 auto" />
    <div
      class="CardDetailsModal__content d-flex flex-direction-column align-center"
      :class="{ 'CardDetailsModal__content-hidden': loading }"
    >
      <h2 class="fs-20 fw-bold c-black text-center mb-8">Card Details</h2>
      <p class="fs-14 fw-500 c-grey5 mb-24 text-center">
        Tip: Copy your card details by clicking each field.
      </p>
      <div
        class="CardDetailsModal__content__card-container text-center"
        :style="{ backgroundImage: `url(${paymentCardImage})` }"
      >
        <div class="CardDetailsModal__card-number" ref="cardNumberRef"></div>
        <div class="CardDetailsModal__card-cvv" ref="cardCvvRef"></div>
        <div class="CardDetailsModal__card-date" ref="cardDateRef"></div>
        <div class="CardDetailsModal__card-user-name">{{ userName }}</div>
        <div class="CardDetailsModal__card-business-name">{{ businessName }}</div>

        <Icon
          v-if="cardNumberVisible"
          name="eye-off"
          class="c-white"
          @click="handleToggleCardNumber"
        />
        <Icon v-else name="eye" class="c-white" @click="handleToggleCardNumber" />

        <Popup :opened="popupOpened" :target="popupTargetRef">{{ popupContent }}</Popup>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted, onUnmounted, Ref, ref } from 'vue'
import { useBreakpoint } from 'vue-composable'
import {
  CardViewerRenderedFields,
  HighnoteEnvironment,
  OnCopyToClipboardSuccessPayload,
  CardViewerCopyToClipboardError,
} from '@highnoteplatform/card-viewer'
import { captureException } from '@/api/sentry/sentry'

import Modal from '@/components/Modal.vue'
import Loader from '@/components/Loader.vue'
import Icon from '@/components/Icon.vue'
import Popup from '@/components/Popup.vue'

import { usePaymentCards } from '@/service/usePaymentCards'
import { useAccountHolder } from '@/store/accountHolder'
import { ENV } from '@/lib/env'
import { ApplicationEnvironment } from '@/lib/env'
import { useBool } from '@/lib/composable/useBool'
import VirtualCardImage from '@/assets/card-green-empty.svg'
import PhysicalCardImage from '@/assets/card-green-physical-empty.svg'
import { consoleLogger } from '@/lib/logger'
import { generatePaymentCardToken } from '@/api/graphql/clientToken'
import { defaultToEmptyString } from '@/lib/string'
import { IPaymentCard } from '@/lib/domain/types'
import { isPaymentCardPhysical } from '@/lib/domain/paymentCard'
import { notNil } from '@/lib/type'
import { useCopyClipboard } from '@/lib/composable/useCopyClipboard'
import { injectCardViewer } from '@/provider/cardViewerProvider'

const props = withDefaults(
  defineProps<{
    opened?: boolean
    id?: Maybe<string>
  }>(),
  {}
)

defineEmits<{
  (event: 'onClose'): void
}>()

const { renderFields } = injectCardViewer()
const { value: loading, truthyfy: showLoading, falsefy: hideLoading } = useBool()
const { value: cardNumberVisible, toggle: toggleCardNumberVisibility } = useBool()
const { screenNotSmall } = useBreakpoint({ screenNotSmall: 768 })
const {
  store: { cardById },
} = usePaymentCards()
const { id: accountHolderId } = useAccountHolder()

onMounted(async () => {
  if (!props.id) {
    return
  }
  showLoading()
  await showCardDetails(props.id)
  hideLoading()
})

onUnmounted(unmountViewer)

const { businessInformation, personalInformation } = useAccountHolder()
const userName = computed(() => personalInformation.value.name)
const businessName = computed(() => businessInformation.value.legalName)
const card = computed<Maybe<IPaymentCard>>(() => cardById.value[defaultToEmptyString(props.id)])
const paymentCardImage: ComputedRef<string> = computed(() =>
  notNil(card.value) && isPaymentCardPhysical(card.value) ? PhysicalCardImage : VirtualCardImage
)

const cardDetailsViewer: Ref<Maybe<CardViewerRenderedFields>> = ref(null)

const getCardDetailsEnv = (): HighnoteEnvironment => {
  if (
    ENV.TC_ENV === ApplicationEnvironment.Production ||
    ENV.TC_ENV === ApplicationEnvironment.Staging
  ) {
    return 'live'
  }
  return 'test'
}

const showCardDetails = async (id: string): Promise<void> => {
  const tokenResult = await generatePaymentCardToken(accountHolderId.value, id)
  if (!tokenResult.ok) {
    return
  }
  const fontStyle = {
    fontSize: screenNotSmall.value ? '18px' : '13px',
    color: 'white',
    fontFamily: 'monospace',
    cursor: 'pointer',
  }
  cardDetailsViewer.value = await renderFields({
    environment: getCardDetailsEnv(),
    clientToken: tokenResult.value.value,
    paymentCardId: id,

    elements: {
      cardNumber: {
        selector: '.CardDetailsModal__card-number',
        styles: fontStyle,
      },
      cvv: {
        selector: '.CardDetailsModal__card-cvv',
        styles: fontStyle,
      },
      expirationDate: {
        selector: '.CardDetailsModal__card-date',
        styles: fontStyle,
      },
    },

    enableClipboard: true,
    onCopyToClipboardSuccess: handleCopyToClipboard,

    onError: handleError,
  })
}

const handleToggleCardNumber = () => {
  if (!cardDetailsViewer.value) {
    return
  }
  toggleCardNumberVisibility()
  cardDetailsViewer.value.toggleCardNumberMask()
}

const { showCopyClipboard, popupOpened, popupTargetRef, popupContent } = useCopyClipboard()
const cardNumberRef: Ref<HTMLElement | null> = ref(null)
const cardCvvRef: Ref<HTMLElement | null> = ref(null)
const cardDateRef: Ref<HTMLElement | null> = ref(null)

const handleCopyToClipboard = (e: OnCopyToClipboardSuccessPayload) => {
  showCopyClipboard({
    htmlTargetElement: getPopupRef(e.field) as HTMLElement,
  })
}

const getPopupRef = (fieldName: OnCopyToClipboardSuccessPayload['field']) => {
  switch (fieldName) {
    case 'cardNumber':
      return cardNumberRef.value
    case 'cvv':
      return cardCvvRef.value
    case 'expirationDate':
      return cardDateRef.value
    default:
      return null
  }
}

const handleError = (e: any) => {
  consoleLogger.error(e)
  captureException(e)
  if (isCopyToClipboardError(e)) {
    showCopyClipboard({
      htmlTargetElement: getPopupRef(e.field) as HTMLElement,
      textCopyConfirm: 'Failed to copy. Try again.',
    })
  }
}

const isCopyToClipboardError = (error: {
  name: string
}): error is CardViewerCopyToClipboardError => {
  return error.name === 'CopyToClipboardError'
}

function unmountViewer() {
  if (!cardDetailsViewer.value) {
    return
  }
  cardDetailsViewer.value.unmount()
}
</script>

<style>
.CardDetailsModal__card-number,
.CardDetailsModal__card-cvv,
.CardDetailsModal__card-date,
.CardDetailsModal__card-number iframe,
.CardDetailsModal__card-cvv iframe,
.CardDetailsModal__card-date iframe {
  height: 18px;
}

.CardDetailsModal__content__card-container {
  position: relative;
  width: 390px;
  height: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  user-select: none;
}

.CardDetailsModal__content__card-container .Icon.icon-eye,
.CardDetailsModal__content__card-container .Icon.icon-eye-off,
.CardDetailsModal__content__card-container .Icon.icon-copy {
  position: absolute;
  cursor: pointer;
  left: 256px;
  top: 130px;
  font-size: 14px;
}
.CardDetailsModal__content__card-container .Icon.icon-copy {
  left: 286px;
}

.CardDetailsModal__content-hidden {
  visibility: hidden;
  height: 0;
}

.CardDetailsModal__card-number,
.CardDetailsModal__card-cvv,
.CardDetailsModal__card-date {
  position: absolute;
}

.CardDetailsModal__card-number {
  left: 24px;
  top: 127px;
  width: 206px;
}

.CardDetailsModal__card-cvv {
  left: 140px;
  top: 162px;
  width: 38px;
}

.CardDetailsModal__card-date {
  left: 52px;
  top: 162px;
  width: 56px;
}

.CardDetailsModal__card-cvv iframe,
.CardDetailsModal__card-date iframe {
  width: 100%;
}

.CardDetailsModal__card-user-name,
.CardDetailsModal__card-business-name {
  position: absolute;
  text-transform: uppercase;
  color: white;
  font-weight: normal;
  font-size: 14px;
  left: 30px;
}
.CardDetailsModal__card-user-name {
  top: 197px;
}
.CardDetailsModal__card-business-name {
  top: 219px;
}

@media all and (max-width: 768px) {
  .CardDetailsModal__content__card-container {
    width: 300px;
    height: 200px;
  }

  .CardDetailsModal__card-number {
    left: 16px;
    top: 97px;
  }

  .CardDetailsModal__card-cvv {
    left: 106px;
    top: 124px;
  }

  .CardDetailsModal__card-date {
    left: 40px;
    top: 124px;
  }
  .CardDetailsModal__content__card-container .Icon.icon-eye,
  .CardDetailsModal__content__card-container .Icon.icon-eye-off,
  .CardDetailsModal__content__card-container .Icon.icon-copy {
    position: absolute;
    cursor: pointer;
    left: 182px;
    top: 101px;
    font-size: 14px;
  }

  .CardDetailsModal__card-user-name,
  .CardDetailsModal__card-business-name {
    font-size: 12px;
  }

  .CardDetailsModal__card-user-name {
    top: 148px;
    left: 20px;
  }
  .CardDetailsModal__card-business-name {
    top: 167px;
    left: 20px;
  }
}
</style>
