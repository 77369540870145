<template>
  <slot v-if="authHandled" />
  <LoaderFullscreen v-else />
</template>

<script setup lang="ts">
/**
 * Configure auth client so that rest of the app code
 * can use it
 */

import { onMounted } from '@vue/runtime-core'
import { ref, Ref } from '@vue/reactivity'
import { useMountedLog } from '@/lib/composable/useMountedLog'

import LoaderFullscreen from '@/components/LoaderFullscreen.vue'
import { configureAuthClient } from '@/api/auth/authClient'

const authHandled: Ref<boolean> = ref(false)

useMountedLog('AuthorizationProvider')

onMounted(async () => {
  const authResult = await configureAuthClient()
  if (authResult.ok) {
    authHandled.value = true
  }
})
</script>
