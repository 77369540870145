<template>
  <Dropdown :value="value" :items="accountIdList" @onSelect="handleAccountSelect">
    <template v-slot:text="item">
      {{ getValueText(item.value) }}
    </template>
    <template v-slot:item="item">
      <BankAccountMenuItem :value="accountById[item.item]" />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { notNil } from '@/lib/type'
import { indexBy } from '@/lib/list'
import { IPaymentAccount } from '@/lib/domain/types'

import Dropdown from '@/components/Dropdown.vue'
import BankAccountMenuItem from '@/components/payment/BankAccountMenuItem.vue'
import { capitalizeWord, defaultToEmptyString } from '@/lib/string'

interface IProps {
  value?: Maybe<string>
  accountList: IPaymentAccount[]
}
const props = defineProps<IProps>()
const emit = defineEmits<{
  (event: 'onChange', value: string): void
}>()

const accountList = computed(() => props.accountList.filter(notNil))
const accountIdList = computed(() =>
  accountList.value.map((a) => a.externalFinancialBankAccountId).filter(notNil)
)
const accountById = computed<Record<string, IPaymentAccount>>(() =>
  indexBy((item) => item.externalFinancialBankAccountId, accountList.value)
)
const handleAccountSelect = (id: string) => emit('onChange', id)
const getValueText = (value: Maybe<string>) => {
  return value && accountById.value[value]
    ? `${capitalizeWord(defaultToEmptyString(accountById.value[value].accountDetails.type))} - ${
        accountById.value[value].accountDetails.mask
      }`
    : 'Bank Account'
}
</script>
