import { computed, ComputedRef } from 'vue'

import {
  FinancialAccount,
  FinancialAccountStatementFragment,
  LedgerName,
} from '@/generated/graphql'
import { defaultToEmptyList, head } from '@/lib/list'
import { addOneMonth, formatDateTimeIsoString, now, setDate, todayDayOfMonth } from '@/lib/date'
import { eq, gt } from '@/lib/logic'
import {
  accountHolderToBusinessInformation,
  accountHolderToPersonInformation,
  getAccountHolderAccountNumber,
  getLedgerBalance,
} from '@/lib/domain/accountHolder'
import { defaultToIfNotSecuredDepositStatement } from '@/lib/domain/statement'
import { injectAccountHolderStore } from '@/provider/accountHolderStoreProvider'

export const useAccountHolder = () => {
  const { state } = injectAccountHolderStore()

  const financialAccount: ComputedRef<Maybe<FinancialAccount>> = computed(
    () => head(defaultToEmptyList(state.accountHolder?.financialAccounts?.edges))?.node
  )
  const ledgerList = computed(() => defaultToEmptyList(financialAccount.value?.ledgers))
  const financialAccountId: ComputedRef<Maybe<string>> = computed(() => financialAccount.value?.id)
  const autopayDueDay: ComputedRef<number> = computed(
    () => financialAccount.value?.activeBillingCycleConfiguration?.billingCycleStartDayOfMonth ?? 1
  )
  const nextAutopayDueDate = computed<Date>(() => {
    const nextAutopayInNextMonth = autopayDueDay.value < todayDayOfMonth()
    const thisMonthAutopayDate = setDate(autopayDueDay.value, now())
    if (nextAutopayInNextMonth) {
      return addOneMonth(thisMonthAutopayDate)
    }
    return thisMonthAutopayDate
  })
  const statementSnapshot = computed(() => {
    const financialAccountWithStatement =
      financialAccount.value as Maybe<FinancialAccountStatementFragment>
    const snapshot = financialAccountWithStatement?.statementSnapshot
    return snapshot
  })
  const creditLimit = computed(() =>
    getLedgerBalance(LedgerName.AccountHolderSecuredDeposit, ledgerList.value)
  )

  return {
    accountHolder: computed(() => state.accountHolder),
    companyName: computed(() => state.accountHolder?.businessProfile?.name?.legalBusinessName),
    accountNumber: computed(() => getAccountHolderAccountNumber(state.accountHolder)),
    personalInformation: computed(() => accountHolderToPersonInformation(state.accountHolder)),
    businessInformation: computed(() => accountHolderToBusinessInformation(state.accountHolder)),
    id: computed(() => state.accountHolder?.id),
    financialAccountId,
    autopayDueDay,
    currentBalance: computed(() =>
      getLedgerBalance(LedgerName.OutstandingBalancePayable, ledgerList.value)
    ),
    availableBalance: computed(() =>
      getLedgerBalance(LedgerName.AvailableCredit, ledgerList.value)
    ),
    creditLimit,
    creditLimitAvailable: computed(() => gt(creditLimit.value, 0)),
    creditLimitNotAvailable: computed(() => eq(creditLimit.value, 0)),

    statementDueDate: computed(() => {
      const latest = defaultToIfNotSecuredDepositStatement(
        null,
        statementSnapshot.value?.latestClosedStatement
      )
      const current = defaultToIfNotSecuredDepositStatement(
        null,
        statementSnapshot.value?.currentOpenStatement
      )
      const currentDue = new Date(current?.paymentDueOn ?? formatDateTimeIsoString(now()))
      const latestDue = new Date(latest?.paymentDueOn ?? formatDateTimeIsoString(now()))
      if (!current) {
        return nextAutopayDueDate.value
      }
      if (!latest) {
        return currentDue
      }
      if (latestDue < now()) {
        return currentDue
      }
      return latestDue
    }),
    statementDueBalance: computed(() => {
      if (
        statementSnapshot.value?.__typename !==
        'SecuredDepositCommercialCreditCardFinancialAccountStatementSnapshot'
      ) {
        return 0
      }
      return statementSnapshot.value?.currentAmountDue?.value ?? 0
    }),
  }
}
