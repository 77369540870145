import { inject, InjectionKey, provide } from 'vue'

import * as Api from '@/api/graphql/graphqlAPI'
import { BaseError } from '@/lib/domain/error/BaseError'

export type API = typeof Api

export const API_INJECTION_KEY = Symbol('Api injection key') as InjectionKey<API>

export function provideApi() {
  provide(API_INJECTION_KEY, Api)
}

export function injectApi(): API {
  const injected = inject(API_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject Api')
  }
  return injected
}
