import { BaseError } from '@/lib/domain/error/BaseError'
import { defaultToEmptyList, filter, find, indexBy, last, length, map, reject } from '@/lib/list'
import { computed, ComputedRef, inject, InjectionKey, provide, reactive } from 'vue'
import { captureException, withScope } from '@/api/sentry/sentry'
import { isNil, notNil } from '@/lib/type'
import { all, complement } from '@/lib/function'
import { MaintenanceWindowValidationError } from '@/lib/domain/error/MaintenanceWindowValidationError'
import { ErrorVendor } from '@/lib/domain/error/vendor'
import { result } from '@/lib/result'
import { gt, not } from '@/lib/logic'
import { now } from '@/lib/date'

export const FEATURE_FLAG_STORE_INJECTION_KEY = Symbol(
  'Feature flag store key'
) as InjectionKey<FeatureFlagStore>

export function provideFeatureFlagStore(): void {
  const store = createFeatureFlagStore()
  provide(FEATURE_FLAG_STORE_INJECTION_KEY, store)
}

export function injectFeatureFlagStore(): FeatureFlagStore {
  const injected = inject(FEATURE_FLAG_STORE_INJECTION_KEY)
  if (!injected) {
    throw new BaseError('Failed to inject feature flag store')
  }
  return injected
}

export type FeatureFlagState = {
  maintenance: MaintenanceFeatureFlagWindow[]
  currentEpoch: number
}

export type MaintenanceFeatureFlagWindow = {
  type: MaintenanceFeatureFlagWindowType
  message: string
  startsEpoch: number // Unix epoch
  endsEpoch?: number // Unix epoch
}

export enum MaintenanceFeatureFlagWindowType {
  DismissableBanner = 'dismissableBanner',
  NonDismissibleBanner = 'nonDismissibleBanner',
  FullOverlay = 'fullOverlay',
}

export type FeatureFlagStore = {
  activeWindow: ComputedRef<Maybe<MaintenanceFeatureFlagWindow>>
  updateFeatureFlags(value: Record<string, boolean | MaintenanceFeatureFlag>): void
}

type MaintenanceFeatureFlag = {
  windows: MaintenanceFeatureFlagWindow[]
}

export function createFeatureFlagStore(): FeatureFlagStore {
  const state = reactive<FeatureFlagState>({
    maintenance: [],
    currentEpoch: getCurrentEpoch(),
  })

  setInterval(updateCurrentEpoch, 1000)

  function updateCurrentEpoch() {
    state.currentEpoch = getCurrentEpoch()
  }

  function getCurrentEpoch(): number {
    return now().getTime() / 1000
  }

  const store: FeatureFlagStore = {
    activeWindow: computed(() => {
      return activeWindowSelector(state.maintenance, state.currentEpoch)
    }),

    updateFeatureFlags(value) {
      const maintenance = <MaintenanceFeatureFlag>value['maintenance']
      const windows: MaintenanceFeatureFlagWindow[] = defaultToEmptyList(maintenance?.windows)
      const validationResult = validateWindowList(windows)
      if (validationResult.ok) {
        state.maintenance = validationResult.value.valid
        validationResult.value.invalid.forEach(captureInvalidWindow)
      } else {
        captureMisconfiguredWindowList(windows, validationResult.error)
      }
    },
  }

  return store
}

export function activeWindowSelector(
  value: MaintenanceFeatureFlagWindow[],
  epoch: number
): Maybe<MaintenanceFeatureFlagWindow> {
  const predicate = all([startsBeforeEpoch(epoch), endsAfterEpoch(epoch)])
  return last(filter(predicate, value))
}

function startsBeforeEpoch(epoch: number) {
  return function (item: MaintenanceFeatureFlagWindow) {
    return item.startsEpoch < epoch
  }
}

function endsAfterEpoch(epoch: number) {
  return function (item: MaintenanceFeatureFlagWindow) {
    return isNil(item.endsEpoch) || item.endsEpoch > epoch
  }
}

export function validateWindowList(
  value: MaintenanceFeatureFlagWindow[]
): ValidateWindowListReturn {
  const startsEpochListOrdered: number[] = map(mapStartsEpoch, value).sort((a, b) => a - b)
  const indexedByStartsEpoch = indexBy((item) => item.startsEpoch, value)
  const haveStartsEpochDuplicates: boolean = startsEpochListOrdered.some(
    (item, index) => startsEpochListOrdered.indexOf(item) !== index
  )
  const moreThanOneEndsEpochDefined = gt(
    length(filter((item) => notNil(mapEndsEpoch(item)), value)),
    1
  )
  const itemWithEndsEpoch = find((item) => notNil(item.endsEpoch), value)
  const lastStartsEpoch = last(startsEpochListOrdered)
  const lastItemWithEndsEpoch = lastStartsEpoch === itemWithEndsEpoch?.startsEpoch

  if (haveStartsEpochDuplicates) {
    const error = new MaintenanceWindowValidationError(
      'Maintenance windows with same starts epoch found.'
    )
    return result.failed(error)
  }

  if (moreThanOneEndsEpochDefined) {
    const error = new MaintenanceWindowValidationError(
      'More than one maintenance window with ends epoch defined.'
    )
    return result.failed(error)
  }

  if (notNil(itemWithEndsEpoch) && not(lastItemWithEndsEpoch)) {
    const error = new MaintenanceWindowValidationError(
      'Maintenance window with ends epoch defined is not the last window in the list.'
    )
    return result.failed(error)
  }

  const orderedList = map(
    (startsEpoch) => indexedByStartsEpoch[startsEpoch],
    startsEpochListOrdered
  )

  const validWindowList = filter((item) => isMaintenanceWindowValid(item), orderedList)

  const invalidWindowList = reject((item) => isMaintenanceWindowValid(item), orderedList)

  return result.ok({ valid: validWindowList, invalid: invalidWindowList })
}

type ValidateWindowListReturn = Result<
  {
    valid: MaintenanceFeatureFlagWindow[]
    invalid: MaintenanceFeatureFlagWindow[]
  },
  BaseError<unknown>
>

function mapStartsEpoch(value: MaintenanceFeatureFlagWindow): number {
  return value.startsEpoch
}

function mapEndsEpoch(value: MaintenanceFeatureFlagWindow): Maybe<number> {
  return value.endsEpoch
}

function isMaintenanceWindowValid(value: Maybe<MaintenanceFeatureFlagWindow>): boolean {
  return notNil(value) && [value.message, value.startsEpoch, value.type].every(notNil)
}

function captureInvalidWindow(w: Maybe<MaintenanceFeatureFlagWindow>): void {
  const e = new MaintenanceWindowValidationError('Misconfigured maintenance window', {
    payload: w,
    vendor: ErrorVendor.LaunchDarkly,
  })
  withScope((scope) => {
    scope.setTag('vendor', e.vendor)
    scope.setContext('data', {
      payload: e.payload ?? null,
      originalError: {
        name: e.originalError?.name,
        message: e.originalError?.message,
        stack: e.originalError?.stack,
      },
    })
    captureException(e, scope)
  })
}

function captureMisconfiguredWindowList(
  value: MaintenanceFeatureFlagWindow[],
  error?: BaseError<unknown>
): void {
  const e = new MaintenanceWindowValidationError('Misconfigured maintenance window list', {
    payload: value,
    vendor: ErrorVendor.LaunchDarkly,
    originalError: error,
  })
  withScope((scope) => {
    scope.setTag('vendor', e.vendor)
    scope.setContext('data', {
      payload: e.payload ?? null,
      originalError: {
        name: e.originalError?.name,
        message: e.originalError?.message,
        stack: e.originalError?.stack,
      },
    })
    captureException(e, scope)
  })
}
