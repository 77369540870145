import { computed, reactive } from '@vue/reactivity'
import { getAppMetadata } from '@/api/graphql/graphqlAPI'
import { consoleLogger } from '../lib/logger'
import { result } from '../lib/result'
import { setApplicationDataFailed } from './error'
import { AppMetadata } from '@/generated/graphqlAppSync'
import { createAuthFunctions, getAuthClient } from '@/api/auth/authClient'

interface IAuthStateUser {
  email: string
  sub?: string
}

interface IAuthState {
  user?: IAuthStateUser
  metadata: Maybe<AppMetadata>
}

const state: IAuthState = reactive({
  user: undefined,
  metadata: null,
})

const setUser = (value?: IAuthStateUser) => {
  state.user = value
}

export const loadUser = async () => {
  const authClient = getAuthClient()
  if (!authClient) {
    consoleLogger.error('[loadUser] Auth client was not configured')
    return result.failed(new Error('[loadUser] Auth client was not configured'))
  }
  if (!(await isAuthenticated())) {
    consoleLogger.error('[loadUser] User not authenticated')
    return result.failed(new Error('[loadUser] User not authenticated'))
  }
  const authFunctions = createAuthFunctions(authClient)
  const userResult = await authFunctions.getUser()
  if (!userResult.ok) {
    consoleLogger.error('[loadUser] Failed to get current user')
    consoleLogger.error(userResult.error)
    setApplicationDataFailed()
    return result.failed(new Error('[loadUser] Failed to get current user'))
  }
  setUser(userResult.value)
  return result.ok(undefined)
}

export const isAuthenticated = async (): Promise<boolean> => {
  const authClient = getAuthClient()
  return !!authClient && (await authClient.isAuthenticated())
}

export const getStoredUser = () => state.user

// account holder
const setAppMeta = (value: AppMetadata) => {
  state.metadata = value
}

const accountHolderIdGetter = computed(() => state.metadata?.accountHolderId)

export const loadAppMetadata = async (): Promise<Maybe<AppMetadata>> => {
  const metaResult = await getAppMetadata()
  if (metaResult.ok) {
    setAppMeta(metaResult.value)
  } else {
    setApplicationDataFailed()
    consoleLogger.error('Failed to get account holder')
  }
  return state.metadata
}

export const useAuthStore = () => {
  return {
    accountHolderId: accountHolderIdGetter,
    initialDepositId: computed(() => state.metadata?.initialDepositTransferId),
    autopayEnabled: computed(() => state.metadata?.autopayEnabled),
    userEmail: computed(() => state.user?.email),

    loadUser,
    loadAppMetadata,
  }
}
