import { computed, reactive } from 'vue'

import {
  AchTransactionEdgeNode,
  IntegratorInitiatedAchStatus,
  SecureDepositAchTransfer,
} from '@/generated/graphql'
import { saveInitialDepositId } from '@/api/graphql/graphqlAPI'
import { getAuthClient, createAuthFunctions } from '@/api/auth/authClient'
import { useFinancialInsitutionStore } from '@/store/useFinancialInsitutionStore'
import { useAccountHolder } from '@/store/accountHolder'
import { useAuthStore } from '@/store/auth'
import { usePaymentsStore } from '@/store/payments'
import { notNil } from '@/lib/type'
import { eq } from '@/lib/logic'
import { byId } from '@/lib/predicate'
import { injectPaymentCardStore } from '@/provider/paymentCard'
import { result } from '@/lib/result'
import { BaseError } from '@/lib/domain/error/BaseError'

const store = reactive<{
  isOnboardingMenuOpen: boolean
}>({
  isOnboardingMenuOpen: true,
})

export const useOnboardingStore = () => {
  const { linkAvailable } = useFinancialInsitutionStore()
  const { creditLimit, creditLimitNotAvailable, creditLimitAvailable } = useAccountHolder()
  const { initialDepositId } = useAuthStore()
  const { depositList } = usePaymentsStore()
  const paymentCardStore = injectPaymentCardStore()
  if (!paymentCardStore) {
    throw new Error('Payment card store not provided.')
  }

  const deposit = computed(() => depositList.value.find(depositById(initialDepositId.value)))
  const depositStatus = computed(() => deposit.value?.status?.status)
  /**
   * Deposit initiated if its ID stored to app metadata
   */
  const depositInitiated = computed(() => notNil(initialDepositId.value))
  /**
   * All failure status can only be derived if deposit is available in API.
   */
  const depositCanceled = computed(() =>
    eq(depositStatus.value, IntegratorInitiatedAchStatus.Canceled)
  )
  const depositReturned = computed(() =>
    eq(depositStatus.value, IntegratorInitiatedAchStatus.Returned)
  )
  const depositFailed = computed(() => eq(depositStatus.value, IntegratorInitiatedAchStatus.Failed))
  const depositWithFailureStatus = computed(() =>
    [depositCanceled.value, depositReturned.value, depositFailed.value].some(Boolean)
  )
  /**
   * Initial deposit considered pending if
   * - deposit initiated but credit limit not set
   */
  const depositPending = computed(
    () => depositInitiated.value && creditLimitNotAvailable.value && !depositWithFailureStatus.value
  )
  const depositProcessed = computed(() => depositInitiated.value && creditLimitAvailable.value)

  const stepBankAccountCompleted = computed(() => linkAvailable.value)
  const stepDepositCompleted = computed(() => depositProcessed.value)
  const stepCardCompleted = computed(
    () => creditLimit.value && paymentCardStore.mainCardActivated.value
  )
  // const appViewAvailable = computed(() => paymentCardStore.mainCardOrdered.value)
  const appViewAvailable = computed(() => true)
  const onboardingCompleted = computed(
    () => stepBankAccountCompleted.value && stepDepositCompleted.value && stepCardCompleted.value
  )
  return {
    appViewAvailable,

    depositPending,
    depositProcessed,
    depositFailed,
    depositCanceled,
    depositReturned,
    depositProcessedCardNotIssued: computed(
      () => depositProcessed.value && !paymentCardStore.mainCard.value
    ),

    isOnboardingMenuOpen: computed(() => store.isOnboardingMenuOpen),
    isOnboardingMenuAvailable: computed(() => {
      if (onboardingCompleted.value) {
        return false
      }
      return !appViewAvailable.value || store.isOnboardingMenuOpen
    }),
    stepBankAccountCompleted,
    stepDepositCompleted,
    stepCardCompleted,
    onboardingCompleted,

    closeOnboardingMenu: () => {
      store.isOnboardingMenuOpen = false
    },
  }
}

export const saveDepositId = async (value: string): Promise<Result> => {
  const authClient = getAuthClient()
  if (!authClient) {
    return result.failed(new BaseError('Auth client not configured.'))
  }
  const authFunctions = createAuthFunctions(authClient)
  const userResult = await authFunctions.getUser()
  if (!userResult.ok) {
    return userResult
  }
  return await saveInitialDepositId(value, userResult.value.sub)
}

const depositById =
  (id: Maybe<string>) =>
  (item: AchTransactionEdgeNode): item is SecureDepositAchTransfer =>
    byId(id)(item)
