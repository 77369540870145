import { onUnmounted } from '@vue/runtime-core'
import { computed, Ref, ref } from '@vue/reactivity'
import { useBool } from '@/lib/composable/useBool'
import { CardListMenuAction } from '@/lib/domain/types'
import { useLockCardModal } from './useLockCard'
import { useUnlockCardModal } from './useUnlockCard'
import { noop } from '../lib/function'
import { IPaymentCardView } from '@/lib/domain/types'

interface IUsePaymentCardMenuOptions {
  onShowDetails: (cardId?: string) => void
  onSaveInOP: (cardId?: string) => void
  onHideDetails: (cardId?: string) => void
  onMenuToggle: (e: MouseEvent, card: IPaymentCardView) => void
}
export const usePaymentCardMenu = ({
  onShowDetails = noop,
  onSaveInOP = noop,
  onHideDetails = noop,
  onMenuToggle = noop,
}: IUsePaymentCardMenuOptions) => {
  const cardForMenu: Ref<IPaymentCardView | undefined> = ref(undefined)
  const { value: cardMenuOpened, toggle: toggleCardMenu, falsefy } = useBool(false)
  const handleToggleMenu = (e: MouseEvent, card: IPaymentCardView) => {
    onMenuToggle(e, card)
    if (cardMenuOpened.value) {
      cardForMenu.value = undefined
    } else {
      cardForMenu.value = card
    }
    toggleCardMenu()
  }
  const handleCloseMenu = falsefy

  const { open: openLockCardModal, close: closeLockCardModal } = useLockCardModal()
  const { open: openUnlockCardModal, close: closeUnlockCardModal } = useUnlockCardModal()
  const cardMenuActionHandler: Record<CardListMenuAction, () => void> = {
    [CardListMenuAction.Lock]: () => {
      toggleCardMenu()
      openLockCardModal(cardForMenu.value as IPaymentCardView)
    },
    [CardListMenuAction.Unlock]: () => {
      toggleCardMenu()
      openUnlockCardModal(cardForMenu.value as IPaymentCardView)
    },
    [CardListMenuAction.ShowDetails]: () => {
      onShowDetails(cardForMenu.value?.id)
      toggleCardMenu()
    },
    [CardListMenuAction.SaveInOP]: () => {
      onSaveInOP(cardForMenu.value?.id)
      toggleCardMenu()
    },
    [CardListMenuAction.HideDetails]: () => {
      onHideDetails(cardForMenu.value?.id)
      toggleCardMenu()
    },
  }
  const handleCardAction = (action: CardListMenuAction) => cardMenuActionHandler[action]()

  onUnmounted(() => {
    closeLockCardModal()
    closeUnlockCardModal()
  })

  return {
    card: computed(() => cardForMenu.value),
    menuOpened: cardMenuOpened,
    menuToggle: handleToggleMenu,
    menuAction: handleCardAction,
    closeMenu: handleCloseMenu,
  }
}
