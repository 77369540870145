<template>
  <div class="ApplicationDocuments">
    <section class="ApplicationDocuments__head">
      <div>
        <h1 class="fs-36 fw-bold c-black mb-16">Application In Review</h1>
        <p class="fs-16 c-grey2">
          Your application is currently under manual review. Please upload the
          <br />
          required documents below to complete the verification process.
        </p>
      </div>
    </section>

    <section v-if="allSessionsSubmitted" class="ApplicationDocuments__submitted bg-grey2">
      <div>
        <h2 class="fs-24 fw-bold mb-8">All documents submitted</h2>
        <p class="fs-16 c-grey2 mb-24">Please wait for status updates</p>
      </div>
    </section>

    <section v-else class="ApplicationDocuments__forms bg-grey2">
      <div v-if="businessVerification.available" class="ApplicationDocuments__form-container">
        <h2 class="fs-24 fw-bold mb-8">Business identity verification</h2>
        <p class="fs-16 c-grey2 mb-24">
          Please upload <strong>only one</strong> document from the options below. You should get a
          success message when done. If not, please upload another document.
        </p>
        <ApplicationDocumentForm
          :value="businessForm"
          @change="handleBusinessFormChange"
          @upload="handleDocumentUpload('business', $event)"
        />
      </div>

      <div
        v-if="personVerification.available"
        class="bg-grey2 ApplicationDocuments__form-container"
      >
        <h2 class="fs-24 fw-bold mb-8">Personal identity verification</h2>
        <p class="fs-16 c-grey2 mb-24">
          Please upload <strong>only one</strong> document from the options below. You should get a
          success message when done. If not, please upload another document.
        </p>

        <ApplicationDocumentForm
          :value="forms.personalForm"
          header="Primary Authorized Person"
          :subheader="personVerification.name"
          @change="handlePersonalFormChange"
          @upload="handleDocumentUpload('personal', $event)"
        />
      </div>

      <div
        v-if="uboVerificationListAvailable"
        class="bg-grey2 ApplicationDocuments__form-container"
        style="padding-top: 0"
      >
        <div v-for="(id, index) of uboIdList" :key="id">
          <ApplicationDocumentForm
            :value="forms.uboFormById[id]"
            :header="index === 0 ? 'Ultimate Beneficial Owner' : undefined"
            :subheader="uboNameById[id]"
            @change="handleUboFormChange($event, id)"
            @upload="handleDocumentUpload('ubo', $event, id)"
          />
        </div>
      </div>

      <div
        v-if="
          businessVerification.available ||
          personVerification.available ||
          uboVerificationListAvailable
        "
        class="ApplicationDocuments__submit text-right"
      >
        <Button variant="primary" :loading="submitLoading" @click="handleSubmit"
          >Submit documents</Button
        >
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'

import {
  startApplicationDocumentUploadSession,
  uploadApplicationDocument,
} from '@/api/graphql/graphqlAPI'
import Button from '@/components/Button.vue'
import ApplicationDocumentForm from '@/components/cardApplication/ApplicationDocumentForm.vue'
import {
  DocumentCategoryType,
  DocumentType,
  DocumentUploadLink,
  DocumentUploadSessionStatusCode,
  UsAccountHolderApplicationDocumentUploadSession,
} from '@/generated/graphql'
import { useCardApplicationDocuments } from '@/lib/composable/useApplicationDocuments'
import {
  createFormValue,
  DocumentFormPartialValue,
  filterAllowedDocumentTypes,
  IApplicationDocumentFormValue,
  IDocumentFormValue,
  setFormError,
  setPrimaryDocumentList,
  setUploading,
  setUploadingFailed,
  setValue,
  validate,
} from '@/lib/domain/application'
import { defaultToEmptyList } from '@/lib/list'
import { consoleLogger } from '@/lib/logger'
import { result } from '@/lib/result'

const props = defineProps<{ accountHolderId?: Maybe<string> }>()

const forms = reactive<{
  personalForm: IApplicationDocumentFormValue
  businessForm: IApplicationDocumentFormValue
  uboFormById: Record<string, IApplicationDocumentFormValue>
}>({
  personalForm: createFormValue(),
  businessForm: createFormValue(),
  uboFormById: {},
})

const submitLoading = ref<boolean>(false)

const businessForm = computed(() => forms.businessForm)

const uboIdList = computed(() => Object.keys(forms.uboFormById))

const {
  personVerification,
  personVerificationUploadSession,

  businessVerification,
  businessVerificationUploadSession,

  uboVerificationList,
  uboVerificationListAvailable,
  uboNameById,

  allSessionsSubmitted,

  loadCardApplication,
  getLatestUploadedDocumentList,
  submit,
} = useCardApplicationDocuments({
  onCardApplicationLoaded: () => {
    updateFormValue()
  },
})

onMounted(() => {
  updateFormValue()
})

const updateFormValue = () => {
  updatePersonVerificationForm()
  updateBusinessVerificationForm()
  updateUboVerificationFormList()
}

const updatePersonVerificationForm = () => {
  const session = personVerificationUploadSession.value
  const uploadedDocumentList = getLatestUploadedDocumentList(
    session,
    DocumentCategoryType.PrimaryDocumentType
  )
  const documentTypeList = filterAllowedDocumentTypes(
    defaultToEmptyList(session?.primaryDocumentTypes)
  )
  let rawValue = createFormValue()
  rawValue.primary.values = documentTypeList.map((documentType: DocumentType) => {
    const uploaded: boolean = uploadedDocumentList.some((doc: DocumentUploadLink) => {
      return doc.documentType === documentType
    })
    const formValue: IDocumentFormValue = {
      id: documentType,
      type: documentType,
      file: null,
      uploading: false,
      error: null,
      state: uploaded ? 'uploaded' : 'initial',
    }
    return formValue
  })
  rawValue = setPrimaryDocumentList(documentTypeList, rawValue)
  forms.personalForm.primary.documentList = [...documentTypeList]
  forms.personalForm.primary.values = [...rawValue.primary.values]
}

const updateBusinessVerificationForm = () => {
  const session = businessVerificationUploadSession.value
  const uploadedDocumentList = getLatestUploadedDocumentList(
    session,
    DocumentCategoryType.PrimaryDocumentType
  )
  const documentTypeList = filterAllowedDocumentTypes(
    defaultToEmptyList(session?.primaryDocumentTypes)
  )
  let rawValue = createFormValue()
  rawValue.primary.values = documentTypeList.map((documentType: DocumentType) => {
    const uploaded: boolean = uploadedDocumentList.some((doc: DocumentUploadLink) => {
      return doc.documentType === documentType
    })
    const formValue: IDocumentFormValue = {
      id: documentType,
      type: documentType,
      file: null,
      uploading: false,
      error: null,
      state: uploaded ? 'uploaded' : 'initial',
    }
    return formValue
  })
  rawValue = setPrimaryDocumentList(documentTypeList, rawValue)
  forms.businessForm.primary.documentList = [...documentTypeList]
  forms.businessForm.primary.values = [...rawValue.primary.values]
}

const updateUboVerificationFormList = () => {
  const formById: Record<string, IApplicationDocumentFormValue> = uboVerificationList.value.reduce(
    (acum, verification) => {
      const session = verification.uploadSession
      const documentTypeList = filterAllowedDocumentTypes(
        defaultToEmptyList(session?.primaryDocumentTypes)
      )
      const uploadedDocumentList = getLatestUploadedDocumentList(
        session,
        DocumentCategoryType.PrimaryDocumentType
      )
      let rawValue = createFormValue()
      rawValue = setPrimaryDocumentList(documentTypeList, rawValue)
      rawValue.primary.values = documentTypeList.map((documentType: DocumentType) => {
        const uploaded: boolean = uploadedDocumentList.some((doc: DocumentUploadLink) => {
          return doc.documentType === documentType
        })
        const formValue: IDocumentFormValue = {
          id: documentType,
          type: documentType,
          file: null,
          uploading: false,
          error: null,
          state: uploaded ? 'uploaded' : 'initial',
        }
        return formValue
      })
      return { ...acum, [verification.id]: rawValue }
    },
    {}
  )
  forms.uboFormById = {}
  Object.keys(formById).forEach((key) => {
    forms.uboFormById[key] = formById[key]
  })
}

const handleBusinessFormChange = (partialForm: DocumentFormPartialValue) => {
  forms.businessForm = setValue(partialForm, forms.businessForm)
}

const handlePersonalFormChange = (partialForm: DocumentFormPartialValue) => {
  forms.personalForm = setValue(partialForm, forms.personalForm)
}

const handleUboFormChange = (partialForm: DocumentFormPartialValue, id: string) => {
  forms.uboFormById[id] = setValue(partialForm, forms.uboFormById[id])
}

const handleDocumentUpload = async (
  name: 'business' | 'personal' | 'ubo',
  formValue: DocumentFormPartialValue,
  uboId?: string
) => {
  switch (name) {
    case 'business': {
      forms.businessForm = setUploading(formValue.id, forms.businessForm)
      const uploadResult = await uploadSessionDocument(
        businessVerification.value.uploadSession,
        forms.businessForm,
        formValue
      )
      if (!uploadResult.ok) {
        forms.businessForm = setUploadingFailed(formValue.id, forms.businessForm)
      } else {
        await loadCardApplication(props.accountHolderId)
      }
      break
    }
    case 'personal': {
      forms.personalForm = setUploading(formValue.id, forms.personalForm)
      const uploadResult = await uploadSessionDocument(
        personVerification.value.uploadSession,
        forms.personalForm,
        formValue
      )
      if (!uploadResult.ok) {
        forms.personalForm = setUploadingFailed(formValue.id, forms.personalForm)
      } else {
        await loadCardApplication(props.accountHolderId)
      }
      break
    }
    case 'ubo': {
      if (uboId) {
        const verification = uboVerificationList.value.find((v) => v.id === uboId)
        if (!verification) {
          return
        }
        forms.uboFormById[uboId] = setUploading(formValue.id, forms.uboFormById[uboId])
        const uploadResult = await uploadSessionDocument(
          verification.uploadSession,
          forms.uboFormById[uboId],
          formValue
        )
        if (!uploadResult.ok) {
          forms.uboFormById[uboId] = setUploadingFailed(formValue.id, forms.uboFormById[uboId])
        } else {
          await loadCardApplication(props.accountHolderId)
        }
      }
      break
    }
  }
}

const uploadSessionDocument = async (
  session: Maybe<UsAccountHolderApplicationDocumentUploadSession>,
  formValue: IApplicationDocumentFormValue,
  partialFormValue: DocumentFormPartialValue
): Promise<Result> => {
  await startSession(session)
  const uploadResult = await uploadDocument(
    session?.id,
    partialFormValue.type,
    partialFormValue.file
  )
  return uploadResult
}

async function uploadDocument(
  sessionId: Maybe<string>,
  documentType: Maybe<DocumentType>,
  documentFile: Maybe<File>
) {
  if (!sessionId || !documentType || !documentFile) {
    return result.failed(
      new Error(
        '[ApplicationDocuments] Failed to upload document without sessionId or documentType or file provided'
      )
    )
  }
  const uploadResult = await uploadApplicationDocument(sessionId, documentType, documentFile)
  return uploadResult
}

async function startSession(session: Maybe<UsAccountHolderApplicationDocumentUploadSession>) {
  const sessionId = session?.id
  if (!sessionId || isUploadSessionStarted(session)) {
    return
  }
  await startApplicationDocumentUploadSession(sessionId)
}

function isUploadSessionStarted(session: UsAccountHolderApplicationDocumentUploadSession): boolean {
  return session.status !== DocumentUploadSessionStatusCode.Created
}

/**
 * End document upload session for personal and business verification
 */
const handleSubmit = async () => {
  if (!validateForm()) {
    return
  }
  try {
    submitLoading.value = true
    await submit()
    window.location.reload()
  } catch (e) {
    submitLoading.value = false
    // TODO: UX ?
    consoleLogger.error(
      '[ApplicationDocuments] Failed to submit one or more document upload sessions'
    )
    consoleLogger.error(e)
  }
}

const validateForm = (): boolean => {
  forms.personalForm = setFormError(forms.personalForm)
  forms.businessForm = setFormError(forms.businessForm)
  uboIdList.value.forEach((id) => {
    forms.uboFormById = {
      ...forms.uboFormById,
      [id]: setFormError(forms.uboFormById[id]),
    }
  })
  const isValid = {
    personal: !personVerification.value.available || validate(forms.personalForm),
    business: !businessVerification.value.available || validate(forms.businessForm),
    ubo:
      !uboVerificationListAvailable.value ||
      uboIdList.value.every((id) => validate(forms.uboFormById[id])),
  }
  return isValid.personal && isValid.business && isValid.ubo
}
</script>

<style>
.ApplicationDocuments__head,
.ApplicationDocuments__submitted > div,
.ApplicationDocuments__forms > div,
.ApplicationDocuments__form-container,
.ApplicationDocuments__submit {
  padding-left: 211px;
  padding-right: 211px;
}

.ApplicationDocuments__head {
  padding-top: 176px;
  padding-bottom: 106px;
}
.ApplicationDocuments__head h1 {
  line-height: normal;
}
.ApplicationDocuments__head p {
  line-height: 1.5;
}
.ApplicationDocuments__submitted > div {
  padding-top: 56px;
  padding-bottom: 56px;
  margin: 0 auto;
}
.ApplicationDocuments__forms > div {
  padding-top: 56px;
  margin: 0 auto;
}
.ApplicationDocuments__form-container {
  padding-top: 56px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.ApplicationDocuments__submit {
  margin: 0 auto;
  padding-bottom: 32px;
}

@media all and (max-width: 768px) {
  .ApplicationDocuments__head,
  .ApplicationDocuments__submitted > div,
  .ApplicationDocuments__forms > div,
  .ApplicationDocuments__form-container,
  .ApplicationDocuments__submit {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ApplicationDocuments__head {
    padding-top: 92px;
    padding-bottom: 32px;
  }
  .ApplicationDocuments__head h1 {
    font-size: 24px;
  }
  .ApplicationDocuments__head p {
    font-size: 14px;
  }
  .ApplicationDocuments__forms > div {
    padding-top: 24px;
  }
  .ApplicationDocuments__form-container h2 {
    font-size: 20px;
  }
  .ApplicationDocuments__form-container p {
    font-size: 14px;
  }
}
</style>
