import { defaultTo } from '@/lib/function'

export const remove = <K extends string | number | symbol>(key: K, obj: Record<K, unknown>) =>
  Object.entries(obj)
    .filter(([objKey]) => objKey !== key)
    .reduce((acum, [key, obj]) => ({ ...acum, [key]: obj }), {})

export const add = <K extends string | number | symbol, V>(key: K, value: V, obj: Record<K, V>) =>
  Object.assign({}, obj, { [key]: value })

export const getTruthyKeyList = <T extends Record<string | number | symbol, unknown>>(
  obj: T
): string[] =>
  Object.entries(obj)
    .filter(([, val]) => Boolean(val))
    .map(([key]) => key)

export const defaultToEmptyObject = <T>(value: T) => defaultTo({}, value)
