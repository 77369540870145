import { computed, reactive } from 'vue'
import { captureException } from '@/api/sentry/sentry'

import { getPaymentList, getDepositList } from '@/api/graphql/graphqlAPI'
import {
  AchTransactionEdgeNode,
  OneTimeAchTransfer,
  RecurringAchTransfer,
  SecureDepositAchTransfer,
} from '@/generated/graphql'
import { addDays, today } from '@/lib/date'
import { createRange, Range } from '@/lib/range'
import { useAccountHolder } from '@/store/accountHolder'
import { isEmpty } from '@/lib/list'
import { useAuthStore } from '@/store/auth'
import { notNil } from '@/lib/type'

interface IPaginationFilter {
  cursor: Maybe<string>
  hasNext: boolean
}
const createPaginationFilter = (): IPaginationFilter => ({ cursor: null, hasNext: false })

const ITEMS_PER_PAGE = 15

const store = reactive<{
  autopayTransfer: Maybe<RecurringAchTransfer>
  scheduled: (OneTimeAchTransfer | RecurringAchTransfer)[]
  posted: AchTransactionEdgeNode[]
  depositList: SecureDepositAchTransfer[]
  error: Maybe<Error>
  loading: boolean
  loadMoreLoading: boolean
  filter: {
    date: Range<Date>
    posted: IPaginationFilter
    scheduled: IPaginationFilter
  }
}>({
  autopayTransfer: null,
  scheduled: [],
  posted: [],
  depositList: [],
  error: null,
  loading: false,
  loadMoreLoading: false,
  filter: {
    date: createRange(addDays(-30, today()), today()),
    posted: createPaginationFilter(),
    scheduled: createPaginationFilter(),
  },
})

export const usePaymentsStore = () => {
  const { id: accountHolderId } = useAccountHolder()

  const updatePaymentList = async () => {
    store.filter.posted = createPaginationFilter()
    store.filter.scheduled = createPaginationFilter()
    const listResult = await getPaymentList(accountHolderId.value, {
      date: store.filter.date,
      posted: {
        count: ITEMS_PER_PAGE,
        cursor: store.filter.posted.cursor,
      },
      scheduled: {
        count: ITEMS_PER_PAGE,
        cursor: store.filter.scheduled.cursor,
      },
    })
    if (!listResult.ok) {
      store.error = listResult.error
    } else {
      store.scheduled = listResult.value.scheduled
      store.posted = listResult.value.posted
      store.autopayTransfer = listResult.value.autopayTransfer
      store.filter = {
        date: store.filter.date,
        posted: {
          cursor: listResult.value.pagination.postedCursor,
          hasNext: listResult.value.pagination.postedHasNext,
        },
        scheduled: {
          cursor: listResult.value.pagination.scheduledCursor,
          hasNext: listResult.value.pagination.scheduledHasNext,
        },
      }
    }
  }

  const loadMore = async () => {
    store.loadMoreLoading = true
    const listResult = await getPaymentList(accountHolderId.value, {
      date: store.filter.date,
      posted: {
        count: ITEMS_PER_PAGE,
        cursor: store.filter.posted.cursor,
      },
      scheduled: {
        count: ITEMS_PER_PAGE,
        cursor: store.filter.scheduled.cursor,
      },
    })
    if (!listResult.ok) {
      store.error = listResult.error
    } else {
      store.scheduled = [...store.scheduled, ...listResult.value.scheduled]
      store.posted = [...store.posted, ...listResult.value.posted]
      store.filter = {
        date: store.filter.date,
        posted: {
          cursor: listResult.value.pagination.postedCursor,
          hasNext: listResult.value.pagination.postedHasNext,
        },
        scheduled: {
          cursor: listResult.value.pagination.scheduledCursor,
          hasNext: listResult.value.pagination.scheduledHasNext,
        },
      }
    }
    store.loadMoreLoading = false
  }

  const setPaymentDateFilter = (value: Range<Date>) => {
    store.filter.date = value
  }

  const loadDepositList = async () => {
    const { accountHolderId } = useAuthStore()
    const result = await getDepositList(accountHolderId.value)
    if (!result.ok) {
      captureException(result.error)
      return
    }
    store.depositList = result.value
  }

  const paymentList = computed(() => [...store.scheduled, ...store.posted])

  const autopayTransfer = computed(() => store.autopayTransfer)

  return {
    paymentList,
    paymentListPosted: computed(() => store.posted),
    paymentListEmpty: computed(() => isEmpty(paymentList.value)),
    depositList: computed(() => store.depositList),
    loading: computed(() => store.loading),
    loadMoreLoading: computed(() => store.loadMoreLoading),
    filter: computed(() => store.filter),
    loadMoreAvailable: computed(
      () => store.filter.posted.hasNext || store.filter.scheduled.hasNext
    ),
    autopayTransfer,
    autopayBankAccountId: computed<Maybe<string>>(() => {
      const account = autopayTransfer.value?.fromFinancialAccount
      return account?.__typename === 'ExternalFinancialBankAccount' ? account.id : null
    }),
    autopayEnabled: computed(() => notNil(autopayTransfer.value)),
    updatePaymentList,
    loadDepositList,
    loadMore,
    setPaymentDateFilter,
    setLoading() {
      store.loading = true
    },
    resetLoading() {
      store.loading = false
    },
  }
}
