import { computed, Ref, ref } from '@vue/reactivity'

export const useBool = (initialValue?: boolean) => {
  const value: Ref<boolean> = ref(initialValue || false)
  const handleToggle = () => {
    value.value = !value.value
  }
  const handleTruthyfy = () => {
    value.value = true
  }
  const handleFalsefy = () => {
    value.value = false
  }
  return {
    value: computed(() => value.value),
    toggle: handleToggle,
    truthyfy: handleTruthyfy,
    falsefy: handleFalsefy,
  }
}
