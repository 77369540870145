<template>
  <div
    class="PageMenuItemOnboarding pad-24"
    style="border-radius: 4px; border: solid 1px #e5e7eb; position: relative"
  >
    <Icon
      v-if="appViewAvailable"
      name="x"
      class="fs-10"
      style="position: absolute; top: 12px; right: 12px; margin: 0; color: #6b7280"
      @click="handleOnboardingMenuClose"
    />
    <div class="fs-14 fw-600 c-black mb-8">Complete account setup</div>
    <div class="mb-16 fs-12 fw-600 c-green2">
      <span class="mr-16">{{ state.text }} </span>
      <Icon name="arrow-left" style="transform: rotate(180deg); display: inline-block" />
    </div>
    <ProgressBar :progress="state.progress" />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from 'vue'
import { useOnboardingStore } from '@/store/onboarding'
import Icon from '@/components/Icon.vue'
import ProgressBar from '@/components/ProgressBar.vue'

const {
  appViewAvailable,
  closeOnboardingMenu,
  stepBankAccountCompleted,
  depositPending,
  stepDepositCompleted,
  stepCardCompleted,
} = useOnboardingStore()
interface IProgressData {
  progress: number
  text: string
  arrow: boolean
}
const state: ComputedRef<IProgressData> = computed(() => {
  if (stepCardCompleted.value) {
    return {
      progress: 100,
      text: 'You are all set!',
      arrow: false,
    }
  }
  if (stepDepositCompleted.value || depositPending.value) {
    return {
      progress: 70,
      text: 'Activate physical card',
      arrow: true,
    }
  }
  if (stepBankAccountCompleted.value) {
    return {
      progress: 40,
      text: 'Initiate deposit',
      arrow: true,
    }
  }
  return {
    progress: 10,
    text: 'Link bank account',
    arrow: true,
  }
})

const handleOnboardingMenuClose = (e: MouseEvent): void => {
  e.stopPropagation()
  e.preventDefault()
  closeOnboardingMenu()
}
</script>
