<template>
  <div class="Box">
    <slot />
  </div>
</template>

<style>
.Box {
  padding: 32px;
}
</style>
