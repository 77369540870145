<template>
  <div class="CardFilter">
    <Button
      variant="secondary"
      bordered
      class="CardFilter__button d-flex justify-between align-center w-100"
      @click="handleOpenClick"
    >
      <span class="fs-12 fw-500 mr-8">Filter</span>
      <div class="d-flex align-center">
        <Badge class="mr-8 bg-green4">{{ filterCount }}</Badge>
        <Icon class="fs-8" name="chevron-down" />
      </div>
    </Button>
    <Popup :opened="popupOpened" position="bottom-left" :target="containerRef" :showArrow="false">
      <div class="pad-16" style="min-width: 220px">
        <p class="fs-12 fw-600 mb-16">BY STATUS</p>
        <form @submit.prevent="handleFormSubmit">
          <FormCheckboxList :value="checkboxListValue" @onChange="handleCheckboxListChange" />
          <Divider />
          <div class="d-flex justify-between">
            <Button
              class="discard-button CardFilter__cancel c-green1"
              type="button"
              @click="handleFormClear"
              >Reset</Button
            >
            <Button class="CardFilter__submit" type="submit" variant="primary">Apply</Button>
          </div>
        </form>
      </div>
    </Popup>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Badge from '@/components/Badge.vue'
import Popup from '@/components/Popup.vue'
import FormCheckboxList from '@/components/FormCheckboxList.vue'
import Divider from '@/components/Divider.vue'

import { useBool } from '@/lib/composable/useBool'
import { filter, length } from '@/lib/list'
import { IFormCheckboxListValueItem, PaymentCardFilterValue } from '@/lib/domain/types'
import { createPaymentCardFilterValue } from '@/lib/domain/paymentCard'
import { PaymentCardStatus } from '@/generated/graphql'

const props = defineProps<{ value?: Maybe<PaymentCardFilterValue> }>()
const safeValue = computed<PaymentCardFilterValue>(
  () => props.value ?? createPaymentCardFilterValue()
)

const emit = defineEmits<{
  (event: 'onChange', value: PaymentCardFilterValue): void
  (event: 'onSubmit', value: PaymentCardFilterValue): void
  (event: 'onReset'): void
}>()

onMounted(() => {
  containerRef.value = document.querySelector('.CardFilter__button')
})

const containerRef = ref<any | null>(null)
const { value: popupOpened, truthyfy: openPopup, falsefy: closePopup } = useBool()
const handleOpenClick = () => (popupOpened.value ? closePopup() : openPopup())

const valueList = computed<boolean[]>(() => Object.entries(safeValue.value).map(([, val]) => val))
const cardStatusAll = computed<boolean>(() => valueList.value.every(Boolean))
const filterCount = computed<number>(() => length(filter(Boolean, valueList.value)))

const checkboxListValue = computed<IFormCheckboxListValueItem[]>(() => {
  return [
    {
      key: 'all',
      value: cardStatusAll.value,
      label: 'All',
    },
    {
      key: PaymentCardStatus.Active,
      value: safeValue.value.ACTIVE,
      label: 'Active',
    },
    {
      key: PaymentCardStatus.Closed,
      value: safeValue.value.CLOSED,
      label: 'Closed',
    },
    {
      key: PaymentCardStatus.Suspended,
      value: safeValue.value.SUSPENDED,
      label: 'Locked',
    },
  ]
})

const handleCheckboxListChange = (event: IFormCheckboxListValueItem) => {
  handleCardStatusChange(event.key as PaymentCardStatus | 'all', event.value)
}

const handleCardStatusChange = (key: PaymentCardStatus | 'all', value: boolean) => {
  if (key === 'all') {
    handleCardStatusAllChange(value)
  } else {
    emit('onChange', Object.assign({}, safeValue.value, { [key]: value }))
  }
}

const handleFormSubmit = () => {
  emit('onSubmit', safeValue.value)
  closePopup()
}

const handleFormClear = () => emit('onReset')

const handleCardStatusAllChange = (value: boolean) => {
  if (!value) {
    emit('onChange', createPaymentCardFilterValue())
  } else {
    emit('onChange', {
      [PaymentCardStatus.Active]: true,
      [PaymentCardStatus.Suspended]: true,
      [PaymentCardStatus.Closed]: true,
    })
  }
}
</script>

<style>
.CardFilter__cancel {
  padding-left: 0;
  text-decoration: underline;
}
</style>
