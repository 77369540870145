<template>
  <Page name="Support">
    <Box class="Support__content bg-white">
      <h2 class="fs-18 fw-600 c-black">Find help here or contact us</h2>
      <Divider style="margin-bottom: 32px" />

      <div class="Support__find-help mb-32">
        <div class="Support__help-item bg-grey pad-32">
          <img :src="HelpCircle" class="d-block mb-24 fs-32" />
          <div class="fs-18 fw-600 c-black mb-8">FAQ</div>
          <div class="fs-14 fw-500 c-grey2 mb-8">Have a question? We have answers.</div>
          <a href="https://help.tillful.com/card" target="_blank" class="fs-14 fw-500 c-green1"
            >Visit our FAQ page.</a
          >
        </div>

        <div class="Support__help-item bg-grey pad-32">
          <img :src="Twitter" class="d-block mb-24 fs-32" />
          <div class="fs-18 fw-600 c-black mb-8">Twitter</div>
          <div class="fs-14 fw-500 c-grey2 mb-8">Need help? 1 tweet = 1 ticket</div>
          <a href="https://twitter.com/TillfulSupport" target="_blank" class="fs-14 fw-500 c-green1"
            >Tweet @tillfulsupport</a
          >
        </div>

        <div class="Support__help-item bg-grey pad-32">
          <img :src="Mail" class="d-block mb-24 fs-32" />
          <div class="fs-18 fw-600 c-black mb-8">Email</div>
          <div class="fs-14 fw-500 c-grey2 mb-8">280 characters not cutting it?</div>
          <a href="mailto:card@tillful.com" target="_blank" class="fs-14 fw-500 c-green1"
            >Email us</a
          >
        </div>
      </div>

      <h2 class="fs-18 fw-600 c-black">Or submit your request below</h2>
      <Divider />
      <div class="fs-14 fw-500 c-grey2 mb-24">We will do our best to reply in 72 hours or less</div>
      <Message v-if="formLoadingFailed" :dismissable="false" :variant="'error'" class="mb-24">
        Failed to load support form.
      </Message>
      <div class="Support__form-container mb-32" :id="SUPPORT_FORM_CONTAINER_ID"></div>
      <a
        v-if="showCreateRequestLink"
        class="fs-14 fw-500 c-green1 mb-32 d-block"
        style="cursor: pointer"
        @click.prevent="handleCreateRequest"
        >Create a new request</a
      >

      <h2 class="fs-18 fw-600 c-black">Need a human voice? Give us a ring.</h2>
      <Divider />
      <div class="mb-24">
        <div class="fs-14 fw-500 c-black2 mb-8">Phone</div>
        <div class="fs-14 fw-500 c-grey2">
          Toll free (US & Canada):
          <LinkTel value="1-877-845-5385">1-877-TILLFUL(845-5385)</LinkTel>
        </div>
        <div class="fs-14 fw-500 c-grey2">
          International: <LinkTel value="1-650-298-2745">1-650-298-2745</LinkTel>
        </div>
      </div>
      <div class="mb-24">
        <div class="fs-14 fw-500 c-black2 mb-8">When</div>
        <div class="fs-14 fw-500 c-grey2">Monday to Friday, 9:00 AM to 7:00 PM (Central Time)</div>
      </div>
    </Box>
  </Page>
</template>

<script setup lang="ts">
import { watchEffect, onUnmounted } from '@vue/runtime-core'

import Page from '@/components/Page.vue'
import { consoleLogger } from '@/lib/logger'
import Box from '@/components/Box.vue'
import Divider from '@/components/Divider.vue'
import HelpCircle from '@/assets/help-circle.svg'
import Twitter from '@/assets/twitter.svg'
import Mail from '@/assets/mail.svg'
import { useHubSpot } from '@/plugins/hubspot/vue/plugin'
import { useBool } from '@/lib/composable/useBool'
import Message from '@/components/Message.vue'
import { useAccountHolder } from '@/store/accountHolder'
import LinkTel from '@/components/LinkTel.vue'

const SUPPORT_FORM_CONTAINER_ID: string = 'supportFormContainer'
const hubSpot = useHubSpot()

/**
 * Append account holder personal info query string.
 * Used to populate hubspot form submit payload.
 */
const { accountHolder } = useAccountHolder()
const stopWatchEffect = watchEffect(
  async () => {
    const user = accountHolder.value?.primaryAuthorizedPerson
    const name = user?.name
    const email = user?.email ? encodeURIComponent(user.email) : null
    if (!email || !name) {
      return
    }
    const queryString = [
      `?email=${email}`,
      `firstname=${name.givenName}`,
      `lastname=${name.familyName}`,
    ].join('&')
    history.replaceState({}, '', queryString)
    await loadSupportForm()
  },
  { flush: 'post' }
)
onUnmounted(() => {
  stopWatchEffect()
})

const { value: showCreateRequestLink, truthyfy, falsefy } = useBool(false)
const { value: formLoadingFailed, truthyfy: showFormLoadingError } = useBool(false)

const loadSupportForm = async () => {
  const formResult = hubSpot.createForm({
    selector: `#${SUPPORT_FORM_CONTAINER_ID}`,
    onSubmit: handleFormSubmitted,
  })
  if (!formResult.ok) {
    showFormLoadingError()
    consoleLogger.error('Failed to load support form')
    consoleLogger.error(formResult.error)
    return
  }
}

const handleFormSubmitted = truthyfy

const handleCreateRequest = () => {
  falsefy()
  loadSupportForm()
}
</script>

<style>
.Support__find-help {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
}
.Support__help-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Support__form-container .hs-form-field {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
}
.Support__form-container .hs-form-field input,
.Support__form-container .hs-form-field textarea,
.Support__form-container .hs-form-field select {
  width: 270px;
  max-width: 100%;
  margin-top: 8px;
  height: 40px;
  color: var(--c-black2);
  gap: 8px;
  padding: 10px 8px 10px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #d1d5db;
}
.Support__form-container .hs-form-field textarea {
  height: 100px;
  width: 560px;
  max-width: 100%;
  font-family: inherit;
}
.Support__form-container .hs-form-field label {
  color: var(--c-black2);
}
.Support__form-container .hs_error_rollup .hs-error-msgs,
.Support__form-container .hs-form-field .hs-error-msgs {
  list-style: none;
  padding-left: 0;
}
.Support__form-container .hs_error_rollup .hs-error-msgs,
.Support__form-container .hs-form-field .hs-error-msgs .hs-error-msg {
  color: var(--c-red);
}
.Support__form-container .hs_submit input {
  height: 36px;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #27ae60;
  color: white;
  border: 0;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .Support__find-help,
  .Support__contacts {
    grid-template-columns: 1fr;
  }
}
</style>
