import { ErrorVendor } from './vendor'

export class BaseError<T> extends Error implements IBaseError<T> {
  public payload: Maybe<T>
  public originalError: Maybe<Error>
  public vendor: Maybe<ErrorVendor>

  constructor(msg: string) {
    super(msg)
    this.name = 'Error'
  }
}

export interface IBaseError<T> {
  name: string
  message: string
  payload: Maybe<T>
  originalError: Maybe<Error>
  stack?: string
  vendor: Maybe<ErrorVendor>
}
