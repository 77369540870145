<template>
  <div class="DateRange__filter-container">
    <label for="from" class="white-space-no-wrap mr-8">
      From:
      <input
        id="from"
        :value="value.from"
        type="date"
        name="from"
        :max="max"
        @change="handleChange($event, 'from')"
      />
    </label>

    <label for="to" class="white-space-no-wrap">
      To:
      <input
        id="to"
        :value="value.to"
        type="date"
        name="to"
        :max="max"
        @change="handleChange($event, 'to')"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
import { createRange, Range } from '@/lib/range'

const props = defineProps<{
  value: Range<string>
  max?: string
}>()

const emit = defineEmits<{
  (event: 'change', value: Range<string>): void
}>()

const handleChange = (e: Event, name: 'from' | 'to'): void => {
  const inputEl = e.target as HTMLInputElement
  const range = createRange(props.value.from, props.value.to)
  switch (name) {
    case 'from':
      range.from = inputEl.value
      break
    case 'to':
      range.to = inputEl.value
      break
    default:
      break
  }
  emit('change', range)
}
</script>

<style>
input {
  font-family: Inter;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #e5e7eb;
  padding: 0 0 0 16px;
}
input:focus-visible {
  outline-color: var(--c-green1);
  outline-style: solid;
  outline-width: 1px;
}

@media screen and (max-width: 768px) {
  .DateRange {
    flex-direction: column;
    align-items: stretch;
  }
  .DateRange__filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .DateRange__filter-container label {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
</style>
