import { injectPaymentCardStore } from '@/provider/paymentCard'

/**
 * Inject store and extend functionality
 */
export const usePaymentCards = () => {
  const store = injectPaymentCardStore()
  if (!store) {
    throw new Error('Payment card store injected before it is provided')
  }
  return { store }
}
